import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';
import { VersionRepositoryService } from '../services/version-repository/version-repository.service';
import { tap } from 'rxjs/operators';
import { Globals } from 'components/shared/globalData';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {

  constructor(public versionRepository: VersionRepositoryService, public gobalData: Globals) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) { // tslint:disable-line:no-any
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => { // tslint:disable-line:no-any
        if (event.type === HttpEventType.ResponseHeader || event.type === HttpEventType.Response) {
          if (req.url.match('/authentication')) {
            this.gobalData.pssidToken = <string>event.headers.get('pssid');
          } else if (req.url.match('/userdetails')) {
            this.gobalData.pssidToken = <string>event.headers.get('pssid');
          }
          this.versionRepository.set(<string>event.headers.get('app-version'));
        }
      })
    );
  }
}