import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SnackBarService } from './services/snackbar/snackbar.service';

@NgModule({
  imports: [TranslateModule]
})

export class SnackBarModule { static forRoot(): ModuleWithProviders<SnackBarModule> {
  return {
    ngModule: SnackBarModule,
    providers: [ SnackBarService ]
  };
}}