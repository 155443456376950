import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AnalysisIndicatorModel } from 'pages/analysis/models';


@Component({
  selector: 'ic-sidebar-indicator',
  templateUrl: 'sidebar-indicator.component.html'
})
export class SidebarIndicatorComponent implements OnInit {
  @Input() indicator!: AnalysisIndicatorModel;
  @Output() indicatorToggle = new EventEmitter<string[]>();
  @Output() close = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onBackClick() {
    this.triggerClose();
  }

  triggerClose() {
    this.close.emit();
  }

  onToggleClick(indicator: AnalysisIndicatorModel) {
    this.indicatorToggle.emit([indicator.name]);
  }

  hasDisabledTooltip(indicator: AnalysisIndicatorModel) {
    return indicator.disabled && indicator.disabledReason;
  }


  get hasNormalizedBy() {
    return this.indicator.normalizedBy && this.indicator.normalizedBy.length > 0;
  }

}
