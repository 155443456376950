import { of } from 'rxjs';

export class TranslateServiceMock {
  
  public store = { currentLang: 'en'};

  instant(text: string) {
    return text;
  }

  get() {
    return of('');
  }

}
