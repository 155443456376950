<cl-popover-modal
  class="cl-popover-modal--saved-item-options"
  [options]="{
    isContext: true,
    modalWidth: '250px',
    align: 'bottom-left',
    fixedPosition: true,
    modifiers: 'no-arrow'
  }"
  (onOpen)="loadFoldersList()"
>
<label class="cl-form-label">{{ 'my-saved.Save to folder' | translate }}</label>
  <cl-popover-modal-button [buttonClass]="'cl-btn cl-btn--text cl-btn--icon'">
    <span class="cl-btn__text" >{{ selectedFolder && selectedFolder.title }}</span>
    <cl-icon [iconId]="'arrow-down'" [color]="'color-gray20'"></cl-icon>
  </cl-popover-modal-button>

  <cl-popover-modal-content>
    <section>
        <div class="cl-form__field">
            <label for="search" class="visually-hide">Search folders</label>
            <span class="cl-form-text--icon">
              <cl-text-input
                [inputValue]="filter"
                [inputId]="'search'"
                [inputClass]="'cl-form-text cl-form-text--underline'"
                [inputPlaceholder]="'Search folders'"
                (valueChangeDebounce)="onSearch($event)"
              ></cl-text-input>
              <cl-icon [iconId]="'search'" [color]="'color-gray80'" [size]="'18'"></cl-icon>
            </span>
        </div>
    </section>
    <section>
    <ul class="cl-context-menu__list" *ngIf="displayedFolders">
      <li *ngFor="let folder of displayedFolders">
        <button type="button" class="cl-btn cl-btn--unstyled" (click)="onItemClick(folder)">{{ folder.title }}</button>
      </li>
    </ul>
    </section>
    <section>
      <button type="button" class="cl-btn cl-btn--text cl-btn--with-icon" (click)="onCreatenewFolder()">
        <cl-icon [iconId]="'expand-small'"></cl-icon>
        <span class="cl-btn__text">{{ 'my-saved.Create new folder' | translate }}</span>
      </button>
    </section>

  </cl-popover-modal-content>

</cl-popover-modal>
