import { FileSystemService, FileTypes } from 'components/common/services/file-system/file-system.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'components/common/services/local-storage/local-storage.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const baseUrl: string = '/incites-app/';

@Injectable()
export class PdfServerRendererService {

  constructor(
    private http: HttpClient,
    private fsService: FileSystemService,
    private localStorage: LocalStorageService,
  ) {}

  fetch(url: string, fileName: string): Promise<void> {
    return this.loadServerRenderedReport(this.getParams(url)).pipe(
      map((response) => this.fsService.saveFile(response, FileTypes.pdf, fileName))
    ).toPromise();
  }

  private loadServerRenderedReport(params: Object): Observable<string> {
    const data = this.transformRequest({'params': JSON.stringify(params)});
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    return this.http.post<string>(`${baseUrl}explore/export/pdf`, data, httpOptions);
  }

  private transformRequest(obj: {[index: string]: string}) {
    const str: string[] = [];

    for (let p in obj) { // tslint:disable-line:forin
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }

    return str.join('&');
  }

  private getParams(url: string) {
    const exportReadyCookieId = Date.now().toString();
    const localStorageItems = this.localStorage.serialize();

    return {url, exportReadyCookieId, localStorageItems};
  }

}
