import { Pipe, PipeTransform} from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

import { uiModule } from '../../../../angularjs/ui';
import { FormatterService } from 'components/ui/filters/formatter/formatter.service';

@Pipe({name: 'icFormatter'})
export class IcFormatterPipe implements PipeTransform {

  constructor(private formatterService: FormatterService) {}

  // tslint:disable-next-line:no-any
  transform(input: number|string, metadata: any) {
    return this.formatterService.format(input, metadata);
  }

}

// Downgrade this pipe for use in AngularJS module as a service
uiModule.factory('icFormatter', downgradeInjectable(IcFormatterPipe));
