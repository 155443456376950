<cl-overlay-modal
  [options]="{ modalWidth: '1400px', modifiers: 'dark-header' }"
>
  <cl-overlay-modal-content
    [modalTitle]="
      'Edit' + ' ' + filterTitle + ' ' + 'Filter ' + '(' + filterLength + ')'
    "
  >
    <div class="ic_sort__options">
      <button
        type="button"
        class="cl-btn cl-btn--text cl-btn--with-icon"
        (click)="sortFilters(true)"
        style="background-color: black"
      >
        <cl-icon [iconId]="'table-asc'" [color]="'color-white'"></cl-icon>
        <span class="cl-btn__text">sort A - Z </span>
      </button>
      <button
        type="button"
        class="cl-btn cl-btn--text cl-btn--with-icon"
        (click)="sortFilters(false)"
        style="background-color: black"
      >
        <cl-icon [iconId]="'table-desc'" [color]="'color-white'"></cl-icon>
        <span class="cl-btn__text">sort Z - A </span>
      </button>
    </div>
    <div class="ic-edit-filter-multi-select">
      <cl-multi-select
        *ngIf="headerMultiSelect"
        class="ic-analysis__header__search__multi-select ic-zoom2"
        [config]="{
          hideInputLabel: true,
          inputId: 'ic-analysis__header__search__input',
          inputLabel: 'Filter analysis data by entity',
          layout: 'inline'
        }"
        [inputValue]="''"
        [options]="filtersConfig.search.options$ | async"
        [values]="filterValues"
        [placeholderText]="filtersConfig.search.placeholder"
        [filteredDataFound]="filtersConfig.search.filteredDataFound"
        (onInputChange)="
          filtersConfig.search.onInputChange($event, filtersConfig.typeValue)
        "
        (onPastedChange)="
          filtersConfig.search.onPastedChange($event, filtersConfig.typeValue)
        "
        (onValuesChange)="onValuesChange($event)"
      ></cl-multi-select>
      <cl-multi-select
        *ngIf="sidebarMultiSelect"
        [config]="{
          hideInputLabel: true,
          inputLabel: sidebarFilters.searchFilterThis.filter.title,
          inputId: sidebarFilters.searchFilterThis.filter.name,
          placeholder: sidebarFilters.searchFilterThis.filter.placeholder,
          layout: 'filter',
          disabled: sidebarFilters.searchFilterThis.filter.disabled
        }"
        [inputValue]="''"
        (onInputChange)="onSidebarInputChange($event)"
        [options]="searchOptions"
        [values]="filterValues"
        [placeholderText]="sidebarFilters.searchFilterThis.filter.placeholder"
        (onValuesChange)="onValuesChange($event)"
        [entity]="state?.currentEntity.id"
      ></cl-multi-select>
      <cl-multi-select
        *ngIf="reportsMultiSelect"
        [config]="reportsConfig"
        [inputValue]="''"
        (onInputChange)="onReportsInputChange($event.value, $event.type, config)"
        [options]="searchOptions"
        [typeOptions]="getAvailableFilterTypeOptions(reports.reportsData.filters)"
        [values]="filterValues"
        [placeholderText]="reports.reportsData.filters.select.placeholder"
        [popUp]="true"
        (onValuesChange)="onValuesChange($event)"
        (onTypeChange)="onTypeChange($event.type,config)"
      ></cl-multi-select>
    </div>
    <div class="ic-edit-filter-column-div">
      <div class="ic-edit-filter-column">
        <ul class="ic-scrollbar">
          <ng-container>
            <li
              *ngFor="let element of filterValues"
              class="list-item ic-edit-filter-list"
            >
              <span>{{ element.label }}</span>
              <button
                type="button"
                type="button"
                aria-label="Delete button"
                title="Delete button"
                role="button"
                class="cl-btn cl-btn--text cl-btn--with-icon ic-trash delete-btn ic-edit-filter-heading-right"
                (click)="removeValue(element)"
              >
                <span>
                  <cl-icon
                    [iconId]="'trash'"
                    [label]="''"
                    class="ic-trash"
                  ></cl-icon>
                </span>
              </button>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div></div>
    <div class="cl-form__fieldset cl-form__fieldset">
      <div class="cl-applied-filters__btn">
        <button
          type="button"
          class="cl-btn cl-btn--unstyled cl-applied-filters__clear-all"
          (click)="onClearAllFilters()"
        >
          Clear all filters
        </button>
      </div>
      <div class="cl-form__actions">
        <button
          type="button"
          class="cl-btn cl-btn--secondary"
          (click)="onCancel()"
        >
          {{ "popup.Cancel" | translate }}
        </button>
        <button
          type="submit"
          class="cl-btn cl-btn--primary"
          (click)="onUpdateFilter()"
        >
          {{ "analysis.edit Filter.Update Filter" | translate }}
        </button>
      </div>
    </div>
  </cl-overlay-modal-content>
</cl-overlay-modal>
