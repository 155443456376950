import { BrowserDetectionService } from 'components/common/services/browser-detection/browser-detection.service';
import {
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { DiagramPdfGeneratorService } from 'components/common/services/pdf-generator/diagram-pdf-generator.service';
import { DropdownItemModel } from 'components/common/components/dropdown/dropdown.component';
import { EventsTrackerService } from 'components/common/services/events-tracker/events-tracker.service';
import { extend, clone, isUndefined, cloneDeep } from 'lodash';
import { FileSystemService } from 'components/common/services/file-system/file-system.service';
import { LocaleService } from 'components/common/services/locale/locale.service';
import { PdfServerRendererService } from 'components/rest/services/pdf-server-renderer/pdf-server-renderer.service';
import { FolderModel } from '@ic/component-lib/src/components/modules/folders/folders.model';
import { ReportModel, ReportTileParamsModel } from 'components/common/interfaces/report.model';
import { ReportTileModel } from 'components/common/interfaces/report-tile.model';
import { RichTileComponent } from 'components/common/components/rich-tile/rich-tile.component';
import { StateService } from '@uirouter/core';
import { TableListTileComponent } from 'components/common/components/table-list-tile/table-list-tile.component';
import { TileSaveComponent } from '../tile-save/tile-save.component';
import { PngGeneratorService } from 'components/common/services/png-generator/png-generator.service';
import { TileRemoveComponent } from '../tile-remove/tile-remove.component';
import { TileRenameComponent } from 'components/ui/components/tile-rename/tile-rename.component';
import {catchError, map, shareReplay, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { SortableTableColumnModel, SortableTableOptionsModel } from '@ic/component-lib/src/components/modules/sortable-table/models';
import { AnalysisTableRowFactory } from 'components/common/factories/analysis-table-row/analysis-table-row.factory';
import { DataRepositoryService } from 'components/rest/services/data-repository/data-repository.service';
import {utilities} from 'angularjs/utilities/utilities';
import {TileService} from 'components/rest/services/tile/tile.service';
import {SettingsRepositoryService} from 'components/rest/services/settings-repository/settings-repository.service';
import {TranslateService} from '@ngx-translate/core';
import {RequestParamsModel} from 'pages/analysis/models/analysis-state.model';
import {MetadataRepositoryService} from 'components/rest/services/metadata-repository/metadata-repository.service';
import {AnalysisAppliedFiltersModel, AnalysisFilterModel, AnalysisIndicatorModel, AnalysisItemModel} from 'pages/analysis/models';
import { AnalysisTableColumnFactory } from 'components/common/factories/analysis-table-column/analysis-table-column.factory';
import { ExploreTileResponseItemModel } from 'components/common/interfaces/exploretile.response.model';
@Component({
  selector: 'ic-tile',
  templateUrl: './tile.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TileComponent implements OnInit, OnChanges {

  @Input() folders: FolderModel[] = [];
  @Input() report: Partial<ReportModel> = {};
  @Input() tile: ReportTileModel = <ReportTileModel>{};
  @Input() tileIndex!: number;
  @Input() tileState: string = 'unselected';
  @Input() smartReportFilters: AnalysisAppliedFiltersModel;
  @Input() esci: string;
  @Input() dataUpdateInfo: string;
  @Output() onUpdate = new EventEmitter();
  @ViewChild(RichTileComponent, { static: false }) richTile!: RichTileComponent;
  @ViewChild(TableListTileComponent, { static: true }) tableListTile!: TableListTileComponent;
  @ViewChild(TileSaveComponent, { static: true }) tileSaveComponent!: TileSaveComponent;
  @ViewChild(TileRemoveComponent, { static: true }) tileRemoveComponent!: TileRemoveComponent;
  @ViewChild(TileRenameComponent, { static: true }) tileRenameComponent!: TileRenameComponent;
  sortableTableOptions: Partial<SortableTableOptionsModel> = {
    enableTextHighlight: true,
    freezeFirstColumn: true,
    freezeFirstRow: true,
    freezeSecondRow: true,
    freezeSecondColumn: true,
    rowDetailsButton: true,
    selectableRows: true,
    sortRowsExternally: true,
    disableDragAndDrop: true,
    dragableGroupName: 'entity-table'
  };

  public dropdownMenu!: DropdownItemModel[];
  public isDownloading: boolean = false;
  public tileClass: string = '';
  public tileData$!: Observable<ReportTileModel>;
  columns: SortableTableColumnModel[];
  private rootState: string = 'app';
  options: {[key: string]: string | number} =  {
    initialValue: 0,
    inputId: 'ic-analysis-visual-rows',
    inputLabel: this.translate.instant('analysis.visual.Show top'),
    max: 25,
    min: 1
  };
  // tslint:disable-next-line:no-any
  defaultTileFilters: Observable<{[key: string]: any }[]>;
  // tslint:disable-next-line:no-any
  env: { [key: string]: any; };
  public initialValue: string = '';
  searchFilter?: AnalysisFilterModel;
  isChecked: boolean = false;
  loadingErrorMessage: string = '';

  constructor(
    private analytics: EventsTrackerService,
    private browserDetectionService: BrowserDetectionService,
    private cd: ChangeDetectorRef,
    private eventsTracker: EventsTrackerService,
    private fsService: FileSystemService,
    private locale: LocaleService,
    private pdfGenerator: DiagramPdfGeneratorService,
    private pdfServerRenderer: PdfServerRendererService,
    private pngGenerator: PngGeneratorService,
    private stateService: StateService,
    private environmentService: EnvironmentService,
    private analysisTableRowFactory: AnalysisTableRowFactory,
    private dataRepositoryService: DataRepositoryService,
    private tileService: TileService,
    private settingsRepositoryService: SettingsRepositoryService,
    private translate: TranslateService,
    private metadataRepository: MetadataRepositoryService,
    private analysisTableColumnFactory: AnalysisTableColumnFactory,
  ) {
    this.env = this.environmentService.getEnvironment();
  }

  async ngOnInit() {
    this.tileClass = this.getTileClass();
    this.tileData$ = this.loadValues() as unknown as Observable<ReportTileModel>;
    if (this.tileClass !== 'alma-analysis-list') {
      this.dropdownMenu = this.generateDropdownMenu();
    }
    if (!this.report.system && this.tile.tileGroup !== 'domino' && this.tile.tileGroup !== 'table-list') {
      this.getDefaultFilters();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.folders && changes.folders.currentValue) {
      this.folders = changes.folders.currentValue;
    }
    if (changes.smartReportFilters && changes.smartReportFilters.currentValue && !changes.smartReportFilters.firstChange
      && !this.report.system && this.tile.tileGroup !== 'domino' && this.tile.tileGroup !== 'table-list' && this.isChecked) {
      if (Object.keys(changes.smartReportFilters.currentValue).length === 0) {
        this.tile.params = this.tileService.getTileParamsById(this.tile.id);
        let requestParams = this.getRequestParams();
        this.refreshTile(requestParams);
        this.getDefaultFilters();
      } else {
        this.applySmartReportFilters(changes.smartReportFilters.currentValue);
      }
    }

    if (this.isChecked && changes.esci) {
      this.applySmartReportESCI(changes.esci.currentValue);
    }

    if (changes.tileState && changes.tileState.currentValue && changes.tileState.currentValue === 'unselected') {
      if (this.isChecked) {
        this.isChecked = false;
      }
    }

    if (changes.tileState && changes.tileState.currentValue && changes.tileState.currentValue === 'locked') {
      if (this.isChecked) {
        this.tileService.backupTileParams({ id : this.tile.id, params : this.tile.params});
      }
    }
  }

  applySmartReportFilters(smartReportFilters: AnalysisAppliedFiltersModel) {
    let params = this.getRequestParams();

    let incomingFilters = Object.keys(smartReportFilters).reduce((acc, key) => {
      let isOrNot = Object.keys(smartReportFilters[key])[0];
      let hasFilterValue = Array.isArray(smartReportFilters[key][isOrNot]) ? (smartReportFilters[key][isOrNot]! as string[] | number[]).length > 0
        : smartReportFilters[key][isOrNot];
      if (hasFilterValue) {
        acc[key] = smartReportFilters[key];
      } else {
        delete params.request.filters[key];
      }

      return acc;
    }, {} as AnalysisAppliedFiltersModel);
    params.request.filters = {...params.request.filters, ...incomingFilters};
    this.refreshTile(params);
    this.getDefaultFilters();
  }

  applySmartReportESCI(esci: string) {
    let params = this.getRequestParams();
    if (esci !== '') {
      params.queryDataCollection = esci;
    } else {
      delete params.queryDataCollection;
    }

    this.refreshTile(params);
  }

  refreshTile(params: RequestParamsModel) {
    this.tile.params = JSON.stringify(params);
    this.tile.data$ = this.tileService.getValues(this.tile);
    this.tileData$ = this.loadValues() as unknown as Observable<ReportTileModel>;
  }

  getDefaultFilters() {
    if (Object.keys(this.getRequestParams()).length > 0) {
      // @ts-ignore
      let params = this.getRequestParams() || JSON.parse(this.tile.request);
      this.defaultTileFilters = this.metadataRepository.getFiltersRx(params.datasetId, params.entity, this.tile.id)
        .pipe(map((data) => {
            const appliedFilters = params.request.filters;
            if (data.period) {
              data.filters.push(data.period);
            }
            let filters =  data.filters || [];
            const filterNames = Object.keys(appliedFilters);
            return filters.reduce((acc, filter) => {
              if (filterNames.includes(filter.name)) {
                let isOrNot = Object.keys(appliedFilters[filter.name])[0];
                let appliedValues =  {
                  [isOrNot] : appliedFilters[filter.name][isOrNot]
                };
                acc.push({ name: filter.name, title: filter.title, appliedValues, disableRemove : true });
              }
              return acc;
              // tslint:disable-next-line:no-any
            }, [] as {[key: string]: any }[]);
          }),
          shareReplay());
    }
  }

  viewData() {

    switch (this.tile.tileGroup) {
      case 'explore':
        this.exploreTileDrilldown();
        break;
      case 'rich':
      case 'table-list':
      default:
        this.richTileDrilldown();
    }
  }

  saveTo() {
    this.analytics.trackEvent('Report', <string>this.tile.title, `${this.report.title} Save a tile copy`);
    this.tileSaveComponent.openModal();
  }

  downloadCsv() {
    // TODO: Implement download Csv function
  }

  downloadPdf() {
    const tileDataPromise = this.tileData$.toPromise();
    const tileBase64Promise = this.richTile.toPngBase64(5);
    const downloadPdfPromise = Promise.all([tileDataPromise, tileBase64Promise])
      .then((data) => this.pdfGenerator.downloadTilePdf(data[0], data[1], this.dataUpdateInfo));

    return this.showProgress(downloadPdfPromise);
  }

  downloadServerRenderedPdf() {
    const fileName: string = this.tile.title as string;
    const downloadSRPdfPromise = this.pdfServerRenderer.fetch(this.getExportUrl(), fileName);

    return this.showProgress(downloadSRPdfPromise);
  }

  rename() {
    this.tileRenameComponent.openModal();
  }

  removeFromReport() {
    this.tileRemoveComponent.openModal();
  }

  private getExportUrl() {
    const params = JSON.parse(this.tile.params);
    const url = this.extendUrlWithLocale(this.stateService.href('app.explore',
      {
        datasetId: params.datasetId,
        entity: params.entity,
        report: this.tile.reportId,
        tile: this.tile.id,
        queryDataCollection: params.queryDataCollection,
        filterAggId: this.tile.filterAggId
      },
      { inherit: false, absolute: true }
    ));

    return url;
  }

  private extendUrlWithLocale(url: string): string {
    if (this.locale.id !== 'en') {
      const parts = url.split('#');
      url = `${parts[0]}${this.locale.id}/#${parts[1]}`;
    }

    return url;
  }

  downloadPng() {
    const fileName = this.tile.title as string;
    const tileDataPromise = this.tileData$.toPromise();
    const tileBase64Promise = this.richTile.toPngBase64(1.8);
    const downloadPngPromise = Promise.all([tileDataPromise, tileBase64Promise])
      .then((data) => this.pdfGenerator.prepareDiagram(data[0], data[1]))
      .then((details) => this.pngGenerator.canvasGraph(details, this.fsService.stringToFilename(fileName), this.dataUpdateInfo));

    return this.showProgress(downloadPngPromise);
  }

  getLoaderString() {
    return this.isDownloading ? 'report.tile.Downloading' : 'report.tile.Loading';
  }

  getRequestParams() {
    let requestParam = {} as RequestParamsModel;
    try {
      requestParam = JSON.parse(this.tile.params);
    } catch (error) {
      console.log(error);
    }

    return requestParam;
  }

  private loadValues() {
    this.tileService.tileLoadFinished.emit(false);
    let that = this;
    this.loadingErrorMessage = '';
    const tileData$ = this.tile.data$!.pipe(
      catchError(_e => {
        this.loadingErrorMessage = 'notifications.errors.common';
        return of({ serverError: 'notifications.errors.common' });
      }),
      map((data) => extend(clone(this.tile), data)),
      map((data) => {
        let nonSubscribedPublications = 'Top 100 recommended publication sources';
        let subscribedPublications = 'Subscribed Publication Sources';
        if (this.report.reportType === 'almaTabRpt' || data.title === nonSubscribedPublications || data.title === subscribedPublications) {

          if (Object.keys(this.getRequestParams()).length > 0) {
            let requestParam = this.getRequestParams();
            if (this.tile.tileType === 'tree map') {
              // @ts-ignore
              that.options.initialValue = requestParam.request.take;
              that.options = cloneDeep(that.options);
            }
            if (that.tile.tileType === 'alma-analysis-list') {
              if (!this.columns) {
                try {
                  let requiredColumns = JSON.parse(this.tile.vis).series.value.map((columnName: string) => columnName.toLowerCase());
                  let indicators = requiredColumns.map((column: string) => data.indicators.find(indicator => indicator.name.toLowerCase() === column.toLowerCase()));
                  let { sortBy, sortOrder } = this.tileService.workaroundForBadParams(
                    requestParam as unknown as ReportTileParamsModel
                  ).request;
                  this.columns = this.analysisTableColumnFactory.createColumns(indicators as unknown as AnalysisIndicatorModel[], sortBy, sortOrder)
                    .map(column => {
                      delete column.menu;
                      return column;
                    });
                  this.columns.find(column => column.id === 'timesCited')!.title = this.translate.instant('almaReport.Citations to the journal');
                } catch (error) {
                  console.log(error);
                }

              }
              let value = data.value.map((d: ExploreTileResponseItemModel) => {
                // @ts-ignore
                d['isOAFlag'] = d['isOAFlag'] ? 'YES' : 'NO';
                return d;
              });
              // @ts-ignore
              return that.analysisTableRowFactory.createRows(value, this.columns, requestParam.entity, false, true);
            }
          }
        }
        return data;
      }),
      tap(() => {
        if (this.tile.id === this.report.tiles![this.report.tiles!.length - 1].id) {
          this.tileService.tileLoadFinished.emit(true);
        }
      }),
      shareReplay()
    );

    if (isUndefined(this.tileIndex)) {
      console.log('@Input() tileIndex is undefined!!!!');
    }
    return tileData$;
  }

  private getTileClass(): string {
    switch (this.tile.tileGroup) {
      case 'domino':
        return 'domino';
      case 'rich':
      case 'explore':
        return this.tile.tileType === 'alma-analysis-list' ? 'alma-analysis-list' : 'rich';
      case 'table-list':
        return 'table-list';
      default:
        return 'retired';
    }
  }

  private generateDropdownMenu(): DropdownItemModel[] {
    const downloadImage = {
      name: 'downloadPng',
      title: 'report.tile.Download as image',
      icon: 'download',
      execute: this.downloadPng.bind(this)
    };
    const downloadPdf = {
      name: 'downloadPdf',
      title: 'report.tile.Download as Pdf',
      icon: 'download',
      execute: this.downloadPdf.bind(this)
    };
    const downloadServerRenderedPdf = {
      name: 'downloadServerRenderedPdf',
      title: 'report.tile.Download as Pdf',
      icon: 'download',
      execute: this.downloadServerRenderedPdf.bind(this)
    };
    // TODO: Implement download Csv function
    /* const downloadCsv = {
      name: 'downloadCsv',
      title: 'report.tile.Download as Csv',
      icon: 'download',
      execute: this.downloadCsv.bind(this)
    }; */
    const saveTo = {
      name: 'saveto',
      title: 'report.tile.Save to report',
      icon: 'save',
      execute: this.saveTo.bind(this)
    };
    const viewData = {
      name: 'viewdata',
      title: 'report.tile.Go to analysis',
      icon: 'external-link',
      execute: this.viewData.bind(this)
    };
    const rename = {
      name: 'rename',
      title: 'report.tile.Rename',
      icon: 'pencil',
      execute: this.rename.bind(this)
    };
    const removeFromReport = {
      name: 'removefromreport',
      title: 'report.tile.Remove from report',
      icon: 'trash',
      execute: this.removeFromReport.bind(this)
    };

    const isAmchartsEnabled = (this.environmentService.getEnvironment().feature.treemapPOC &&
      ['tree map', 'treemap amCharts'].includes(this.tile.tileType)) ||
      (this.environmentService.getEnvironment().feature.barAmchartsPOC &&
        ['bar chart', 'bar amCharts'].includes(JSON.parse(this.tile.vis).type)) ||
      (this.environmentService.getEnvironment().feature.heatmapAmchartsPOC &&
        ['heat map', 'heatmap amCharts'].includes(this.tile.tileType)) ||
      (this.environmentService.getEnvironment().feature.amchartsPOC &&
        ['map chart', 'map amCharts'].includes(this.tile.tileType)) ||
      (['multiIndicatorLine amCharts', 'multiIndicatorLine chart'].includes(JSON.parse(this.tile.vis).type) &&
        this.environmentService.getEnvironment().feature.multiIndicatorTrendGraphPOC) ||
      (this.environmentService.getEnvironment().feature.trendGraphPOC &&
        ['line chart', 'line amCharts'].includes(JSON.parse(this.tile.vis).type)) ||
      (this.environmentService.getEnvironment().feature.fiveYearTrendGraphPOC &&
        ['period chart', 'period amCharts'].includes(JSON.parse(this.tile.vis).type)) || (this.environmentService.getEnvironment().feature.wordCloudPOC &&
          JSON.parse(this.tile.vis).type === 'wordCloud graph') ||
      (this.environmentService.getEnvironment().feature.pieAmchartsPOC &&
        ['pie', 'pie amCharts'].includes(JSON.parse(this.tile.vis).type));
    const menu = [];

    if (this.tileClass !== 'retired') menu.push(saveTo);

    if (this.report.system) {
      if (this.tile.tileGroup !== 'table-list' && this.tile.tileGroup !== 'domino' && !isAmchartsEnabled) {
        if (!this.browserDetectionService.isIE11()) {
          menu.push(downloadImage);
          menu.push(downloadPdf);
        } else {
          if (this.tile.tileGroup !== 'rich') {
            menu.push(downloadServerRenderedPdf);
          }
        }
      }
    } else {
      if (this.report.isOwners!()) {
        if (this.tileClass !== 'retired') menu.push(rename);
        menu.push(removeFromReport);
      }
    }

    if (this.tile.tileGroup !== 'domino') {
      menu.push(viewData);
    }

    return menu;
  }

  private exploreTileDrilldown(): void {
    const params = JSON.parse(this.tile.params);
    this.eventsTracker.trackEvent('Report', <string>this.tile.title, 'Tile drill down');
    if (this.stateService.is('app.reports')) {
      this.stateService.go('app.analysis', {
        datasetId: params.datasetId,
        entityId: params.entity,
        reportId: this.tile.reportId,
        tileId: this.tile.id,
        queryDataCollection: params.queryDataCollection,
        filterAggId: this.tile.filterAggId
      });
    } else {
      this.goAnalysisState(params);
    }
  }

  // tslint:disable-next-line:no-any
  private goAnalysisState(params: { datasetId: any; entity: any; queryDataCollection: any; }) {
    this.stateService.go(`${this.rootState}.analysis`, {
      datasetId: params.datasetId,
      entityId: params.entity,
      reportId: this.tile.reportId,
      tileId: this.tile.id,
      queryDataCollection: params.queryDataCollection,
      filterAggId: this.tile.filterAggId
    });
  }

  private richTileDrilldown(): void {
    this.eventsTracker.trackEvent('Report', <string>this.tile.title, 'Rich Tile drill down');
    this.stateService.go(`${this.rootState}.report-drill-down`, {
      reportId: this.report.id,
      tileId: this.tile.id,
      slug: this.tile.safeTitle(),
      group: this.tile.tileGroup
    });
  }

  private showProgress(promise: Promise<ReportTileModel | void>) {
    this.isDownloading = true;
    // @ts-ignore
    return promise.finally(() => {
      this.isDownloading = false;
      this.cd.detectChanges();
    });
  }
  @HostListener('window:scroll', ['$event'])
  @debounce()
  // tslint:disable-next-line:no-any
  onLoadMoreItems(event: any) {
    this.onLoadMoreItemsHelper(event);
  }

  private onLoadMoreItemsHelper(event: Event) {
    let target = event.target as HTMLElement;
    if (target.hasAttribute && target.hasAttribute('cl-top-scroll')) {
      if (Math.abs(target.scrollHeight - target.clientHeight - target.scrollTop) < 2) {
        let requestParam = JSON.parse(this.tile.params);
        requestParam.request.skip = requestParam.request.skip + 25;

        if (this.shouldStopLoadingItems(requestParam)) {
          return;
        }
        this.tile.params = JSON.stringify(requestParam);
        this.onLoadMoreItemsExplore(requestParam);
      }
    }
  }

  // tslint:disable-next-line:no-any
  private shouldStopLoadingItems(requestParam: any) {
    return !isUndefined(this.env.report.alma) &&
      !isUndefined(this.env.report.alma.listMax) &&
      requestParam.request.almaSubNonSub === 'nonSubscribed' &&
      requestParam.request.skip >= this.env.report.alma.listMax;
  }

  // tslint:disable-next-line:no-any
  private onLoadMoreItemsExplore(requestParam: any) {
    this.dataRepositoryService.explore(requestParam.datasetId, requestParam.entity, requestParam.request, requestParam.queryDataCollection).subscribe(data => {
      if (isUndefined(this.tileData$)) {
        return;
      }
      this.tileData$ = this.tileData$!.pipe(map((value) => {
        let items = data.items.map((d: ExploreTileResponseItemModel) => {
          // @ts-ignore
          d['isOAFlag'] = d['isOAFlag'] ? 'YES' : 'NO';
          return d;
        });
        // @ts-ignore
        value = [...value, ...this.analysisTableRowFactory.createRows(items, this.columns, requestParam.entity, false, true)];
        if ( requestParam.request.sortBy === 'timesCited' || requestParam.request.sortBy === 'totalCitesCited') {
          // @ts-ignore
          value = this.sortByTimesCitedOrtotalCitesCited(value, requestParam.request.sortOrder, requestParam.request.sortBy);
        }
        return value;
      }));
      if (!this.cd['destroyed' as keyof ChangeDetectorRef]) {
        this.cd.detectChanges();
      }
    });
  }

  // tslint:disable-next-line:no-any
  sortByTimesCitedOrtotalCitesCited(value: any[], direction: 'asc' | 'desc', sortBy: string): ReportTileModel[] {
    // tslint:disable-next-line:no-any
    let sortValue = (a: any, b: any, sortColumn: string) => {
      if (direction === 'asc') {
        return a[sortColumn] - b[sortColumn];
      } else {
        return b[sortColumn] - a[sortColumn];
      }
    };
    return value.sort(
      (a, b) => sortValue(a, b, sortBy));
  }

  getMinHeight() {
    let dataValue;
    this.tileData$.subscribe(data => {
      dataValue = data;
    });
    // @ts-ignore
    let length = dataValue && dataValue.length || 0;
    const sortableTableElem = document.getElementsByClassName('cl-sortable-table')[0];
    let offset = 200;
    if (sortableTableElem) {
      offset = (dataValue || sortableTableElem.getElementsByTagName('table')[0].getElementsByTagName('tbody')[0].getElementsByTagName('tr')).length * 100 + 100;
    }

    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    return length === 1 && vh - 250 > offset ? offset + 'px' : `calc(100vh - 250px)`;
  }

  getClass(tile: ReportTileModel) {
    if (tile.filterAggId === 'almaTabRpt') {
      return this.tileClass === 'alma-analysis-list' ? 'cl-tile__header ic-alma-title table-list-header' : 'cl-tile__header ic-alma-title';
    } else {
      return 'cl-tile__header';
    }
  }

  onItemsCountChange(itemsCount: number) {
    let requestParam = utilities.fromJson(this.tile.params);
    requestParam.request.take = itemsCount;
    this.tile.params = JSON.stringify(requestParam);
    this.tile.data$ = this.tileService.getValues(this.tile);
    this.tileData$ = this.tile.data$!.pipe(
      map((data) => extend(clone(this.tile), data)),
      shareReplay()
    );
    // @ts-ignore
    this.settingsRepositoryService.save({almaTakeCount : itemsCount});
  }

  valueChange(value: string) {
    if (!value) {
      this.onSubmit(value);
    }
  }

  onSortUpdate(e: { sort: { column: { name: string; }, direction: string; } }) {
    if (e && e.sort && e.sort.column && e.sort.column.name && e.sort.direction) {
      let requestParam = utilities.fromJson(this.tile.params);

      if (requestParam.request.sortBy === e.sort.column.name && requestParam.request.sortOrder === e.sort.direction) {
        return;
      }
      requestParam.request.skip = 0;
      requestParam.request.sortBy = e.sort.column.name;
      requestParam.request.sortOrder = e.sort.direction;
      this.tile.params = JSON.stringify(requestParam);
      this.dataRepositoryService.explore(requestParam.datasetId, requestParam.entity, requestParam.request, requestParam.queryDataCollection).subscribe(data => {
        if (isUndefined(this.tileData$)) {
          return;
        }
        this.tileData$ = this.tileData$!.pipe(map((value) => {
          let items = data.items.map((d: ExploreTileResponseItemModel) => {
            d['isOAFlag'] = d['isOAFlag'] ? 'YES' : 'NO';
            return d;
          });
          value = [...this.analysisTableRowFactory.createRows(
            items as unknown as AnalysisItemModel[], this.columns, requestParam.entity, false, true
          )] as unknown as ReportTileModel;
          return value;
        }));
        if (!this.cd['destroyed' as keyof ChangeDetectorRef]) {
          this.cd.detectChanges();
        }
      });
    }
  }

  onSubmit(value: string) {
    let params = JSON.parse(this.tile.params);
    params.request.skip = 0;
    if (value) {
      params.request.filters['jrnsearch'] = {is: value};
    } else {
      delete params.request.filters['jrnsearch'];
    }

    this.refreshTile(params);
  }

  countTileSelection() {
    this.tileService.setSelectedTilesCount(this.isChecked);
  }

  uncheckTile() {
    this.tileService.setSelectedTilesCount(false);
  }
}

export function debounce(delay: number = 300): MethodDecorator {
  // tslint:disable-next-line:no-any
  return function (_target: any, _propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const timeoutKey = Symbol();
    const original = descriptor.value;
    // tslint:disable-next-line:no-any
    descriptor.value = function (...args: any) {
      // @ts-ignore
      clearTimeout(this[timeoutKey]);
      // @ts-ignore
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}