import { Component, ViewEncapsulation } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { ReportModel } from 'components/common/interfaces/report.model';
import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';
import { StateService } from '@uirouter/core';
import { TabReportModel } from 'pages/tab-report/interfaces/tab-report.model';

interface ReportParamsModel {
  disableMessage: string;
  id: string;
  newExpLabel?: boolean;
  open: () => void;
  rClass: string | undefined;
  title: string;
}

@Component({
  selector: 'ic-reports',
  templateUrl: './reports.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ReportsComponent {
  public reportsParams$: Observable<ReportParamsModel[]>;

  constructor(
    private reportsRepository: ReportsRepositoryService,
    private stateService: StateService
  ) {
    this.reportsParams$ = forkJoin(
      this.reportsRepository.rxGetReportsList({ group: 'system' }),
      this.reportsRepository.getTabReportsList()
    ).pipe(
      map(this.getReportsParams.bind(this))
    );
  }

  private getReportsParams(result: [ReportModel[], TabReportModel[]]): ReportParamsModel[] {
    const [oldReports, tabReports] = result;
    const oldReportsParams = oldReports.map(this.getOldReportParams.bind(this));
    const tabReportsParams = tabReports.map(this.getTabReportParams.bind(this));

    return oldReportsParams.concat(tabReportsParams) as ReportParamsModel[];
  }

  private getOldReportParams(report: ReportModel) {
    const { id, title } = report;
    const rClass = this.getReportClass(report);
    const open = this.openReport.bind(this, report);

    return { id, title, rClass, open };
  }

  private getTabReportParams(tabReport: TabReportModel) {
    const { id, title, newExpLabel, disableMessage } = tabReport;
    const rClass = tabReport.type;
    const open = this.openTabReport.bind(this, tabReport);

    return { id, title, rClass, newExpLabel, disableMessage, open };
  }

  private getReportClass(report: ReportModel): string | undefined {
    // @ts-ignore TODO: remove when report types will be fixed
    if (report.reportType === 'wp' || report.reportType === 'iipFaculty' || report.wp) {
      return 'researcher-report';
      // @ts-ignore TODO: remove when report types will be fixed
    } else if (report.reportType === 'wpdept' || report.reportType === 'iipDept' || report.wpdept) {
      return 'department-report';
    } else if (report.institutionProfile) {
      return 'institution-profile';
    } else if (report.ljur) {
      return 'ljur';
    } else {
      return;
    }
  }

  private openReport(report: ReportModel) {
    this.stateService.go('app.report', { reportId: report.id, slug: report.safeTitle!() });
  }

  private openTabReport(report: TabReportModel): void {
    this.stateService.go('app.tabreport', { tabReportId: report.id });
  }

}