import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const baseUrl: string = '/incites-app/';

@Injectable()
export class LogEventService {

  constructor(
    private http: HttpClient
  ) {}

  send(event: string) {
    return this.http.post(`${baseUrl}logevent/send`, { event: event })
      .toPromise();
  }

}