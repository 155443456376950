import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';

import { ChooseDepartmentService } from 'components/common/services/choose-department/choose-department.service';
import { DepartmentModel } from 'components/common/services/choose-department/models';
import { MultiSelectOptionModel } from '@ic/component-lib/src/components/modules/multi-select/models';
import { TreeViewOptionModel } from '@ic/component-lib/src/components/modules/tree-view/models';


@Component({
  selector: 'ic-choose-department',
  templateUrl: 'choose-department.component.html'
})
export class ChooseDepartmentComponent implements OnInit, OnChanges {

  @Input() labels!: { [key: string]: string; };

  @Input() values: MultiSelectOptionModel[] = [];

  @Output() update = new EventEmitter();

  isLoading = false;

  modalOptions!: MultiSelectOptionModel[];

  modalValues: MultiSelectOptionModel[] = [];

  treeOptions!: TreeViewOptionModel[];

  treeValues: TreeViewOptionModel[] = [];

  private defaultLabels: { [key: string]: string; } = {
    openModalButton: 'Choose a department',
    updateButton: 'Update'
  };

  constructor(
    private chooseDepartmentService: ChooseDepartmentService,
  ) {}

  ngOnInit() {
    this.labels = { ...this.defaultLabels, ...this.labels };
    this.loadDepartmentOptions();
  }

  private loadDepartmentOptions() {
    return this.chooseDepartmentService.getDepartmentHierarchy()
      // tslint:disable-next-line:no-any
      .subscribe((departments: any[]) => {
        const flattenedDepartments = this.chooseDepartmentService.flattenDepartments(departments);
        this.treeOptions = departments.map(dept => this.createDepartmentOption(dept)) as TreeViewOptionModel[];
        this.modalOptions = flattenedDepartments.map(dept => this.createDepartmentOption(dept));
      });
  }

  private createDepartmentOption(department: DepartmentModel): DepartmentModel & MultiSelectOptionModel {
    if (department.children) {
      department.children = department.children.map(dept => this.createDepartmentOption(dept));
    }
    return {
      ...department,
      label: department.title || '',
      value: department.name,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.values && changes.values.currentValue) {
      this.updateSelectedValues(this.values);
    }
  }

  private updateSelectedValues(values: MultiSelectOptionModel[]) {
    this.modalValues = [...values];
    // @ts-ignore
    this.treeValues = [...values];
  }

  get hasValues() {
    return this.values.length > 0;
  }

  onModalOpen() { }

  onModalClose() { }

  onUpdateClick() {
    this.update.emit(this.modalValues);
  }

  onMultiSelectInputChange(value: string) {
    console.log('TODO: onMultiSelectInputChange', value);
  }

  onMultiSelectValuesChange(values: MultiSelectOptionModel[]) {
    this.updateSelectedValues(values);
  }

  onTreeValuesChange(values: MultiSelectOptionModel[]) {
    this.updateSelectedValues(values);
  }

}
