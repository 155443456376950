import { Component, ViewEncapsulation, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ErrorModel } from 'components/app/interfaces/error-model.interface';

import { FoldersRepositoryService } from 'components/rest/services/folders-repository/folders-repository.service';
import { FoldersViewModelService } from 'components/view-models/services/folders-view-model/folders-view-model.service';
import { NavigationService } from 'components/navigation/services/navigation/navigation.service';
import { OverbarService } from 'components/overbar/services/overbar/overbar.service';
import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';

import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';

import { FolderModel } from '@ic/component-lib/src/components/modules/folders/folders.model';
import { ReportModel } from 'components/common/interfaces/report.model';
import { CreateFolderComponent } from '../../../../components/ui/components/create-folder/create-folder.component';
import { EventsTrackerService } from 'components/common/services/events-tracker/events-tracker.service';
import { invalidCharacterTitleValidator } from 'pages/my-saved/components/saved-folders/saved-folders.validators';

@Component({
  selector: 'ic-report-save',
  templateUrl: './report-save.component.html',
  encapsulation: ViewEncapsulation.None
})

export class ReportSaveComponent {
  @Input() report: Partial<ReportModel> = {};
  @ViewChild(OverlayModalComponent, { static: true }) overlayModal!: OverlayModalComponent;
  @ViewChild(CreateFolderComponent, { static: true }) createFolderComponent!: CreateFolderComponent;

  public saveForm: FormGroup;
  public folders: FolderModel[] = [];

  public selectedReport: ReportModel|null = null;
  public selectedFolder: FolderModel|null = null;
  private foldersApi: Partial<{
      selectFolder: (folder: FolderModel) => void,
      showFolder: (folder: FolderModel) => void ,
      getFolderById: (reportFolderId: number|'root') => void
    }> = {};

  private titleMaxLength = 100;
  constructor(
    private eventsTracker: EventsTrackerService,
    private translate: TranslateService,
    private foldersRepository: FoldersRepositoryService,
    private foldersViewModel: FoldersViewModelService,
    private navigation: NavigationService,
    private overbar: OverbarService,
    private reportsRepository: ReportsRepositoryService,
    private cd: ChangeDetectorRef
  ) {
    this.saveForm = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.titleMaxLength),
        invalidCharacterTitleValidator.bind(this),
      ])
    });

    this.selectedFolder = null;
  }

   onModalOpen() {
    this.eventsTracker.trackEvent('Report', this.report.title!, `${this.report.title} Save a copy`);
    this.saveForm.setValue({
      title: this.report.title || ''
    });
    this.loadFolders();
  }

  setSelectedFolder(folder: FolderModel) {
    folder.selected = true;
    this.selectedFolder = folder;
  }

  onFolderCreate(folder: FolderModel) {
    this.selectedFolder!.folders.push(folder);
    this.foldersApi.selectFolder!(folder);
    this.foldersApi.showFolder!(folder);
    this.cd.detectChanges();
  }

  openCreateFolderModal() {
    this.createFolderComponent.openModal();
  }

  saveReport() {
    const selectedFolderId = this.selectedFolder!.id;
    let report = this.report;
    let params = {
      title: this.saveForm.value.title,
      folderId: selectedFolderId !== 'root' ? selectedFolderId : null,
      reportType: report.type
    };

    this.reportsRepository.clone(report, params)
      .then((copy: ReportModel) => {
        this.overbar.notify(this.translate.instant('notifications.Report Saved As', {
          report: report.title,
          copy: this.navigation.buildReportLinkModerize(copy)
        }));
        this.closeModal();
        
      })
      .catch((error: ErrorModel) => {
        console.error(error);

        if (error.data.message) {
          this.overbar.notifyError(error.data.message);
        }
      })
      // @ts-ignore
      .finally(() => {
        this.cd.detectChanges();
      });
  }

  openModal() {
    this.overlayModal.openModal();
  }

  private closeModal() {
    this.overlayModal.closeModal();
  }

  private loadFolders() {
    this.foldersRepository.getList()
      .then((folders: FolderModel[]) => {
        this.folders = this.foldersViewModel.createRootArray(folders);
        this.setDefaultFolder();
      })
      .catch((err: ErrorModel) => {
        console.error(err);

        if (err.data.message) {
          this.overbar.notifyError(err.data.message);
        }
      })
      // @ts-ignore
      .finally(() => {
        this.cd.detectChanges();
      });
  }

  private setDefaultFolder() {
    const reportFolderId = this.report.folderId;
    const defaultFolder = reportFolderId ? this.foldersApi.getFolderById!(reportFolderId) : this.folders[0];

    if (defaultFolder) {
      this.setSelectedFolder(defaultFolder);
    }
  }

}