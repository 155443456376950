import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { MessageModel } from '../../interfaces/message.model';

@Injectable()
export class NotificationBannerService {
  private messages$: Subject<MessageModel> = new Subject();
  
  constructor() {}
  
  showMessage(message: MessageModel) {
    this.messages$.next(message);
  }

  get messages(): Subject<MessageModel> {
    return this.messages$;
  }

}