import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DepartmentModel } from './models';
import { AnalysisService } from 'pages/analysis/services/analysis-service/analysis.service';

@Injectable()
export class ChooseDepartmentService {
  onClear = new EventEmitter();

  constructor(
    private http: HttpClient,
    private analysisService: AnalysisService,
  ) {}

  getDepartmentHierarchy() {
    return this.http.get<DepartmentModel[]>('/test/data/departments.json');
  }

  flattenDepartments(departments: DepartmentModel[]) {
    return departments.reduce((result: DepartmentModel[], department) => {
      return result.concat(this.flattenDepartment(department));
    }, []);
  }

  private flattenDepartment(department: DepartmentModel): DepartmentModel[] {
    let children: DepartmentModel[] = [];
    if (department.childDepts) {
      children = department.childDepts
        .reduce((result: DepartmentModel[], child) => {
          return result.concat(this.flattenDepartment(child));
        }, []);
    }
    return [department, ...children];
  }

  getSearchedDepartment(searchTerm: string, requestBody: string[]) {
    const { datasetId } = this.analysisService.config;
    if (searchTerm.trim() !== '') {
      const queryParams = {
        src: 'dept',
        isSearch: true,
        term: searchTerm
      };

      return this.http.post(`incites-app/explore/${datasetId}/filter/departments`, requestBody, { params: queryParams });
    }
  }
}
