<div class="modal-body">
  <section class="forms">
    <h4>{{ stateHeaders[state] }}</h4>
    <div class="toolbar" *ngIf="state === 'list'">
        <button (click)="add()">Add</button>
        <button (click)="findMsg()">Find notifications</button>
    </div>
    <form name="notificationForm" *ngIf="state === 'add' || state === 'edit'" #notificationForm="ngForm">
      <label>{{ 'userNotification.Start date' | translate }} <input type="text" [(ngModel)]="currentNotification.startDate" [pattern]="validLongDatePattern" required name="notificationStartDate"></label>
      <label>{{ 'userNotification.End date' | translate }} <input type="text" [(ngModel)]="currentNotification.endDate" [pattern]="validLongDatePattern" required name="notificationEndDate"></label>
      <label>{{ 'userNotification.Title' | translate }} <input type="text" [(ngModel)]="currentNotification.title" placeholder="Notification title" name="notificationTitle"></label>
      <label>{{ 'userNotification.Notification text' | translate }}
        <textarea placeholder="{{ 'userNotification.Notification text' | translate }}" [(ngModel)]="currentNotification.messageBody" required name="notificationText"></textarea>
      </label>
      <button ng-disabled="notificationForm.$invalid" class="btn btn-primary" (click)="postMsg()" [disabled]="!notificationForm.form.valid" translate="popup.Save"></button>
      <button class="btn" (click)="changeState('list')" translate="popup.Cancel"></button>
    </form>
    <form name="searchForm" *ngIf="state === 'find'" #searchForm="ngForm">
      <label class="id">ID: <input type="text" [(ngModel)]="search.id" pattern="^(\d+)$" placeholder="99" name="id"/></label>
      <label class="start-date">Start date: <input type="text" [(ngModel)]="search.startDate" [pattern]="validShortDatePattern" placeholder="MM/DD/YYYY" name="startDate"></label>
      <label class="end-date">End date: <input type="text" [(ngModel)]="search.endDate" [pattern]="validShortDatePattern" placeholder="MM/DD/YYYY" name="endDate"></label>
      <label class="title">Title: <input type="text" [(ngModel)]="search.title" placeholder="Fragment of title" name="title"></label>
      <label class="message">Text: <input type="text" [(ngModel)]="search.messageBody" placeholder="Fragment of title" name="messageBody"></label>
      <label class="author">Editor name: <input type="text" [(ngModel)]="search.lastModifiedUser" placeholder="john.doe@clarivate.com" name="lastModifiedUser"></label>
      <button [disabled]="!searchForm.form.valid" (click)="applySearch()">Find</button>
      <button class="btn" (click)="changeState('list')" translate="popup.Cancel"></button>
    </form>
  </section>
  <section class="list" *ngIf="state === 'list'">
    <ul>
      <li class="msg" *ngFor="let msg of notifications">
        <div>
          <h5><span>#{{ msg.id }}</span>  {{ msg.title }}</h5>
          <span class="range">Start: {{ msg.startDate }} End: {{ msg.endDate }}</span>
          <div>{{ msg.messageBody }}</div>
        </div>
        <div>
          <button (click)="editMsg(msg)">Edit</button>
          <button (click)="deleteMsg(msg.id)">Delete</button>
        </div>
      </li>
    </ul>
  </section>
</div>