<cl-slidein-modal
  class="ic-choose-department"
  (onOpen)="onModalOpen()"
  (onClose)="onModalClose()"
  [options]="{ zIndexOverlay: 40 }"
>

  <cl-slidein-modal-button
    [buttonClass]="'cl-btn cl-btn--text cl-btn--with-icon ic-choose-department__toggle-btn'"
  >
    <span class="cl-btn__text">{{ labels.openModalButton }}</span>
    <cl-icon [iconId]="'caret-right'"></cl-icon>
  </cl-slidein-modal-button>

 <cl-slidein-modal-overlay>

    <cl-slidein-modal-header>
      <div class="ic-choose-department__header">
        <h2 class="h2">{{ 'departments.Choose department(s) from My Organization' | translate }}</h2>
        <a class="cl-btn cl-btn--text" href="#">
          {{ 'departments.Manage my organization structure' | translate }}
        </a>
      </div>
      <cl-multi-select
        [config]="{
          detailsHeading: '',
          hideInputLabel: true,
          inputId: 'cl-choose-department-search-input',
          inputLabel: 'choose',
          loadingMessage: 'loading'
        }"
        [isLoading]="isLoading"
        [placeholderText]="hasValues ? ('departments.Add another department' | translate) : ('departments.Enter a department' | translate)"
        [options]="modalOptions"
        [values]="modalValues"
        (onInputChange)="onMultiSelectInputChange($event.value)"
        (onValuesChange)="onMultiSelectValuesChange($event.values)"
      ></cl-multi-select>
    </cl-slidein-modal-header>

    <cl-slidein-modal-content>
      <cl-tree-view
        *ngIf="treeOptions"
        [options]="treeOptions"
        [values]="treeValues"
        (onSelect)="onTreeValuesChange($event)"
      ></cl-tree-view>
    </cl-slidein-modal-content>

    <cl-slidein-modal-footer>
      <button type="button" class="cl-btn cl-btn--primary" (click)="onUpdateClick()">
        {{ labels.updateButton }}
      </button>
    </cl-slidein-modal-footer>

  </cl-slidein-modal-overlay>

</cl-slidein-modal>
