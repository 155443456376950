<article class="cl-tile">
  <span class="tile-checkbox" *ngIf="!report.system && (tileState === 'select' || tileState === 'locked') && tile.tileGroup !== 'domino' && tile.tileGroup !== 'table-list'">
    <input class="cl-form-checkbox cl-form-checkbox--small" [(ngModel)]="isChecked" (change)="countTileSelection()" type="checkbox"
           id="{{tile.id}}" [disabled]="tileState === 'locked' || (!isChecked && tileState === 'select' && this.tileService.getSelectedTilesCount() === 6)">
    <label class="cl-form-label" for="{{tile.id}}">
      <span class="visually-hide">{{ tile.title }}</span>
    </label>
  </span>
  <header  [ngClass]="getClass(tile)">
    <!-- Needs to change when alma real data comes -->
    <div>
    <h3 class="cl-tile__header__title h4"><span *ngIf="tile.subscribedCountUrl">{{ (tile.subscribedCount | async)?.totalItems }} &nbsp;</span>{{ tile.title }}</h3>
    <div class="tile-handle"><ng-content></ng-content></div>
    <ic-dropdown class="tile-options" [items]="dropdownMenu" [options]="{ icon: 'options', width: 220 }" *ngIf="report.reportType !== 'almaTabRpt' && !(report.typeOfReport === 'almaTabRpt' && !['dbt_312','dbt_313'].includes(tile.id))"></ic-dropdown>
    <cl-number-input class="treemap-counter" *ngIf="report.reportType === 'almaTabRpt' && tile.tileType === 'tree map'"
      [options]="options"
      (changeValue)="onItemsCountChange($event)"
    ></cl-number-input>
    <p *ngIf="tile.subtitle" class="cl-tile__header__desc">{{ tile.subtitle }}</p>
    </div>
    <div *ngIf="tileClass === 'alma-analysis-list'" class="table-search">
      <button class="cl-btn cl-btn--text cl-btn--with-icon cl-context-menu__btn">
          <span class="cl-btn__text">
              {{ "analysis.table search.Find in table" | translate }}
            </span>
      </button>
      <div class="cl-form__field">
        <label class="visually-hide" for="search-query">
          {{ "analysis.table search.Find in table" | translate }}
        </label>
        <span class="cl-form-text--icon">
                  <input
                    class="cl-form-text cl-form-text--underline"
                    aria-label="find in table input field"
                    #searchQuery
                    type="text"
                    placeholder="e.g. Journal of Clinical Investigation"
                    [value]="initialValue"
                    (input)="valueChange(searchQuery.value)"
                    (keyup.enter)="onSubmit(searchQuery.value)"
                  />
                  <cl-icon [iconId]="'search'" [color]="'color-gray80'" [size]="16"></cl-icon>
                </span>
      </div>
    </div>
  </header>

  <div class="cl-tile__body" [ngClass]="tileClass">
    <ng-container *ngIf="tileData$ | async as tileData; else loader">
      <span class="cl-tile--domino__value" *ngIf="tileClass === 'domino'">
        {{ (tileData && tileData.value && tileData.value.value | icFormatter:{type: 'number', unit: tileData.tileType}) || '…' }}
      </span>

        <div class="nodata centered-text-block"
          *ngIf="(tileClass !== 'domino' && tileData.noData) ||(tileClass === 'alma-analysis-list' && tileData && !tileData.length) ">
        <span>{{ "No values" | translate }}</span>
      </div>
      <div class="nodata centered-text-block" *ngIf="tileData.serverError">
        <span>{{ tileData.serverError | translate }}</span>
      </div>

      <ic-rich-tile
        *ngIf="!tileData.noData && tileClass === 'rich'"
        class="diagram cl-tile__body__chart"
        [data]="tileData"
        [tileIndex]="tileIndex"
        [report]="report"
        [dataUpdateInfo]="dataUpdateInfo"
      ></ic-rich-tile>

      <div [style.minHeight]="getMinHeight()" *ngIf="tileClass === 'alma-analysis-list' && tileData && tileData.length"
        class="ic-drag_drop_table">
        <div [style.height]="getMinHeight()">
          <cl-sortable-table [options]="sortableTableOptions" [rows]="tileData" [columns]="columns"
            (sortableTableScroll)="onLoadMoreItems($event)"
            (sortUpdated)="onSortUpdate($event)"
            >
          </cl-sortable-table>
        </div>
      </div>

      <ic-table-list-tile
        *ngIf="!tileData.noData && tileClass === 'table-list'"
        [data]="tileData"
        [report]="report"
      ></ic-table-list-tile>
    </ng-container>

    <ng-container *ngIf="!isDownloading; else loader"></ng-container>

    <ng-template #loader>
      <div class="cl-tile__loading">
        <ng-container *ngIf="loadingErrorMessage.length else noLoadingErrorMessage">
          {{ loadingErrorMessage | translate }}
        </ng-container>
        <ng-template #noLoadingErrorMessage>
          <cl-loading-indicator [label]="getLoaderString() | translate"></cl-loading-indicator>
        </ng-template>
      </div>
    </ng-template>
  </div>
  <div class="applied-filters" *ngIf="tile.tileGroup !== 'domino' && tile.tileGroup !== 'table-list' && (tileState === 'select' || tileState === 'locked')">
    <div *ngIf="defaultTileFilters | async as defaultFilters;else loading">
      <cl-applied-filters
        [options]="{
          showClearAllButton: false}"
        [appliedFilters]="defaultFilters"
      ></cl-applied-filters>
    </div>
    <ng-template #loading>
        <cl-loading-indicator [label]="'Loading...'"></cl-loading-indicator>
    </ng-template>

  </div>
  <footer class="cl-tile__footer" *ngIf="tileState !== 'select' && tileState !== 'locked' && (tile.tileGroup !== 'domino' && report.typeOfReport !== 'almaTabRpt' && report.reportType !== 'almaTabRpt') &&!(tile.title==='Subscribed Publication Sources' || tile.title==='Top 100 recommended publication sources')">
    <button class="cl-btn cl-btn--text" (click)="viewData()" [attr.aria-label]="'View more data for '+tile.title">{{ 'report.tile.View Data' | translate }}</button>
  </footer>

</article>

<ic-tile-save [report]="report" [reportFolders]="folders" [tile]="tile"></ic-tile-save>
<ic-tile-remove [report]="report" [tile]="tile" (uncheckTile)="uncheckTile()"></ic-tile-remove>
<ic-tile-rename [report]="report" [tile]="tile"></ic-tile-rename>