import { cloneDeep, extend, isUndefined, some, every, reject } from 'lodash';
import { DecimalPipe } from '@angular/common';
import { FilterModel, FilterSummaryModel, FilterValueModel, FilterGroupModel } from 'components/analytics/interfaces/filter.interface';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isObject } from 'angular';

const parentKey = '_parent';
const childrenKey  = '_children';

@Injectable()
export class FilterService {

  constructor(
    private decimalPipe: DecimalPipe,
    public translate: TranslateService
  ) {}

  flattenFilters(filters: FilterModel[]): FilterModel[] {
    let result: FilterModel[] = [];
    let i;

    for (i = 0; i < filters.length; i++) {
      let filter = filters[i];
      result.push(filter);

      if (filter.filters && filter.filters.length) {
        result = result.concat(this.flattenFilters(filter.filters));
      }
    }

    return result;
  }

  formatFilterValue(filter: FilterModel, value: Partial<FilterValueModel>): string|string[]|undefined {
    const negated = !!value.not;
    const negation = negated ? this.translate.instant('analytics.explore.filter.negation') : '';
    let val = value.is || value.not!;

    switch (filter.type) {
      case 'year':
        return [negation + (<string[]>val).join('-')];
      case 'string':
      case 'option':
        if (filter.choice === 'multiple') {
          if (!Array.isArray(val)) {
            val = [val];
          }
          return (<string[]>val).map((v) => negation + v);
        } else if (filter.choice === 'link') {
          if (isObject(val)) {
            return Object.values(val);
          }
        } else {
          return [negation + val];
        }
        break;
      case 'boolean':
        return [!negated && val
          ? this.translate.instant('analytics.explore.filter.Enabled')
          : this.translate.instant('analytics.explore.filter.Disabled')
        ];
      case 'list':
        return [<string>val];
      case 'number':
        if (filter.choice === 'range') {
          const min = this.decimalPipe.transform(val[0]);
          const max = this.decimalPipe.transform(val[1]);

          return [`${min}-${max}`];
        } else {
          return [<string>val];
        }
      case 'person':
        return (<string[]>val).map((v) => negation + v);
      default:
        return undefined;
    }
  }

  getFilterSummary(
    filters: FilterModel[],
    values: {[index: string]: Partial<FilterValueModel>}
  ): FilterModel[] {
    let summary: FilterModel[] = [];

    if (filters && values) {
      filters.forEach((filter) => {
        if (filter.filters) {
          if (filter.type === 'person') {
            let personId: Partial<FilterValueModel>|undefined = undefined;

            if (filter.filters[2]) {
              personId = values[filter.filters[2].name];

              this.addFilterSummaryEntry(summary, filter, personId);
            } else if (filter.filters[1] && filter.name && (filter.name === 'funderList')) {
              filter.filters.forEach((fltr) => {
                personId = values[fltr.name];
                this.addFilterSummaryEntry(summary, fltr, personId);
              });
            } else {
              this.addFilterSummaryEntry(summary, filter, personId!);
            }
          } else {
            this.parseIncludedFilters(summary, filter, values);
          }
        } else {
          this.addFilterSummaryEntry(summary, filter, values[filter.name]);
        }
      });
    }
    return summary;
  }


  getFilterValues(filters: FilterModel[]) {
    return filters.reduce((values: {[index: string]: Partial<FilterValueModel>}, filter) => {
      if (filter.hidden) {
        return values;
      }
      if (filter.value) {
        let value = cloneDeep(filter.value);

        if ((filter.type === 'range') || (filter.type === 'year')) {
          if ((filter.value[0] !== filter.source[0]) || (filter.value[1] !== filter.source[1])) {
            values[filter.name] = filter.not ? { not: value } : { is: value };
          }
        } else {
          values[filter.name] = filter.not ? { not: value } : { is: value };
        }
      }
      if (filter.filters) {
        extend(values, this.getFilterValues(filter.filters));
      }
      return values;
    }, {});
  }

  groupFilters(filters: FilterModel[], groups: FilterGroupModel[]) {
    let tree: FilterModel = {name: '', title: '', path: '', type: ''};

    groups.forEach((node) => this.buildTree(node, tree));
    filters.forEach((node) => this.buildTree(node, tree));

    return this.traverse(tree);
  }

  private addFilterSummaryEntry(
    summary: FilterSummaryModel[],
    filter: FilterModel,
    filterValue: Partial<FilterValueModel>
  ): void {
    if (isUndefined(filterValue)) {
      return;
    }
    summary.push({
      title: filter.title!,
      value: this.formatFilterValue(filter, filterValue)
    });
  }

  private buildTree(node: FilterGroupModel|FilterModel, tree: FilterModel): void {
    let path = node.path!.split('.');
    let root: FilterModel = tree;
    let parent = tree;
    let segment: string;
    let i;

    for (i = 0; i < path.length; i++) {
      segment = path[i];
      if (isUndefined(root[segment])) {
        root[segment] = {};
      }
      parent = root;
      if (isUndefined(parent[childrenKey])) {
        parent[childrenKey] = [];
      }
      root = root[segment];
    }
    root[parentKey] = <FilterModel>node;
    parent[childrenKey]!.push(<FilterModel>node);
  }

  private traverse(root: FilterModel): FilterModel[]|undefined {
    Object.keys(root)
      .filter((key) => key !== parentKey && key !== childrenKey )
      .forEach((key) => this.traverse(root[(key)]!));

    if (root[parentKey] && root[childrenKey]) {
      root[parentKey]!.filters = <FilterModel[]>root[childrenKey]!;
    }
    return root[childrenKey];
  }

  private parseIncludedFilters(
    summary: FilterModel[],
    filter: FilterModel,
    values: {[index: string]: Partial<FilterValueModel> }
  ) {
    if (!this.primaryFiltersNotApplied(filter.filters!, values)) {
      if (filter.filters && filter.filters.length > 0) {
        filter.filters.forEach((fltr) => {
          this.parseIncludedFilters(summary, fltr, values);
        });
      }

      this.addFilterSummaryEntry(summary, filter, values[filter.name]);
    }
  }

  private primaryFiltersNotApplied(
    filters: FilterModel[],
    values: {[index: string]: Partial<FilterValueModel>}
  ): boolean {
    return some(filters, 'secondary') &&
           every(reject(filters, 'secondary'), (filter) => isUndefined(values[filter.name]));
  }

}