import { Component, EventEmitter, ViewEncapsulation, Output, ViewChild, OnInit, forwardRef } from '@angular/core';

import { FolderExtModel } from 'components/common/interfaces/folder.interface';
import { FolderModel } from '@ic/component-lib/src/components/modules/folders/folders.model';
import { FoldersRepositoryService } from 'components/rest/services/folders-repository/folders-repository.service';
import { FoldersService } from 'components/analytics/services/folders/folders.service';
import { FoldersViewModelService } from 'components/view-models/services/folders-view-model/folders-view-model.service';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { PopoverModalComponent } from '@ic/component-lib/src/components/modules/popover-modal/popover-modal.component';

@Component({
  selector: 'ic-folder-select',
  templateUrl: './folder-select.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FolderSelectComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => FolderSelectComponent), multi: true }
  ]
})

export class FolderSelectComponent implements ControlValueAccessor, OnInit {
  @Output() onSelect = new EventEmitter<FolderExtModel>();
  @ViewChild(PopoverModalComponent, { static: false }) popoverModal!: PopoverModalComponent;

  private folders: FolderExtModel[] = [];
  public displayedFolders: FolderExtModel[] = [];
  public selectedFolder: FolderModel|null = null;
  public filter: string = '';

  constructor(
    private foldersRepository: FoldersRepositoryService,
    private foldersViewModel: FoldersViewModelService,
    private foldersService: FoldersService,
  ) {}

  ngOnInit() {
    this.loadFoldersList()
      .then(() => {
        if (this.selectedFolder === null) {
          this.selectFolder(this.foldersService.getFolderById(this.folders, 'root')!);
        }
      });
  }

  loadFoldersList(): Promise<FolderExtModel[]> {
    return this.foldersRepository.getList().then((folders) => {
      this.folders = this.foldersViewModel.createRootArray(<FolderExtModel[]>folders);
      this.displayedFolders = this.filterFolders();
      return this.folders;
    });
  }

  onItemClick(folder: FolderExtModel) {
    this.selectFolder(folder);
    this.propagateChange(folder.id);
    this.popoverModal.closePopover();
  }

  onSearch(text: string) {
    this.filter = text;
    this.displayedFolders = this.filterFolders();
  }

  onCreatenewFolder() {

  }

  onTouched = () => {};

  propagateChange: any = () => {}; // tslint:disable-line:no-any
  
  validateFn: any = () => {}; // tslint:disable-line:no-any

  writeValue(folderId: number|'root') {
    if (folderId) {
      const folder = this.foldersService.getFolderById(this.folders, folderId);
      if (folder) {
        this.selectFolder(folder);
      }
    }
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate() {
    return this.validateFn();
  }

  private filterFolders(): FolderExtModel[] {
    const list = [this.folders[0], ...this.folders[0].folders];

    if (this.filter && (this.filter.length > -1)) {
      return list.filter(item => item.title.indexOf(this.filter) > -1);
    }

    return list;
  }

  private selectFolder(folder: FolderModel) {
    this.selectedFolder = folder;
  }

}
