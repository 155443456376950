import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize, map } from 'rxjs/operators';
import { MultiSelectOptionModel, MultiSelectTypeModel } from '@ic/component-lib/src/components/modules/multi-select/models';
import { Observable, of } from 'rxjs';
import { clone, isUndefined } from 'lodash';
import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';

@Component({
  selector: 'ic-report-filter',
  templateUrl: './report-filter.component.html',
  encapsulation: ViewEncapsulation.None
})

export class ReportFilterComponent {
  @Output() onUpdate: EventEmitter<{}> = new EventEmitter;

  private optionsUrl: string|undefined;
  public filterOptions: MultiSelectOptionModel[]|Observable<MultiSelectOptionModel[]> = of([]);
  public filterTypes!: MultiSelectTypeModel[];
  public filterValues!: MultiSelectOptionModel[];
  public labels: {[index: string]: string} = {};
  public loading: boolean = false;
  public placeholderText: string = '';
  public savedValues: {[index: string]: MultiSelectOptionModel[]} = {};

  @Input()
  set reportType(type: string|undefined) {
    if (type === 'orcidrid') {
      this.labels = {
        label: this.translate.instant('dashboard.Find Person:') };
    } else if (type === 'wp' || type === 'iipFaculty') {
      this.labels = { label: this.translate.instant('dashboard.Find Person:') };
    } else if (type === 'wpdept' || type === 'iipDept') {
      this.labels = { label: this.translate.instant('dashboard.Find Department:') };
    }
  }

  @Input()
  set options(options: string[]|string) {
    if (options) {
      if (Array.isArray(options)) {
        this.filterOptions = options && options.map(this.mapSelectOption);
      } else {
        this.optionsUrl = options;
      }
    }
  }

  @Input()
  set values(vals: string[]) {
    this.filterValues = vals && vals.map(this.mapSelectOption);
  }

  @Input()
  set types(types: MultiSelectTypeModel[]) {
    if (types) {
      this.filterTypes = types.map((item, i) => {
        item.default = i === 0;
        return item;
      });
      this.onTypeChange(types[0]);
    }
  }

  constructor(
    private reportsRepository: ReportsRepositoryService,
    public translate: TranslateService
  ) {

  }

  onInputChange(text: string, type?: MultiSelectTypeModel) {
    if (text && text.length > 0) {
      const typeOptions = type ? {personIdType: type.value} : undefined;
      
      this.loading = true;
      this.filterOptions = this.reportsRepository.getFilterOptions(this.optionsUrl!, text, typeOptions)
        .pipe(
          map(data => data.map(this.mapSelectOption)),
          finalize(() => this.loading = false)
        );
    }
  }

  onValuesChange(values: MultiSelectOptionModel[], type: MultiSelectTypeModel) {
    this.onUpdate.emit({
      values: values.map((item) => item.value),
      type: type && type.value
    });
  }

  onTypeChange(type: MultiSelectTypeModel, previousType?: MultiSelectTypeModel) {
    this.updatePlaceholder(type);
    this.filterOptions = of([]);

    if (previousType) {
      if (!isUndefined(previousType.value)) {
        this.savedValues[previousType.value] = clone(this.filterValues);
      }

      if (!isUndefined(type.value) && this.savedValues[type.value]) {
        this.filterValues = this.savedValues[type.value];
      } else {
        this.filterValues = [];
      }
    }

  }

  private mapSelectOption(val: string): MultiSelectOptionModel {
    return {
      label: val,
      value: val
    };
  }

  private updatePlaceholder(type: MultiSelectTypeModel) {
    switch (type.value) {
      case 'orcId':
        this.placeholderText = '0000-0001-5000-0138';
        break;
      case 'researcherId':
        this.placeholderText = 'A-1397-2010';
        break;
      default:
        this.placeholderText = '';
    }
  }

}