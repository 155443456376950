<div class="table-list-search drilldown-table">
  <div class="loading-indicator" *ngIf="loading"><i [title]="'Loading…' | translate" class="loading-icon"></i></div>
  <div class="table-list-search-head">
    <span class="pagingtop">
      <label for="itemsPerPage" translate="drilldown.Rows Per Page"></label>
      <select id="itemsPerPage" ui-select2="{ dropdownAutoWidth: true }" [(ngModel)]="itemsPerPage" (ngModelChange)="setPageSize($event)">
        <option *ngFor="let pageSize of table.pageSizes" value="{{ pageSize }}">{{ pageSize }}</option>
      </select>
    </span>
    <div class="head-line"></div>
  </div>
  <div class="table-list-search-content" ic-top-scroll>
    <table summary="{{ 'report.Table displaying data' | translate }}">
      <thead>
        <tr>
          <th *ngFor="let column of visibleColumns"
              class="{{ column.type }}"
              >
            <span *ngIf="sortDisabled || column.sortable === false"
                  class="column-name">{{ column.title }}</span>
            <button *ngIf="!sortDisabled && column.sortable !== false"
               (click)="sort(column)"
               class="button-text"
               >{{ column.title }}<i *ngIf="column.name === table.sortBy" class="sort-icon {{ table.sortOrder }}"></i>
            </button>
          </th>
        </tr>
      </thead>
      <ng-container *ngIf="benchmarks">
        <tbody class="benchmark {{ benchmark.name }}"
               *ngFor="let benchmark of benchmarks.items">
          <tr *ngFor="let item of benchmark.value">
            <td *ngFor="let column of visibleColumns" class="{{ column.type }}">
              <span [innerHTML]="item[column.name] | icFormatter:column"
                    ></span>
            </td>
          </tr>
        </tbody>
      </ng-container>
      <tbody *ngIf="rows">
        <tr *ngFor="let row of rows">
          <td *ngFor="let column of visibleColumns" class="label-bold {{ column.type }}">
            <a *ngIf="column.expandable" href=""
               (click)="expand(row[column.name])"
               [innerHTML]="row[column.name].value"></a>
            <span *ngIf="!column.expandable && column.type != 'link'"
                  [innerHTML]="row[column.name] | icFormatter:column">
            </span>
            <a *ngIf="column.type == 'link'"
               href="{{ row[column.name].url }}"
               target="_blank"
               [innerHTML]="row[column.name].title"></a>
          </td>
        </tr>
        <tr *ngIf="rows.length === 0">
          <td [colSpan]="visibleColumns.length" translate="drilldown.No values"></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-list-search-footer">
    <span
      *ngIf="table.getLastItemIndex() >= drilldownSkipLimit"
    >{{ 'notifications.The first n documents are available for view and export' | translate:{ n: drilldownSkipLimit } }}</span>
    <div class="bottom-pagination-block" *ngIf="table.totalItems !== '0'">
      <button (click)="firstPage()" class="show-first" [ngClass]="{ inactive: table.isFirstPage()}"></button>
      <button (click)="prevPage()" class="show-prev" [ngClass]="{ inactive: table.isFirstPage()}"></button>
      <span class="current-paging">

        <b
          *ngIf="(table.getFirstItemIndex()| number) !== (table.getLastItemIndex() | number)"
        >{{ table.getFirstItemIndex() | number }} - {{ table.getLastItemIndex() | number }}</b>
        <b
          *ngIf="(table.getFirstItemIndex()| number) === (table.getLastItemIndex() | number)"
          [innerHTML]="table.getFirstItemIndex() | number"
        ></b>
        <span>{{ 'drilldown.paging' | translate: { total: table.totalItems | number } }}</span>
      </span>
      <button
        (click)="(table.getLastItemIndex() < drilldownSkipLimit) && nextPage()"
        class="show-next"
        [ngClass]="{ inactive: table.isLastPage() || table.getLastItemIndex() >= drilldownSkipLimit }"
      ></button>
      <button
        (click)="(drilldownSkipLimit > table.totalItems) && lastPage()"
        class="show-last"
        [ngClass]="{ inactive: table.isLastPage() || table.totalItems >= drilldownSkipLimit }"
      ></button>
    </div>
    <div class="bottom-pagination-block" *ngIf="table.totalItems === '0'">
      No data to Display
    </div>
  </div>
</div>