<div>
  <ul class="part-content-reports legacy">
    <li
      class="report {{ report.id }}"
      *ngFor="let report of reportsParams$ | async"
    >
      <div class="logo" [ngClass]="report.rClass"></div>
      <h4>
        {{ report.title }}
        <span class="new-exp" *ngIf="report.newExpLabel">
          ({{ "analytics.landing.New Experience" | translate }})
        </span>
      </h4>
      <div *ngIf="report.disableMessage; else openButton">
        <p class="disable-message">{{ report.disableMessage }}</p>
        <a
          class="btn btn-primary system-report-learn-more"
          translate="analysis.sidebar.indicators.Learn more"
          href="/incites-app/redirect/myorg"
        ></a>
      </div>
      <ng-template #openButton>
        <button
          type="button"
          class="btn btn-primary system-report-run"
          translate="analytics.landing.Run"
          (click)="report.open()"
        ></button>
      </ng-template>
    </li>
  </ul>
</div>

<div class="report-footer">
  <div class="report-footer-links">
    <div class="report-footer__change">
      <a
        class="external"
        href="/incites-app/redirect/whatsnew"
        target="_blank"
        >{{ "analytics.landing.Learn what changed" | translate }}</a
      >
    </div>
    <div class="report-footer__feedback">
      <a
        class="external"
        href="https://www.surveymonkey.com/r/HL5XJXD"
        rel="noopener noreferrer"
        target="_blank"
        >{{ "analytics.landing.Share feedback" | translate }}</a
      >
    </div>
  </div>
</div>
