<div role='alert' class="overbar notify-top">
  <div class="notification" [ngClass]="{ error: notify.isError }" *ngFor="let notify of (notifications$ | async)">
    <div class="second-wrapper">
      <div class="action-menu" *ngIf="notify.isError">
        <a (click)="dissmissNotification(notify)" href="javascript:;" [translate]="'notifications.Dismiss'"></a>
      </div>
      <span class="message" [innerHTML]="notify.text"></span>
      <div *ngIf="notify.showDetails" class="details">
        <div><strong>{{ 'notifications.errors.Error Message:' | translate }}</strong>&nbsp;{{ notify.details.message }}</div>
        <div *ngIf="notify.details.diagnosticTag">
          <strong>{{ 'notifications.errors.Error ID:' | translate }}</strong>&nbsp;<a [href]="notify.details.url">{{ notify.details.diagnosticTag }}</a>
        </div>
      </div>
    </div>
  </div>
</div>