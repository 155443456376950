import { Injectable } from '@angular/core';

import { EntityModel } from './entity.model';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
const baseUrl: string = '/incites-app/';
// Formerly known as contextsRepository
@Injectable()
export class EntitiesRepositoryService {
  myOrgDataSetEntities: EntityModel[];
  incitesDataSetEntities: EntityModel[];

  constructor(
    private http: HttpClient,
  ) { }

  getList(datasetId?: string): Observable<EntityModel[]> {
    // tslint:disable-next-line:radix
    if ((!parseInt(datasetId as string) && this.incitesDataSetEntities) || (datasetId && this.myOrgDataSetEntities)) {
      // tslint:disable-next-line:radix
      return of(!parseInt(datasetId as string) ? this.incitesDataSetEntities : this.myOrgDataSetEntities);
    } else {
      return this.http.get<EntityModel[]>(`${baseUrl}explore/${datasetId ? datasetId : 0}/analyzeDropdown`);
    }

  }

}