import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { NumberFormatterService } from './../number-formatter/number-formatter.service';

import { uiModule } from '../../../../angularjs/ui/ui.module';

@Injectable()
export class FormatterService {
  notAvailable: string;

  constructor(
    private translate: TranslateService,
    private numberFormatter: NumberFormatterService
  ) {
    this.notAvailable = this.translate.instant('n/a');
  }

  format(input: string|number, metadata?: any) { // tslint:disable-line
    if (input == null) {
      return this.notAvailable;
    }

    if (!metadata) {
      return input;
    }
    switch (metadata.type) {
      case 'string':
        return input || this.notAvailable;
      case 'number':
        return this.numberFormatter.format(input, metadata) || this.notAvailable;
      default:
        return input;
    }
  }
}

// Register downgraded Angular providers with this AngularJS module.
uiModule.factory('formatter', downgradeInjectable(FormatterService));
