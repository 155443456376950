<cl-overlay-modal [options]="{ 'modalWidth': '500px' , 'modifiers': 'dark-header' }">
  <cl-overlay-modal-content [modalTitle]="'groupAlert.Info' | translate">
    <div class="cl-form__fieldset cl-form__fieldset">
      <div class="cl-form__field">
        <p class="text-left"> {{ message| translate }} </p>
      </div>
    </div>
    <div class="cl-form__fieldset cl-form__fieldset">
      <div class="cl-form__actions">
        <button type="button" class="cl-btn cl-btn--primary" (click)="closeModal()">
          {{ 'groupAlert.OK' | translate }}
        </button>
      </div>
    </div>
  </cl-overlay-modal-content>
</cl-overlay-modal>