import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ajsAppModule } from '../../../../../angularjs/app/app.module.ajs';
import './global-header.component.less';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { Subscription } from 'rxjs';
import { IcTogglesNgService } from 'components/common/services/ic-toggles-ng/ic-toggles-ng.service';
import { map } from 'rxjs/operators';
import {Globals} from 'components/shared/globalData';

@Component({
  selector: 'ic-global-header',
  templateUrl: './global-header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class GlobalHeaderComponent implements OnInit {
  env = this.environmentService.getEnvironment();
  IS_CDX_HEADER_ON: boolean = false;
  subscription = new Subscription();
  private envConfig: {[key: string]: any}; // tslint:disable-line:no-any
  rfNavLinkEnabled: boolean = false;
  productsNavLinks: { [key: string]: string | boolean }[] = [];
  incitesLogo = 'data:image/png;base64,iVBORw0KGgoAAA' +
    'ANSUhEUgAAAEwAAAATCAQAAACQRiOCAAAABGd' +
    'BTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQA' +
    'APoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8A' +
    'AAAAmJLR0QA/4ePzL8AAAAHdElNRQflDA4SHw' +
    'R65wc6AAADMUlEQVRIx7XVb2jVVRgH8M+2u7Y' +
    'pzKblxCBdKIvWSrQXIkWaaFlvwjEMiTTC5ouK' +
    'epGsf75wKf31VWihUBItwQWD/th/RySmjKgQy' +
    'WJO6Y+C6eZqbm73enrR2fXuetnuLvk8L36/53' +
    'u+zznPec55nkOhMrlgzzxlvk6dOk3Nk1/jVZ8' +
    '5Keh10GuqR43e5B1bzfw/ArtTEATT8+AWe8I/k' +
    'T+i/V5UnGYcEgS7olWpTp2aKx1YwpdZQY3oTk' +
    'WRcVYQHIweqwTBgULCKp4At9lS8Jcm9cpc71G/g' +
    'UdsBklbDOrxUmE5KixjtxgSBIfMyEBv1C8IBkyL' +
    'yFQV6dErkrFJFpqrJG0/pxTD1jqVwfrZk6Dcuo' +
    'icNTDuqrMsylFsk9xqoSm5M9YqJWW9nZKCoNuyy' +
    'DsqCN7OsUydevVmmywVdQeqpFyMc6ek1IISzXo' +
    'j+ot70nMkbHY+4r9quDyw9wXBuYyLfd4cTJISB' +
    'I+PkYfJGcVAVVaB1CLhmyz0hej7ehZ+f+6jrNT' +
    'nEydAhccwNx561xiBJbU6nLaGtNoPzmjVqg/N7' +
    'kCfHdbbCzaqQ4km8IyVthqyXXvujH1vBop8LQ' +
    'g+z9j/OmPLpnTGyL78pQYEwepo7xEEbSiPJdSk' +
    'lP9udu6M7XYKwe6YP3piY6hTuNQqR9JKbdq0xf' +
    'dhHgZ9Ct502i7DUuP1sZ6M/5/Amsvayl0GXXDBy+M' +
    'GdgNIaIi6CMxWig06wBQPOeJ5Ennvd7v7cLU3rJZMo' +
    '9O8qwwXvTXuDMdA8Ky+UfhVhnVZolGDFSpVaPFR/p' +
    '3/49gqGh2wIPa3+drjgbTFZXNJefweNYgiZbbZZpsT+N' +
    'N+/bjGKns84DofggfzzxhPWWI2btNpQJdq18aRbz2c0y' +
    'MF5vnA315xRIvN2Oh2e9VZqxib/KjYe5Zr0u543Ohg7' +
    'qrcECduFATfpReqsivHE75XZZoxuipvzmAtRsK+LN8OFV' +
    'gW2/mI9qqZyCNOjzXutc+ZaA/psNiKrDtzSQ7bMuqBSlr' +
    'qab3ROq3FcgP4wgJfGYr4D+7WXTShwC7JTHP84Xg8rLGk' +
    'TLWkc/ozsFlqnHTM8ChmwhzTdftd4F+Yx2f/rwUKzgAAA' +
    'CV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0xMi0xNFQxNTozMT' +
    'owNCswMzowMEkKbOYAAAAldEVYdGRhdGU6bW9kaWZ5ADI' +
    'wMjEtMTItMTRUMTU6MzE6MDQrMDM6MDA4V9RaAAAAAElFTkSuQmCC';

  constructor(
    private environmentService: EnvironmentService,
    private icTogglesNgService: IcTogglesNgService,
    private globalData: Globals
  ) {
      this.envConfig = this.environmentService.getEnvironment().splitioTreatment;
      this.IS_CDX_HEADER_ON = this.env.feature.cdxHeaderFooter;
  }

  ngOnInit(): void {
    this.setSplitIOToggles();
  }

  loadProductLinks() {
    if (this.IS_CDX_HEADER_ON) {
      this.productsNavLinks = [
        { label: 'Web of Science' },
        { href: '/incites-app/redirect/wos', label: 'Web of Science' },
        { href: '/incites-app/redirect/mjl', label: 'Master Journal List' },
        { href: '/incites-app/redirect/publons', label: 'Publons' },
        {
          href: '/incites-app/redirect/jcr',
          label: 'Journal Citation Reports™',
        },
        {
          href: '/incites-app/redirect/esi',
          label: 'Essential Science Indicators',
        },
        {
          href: '/incites-app/redirect/rhn',
          label: 'Research Horizon Navigator™',
          hideInMenu: !this.rfNavLinkEnabled
        },
        { label: 'REFERENCE MANAGER' },
        { href: '/incites-app/redirect/endnote', label: 'EndNote' },
        { href: '/incites-app/redirect/endclick', label: 'EndNote Click' },
      ];
    } else {
      this.productsNavLinks = [
        { href: '/incites-app/redirect/wos', label: 'Web of Science' },
        { href: '#', label: 'InCites', active: true },
        {
          href: '/incites-app/redirect/jcr',
          label: 'Journal Citation Reports',
        },
        {
          href: '/incites-app/redirect/esi',
          label: 'Essential Science Indicators',
        },
        { href: '/incites-app/redirect/endnote', label: 'EndNote' },
        { href: '/incites-app/redirect/publons', label: 'Publons' },
      ];
    }
  }

  setSplitIOToggles() {
    this.subscription.add(
      this.icTogglesNgService.toggles().pipe(
        map((toggles) => {
          this.globalData.toggleData = toggles;
          this.rfNavLinkEnabled = toggles[this.envConfig.isRfNavLinkEnabled] === 'on';
          this.loadProductLinks();
        })
      ).subscribe()
    );
  }

}

// Downgrade component for use in AngularJS template
ajsAppModule.directive(
  'icGlobalHeader',
  downgradeComponent({
    component: GlobalHeaderComponent,
  }) as angular.IDirectiveFactory
);
