import { Injectable } from '@angular/core';
import { AnalysisIndicatorGroupModel } from 'pages/analysis/models/analysis-indicator-group.model';
import { AnalysisIndicatorModel } from 'pages/analysis/models/analysis-indicator.model';

import { assign } from 'lodash';

@Injectable()
export class AnalysisIndicatorService {

  constructor() {}

  getIndicators(rawIndicators: any[], enabled: string[], primary: string) { // tslint:disable-line no-any
    return rawIndicators.map(
      (indicator: AnalysisIndicatorModel) => this.getIndicator(indicator, enabled, primary)
    );
  }

  getGroupedIndicators(
    indicators: AnalysisIndicatorModel[],
    groups: any[], // tslint:disable-line no-any
  ): AnalysisIndicatorGroupModel[] {  
    return groups.map((group) => {
        return {
          ...group,
          indicators: indicators
            .filter(indicator => indicator.group === group.name)
            .sort(this.sortIndicatorsByOrder)
        };
      })
      .filter((group: AnalysisIndicatorGroupModel) => group.indicators!.length > 0);
  }

  getEnabledIndicators(indicators: AnalysisIndicatorModel[]) {
    if (typeof indicators === 'undefined') {
      return [];
    }
    return indicators.filter((indicator) => indicator.enabled || indicator.required);
  }

  getEnabledIndicatorsNames(indicators: AnalysisIndicatorModel[]) {
    return this.getEnabledIndicators(indicators).map((indicator) => indicator.name);
  }

  getRequiredIndicators(indicators: AnalysisIndicatorModel[]) {
    if (typeof indicators === 'undefined') {
      return [];
    }
    return indicators.filter((indicator) => indicator.required);
  }

  getRequiredIndicatorsNames(indicators: AnalysisIndicatorModel[]) {
    return this.getRequiredIndicators(indicators).map((indicator) => indicator.name);
  }

  private getIndicator(
    indicator: AnalysisIndicatorModel,
    enabledIndicators: string[],
    primaryIndicator: string
  ): AnalysisIndicatorModel {
    const required = this.isRequired(indicator);
    const enabled = this.isEnabled(indicator, enabledIndicators) || required;
    const isDefaultSort = indicator.name === primaryIndicator;

    return assign(indicator, {enabled, isDefaultSort, required});
  }

  private isEnabled(indicator: AnalysisIndicatorModel, enabledIndicators: string[]): boolean {
    return enabledIndicators.includes(indicator.name);
  }

  private isRequired(indicator: AnalysisIndicatorModel): boolean {
    return !!(indicator.required || indicator.group === 'dptmnt');
  }

  private sortIndicatorsByOrder(indicatorA: AnalysisIndicatorModel, indicatorB: AnalysisIndicatorModel) {
    const orderA = indicatorA.order || 0;
    const orderB = indicatorB.order || 0;
    if (orderA > orderB) return 1;
    else if (orderA < orderB) return -1;
    else return 0;
  }

}
