import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { AnalysisBenchmarkModel } from 'pages/analysis/models';


@Component({
  selector: 'ic-sidebar-baselines',
  templateUrl: 'sidebar-baselines.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarBaselinesComponent {
  @Input() baselines: AnalysisBenchmarkModel[] = [];
  @Output() benchmarkToggle = new EventEmitter<AnalysisBenchmarkModel>();

  constructor() {}

  onToggleClick(benchmark: AnalysisBenchmarkModel) {
    this.benchmarkToggle.emit(benchmark);
  }

}
