import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateServiceMock } from 'test/mocks/services/translate.service.mock';

import { DataRepositoryService } from './services/data-repository/data-repository.service';
import { DatasetsRepositoryService } from './services/datasets-repository/datasets-repository.service';
import { EntitiesRepositoryService } from './services/entities-repository/entities-repository.service';
import { FoldersRepositoryService } from './services/folders-repository/folders-repository.service';
import { LogEventService } from './services/log-event/log-event.service';
import { MetadataRepositoryService } from './services/metadata-repository/metadata-repository.service';
import { NotificationsRepositoryService } from './services/notifications-repository/notifications-repository.service';
import { PdfServerRendererService } from './services/pdf-server-renderer/pdf-server-renderer.service';
import { ReportsRepositoryService } from './services/reports-repository/reports-repository.service';
import { SettingsRepositoryService } from './services/settings-repository/settings-repository.service';
import { TileService } from '../../components/rest/services/tile/tile.service';
import { ExploreStateService } from 'components/rest/services/explore-state/explore-state.service';
import { ExportService } from './services/export/export.service';
import { BaselineIndicatorsService } from './services/baseline-indicators/baseline-indicators.service';
import { IcTogglesNgService } from 'components/common/services/ic-toggles-ng/ic-toggles-ng.service';

@NgModule({
  imports: [
    TranslateModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    DataRepositoryService,
    DatasetsRepositoryService,
    EntitiesRepositoryService,
    ExploreStateService,
    ExportService,
    FoldersRepositoryService,
    LogEventService,
    MetadataRepositoryService,
    NotificationsRepositoryService,
    PdfServerRendererService,
    ReportsRepositoryService,
    SettingsRepositoryService,
    TileService,
    BaselineIndicatorsService,
    IcTogglesNgService
  ]
})

export class RestModule {
  static forRoot(): ModuleWithProviders<RestModule> {
    return {
      ngModule: RestModule,
      providers: [
        { provide: TranslateService, useClass: TranslateServiceMock },
      ]
    };
  }
}
