import { Component, ViewEncapsulation, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FolderModel, FoldersComponentApiModel } from '@ic/component-lib/src/components/modules/folders/folders.model';
import { FoldersViewModelService } from 'components/view-models/services/folders-view-model/folders-view-model.service';
import { FoldersRepositoryService } from 'components/rest/services/folders-repository/folders-repository.service';
import { FoldersComponent } from '@ic/component-lib/src/components/modules/folders/folders.component';
import { FormControl } from '@angular/forms';
import { ErrorModel } from 'components/app/interfaces/error-model.interface';
import { FolderViewModelService } from 'components/view-models/services/folder-view-model/folder-view-model.service';
@Component({
  selector: 'ic-folders-listing',
  templateUrl: './folders-listing.component.html',
  encapsulation: ViewEncapsulation.None
})

export class FoldersListingComponent implements OnInit {

  @Input() parentFolder!: FormControl;
  @Input() folderIdToRemove!: number | string;
  @Input() selectedFolderId: number | string = 'root';
  @Input() noDefaultFolderSelection: boolean = false;
  @Output() updateFolders = new EventEmitter<{folders: Partial<FolderModel[]>, parentFolderId: number|string}>();

  public folders: FolderModel[] = [];
  public foldersApi: FoldersComponentApiModel = <FoldersComponentApiModel>{};
  public showLoader = false;

  @ViewChild(FoldersComponent, { static: true }) foldersComponent!: FoldersComponent;
  constructor(
    private foldersRepository: FoldersRepositoryService,
    private foldersViewModel: FoldersViewModelService,
    private folderViewModel: FolderViewModelService,
  ) { }

  ngOnInit() {
    this.loadFolders();
  }

  loadFolders() {
    this.showLoader = true;
    this.foldersRepository.getFoldersList('root')
      .then((folders) => {
        this.showLoader = false;
        this.folders = this.foldersViewModel.createRootArray(folders);
        if (typeof (this.folderIdToRemove) === 'string' && this.folderIdToRemove.includes(',')) {
          let val = this.folderIdToRemove.split(',');
          this.removeFolders(val);
        } else {
          if (typeof (this.folderIdToRemove) === 'string') {
            this.folderIdToRemove = Number(this.folderIdToRemove.trim());
          }
          // tslint:disable-next-line: no-any
          if (this.folderIdToRemove) this.removeFolders(this.folderIdToRemove);
        }
        this.selectFolder(this.folders[0], this.selectedFolderId);
      });
  }

  loadSubFolders(parentFolderId: number|string) {
    this.foldersRepository.getFoldersList(parentFolderId)
      .then((res: FolderModel[]) => {
        res.forEach(folder => {
          this.onFoldersDataUpdate({ savedFolder: folder, parentFolderId });
        });
        this.updateFolders.emit({folders: res, parentFolderId});
      })
      .catch((err: ErrorModel) => {
        console.error(err);
      });
  }

  onFoldersDataUpdate({ savedFolder, parentFolderId }: { savedFolder: FolderModel, parentFolderId: number | string }) {
    let parentFolder = (this.folders as any).find(parentFolderId); // tslint:disable-line: no-any
    let extSavedFolder = this.folderViewModel.getFolderViewModel(savedFolder, parentFolder);
    parentFolder.folders.push(extSavedFolder);
  }

  removeFolders(folderIds: string[] | number) {
    if (typeof folderIds === 'number') {
      // tslint:disable-next-line: no-any
      (this.folders as any).remove(folderIds);
    } else {
      folderIds.forEach((item) => {
        // tslint:disable-next-line: no-any
        (this.folders as any).remove(Number(item));
      });
    }
  }

  onFolderSelect(folder: FolderModel) {
    this.parentFolder.setValue(folder);
    if (folder?.hasSubFolders && !(folder?.folders?.length)) this.loadSubFolders(folder.id);
  }

  selectFolder(folder: FolderModel, folderId: number | string = 'root') {
    if (folder.id === folderId) {
      if (folder.folders && folder.folders.length > 0) {
        folder.open = true;
      }
      if (!this.noDefaultFolderSelection) {
        this.foldersComponent.selectFolder(folder);
      }

      return true;
    } else if (folder.folders && folder.folders.length > 0) {
      for (let i = 0; i < folder.folders.length; i += 1) {
        if (this.selectFolder(folder.folders[i], folderId)) {
          return true;
        }
      }
    }
    return false;
  }
}