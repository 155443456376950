import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { navigationModule } from '../../../../angularjs/navigation/navigation.module';

import { LocaleService } from '../../../common/services/locale/locale.service';
import { StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { ReportModel } from 'components/common/interfaces/report.model';
import { BreadcrumbItem } from '@ic/component-lib/src/components/modules/breadcrumbs/breadcrumbs.model';
import { BreadcrumbParams } from './navigation.model';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class NavigationService {
  private rootState = 'app'; // previously analytics
  private template = (options: { url: string, title: string}) => `<a href="${options.url}">${options.title}</a>`;
  private urlParser: any; // tslint:disable-line:no-any
  landing: string = `${this.rootState}.landing`;
  mySaved: string = `${this.rootState}.my-saved`;
  private analysisAndReportNav$ = new BehaviorSubject([]);
  navigationObs = this.analysisAndReportNav$.asObservable();

  constructor(
    private localeService: LocaleService,
    private stateService: StateService,
    private translate: TranslateService,
  ) {
    this.urlParser = document.createElement('a');
  }

  getReportState(params: any) { // tslint:disable-line:no-any
    return {
      state: `${this.rootState}.report`,
      params: {
        reportId: params.report.id,
        slug: params.report.safeTitle()
      },
      title: params.report.title
    };
  }

  getTabReportState(params: any) { // tslint:disable-line:no-any
    return {
      state: `${this.rootState}.tabreport`,
      href: this.localeService.href(`#/tabreports/${params.report.parentTabReportId}/${params.report.id ? '?selectedReportId=' + params.report.id : ''}`),
      params: {
        tabReportId: params.report.parentTabReportId,
        reportId: params.report.id
      },
      title: params.report.title
    };
  }

  buildBreadcrumbsAsync(params: BreadcrumbParams, callback: (_: BreadcrumbItem[]) => void | undefined) {
    callback(this.buildBreadcrumbs(params));
  }

  buildBreadcrumbs(params: BreadcrumbParams) {
    params = params || {};

    const myFolders = [
      {
        state: this.landing,
        href: this.localeService.href(this.stateService.href(this.landing, {})),
        title: this.translate.instant('nav.Analytics')
      },
      {
        state: this.mySaved,
        href: this.localeService.href(this.stateService.href(this.mySaved, {})),
        title: this.translate.instant('nav.My Folders')
      }
    ];

    const map: {[index: string]: any} = { // tslint:disable-line:no-any
      [this.mySaved]: myFolders,
      [`${this.mySaved}.reports`]: myFolders,
      [`${this.mySaved}.tiles`]: myFolders,
      'dashboard': [{
        state: 'app.dashboard',
        href: this.localeService.href(this.stateService.href('app.dashboard', {})),
        title: this.translate.instant('nav.Dashboard')
      }]
    };

    switch (params.state) {
      case `${this.rootState}.analysis`:
        if (params.report && params.tile) {
          if (params.report.isShared()) {
            map[`${this.rootState}.analysis`] = [{
              state: this.mySaved,
              href: this.localeService.href(this.stateService.href(this.mySaved, {})),
              title: this.translate.instant('nav.My Folders')
            }, {
              state: this.mySaved,
              href: this.localeService.href(this.stateService.href(this.mySaved, {})),
              title: this.translate.instant('nav.Shared with me')
            }, {
              state: `${this.rootState}.reports`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.reports`, {
                reportId: params.report.id,
                slug: params.report.safeTitle()
              })),
              params: {
                reportId: params.report.id,
                slug: params.report.safeTitle()
              },
              title: params.report.title
            }, {
              state: `${this.rootState}.analysis`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.analysis`, {})),
              title: params.tile.title
            }];
          } else if (params.report.subBuckets && params.report.parentTabReportId) {
            map[`${this.rootState}.analysis`] = [
              {
                state: this.landing,
                href: this.localeService.href(this.stateService.href(this.landing, {})),
                title: this.translate.instant('nav.Analytics')
              }, this.getTabReportState(params), {
                state: `${this.rootState}.analysis`,
                href: this.localeService.href(this.stateService.href(`${this.rootState}.analysis`, {})),
                title: params.tile.title
              }];
          } else if (params.report.system) {
            map[`${this.rootState}.analysis`] = [{
              state: this.landing,
              href: this.localeService.href(this.stateService.href(this.landing, {})),
              title: this.translate.instant('nav.Analytics')
            }, this.getReportState(params), {
              state: `${this.rootState}.analysis`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.analysis`, {})),
              title: params.tile.title
            }];
          } else if (params.report.dashboard) {
            map[`${this.rootState}.analysis`] = [{
              state: 'app.reports',
              href: this.localeService.href(this.stateService.href('app.reports', { reportId: ''})),
              title: this.translate.instant('nav.Dashboard')
            }, {
              state: `${this.rootState}.analysis`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.analysis`, {})),
              title: params.tile.title
            }];
          } else {
            map[`${this.rootState}.analysis`] = [{
              state: this.mySaved,
              href: this.localeService.href(this.stateService.href(this.mySaved, {})),
              title: this.translate.instant('nav.My Folders')
            }, {
              state: this.mySaved,
              href: this.localeService.href(this.stateService.href(this.mySaved, {})),
              title: this.translate.instant('nav.My Items')
            }, {
              state: `${this.rootState}.reports`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.reports`, {
                reportId: params.report.id,
                slug: params.report.safeTitle()
              })),
              params: {
                reportId: params.report.id,
                slug: params.report.safeTitle()
              },
              title: params.report.title
            }, {
              state: `${this.rootState}.analysis`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.analysis`, {})),
              title: params.tile.title
            }];

          }
        } else {
          map[`${this.rootState}.analysis`] = [
            {
              state: this.landing,
              href: this.localeService.href(this.stateService.href(this.landing, {})),
              title: this.translate.instant('nav.Analytics')
            },
            {
              state: `${this.rootState}.analysis`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.analysis`, {})),
              title: this.translate.instant('nav.Explore analyses')
            }
          ];
        }

        break;
      case `${this.rootState}.reports-landing`:
        map[`${this.rootState}.reports-landing`] = [
          {
            state: this.landing,
            href: this.localeService.href(this.stateService.href(this.landing, {})),
            title: this.translate.instant('nav.Analytics')
          },
          {
            state: `${this.rootState}.reports-landing`,
            href: this.localeService.href(this.stateService.href(`${this.rootState}.reports-landing`, {})),
            title: this.translate.instant('nav.Reports')
          }
        ];
        break;
      case `${this.rootState}.analysis-landing`:
        map[`${this.rootState}.analysis-landing`] = [
          {
            state: this.landing,
            href: this.localeService.href(this.stateService.href(this.landing, {})),
            title: this.translate.instant('nav.Analytics')
          },
          {
            state: `${this.rootState}.analysis-landing`,
            href: this.localeService.href(this.stateService.href(`${this.rootState}.analysis-landing`, {})),
            title: this.translate.instant('nav.Analysis')
          }
        ];
        break;
      case `${this.rootState}.my-folders`:
      case `${this.rootState}.my-saved`:
        map[this.mySaved] = [
          {
            state: this.landing,
            href: this.localeService.href(this.stateService.href(this.landing, {})),
            title: this.translate.instant('nav.Analytics')
          },
          {
            state: this.mySaved,
            href: this.localeService.href(this.stateService.href(this.mySaved, {})),
            title: this.translate.instant('nav.My Folders')
          }
        ];
        break;
      case `${this.rootState}.tabreport`:
        map[`${this.rootState}.tabreport`] = [{
          state: this.landing,
          href: this.localeService.href(this.stateService.href(this.landing, {})),
          title: this.translate.instant('nav.Analytics')
        }, {
          state: `${this.rootState}.tabreport`,
          href: this.localeService.href(this.stateService.href(`${this.rootState}.tabreport`, {})),
          title: params.report.title
        }];
        break;
      case `${this.rootState}.report`:
        if (params.report.system) {
          map[`${this.rootState}.report`] = [{
            state: this.landing,
            href: this.localeService.href(this.stateService.href(this.landing, {})),
            title: this.translate.instant('nav.Analytics')
          }, {
            state: `${this.rootState}.report`,
            href: this.localeService.href(this.stateService.href(`${this.rootState}.report`, {})),
            title: params.report.title
          }];
        } else if (params.report.isShared()) {
          map[`${this.rootState}.report`] = [{
            state: this.mySaved,
            href: this.localeService.href(this.stateService.href(this.mySaved, {})),
            title: this.translate.instant('nav.My Folders')
          }, {
            state: this.mySaved,
            href: this.localeService.href(this.stateService.href(this.mySaved, {})),
            title: this.translate.instant('nav.Shared with me')
          }, {
            state: `${this.rootState}.report`,
            href: this.localeService.href(this.stateService.href(`${this.rootState}.report`, {})),
            title: params.report.title
          }];
        } else {
          map[`${this.rootState}.report`] = [{
            state: this.mySaved,
            href: this.localeService.href(this.stateService.href(this.mySaved, {})),
            title: this.translate.instant('nav.My Folders')
          }, {
            state: this.mySaved,
            href: this.localeService.href(this.stateService.href(this.mySaved, {})),
            title: this.translate.instant('nav.My Items')
          }, {
            state: `${this.rootState}.report`,
            href: this.localeService.href(this.stateService.href(`${this.rootState}.report`, {})),
            title: params.report.title
          }];
        }
        break;
      case `${this.rootState}.reports`:
        if (params.report.system) {
          map[`${this.rootState}.reports`] = [{
            state: this.landing,
            href: this.localeService.href(this.stateService.href(this.landing, {})),
            title: this.translate.instant('nav.Analytics')
          }, {
            state: `${this.rootState}.reports`,
            href: this.localeService.href(this.stateService.href(`${this.rootState}.reports`, {})),
            title: params.report.title
          }];
        } else if (params.report.isShared()) {
          map[`${this.rootState}.reports`] = [{
            state: this.mySaved,
            href: this.localeService.href(this.stateService.href(this.mySaved, {})),
            title: this.translate.instant('nav.My Folders')
          }, {
            state: this.mySaved,
            href: this.localeService.href(this.stateService.href(this.mySaved, {})),
            title: this.translate.instant('nav.Shared with me')
          }, {
            state: `${this.rootState}.reports`,
            href: this.localeService.href(this.stateService.href(`${this.rootState}.reports`, {})),
            title: params.report.title
          }];
        } else {
          map[`${this.rootState}.reports`] = [{
            state: this.mySaved,
            href: this.localeService.href(this.stateService.href(this.mySaved, {})),
            title: this.translate.instant('nav.My Folders')
          }, {
            state: this.mySaved,
            href: this.localeService.href(this.stateService.href(this.mySaved, {})),
            title: this.translate.instant('nav.My Items')
          }, {
            state: `${this.rootState}.reports`,
            href: this.localeService.href(this.stateService.href(`${this.rootState}.reports`, {})),
            title: params.report.title
          }];
        }
        break;
      case `${this.rootState}.explore`:
        if (params.report && params.tile) {
          if (params.report.isShared()) {
            map[`${this.rootState}.explore`] = [{
              state: this.mySaved,
              href: this.localeService.href(this.stateService.href(this.mySaved, {})),
              title: this.translate.instant('nav.My Folders')
            }, {
              state: this.mySaved,
              href: this.localeService.href(this.stateService.href(this.mySaved, {})),
              title: this.translate.instant('nav.Shared with me')
            }, {
              state: `${this.rootState}.report`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.report`, {
                reportId: params.report.id,
                slug: params.report.safeTitle()
              })),
              params: {
                reportId: params.report.id,
                slug: params.report.safeTitle()
              },
              title: params.report.title
            }, {
              state: `${this.rootState}.explore`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.explore`, {})),
              title: params.tile.title
            }];
          } else if (params.report.subBuckets && params.report.parentTabReportId) {
            map[`${this.rootState}.explore`] = [
              {
                state: this.landing,
                href: this.localeService.href(this.stateService.href(this.landing, {})),
                title: this.translate.instant('nav.Analytics')
              }, this.getTabReportState(params), {
              state: `${this.rootState}.explore`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.explore`, {})),
              title: params.tile.title
            }];
          } else if (params.report.system) {
            map[`${this.rootState}.explore`] = [{
              state: this.landing,
              href: this.localeService.href(this.stateService.href(this.landing, {})),
              title: this.translate.instant('nav.Analytics')
            }, this.getReportState(params), {
              state: `${this.rootState}.explore`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.explore`, {})),
              title: params.tile.title
            }];
          } else if (params.report.dashboard) {
            map[`${this.rootState}.explore`] = [{
              state: 'app.dashboard',
              href: this.localeService.href(this.stateService.href('app.dashboard', {})),
              title: this.translate.instant('nav.Dashboard')
            }, {
              state: `${this.rootState}.explore`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.explore`, {})),
              title: params.tile.title
            }];
          } else {
            map[`${this.rootState}.explore`] = [{
              state: this.mySaved,
              href: this.localeService.href(this.stateService.href(this.mySaved, {})),
              title: this.translate.instant('nav.My Folders')
            }, {
              state: this.mySaved,
              href: this.localeService.href(this.stateService.href(this.mySaved, {})),
              title: this.translate.instant('nav.My Items')
            }, {
              state: `${this.rootState}.report`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.report`, {
                reportId: params.report.id,
                slug: params.report.safeTitle()
              })),
              params: {
                reportId: params.report.id,
                slug: params.report.safeTitle()
              },
              title: params.report.title
            }, {
              state: `${this.rootState}.explore`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.explore`, {})),
              title: params.tile.title
            }];
          }
        } else {
          map[`${this.rootState}.explore`] = [[
            {
              state: `${this.rootState}.explore`,
              href: this.localeService.href(this.stateService.href(`${this.rootState}.explore`, {})),
              title: this.translate.instant('nav.New Tile')
            }
          ]];
        }
        break;
      default:
    }

    return map[params.state];
  }

  localizeLink(url: string) {
    this.urlParser.href = url;
    return this.urlParser.href + (this.urlParser.search ? '&' : '?') + `lc=${this.localeService.id.toLowerCase()}`;
  }

  buildLink(url: string, title: string) {
    return this.template({ url: url, title: title });
  }

  buildReportLink(report: ReportModel) {
    return this.buildLink(
      this.localeService.href(this.stateService.href(`${this.rootState}.report`, { reportId: report.id, slug: report.safeTitle!() })),
      report.title);
  }

  buildDashboardLink() {
    return this.buildLink(this.localeService.href(this.stateService.href('app.dashboard', {})), this.translate.instant('nav.Dashboard'));
  }

  buildReportLinkModerize(report: ReportModel) {
    return this.buildLink(
      this.localeService.href(this.stateService.href(`${this.rootState}.reports`, { reportId: report.id})),
      report.title);
  }

  buildDashboardLinkModerize() {
    return this.buildLink(this.localeService.href(this.stateService.href('app.reports', { reportId: ''})), this.translate.instant('nav.Dashboard'));
  }

  buildFoldersLink() {
    return this.buildLink(this.localeService.href(this.stateService.href(this.mySaved, {})), this.translate.instant('nav.My Folders'));
  }


  // tslint:disable-next-line: no-any
  setAnalysisAndReportNav(navigation: any) {
    this.analysisAndReportNav$.next(navigation);
  }

}

// Downgrade this service for use in AngularJS module
navigationModule.factory('navigation', downgradeInjectable(NavigationService));
