import { commonModule } from '../../../../angularjs/common/common.module';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import { WindowRefService } from 'components/common/window/window-ref.service';

@Injectable()
export class BrowserDetectionService {

  private window: Window;

  constructor(private windowRef: WindowRefService) {
    this.window = this.windowRef.nativeWindow();
  }

  isIE11(): boolean {
    // @ts-ignore: IE11 detection hack
    return !!this.window.MSInputMethodContext && !!this.window.document.documentMode;
  }
}

// Register downgraded Angular providers with this AngularJS module.
commonModule.factory('BrowserDetectionService', downgradeInjectable(BrowserDetectionService));
