import { AnalysisItemModel } from 'pages/analysis/models/analysis-item.model';
import { EntityModel } from 'components/rest/services/entities-repository/entity.model';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isObject, isUndefined } from 'lodash';

@Injectable()
export class AnalysisEntityService {
  private readonly entityNameProps: { [key: string]: string } = {
    person: 'prsnName',
    organization: 'orgName',
    region: 'rgnName',
    subject: 'sbjName',
    funder: 'funder',
    journal: 'jrnName',
    department: 'deptName'
  };

  private readonly entityTitles: { [key: string]: string } = {
    organization: 'analytics.export.Organizations',
    person: 'analytics.export.Researchers',
    region: 'analytics.export.Locations',
    subject: 'analytics.export.Research Areas',
    journal: 'analytics.export.Journals',
    funder: 'analytics.export.Funder'
  };

  private readonly excludeFilterNames: { [key: string]: string } = {
    person: 'personId',
    organization: 'orgname',
    region: 'location',
    subject: 'sbjname',
    funder: 'funderOrPublisher',
    journal: 'jrnname',
    department: 'departments'
  };

  private readonly myOrgExcludeFilterName: { [key: string]: string } = {
    person: 'wppersonId',
    organization: 'wpdepts',
    region: 'location',
    subject: 'sbjname',
    funder: 'funderOrPublisher',
    journal: 'jrnname',
  };

  constructor(private translate: TranslateService) {}

  getNameFilterId(id: string) {
    return this.excludeFilterNames[id];
  }

  getMyOrgNameFilterId(id: string) {
    return this.myOrgExcludeFilterName[id];
  }

  getNamePropForEntity(entity: EntityModel) {
    return this.getNamePropForEntityId(entity.id);
  }

  getNamePropForEntityId(id: string) {
    return this.entityNameProps[id];
  }

  getRowNameForEntity(row: AnalysisItemModel, entity: EntityModel): string {
    let nameProp = this.getNamePropForEntity(entity);
    let name: string | undefined | { value: string; } = row[nameProp];

    return !isUndefined(name) ?
      // @ts-ignore
      isObject(name) ? name.value : name :
      row.key;
  }

  getSingularEntityName(entity: EntityModel): string {
    const key = `analysis.entity.singular.${entity.id}`;
    return this.translate.instant(key) || entity.title;
  }

  getPluralEntityName(entity: EntityModel): string {
    const key = `analysis.entity.plural.${entity.id}`;
    return this.translate.instant(key) || entity.title;
  }

  getLocalizedTitle(entity: string) {
    return this.entityTitles[entity] ? this.translate.instant(this.entityTitles[entity]) : '';
  }

}
