/// <reference path='./index.d.ts'/>

import * as angular from 'angular';
import { StateDeclaration, StateRegistry, UrlService } from '@uirouter/core';
import { newTracker } from '@snowplow/browser-tracker';
// import { Trace } from '@uirouter/core'; // Uncomment this line if you need state trace
import { upgradeModule } from '@uirouter/angular-hybrid';

import { appState } from './app.states';
import { authModule } from '../auth';
import { navigationModule } from '../navigation';
import { securityModule } from '../security';

export const ajsAppModule = angular.module('incitesApp', [
  authModule.name,
  navigationModule.name,
  securityModule.name,
  upgradeModule.name,
]);

// Using AngularJS config block, call `deferIntercept()`.
// This tells UI-Router to delay the initial URL sync (until all bootstrapping is complete)
ajsAppModule.config(['$urlServiceProvider', ($urlService: UrlService) => {
  return $urlService.deferIntercept();
}]);

// @ts-ignore
ajsAppModule.config(['$httpProvider', ($httpProvider) => {
  const lang = document.documentElement.lang;
  $httpProvider.defaults.headers.common['Accept-Language'] = lang.match(/en-US|ru|zh/) ? lang : 'en-US';
}]);

// Default state loads the Angular component "HomeComponent"
export const DEFAULT_STATE = 'app.landing';

// Register router states
ajsAppModule.run([
  '$stateRegistry',
  '$urlService',
  '$trace',
(
  $stateRegistry: StateRegistry,
  $urlService: UrlService // , $trace: Trace // Uncomment this line if you need state trace

) => {
  $urlService.rules.initial({ state: DEFAULT_STATE });

  // $trace.enable('TRANSITION', 'VIEWCONFIG'); // Uncomment this line if you need state trace
  // You cannot nest AngularJS substates within an Angular component (limitation of ui-router)
  // So the AngularJS app component provides the outermost chrome for AngularJS or Angular substates

  $stateRegistry.register(appState as StateDeclaration);
}]);

newTracker('newTracker', (() => {
  switch (window.location.hostname) {
    case 'localhost':
    case 'incites.int1.dev-incites.com':
    case 'incites.int2.dev-incites.com':
    case 'incites.qa1.dev-incites.com':
    case 'incites.qa2.dev-incites.com':
    case 'incites.qa3.dev-incites.com':
      return 'snowplow-collector.staging.userintel.dev.sp.aws.clarivate.net';
    case 'incites.clarivate.com':
    default:
      return 'snowplow-collector.userintel.prod.sp.aws.clarivate.net';
  }
})(), {
  encodeBase64: false,
  appId: 'incites',
  eventMethod: 'get',
  platform: 'web',
  discoverRootDomain: true,
});