import { Injectable } from '@angular/core';
import { WindowRefService } from 'components/common/window/window-ref.service';

export enum FileTypes {
  csv = 'csv',
  pdf = 'pdf',
  png = 'png',
  svg = 'svg'
}

@Injectable()
export class FileSystemService {

  private mimeTypes: {[index: string]: string} = {
    'csv': 'text/csv',
    'pdf': 'application/pdf',
    'png': 'image/png',
    'svg': 'image/svg+xml'
  };
  private window: Window;

  constructor(windowRef: WindowRefService) {
    this.window = windowRef.nativeWindow();
  }

  saveFile(base64: string, type: FileTypes, fileName: string): void {
    if (base64.indexOf('data') !== -1) {
      base64 = base64.split(',')[1];
    }

    const blobObject = this.b64toBlob(base64, this.mimeTypes[type]);

    return this.save(blobObject, type, fileName);
  }

  saveTextFile(data: string, type: FileTypes, fileName: string) {
    const blobObject = new Blob(['\uFEFF' + data], {type: 'text/csv'});

    return this.save(blobObject, type, fileName);
  }

  stringToFilename(str: string): string {
    return str.replace(/["\/<>#%\{\}\|\\\^~\[\]`;\?:@=&]/gi, '').toLowerCase();
  }

  private save(blobObject: Blob, type: FileTypes, fileName: string) {
    fileName = `${fileName}.${type}`;

    if (this.window.navigator.msSaveOrOpenBlob) { // MS browsers
      this.window.navigator.msSaveOrOpenBlob(blobObject, fileName);
    } else { // All other browsers
      const data = URL.createObjectURL(blobObject);
      const link = this.window.document.createElement('a');
      link.href = data;
      link.download = fileName;
      link.click();
    }
  }

  private b64toBlob(b64Data: string, contentType: string, sliceSize?: number): Blob {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  }

}
