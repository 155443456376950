import { Injectable } from '@angular/core';
import { TogglesState } from '@wos/toggles-core';
import { wosToggleClient } from '@wos/toggles-client';
import { Observable, Observer } from 'rxjs';

@Injectable()
export class IcTogglesNgService {
  private readonly state: Observable<TogglesState>;

  constructor() {
    // tslint:disable-next-line:no-any
    this.state = new Observable<any>((obs: Observer<any>) => {
      if (wosToggleClient.on) {
        wosToggleClient.on('change', obs.next.bind(obs));
      }
    });
  }

  // tslint:disable-next-line:no-any
  public init(key: string, attributes: any = {}) {
    if (wosToggleClient.init) {
      wosToggleClient.init(key, attributes);
    }
  }

  public reset() {
    if (wosToggleClient.reset) {
      wosToggleClient.reset();
    }
  }

  public toggles(): Observable<TogglesState> {
    return this.state;
  }
}