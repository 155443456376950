<div class="ic-analysis-sidebar-wrapper" [ngClass]="{'disableDiv':multiTrendFlag}">
  <aside class="ic-analysis-sidebar">
    <cl-tab-nav class="ic-analysis-sidebar__tabs">
      <cl-tab-pane
        [tabId]="'analysis-sidebar-filters'"
        [title]="'analysis.sidebar.filters.Filters' | translate"
      >
        <ic-sidebar-filters
          [datasets]="state.datasets"
          [includeEsciDocuments]="state.includeEsciDocuments"
          [filters]="state.filters"
          (datasetChange)="datasetChange.emit($event)"
          [outsideClickEvent]="outsideClickEvent"
          (esciToggle)="esciToggle.emit($event)"
          (filterApply)="onFilterApply($event)"
          (inputFilters) = "emittedInputFilters($event)"
          [context] = "context"
          [entity]="state.currentEntity"
          (showChooseTreeModal)="showChooseTreeModal.emit($event)"
          [dataUpdateInfo]="state.dataUpdateInfo"
        ></ic-sidebar-filters>
      </cl-tab-pane>
      <cl-tab-pane
        [tabId]="'analysis-sidebar-indicators'"
        [title]="'analysis.sidebar.indicators.Indicators' | translate"
        *ngIf="state.indicators"
      >
        <ic-sidebar-indicators
          [indicators]="state.indicators"
          (indicatorToggle)="indicatorToggle.emit($event)"
          [activeTabIndex]="activeTabIndex"
          [state]="state"
          [reputationGrp] = "reputationGrp"
        ></ic-sidebar-indicators>
      </cl-tab-pane>
      <cl-tab-pane
        *ngIf="state.benchmarks"
        [tabId]="'analysis-sidebar-baselines'"
        [title]="'analysis.sidebar.baselines.Baselines' | translate"
      >
        <ic-sidebar-baselines
          [baselines]="state.benchmarks"
          (benchmarkToggle) = "benchmarkToggle.emit($event)"
        ></ic-sidebar-baselines>
      </cl-tab-pane>
    </cl-tab-nav>
  </aside>
</div>
