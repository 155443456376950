import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { OverbarService } from './services/overbar/overbar.service';

@NgModule({
  imports: [TranslateModule]
})

export class OverbarModule {  static forRoot(): ModuleWithProviders<OverbarModule> {
  return {
    ngModule: OverbarModule,
    providers: [OverbarService]
  };
}}

