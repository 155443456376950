<form name="form" action="{{ url }}" method="{{ method }}" novalidate>
  <div class="popup-header">
    <h3 translate="analytics.export.Export These Results"></h3>
  </div>
  <div class="popup-body">
    <input *ngIf="method === 'post'" type="hidden" id="params" name="params" value="{{ params }}">
    <div *ngIf="method === 'get'">
      <input
        type="hidden"
        *ngFor="let param of params"
        id="{{ param.name }}"
        name="{{ param.name }}"
        value="{{param.value}}" />
    </div>
    <div class="row">
      <label for="fileName" translate="analytics.export.File Name"></label>
      <input
        id="fileName"
        name="fileName"
        type="text"
        [(ngModel)]="file.name"
        required
        maxlength="100"
        placeholder="{{ 'analytics.export.File Name' | translate }}" />
    </div>
    <div class="row clearfix">
      <div class="right">
        <label for="records">{{ 'analytics.export.Records' | translate }} <span class="max-records" title="{{ 'analytics.export.MaxRecords' | translate:{ maxRecords: maxRecords } }}">{{ maxRecords | number }}</span></label>
        <input
          id="records"
          type="number"
          min="1"
          max="{{ maxRecords }}"
          name="records"
          [(ngModel)]="file.records"
          required
          placeholder="{{ maxRecords }} {{ 'analytics.export.Records' | translate }}"
        />
      </div>
      <div class="left">
        <label for="fileType" translate="analytics.export.File Type"></label>
        <input
          id="fileType"
          name="fileType"
          type="text"
          [(ngModel)]="file.type"
          [disabled]="true"
          required
          placeholder="{{ 'analytics.export.File Type' | translate }}"
        />
      </div>
    </div>
  </div>
  <div class="popup-footer">
    <div class="trend-data" *ngIf="trendDataIsAvailable">
      <input id="trendData" name="trendData" type="checkbox" [(ngModel)]="file.trendData" />
      <label for="trendData" translate="analytics.export.Trend Data"></label>
    </div>
    <button
      type="submit"
      class="btn btn-primary"
      (click)="submitAndClose()"
      translate="analytics.export.Export"
    ></button>
  </div>
</form>