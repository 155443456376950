<cl-popover-modal
  [options]="{isContext: true, closeOnOutsideClick: false, modifiers: 'no-arrow', modalWidth: '313px'}"
  (onOpen)="downloadPdf()"
>
  <cl-popover-modal-button
    [buttonClass]="'cl-btn cl-btn--secondary cl-btn--with-icon ic-report__header__actions__btn'"
  >
    <cl-icon [iconId]="'download'" [label]="'dashboard.Download PDF' | translate"></cl-icon>
    <span aria-hidden="true" class="cl-btn__text">{{'dashboard.Download PDF' | translate}}</span>
  </cl-popover-modal-button>

  <cl-popover-modal-content>
    <cl-loading-indicator [label]="'Downloading report...'"></cl-loading-indicator>
  </cl-popover-modal-content>

</cl-popover-modal>

<button tabindex="-1" id="legacy-export" class="visually-hidden" (click)="requestAndDownload()">&dArr;</button>
<button tabindex="-1" id="export-pdf" class="visually-hidden" 
aria-label="Download PDF" aria-labelledby="Download PDF" title="Download PDF"(click)="exportPdf()">Download PDF</button>
<code id="pdf-base64" class="visually-hidden">{{ pdfBase64 }}</code>