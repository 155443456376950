import {EventEmitter, Injectable} from '@angular/core';
import { DataRepositoryService } from 'components/rest/services/data-repository/data-repository.service';
import { MetadataRepositoryService } from 'components/rest/services/metadata-repository/metadata-repository.service';
import { ReportTileParamsModel, ReportTileVisualisationModel, ReportTileRequestModel } from 'components/common/interfaces/report.model';
import { map, mergeMap, share } from 'rxjs/operators';
import {isString, take, cloneDeep, extend, assign } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import {ExploreTileResponseItemModel, ExploreTileResponseModel} from 'components/common/interfaces/exploretile.response.model';
import { TileData, ReportTileModel } from 'components/common/interfaces/report-tile.model';
import { Indicator } from 'components/common/interfaces/indicator.interface';
import { BaselineIndicatorsService } from '../baseline-indicators/baseline-indicators.service';
import { isPlainObject } from 'jquery';
import { isUndefined } from 'angular';
import {SettingsRepositoryService} from 'components/rest/services/settings-repository/settings-repository.service';
import {RequestParamsModel} from 'pages/analysis/models/analysis-state.model';

@Injectable()
export class TileService {
  private minItems: number;
  private IS_GROUP_ENABLED: boolean = false;
  private selectedTilesCount: number = 0;
  tileLoadFinished = new EventEmitter<boolean>();
  allTileData: { id: string , params: string}[] = [];
  ignoredIndicatorKeys: string[];

  constructor(
    private dataRepository: DataRepositoryService,
    private environmentService: EnvironmentService,
    private metadataRepository: MetadataRepositoryService,
    private http: HttpClient,
    private baselineIndicatorsService: BaselineIndicatorsService,
    private settingsRepository: SettingsRepositoryService,
  ) {
    const env = this.environmentService.getEnvironment();
    this.minItems = env && env.explore && env.explore.minItems || 25;
    this.ignoredIndicatorKeys = env && env.tileService && env.tileService.ignoredIndicatorKeys || [];
    this.IS_GROUP_ENABLED = env.feature.groups;
  }

  getValues(tile: ReportTileModel): Observable<TileData> {
    return this.getData(tile).pipe(
      map((data => <TileData>Object.assign(data, { noData: this.getNoData(tile.tileGroup, data) })))
    );
  }

  setSelectedTilesCount(checked: boolean) {
    checked ? this.selectedTilesCount++ : this.selectedTilesCount--;
  }

  resetSelectedTilesCount() {
    this.selectedTilesCount = 0;
  }

  getSelectedTilesCount() {
    return this.selectedTilesCount;
  }

  backupTileParams(tileData: { id: string , params: string}) {
    this.allTileData.push(tileData);
  }

  getTileParamsById(tileId: string): string {
    let tileData = this.allTileData.find(({id}) => id === tileId);
    return tileData ? tileData.params : '';
  }
  private getData(tile: ReportTileModel): Observable<Partial<TileData>> {
    switch (tile.tileGroup) {
      case 'domino':
        return this.getDominoValues(tile);
      case 'double_domino':
        return this.getDoubleDominoValue(tile);
      case 'explore':
        return this.getExploreValues(tile);
      case 'rich':
      case 'table-list':
        return this.dataRepository.getTableTileValuesAndIndicators(<string>tile.url);
      default:
        return this.getRetiredValue();
    }
  }

  private getRetiredValue(): Observable<Partial<TileData>> {
    return of({ value: 'retired' });
  }

  // tslint:disable-next-line: no-any
  mapForDominoValues(response: any, tile: ReportTileModel) {
    let result;

    if (tile.totalUrl) {
      result = { value: response.totalItems };
    } else {
      if (!isUndefined(response.items[0]) && response.items[0].value.length > 0) {
        result = this.returnIndicatorValue(response.items[0].value[0][tile.tileIndicator]);
      } else {
        result = 0;
      }
    }
    return { value: result };
  }

  private getDominoValues(tile: ReportTileModel): Observable<Partial<TileData>> {
    if (tile.newDominoTile) {
      try {
        const parsedTileParam = JSON.parse(tile.params);
        const parsedTileVis = JSON.parse(tile.vis);
        return this.getBenchmarks(parsedTileParam, parsedTileVis, tile).pipe(
          // tslint:disable-next-line: no-any
          map((response: any) => this.mapForDominoValues(response, tile)));
      } catch (error) {
        console.log(error);
      }
    }
    return this.http.get(<string>tile.url).pipe(
      map((response: any) => ({ value: response }))// tslint:disable-line:no-any
    );
  }

  // tslint:disable-next-line: no-any
  private returnIndicatorValue(item: any) {
    const isValObj = item != null && typeof (item) !== 'string' && isPlainObject(item);
    const val = item.value != null ? item.value : 0;
    return { value: isValObj ? val : item };
  }

  private getDoubleDominoValue(tile: ReportTileModel): Observable<Partial<TileData>> {
    const urls = typeof tile.url === 'string' ? [tile.url] : tile.url;
    const requests = urls.map(url => this.http.get(url).pipe(map((response: any) => response.data))); // tslint:disable-line:no-any
    // TODO: we don't have double dominos in new reports. Possible we dont need this at all
    // @ts-ignore
    return new Observable().zip(...requests);
  }

  // tslint:disable-next-line no-any
  mapForExploreValues(data: any[], vis: ReportTileVisualisationModel) {
    let result = this.baselineIndicatorsService.updateBaselineIndicators(data[0], data[2]);
    if (Object.keys(data[3]).length > 0 && data[3].items.length > 0) {
      result[0] = this.settingsRepository.getCombinedDataItems(data[3].items, result[0], data[3].take as number, vis.dataUrl);
    }
    return { value: result[0], indicators: data[1], benchmarks: result[1] };
  }

  private getExploreValues(tile: ReportTileModel): Observable<Partial<TileData>> {
    try {
      const vis: ReportTileVisualisationModel = JSON.parse(tile.vis);
      const params: ReportTileParamsModel = this.getParamsWithDefaults(tile.params, vis) as unknown as ReportTileParamsModel;
      const benchmarks = this.getBenchmarks(params, vis, tile);
      const { value, indicators } = this.getValuesAndIndicators(params, vis);
      const groups = this.getGroups(vis.groupDataUrl as string, params);

      // @ts-ignore
      return forkJoin([value, indicators, benchmarks, groups]).pipe(
        map((data) => this.mapForExploreValues(data, vis)));
    } catch (error) {
      console.log(error);
      return new Observable();
    }
  }

  mapItemForGroups(item: ExploreTileResponseItemModel, request: ReportTileRequestModel) {
    item.pinned = !isUndefined(request.groupPinned) && request.groupPinned.includes(item.key);

    return item;
  }

  getGroups(groupDataUrl: string, params: ReportTileParamsModel): Observable<ExploreTileResponseModel> {
    const getgroupsItems = (url: string) => {
      const requestParamsCopy = cloneDeep(params.request);
      const payload = extend( requestParamsCopy, { compositeEntityKey : params.compositeEntityKey });
      // @ts-ignore
      return this.dataRepository.groups(params.datasetId, params.entity, payload, params.queryDataCollection, url).pipe(map((data: ExploreTileResponseModel) => {
        data.items = data.items.map((item: ExploreTileResponseItemModel) => this.mapItemForGroups(item, requestParamsCopy));

        return data;
      }));
    };

    return (groupDataUrl && this.IS_GROUP_ENABLED) ? getgroupsItems(groupDataUrl) : of({} as ExploreTileResponseModel);
  }

  workaroundForBadParams(params: ReportTileParamsModel) {
    if (params.request) {
      if (params.request.sortBy && params.request.sortBy.match(/\${[^}]+}/)) {
        params.request.sortBy = 'timesCited';
      }
      // @ts-ignore
      if (params.request.sortOrder && params.request.sortOrder.match(/\${[^}]+}/)) {
        params.request.sortOrder = 'desc';
      }
    }
    return params;
  }

  private getParamsWithDefaults(unparsed: string, vis: ReportTileVisualisationModel) {
    try {
      let params: ReportTileParamsModel = JSON.parse(unparsed);

      if (params.request && !params.request.take && vis.type !== 'multiIndicatorLine chart' && vis.type !== 'radar chart' &&
        (vis.type !== 'line chart' && vis.itemsCount !== 0)) {
        params.request.take = this.minItems;
      }
      return this.workaroundForBadParams(params);
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  private getBenchmarks(params: ReportTileParamsModel, vis: ReportTileVisualisationModel, tile: ReportTileModel): Observable<Object> {
    if (params.benchmarks && params.benchmarks.length) {
      if (vis.benchmarksUrl) {
        const request: ReportTileRequestModel = {
          ...params.request,
          benchmarkNames: params.benchmarks,
          extraType: params.request.extraType,
        };

        return this.dataRepository.exploreByUrl(
          vis.benchmarksUrl,
          request,
          params.queryDataCollection
        );
      } else {
        return this.dataRepository.getBenchmarks(
          params.datasetId,
          params.entity,
          params.request,
          params.queryDataCollection
        );
      }
    } else if (tile.totalUrl) {
      return this.dataRepository.exploreByUrl(
        tile.totalUrl,
        params.request,
        params.queryDataCollection
      );
    }

    return of({});
  }

  getAlmaCount(params: ReportTileParamsModel, subscribedCountUrl: string) {
    return this.dataRepository.exploreByUrl(
      subscribedCountUrl,
      params.request,
      params.queryDataCollection
    );
  }

  mapForMarkingPinnedItems(item: ExploreTileResponseItemModel, pinned: string[]) {
    item.pinned = !isUndefined(pinned) && pinned.includes(item.key);

    return item;
  }

  markPinnedItems(items: ExploreTileResponseItemModel[], pinned: string[]) {
    return items.map((item) => this.mapForMarkingPinnedItems(item, pinned));
  }

  mergeMapForValuesAndIndicators(indicatorsList: Indicator[], params: ReportTileParamsModel, vis: ReportTileVisualisationModel) {
    const { dimensions, type } = vis;

    if (type === 'map chart' || type === 'map amCharts') {
      // @ts-ignore
      const dimensionIndicator = dimensions && dimensions.value;

      if (dimensionIndicator && isString(dimensionIndicator)) {
        const indicator = indicatorsList.find((ind) => ind.name === dimensionIndicator) as Indicator;

        if (!indicator) return this.metadataRepository.getIndicators(params.entity, params.datasetId).pipe(map((data) => { return data.indicators; }));
      }
    }
    if (type === 'alma-analysis-list') {
      return this.sortableTableIndicators(indicatorsList, params);
    }
    return of(indicatorsList);
  }

  sortableTableIndicators(indicatorsList: Indicator[], params: ReportTileParamsModel) {
    return this.metadataRepository.getIndicators(params.entity, params.datasetId).pipe(map((data) => {
      let indicators: Indicator[] = cloneDeep(indicatorsList);
      let indicatorsByName: { [key: string]: Indicator } = {};

      for (let indicator of data.indicators) {
        indicatorsByName[indicator.name] = indicator;
      }
      for (let indicator of indicators) {
        if (indicatorsByName[indicator.name]) {
          indicator = assign(indicator, indicatorsByName[indicator.name]);
          for (let key in indicator) {
            if (this.ignoredIndicatorKeys.includes(key)) {
              // @ts-ignore
              delete indicator[key];
            }
          }
        }
      }
      return indicators;
    }));
  }

  private getValuesAndIndicators(
    params: ReportTileParamsModel,
    vis: ReportTileVisualisationModel
  ) {
    let value: Observable<ExploreTileResponseItemModel[]>;
    let indicators: Observable<Indicator[]>;

    if (vis && vis.dataUrl && vis.itemsCount !== 0) {
      const values = this.dataRepository.exploreByUrl(
        vis.dataUrl,
        params.request,
        params.queryDataCollection
      ).pipe(share());

      value = values.pipe(
        map(response => this.markPinnedItems(response.items, params.request.pinned || []))
      );

      indicators = values.pipe(
        map(response => response.indicators),
        mergeMap((indicatorsList: Indicator[]) => this.mergeMapForValuesAndIndicators(indicatorsList, params, vis))
      );
    } else {
      value = this.dataRepository
        .explore(params.datasetId, params.entity, params.request as RequestParamsModel, params.queryDataCollection).pipe(
          map((data) => take(data.items, params.request.take)),
          map(items => this.markPinnedItems(items, params.request.pinned || []))
        );

      indicators = this.metadataRepository
        .getIndicators(params.entity, params.datasetId).pipe(
          map((data) => { return data.indicators; })
        );
    }

    return { value, indicators };
  }

  private getNoData(tileGroup: string, data: any): boolean { // tslint:disable-line:no-any

    switch (tileGroup) {
      case 'rich':
      case 'explore':
      case 'table-list':
        const noValues = !(data && data.value && data.value.length > 0);
        let noBenchmarks = false;
        if (data.benchmarks &&
          data.benchmarks.items &&
          data.benchmarks.items[0] &&
          data.benchmarks.items[0].value &&
          data.benchmarks.items[0].value.length === 1 &&
          data.benchmarks.items[0].value[0].hasOwnProperty('wosDocuments') &&
          data.benchmarks.items[0].value[0].wosDocuments === 0) {
          noBenchmarks = true;
        } else {
           noBenchmarks = !(data.benchmarks && data.benchmarks.items && (data.benchmarks.items.length > 0 && data.benchmarks.items[0].value.length > 0));
        }
        return noValues && noBenchmarks;
      case 'domino':
      default:
        return false;
    }
  }

}