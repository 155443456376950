import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { CommonModule } from '../../components/common/common.module';
import { CommonModule as NgCommonModule, DatePipe} from '@angular/common';

import { IconModule } from '@ic/component-lib/src/components/modules/icon/icon.module';
import { LoadingIndicatorModule } from '@ic/component-lib/src/components/modules/loading-indicator/loading-indicator.module';
import { MultiSelectModule } from '@ic/component-lib/src/components/modules/multi-select/multi-select.module';
import { NavigationModule } from '@ic/component-lib/src/components/modules/navigation/navigation.module';
import { OverlayModalModule } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.module';
import { PopoverModalModule } from '@ic/component-lib/src/components/modules/popover-modal/popover-modal.module';
import { SlideinModalModule } from '@ic/component-lib/src/components/modules/slidein-modal/slidein-modal.module';
import { TabNavModule } from '@ic/component-lib/src/components/modules/tab-nav/tab-nav.module';
import { TextInputLimiterModule } from '@ic/component-lib/src/components/modules/text-input-limiter/text-input-limiter.module';
import { TooltipModule } from '@ic/component-lib/src/components/modules/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '../../components/ui/ui.module';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { DrilldownTableComponent } from './components/drilldown-table/drilldown-table.component';
import { GlobalFooterComponent } from './components/global-footer/global-footer.component';
import { GlobalHeaderComponent } from './components/layout/header/global-header.component';
import { GlobalNavComponent } from './components/layout/nav/global-nav.component';
import { LegacyReportsComponent } from './components/legacy-reports/legacy-reports.component';
import { LocaleMenuComponent } from './components/locale-menu/locale-menu.component';
import { NotificationsEditorComponent } from './components/notifications-editor/notifications-editor.component';
import { ReportFilterComponent } from './components/report-filter/report-filter.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SkipLinkComponent } from './components/skip-link/skip-link.component';
import { TopScrollComponent } from './components/top-scroll/top-scroll.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';

import { FilterService } from './services/filter/filter.service';
import { FoldersService } from './services/folders/folders.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsModule } from '@cdx/icons';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UserStorageService } from './services/user-storage/user-storage.service';

// tslint:disable-next-line:no-any
(window as any).retain = [
  DrilldownTableComponent,
  GlobalFooterComponent,
  GlobalHeaderComponent,
  GlobalNavComponent,
  LegacyReportsComponent,
  LocaleMenuComponent,
  NotificationsEditorComponent,
  LocaleMenuComponent,
  ReportFilterComponent,
  ReportsComponent,
  SkipLinkComponent,
  UserMenuComponent,
];

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    NgCommonModule,
    FormsModule,
    IconModule,
    IconsModule,
    HttpClientModule,
    LoadingIndicatorModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTabsModule,
    MatToolbarModule,
    MultiSelectModule,
    NavigationModule,
    OverlayModalModule,
    PopoverModalModule,
    ReactiveFormsModule,
    TabNavModule,
    TextInputLimiterModule,
    SlideinModalModule,
    TooltipModule,
    TranslateModule,
    UiModule,
    UIRouterUpgradeModule
  ],
  declarations: [
    DrilldownTableComponent,
    GlobalFooterComponent,
    GlobalHeaderComponent,
    GlobalNavComponent,
    LegacyReportsComponent,
    LocaleMenuComponent,
    NotificationsEditorComponent,
    LocaleMenuComponent,
    ReportFilterComponent,
    ReportsComponent,
    SkipLinkComponent,
    TopScrollComponent,
    UserMenuComponent,
  ],
  exports: [
    DrilldownTableComponent,
    GlobalFooterComponent,
    GlobalNavComponent,
    LegacyReportsComponent,
    LocaleMenuComponent,
    NotificationsEditorComponent,
    LocaleMenuComponent,
    ReportFilterComponent,
    ReportsComponent,
    SkipLinkComponent,
    TopScrollComponent,
    UserMenuComponent
  ],
  entryComponents: [
    DrilldownTableComponent,
    GlobalFooterComponent,
    GlobalHeaderComponent,
    GlobalNavComponent,
    LegacyReportsComponent,
    LocaleMenuComponent,
    NotificationsEditorComponent,
    LocaleMenuComponent,
    ReportFilterComponent,
    ReportsComponent,
    SkipLinkComponent,
    UserMenuComponent
  ],
  providers: [
    DatePipe,
    FilterService,
    FoldersService,
    UserStorageService
  ]
})

export class AnalyticsModule {}
