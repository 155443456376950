import { Injectable } from '@angular/core';
import { extend, forEach, groupBy, cloneDeep, isArray } from 'lodash';
import { Indicator } from '../../../common/interfaces/indicator.interface';

@Injectable()
export class DiagramTransformService {

  constructor() {}

  many2many(data: Array<{[index: string]: {}}>) {
    let nodes: Array<{[index: string]: {}}> = [];

    forEach(groupBy(data, 's_id'), (value, key) => {
      const first = value[0];
      let links: Array<{[index: string]: {}}> = [];
      let node;

      forEach(value, (link) => {
        links.push(extend({ id: link.t_id }, link));
      });

      node = extend({ id: key, name: first.s_name, links: links }, first);
      node.value = node.s_value;
      nodes.push(node);
    });

    return nodes;
  }

  radar(data: Array<{[index: string]: {}}>, indicators: Array<Indicator>) {
    let transformed: Array<{}> = [];
    let items = cloneDeep(data);

    if (isArray(items)) {
      items.forEach((item) => {
        indicators.forEach((indicator) => {
          transformed.push(extend({}, item, {
            'indicator-name': indicator.title,
            'indicator-value': item[indicator.name],
            'indicator-unit': indicator.unit
          }));
        });
      });
    }

    return transformed;
  }


}