
import { Component, ViewEncapsulation, OnInit, OnDestroy, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Deferred } from 'components/common/objects/deferred.object';
import { EnvironmentService } from 'components/app/services/environment/environment.service';

import { EventsTrackerService } from 'components/common/services/events-tracker/events-tracker.service';
import { PopoverModalComponent } from '@ic/component-lib/src/components/modules/popover-modal/popover-modal.component';

@Component({
  selector: 'ic-export',
  templateUrl: './export.component.html',
  encapsulation: ViewEncapsulation.None
})

export class ExportComponent implements OnInit, OnDestroy {

  @Input('by') sortBy: string = 'cites';
  @Input('order') sortOrder: string = 'desc';
  @Input('total') totalCount: number = 0;
  @Input('trend') trendDataIsAvailable: boolean = false;
  @Output() onUpdate = new EventEmitter<string>();
  @ViewChild(PopoverModalComponent, { static: false }) popoverModal!: PopoverModalComponent;

  private abort!: Deferred<{}>;

  public file: any; // tslint:disable-line no-any
  public form: FormGroup;
  public maxRecords: number = 0;
  public method: string;
  public url: string = '';

  constructor(
    private analytics: EventsTrackerService,
    private environmentService: EnvironmentService,
    public translate: TranslateService
  ) {
    this.method = 'post';
    this.form = new FormGroup({});
  }

  ngOnInit() {
    this.loadValues();
  }

  ngOnDestroy() {
    if (this.abort && this.abort.resolve) {
      this.abort.resolve();
    }
  }

  private loadValues() {
    let fileName = this.translate.instant('analytics.export.Web Of Science Documents');

    this.abort = new Deferred();
    this.maxRecords = Math.min(this.totalCount, this.environmentService.getEnvironment().export.maxRecords || 50000);
    this.file = {
      type: 'csv',
      name: fileName,
      records: this.maxRecords
    };
  }

  submitAndClose() {
    this.analytics.trackEvent(
      'Export',
      'Export Data Table',
      'Export Data Table',
      this.file.name
    );
    this.popoverModal.closePopover();
  }

}