import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

export const analysisLandingFutureState: NgHybridStateDeclaration = {
  name: 'app.analysis-landing.**',
  url: '/analysis',
  loadChildren: () => import('../../pages/analysis-landing/analysis-landing.module').then(result => result.AnalysisLandingModule),
};

export const analysisFutureState: NgHybridStateDeclaration = {
  name: 'app.analysis.**',
  url: '/analysis/:datasetId/:entityId',
  loadChildren: () => import('../../pages/analysis/analysis.module').then(result => result.AnalysisModule),
};

export const analyticsFutureState: NgHybridStateDeclaration = {
  name: 'app.landing.**',
  url: '/landing',
  loadChildren: () => import('../../pages/analytics-landing/analytics-landing.module').then(result => result.AnalyticsLandingModule),
};

export const mySavedFutureState: NgHybridStateDeclaration = {
  name: 'app.my-saved.**',
  url: '/my-saved',
  loadChildren: () => import('../../pages/my-saved/my-saved.module').then(result => result.MySavedModule),
};

export const tabReportFutureState: NgHybridStateDeclaration = {
  name: 'app.tabreport.**',
  url: '/tabreports/:tabReportId/:reportId',
  loadChildren: () => import('../../pages/tab-report/tab-report.module').then(result => result.TabReportModule),
};

export const homeFutureState: NgHybridStateDeclaration = {
  name: 'app.home.**',
  url: '/home',
  loadChildren: () => import('../../pages/home/home.module').then(result => result.HomeModule),
};

export const reportFutureState: NgHybridStateDeclaration = {
  name: 'app.reports.**',
  url: '/report/:reportId',
  loadChildren: () => import('../../pages/report/report.module').then(result => result.ReportModule),
};

export const reportsLandingFutureState: NgHybridStateDeclaration = {
  name: 'app.reports-landing.**',
  url: '/reports',
  loadChildren: () => import('../../pages/reports-landing/reports-landing.module').then(result => result.ReportsLandingModule),
};

export const reportsDrillDownFutureState: NgHybridStateDeclaration = {
  name: 'app.report-drill-down.**',
  url: '/report-drill-down/:reportId/:tileId',
  loadChildren: () => import('../../pages/report-drill-down/report-drill-down.module').then(result => result.ReportDrillDownModule),
};