<cl-popover-modal (onOpen)="onModalOpen()" [options]="{modalWidth: '313px'}">

  <cl-popover-modal-button
    [buttonClass]="'cl-btn cl-btn--secondary cl-btn--with-icon ic-report__header__actions__btn'">
    <cl-icon [iconId]="'share'"></cl-icon>
    <span class="cl-btn__text">{{ 'dashboard.Share' | translate }}</span>
    <cl-icon [iconId]="'arrow-down'" [color]="'color-gray20'"></cl-icon>
  </cl-popover-modal-button>

  <cl-popover-modal-content [modalTitle]="'share.Share this Report' | translate">

    <form class="cl-form" *ngIf="!request" [formGroup]="shareForm" (ngSubmit)="onSubmit($event)">
      <fieldset class="cl-form__fieldset cl-form__fieldset--stacked">
        <legend class="visually-hidden">{{ 'share.Share this Report' | translate }}</legend>
        <div class="cl-form__field">
          <label class="cl-form-label" for="email">{{ 'share.Recipient Email' | translate }}</label>
          <input class="cl-form-text"
            type="text"
            id="email"
            placeholder="{{ 'share.Email address' | translate }}"
            formControlName="email"
            aria-required="true"
          />
        </div>
        <div class="cl-form__field">
          <cl-text-input-limiter [inputValue]="message" [config]="textInputLimiterOptions" (onInputValueChange)="onMessageUpdate($event)"></cl-text-input-limiter>
        </div>
        <div class="cl-form__actions">
          <button type="submit" class="cl-btn cl-btn--primary" [disabled]="!shareForm.valid || !messageValidator()">{{ 'share.Send' | translate }}</button>
        </div>
      </fieldset>
    </form>

    <div *ngIf="request">
      <p *ngIf="request | async; else sending">{{ 'share.confirmation' | translate }}</p>
      <ng-template #sending><p>{{ 'share.Sending…' | translate }}</p></ng-template>
    </div>   

  </cl-popover-modal-content>

</cl-popover-modal>