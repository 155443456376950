import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { TranslationsComponent } from './translations.component';

// tslint:disable-next-line:no-any
(window as any).retain = [TranslationsComponent];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [TranslationsComponent],
  exports: [TranslationsComponent],
  entryComponents: [TranslationsComponent]
})

export class TranslationsModule {}