import {Component, Input, ViewChild, ViewEncapsulation, EventEmitter, Output} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';

export interface Labels {
  message: string;
  okLabel: string;
  cancelLabel: string;
  title: string;
}

@Component({
     selector: 'ic-confirm-dialog',
  templateUrl: './confirm.component.html',
  encapsulation: ViewEncapsulation.None
})

export class ConfirmComponent {
  @ViewChild(OverlayModalComponent, { static: true }) overlayModal!: OverlayModalComponent;
  @Input() labels: Labels = {} as Labels;
  @Output() onConfirm = new EventEmitter();
  constructor(public translate: TranslateService) {

  }
  openModal() {
    this.overlayModal.openModal();
  }
  closeModal() {
    this.overlayModal.closeModal();
  }

  confirm() {
    this.onConfirm.emit();
    this.closeModal();
  }

}