import { Component, EventEmitter, ViewEncapsulation, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OverbarService } from 'components/overbar/services/overbar/overbar.service';
import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';

import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';
import { ReportModel } from 'components/common/interfaces/report.model';
import { FoldersListingComponent } from '../folders-listing/folders-listing.component';
import { FolderModel } from '@ic/component-lib/src/components/modules/folders/folders.model';
import { invalidCharacterTitleValidator } from 'pages/my-saved/components/saved-folders/saved-folders.validators';

@Component({
  selector: 'ic-report-create',
  templateUrl: './report-create.component.html',
  encapsulation: ViewEncapsulation.None
})

export class ReportCreateComponent {

  public createForm!: FormGroup;
  public showFolderListing = false;

  @Output() onCreate = new EventEmitter<{ savedReport: ReportModel, parentFolderId: number|string}>();
  @Output() onFoldersUpdate = new EventEmitter<{folders: FolderModel[], parentFolderId: number|string}>();

  @ViewChild(OverlayModalComponent, { static: true }) overlayModal!: OverlayModalComponent;
  @ViewChild(FoldersListingComponent, { static: true }) folderListing!: FoldersListingComponent;

  selectedFolderId: number | string;

  constructor(
    public overbarService: OverbarService,
    public reportsRepository: ReportsRepositoryService,
    public translate: TranslateService,
    public fb: FormBuilder
  ) {
    this.initForm();
  }

  openModal(selectedFolderId: number | string = 'root') {
    this.selectedFolderId = selectedFolderId;
    this.showFolderListing = true;
    this.createForm.reset();
    this.overlayModal.openModal();
  }

  closeModal() {
    this.overlayModal.closeModal();
  }

  onModalClose() {
    this.showFolderListing = false;
  }

  getSelectedFolder() {
    return this.folderListing.foldersApi.getSelectedFolder();
  }

  onSubmit() {
    const { title, parentFolder } = this.createForm.value;
    this.createReport(title, parentFolder);
  }

  private initForm() {
    this.createForm = new FormGroup({
      title: this.fb.control('', [
        Validators.required, Validators.maxLength(100), invalidCharacterTitleValidator.bind(this),
      ]),
      parentFolder: this.fb.control('', [ Validators.required ])
    });
  }

  private createReport(title: string, selectedFolder: FolderModel) {
    let newReport: Partial<ReportModel> = {
      title: title
    };

    if (!this.isRootFolder(selectedFolder)) {
      newReport.folderId = <number>selectedFolder.id;
    }


    this.reportsRepository.create(newReport)
      .then((savedReport: ReportModel) => {
        this.overbarService.notify(
          this.translate.instant('notifications.Report Created', { report: savedReport.title })
        );
        savedReport = { ...savedReport, type: 'report' };
        this.onCreate.emit({savedReport, parentFolderId: selectedFolder.id});
      })
      .catch((error: Error & { error: {  message: string, code: number } }) => {
        if (error.error && error.error.code === 400 && error.error.message) {
          this.overbarService.notify(this.translate.instant( `notifications.errors.report.${error.error.message}`));
        }
      });
  }

  private isRootFolder(folder: FolderModel) {
    return folder.id === 'root';
  }

  updateFolders({folders, parentFolderId}: {folders: FolderModel[], parentFolderId: number|string}) {
    this.onFoldersUpdate.emit({folders, parentFolderId});
  }

}
