<cl-popover-modal
  class="cl-popover-modal--saved-item-options"
  [options]="{
    isContext: true,
    modalWidth: width,
    modifiers: 'offset outline'
  }"
>
  <cl-popover-modal-button [buttonClass]="generateButtonClasses()" [disabled]="disabled"
                           *ngIf="options"
                           >
    <cl-icon *ngIf="options.icon" [iconId]="options.icon" [size]="options.size"></cl-icon>
    <span class="cl-btn__text" *ngIf="title">{{ title }}</span>
    <cl-icon *ngIf="options.arrow" [iconId]="'arrow-down'" [color]="'color-gray20'"></cl-icon>
  </cl-popover-modal-button>

  <cl-popover-modal-content [buttonLabel]="labelItem">
    <ul class="cl-context-menu__list" *ngIf="items">
      <li *ngFor="let item of items"
          class="cl-context-menu__list__item"
          >
        <ng-container *ngIf="item">
          <button
            type="button"
            class="cl-btn cl-btn--text cl-btn--text-secondary"
            [ngClass]="{'cl-btn--icon': item.icon }"
            (click)="select(item)"
          >
            <cl-icon *ngIf="item && item.icon" [iconId]="item.icon" [color]="item.iconColor || 'teal'"></cl-icon>
            <span *ngIf="item.title">{{ item.title | translate }}</span>
          </button>
        </ng-container>
      </li>
    </ul>
  </cl-popover-modal-content>

</cl-popover-modal>
