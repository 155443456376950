import { ajsAppModule } from './app.module.ajs';
import appComponentTpl from './app.component.html';
import { MainContainerService } from 'components/app/services/main-container/main-container.service';
import { PendoService } from 'components/app/services/pendo/pendo.service';

import './app.component.less';
import './app.component.scss';

appComponentCtrl.$inject = ['mainContainerService', 'pendoService'];

function appComponentCtrl(this: any, mainContainerService: MainContainerService, pendoService: PendoService) { // tslint:disable-line:no-any

  this.$onInit = () => {
    mainContainerService.getModifierClassesObservable()
      .subscribe(value => {
        this.modifierClasses = value;
        pendoService.setup();
      });
  };

}

ajsAppModule.component('app', {
  controller: appComponentCtrl,
  template: appComponentTpl
});
