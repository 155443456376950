import { remove, cloneDeep, isFunction } from 'lodash';
import { FolderExtModel } from 'components/common/interfaces/folder.interface';

export class RootFolderModel implements FolderExtModel {
  public id: number|'root' = 'root';
  public title: string = '';
  public folders: FolderExtModel[] = [];
  public open: boolean = false;
  public selected: boolean = false;
  public type: string = 'folder';
  public isDisabled: boolean;

  constructor(
    fldrs: FolderExtModel[],
    rootTitle: string
  ) {
    this.title = rootTitle;
    this.folders = this.normalizeFolders(cloneDeep(fldrs), this);
  }

  public find(condition: 'root'|number|Function, target?: Function) {
    const defautTarget = (folder: FolderExtModel) => folder;
    
    target = target || defautTarget;

    if (isFunction(condition)) {
      return this.findFolder(this.folders, condition, target);
    } else if (condition === 'root') {
      return this;
    } else {
      return this.findFolder(
        this.folders,
        (folder: FolderExtModel) => { return folder.id === condition; },
        target);
    }
  }

  public remove(id: number) {
    this.removeFolder(this.folders, id);
  }

  public reveal(id: number) {
    const folder = this.find(id);

    if (folder) {
      this.revealFolder(folder);
    }
  }

  private normalizeFolders(folders: FolderExtModel[], parentFolder?: FolderExtModel): FolderExtModel[] {
    return folders.map(folder => {
      folder.type = 'folder';
      folder.parent = parentFolder;
      folder.folders = folder.folders ? this.normalizeFolders(folder.folders, folder) : [];
      
      return folder;
    });
  }

  private findFolder(folders: FolderExtModel[], condition?: Function, target?: Function): FolderExtModel|undefined {
    for (let i = folders.length - 1; i >= 0; i--) {
      if (condition && condition(folders[i])) {
        return target!(folders[i]);
      } else if (folders[i].folders) {
        const result = this.findFolder(folders[i].folders!, condition, target);

        if (result) {
          return result;
        }
      }
    }
  }

  private removeFolder(folders: FolderExtModel[], folderId: number) {
    for (let i = folders.length - 1; i >= 0; i--) {
      if (folders[i].id === folderId) {
        remove(folders, folders[i]);
        break;
      } else if (folders[i].folders) {
        this.removeFolder(folders[i].folders!, folderId);
      }
    }
  }

  private revealFolder(folder: FolderExtModel) {
    folder.open = true;

    if (folder.parent) {
      this.revealFolder(folder.parent);
    }
  }

}
