import {Component, EventEmitter, Input, ViewEncapsulation, Output, ViewChild, ChangeDetectorRef} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OverbarService } from 'components/overbar/services/overbar/overbar.service';
import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';

import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';
import { ReportModel } from 'components/common/interfaces/report.model';
import { FolderExtModel } from 'components/common/interfaces/folder.interface';

@Component({
  selector: 'ic-create-report',
  templateUrl: './create-report.component.html',
  encapsulation: ViewEncapsulation.None
})

export class CreateReportComponent {
  @Input() destinationFolder: Partial<FolderExtModel> = {};
  @Output() onCreate = new EventEmitter<ReportModel>();
  @ViewChild(OverlayModalComponent, { static: true }) overlayModal!: OverlayModalComponent;

  public createForm: FormGroup;
  private titleMaxLength = 100;

  constructor(
    public overbarService: OverbarService,
    public reportsRepository: ReportsRepositoryService,
    public translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    this.createForm = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.titleMaxLength)
      ])
    });
  }

  onSubmit() {
    this.createReport(this.createForm.value.title, this.destinationFolder);
  }

  private createReport(title: string, folder: Partial<FolderExtModel>) {
    let newReport: Partial<ReportModel> = { title: title };

    if (folder && folder.parent) {
      newReport.folderId = <number|'root'|undefined>folder.id;
    }

    this.reportsRepository.create(newReport)
      .then((savedReport: ReportModel) => {
        this.overbarService.notify(
          this.translate.instant('notifications.Report Created', { report: savedReport.title })
        );

        savedReport = <ReportModel>{ ...savedReport, type: 'report' };
        this.onCreate.emit(savedReport);
      })
      .catch((error: Error) => console.error(error))
      .then(() => this.closeModal());
  }

  openModal() {
    this.createForm.controls['title'].setValue(
      this.translate.instant('analytics.my-folders.New Report'),
      {onlySelf: true}
    );
    this.overlayModal.openModal();
  }

  closeModal() {
    this.overlayModal.closeModal();
    this.cd.detectChanges();
  }

}