import { AnalysisIndicatorModel, AnalysisTableColumnModel, AnalysisTableSortOptionModel } from '../../../../pages/analysis/models';
import { FormatterService } from 'components/ui/filters/formatter/formatter.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  SortableTableColumnMenuOptionModel,
  SortableTableSortDirection,
  SortableTableValueFormatter,
} from '@ic/component-lib/src/components/modules/sortable-table/models';
import { EnvironmentService } from 'components/app/services/environment/environment.service';

@Injectable()
export class AnalysisTableColumnFactory {
  private unlockRank: boolean = false;

  constructor(
    private formatter: FormatterService,
    private environmentService: EnvironmentService,
    public translate: TranslateService,
  ) {
    this.unlockRank = this.environmentService.getEnvironment().feature.unlockRank;
  }

  createColumns(indicators: AnalysisIndicatorModel[], sortBy?: string, sortOrder?: string) {
    if (this.unlockRank) {
      return indicators
        .filter(indicator => !indicator.hidden)
        .map((indicator, index, arr) => this.createColumn(indicator, index, arr.length, sortBy, sortOrder));
    }
    return indicators
      .filter(indicator => !indicator.hidden)
      .reduce((list, indicator, index) => {
        if (indicator.name === 'rank' && index > 0) {
          list.splice(1, 0, indicator);
        } else {
          list.push(indicator);
        }
        return list;
      }, [] as AnalysisIndicatorModel[])
      // @ts-ignore
      .map((indicator, index, arr) => this.createColumn(indicator, index, arr.length, sortBy, sortOrder));
  }

  createColumn(
    indicator: AnalysisIndicatorModel,
    index: number,
    length: number,
    sortBy?: string,
    sortOrder?: string,
  ): AnalysisTableColumnModel & AnalysisIndicatorModel {
    return {
      ...indicator,
      id: indicator.name,
      menu: {
        description: indicator.description,
        options: this.getMenuOptions(indicator, index, length),
        help: {
          label: this.translate.instant('analysis.table.More about this indicators'),
          url: 'https://incites.help.clarivate.com/Content/Indicators-Handbook/ih-about.htm',
        }
      },
      isDefaultSort: indicator.name === sortBy,
      defaultSortOrder: <SortableTableSortDirection>sortOrder,
      sortOptions: this.getSortOptions(indicator),
      valueFormatter: this.getValueFormatter(indicator),
    };
  }

  private getValueFormatter(indicator: AnalysisIndicatorModel): SortableTableValueFormatter {
    return (value: any) => this.formatter.format(value, indicator); // tslint:disable-line no-any
  }

  private getMenuOptions(indicator: AnalysisIndicatorModel, index: number, length: number): SortableTableColumnMenuOptionModel[] {
    const options: SortableTableColumnMenuOptionModel[] = [];
    const sortAsc = {
      id: 'sort-asc',
      label: `Sort ${this.getSortLabel(indicator.type, 'asc')}`,
      iconId: 'table-asc'
    };
    const sortDesc = {
      id: 'sort-desc',
      label: `Sort ${this.getSortLabel(indicator.type, 'desc')}`,
      iconId: 'table-desc'
    };

    const moveLeft = {
      id: 'move-left',
      label: this.translate.instant('analysis.table.Move left'),
      iconId: 'circled-arrow-left'
    };
    const moveRight = {
      id: 'move-right',
      label: this.translate.instant('analysis.table.Move right'),
      iconId: 'circled-arrow-right'
    };

    const remove = {
      id: 'remove',
      label: this.translate.instant('analysis.table.Remove'),
      iconId: 'close-small'
    };

    if (indicator.sortable) {
      options.push(sortAsc, sortDesc);
    }

    if (index > 0) {
      if (index > 1) {
        options.push(moveLeft);
      }
      if (index < length - 1) {
        options.push(moveRight);
      }
    }

    if (!indicator.required) {
      options.push(remove);
    }

    return options;
  }

  private getSortOptions(indicator: AnalysisIndicatorModel): AnalysisTableSortOptionModel[] {
    return indicator.sortable ? [
      {
        direction: 'asc',
        label: this.getSortLabel(indicator.type, 'asc')
      },
      {
        direction: 'desc',
        label: this.getSortLabel(indicator.type, 'desc')
      }
    ] : [];
  }

  private getSortLabel(columnType: string, direction: SortableTableSortDirection) {
    const isAscending = direction === 'asc';
    const isNumeric = (['number', 'percentage']).indexOf(columnType!) > -1;
    const low = isNumeric ? 'low' : 'A';
    const high = isNumeric ? 'high' : 'Z';
    return isAscending ? `${low} to ${high}` : `${high} to ${low}`;
  }

}
