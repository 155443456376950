import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { UpgradeModule } from '@angular/upgrade/static';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { ajsAppModule } from '../../angularjs/app/app.module.ajs';
import { AnalyticsModule } from '../analytics/analytics.module';
import { AuthModule } from './../auth/auth.module';
import { CommonModule } from '../common/common.module';
import { DiagramModule } from '../diagram/diagram.module';
import { NavigationModule } from '../navigation/navigation.module';
import { RestModule } from '../rest/rest.module';
import { ViewModelsModule } from '../view-models/view-models.module';
import { UiModule } from '../ui/ui.module';
import { TranslationsModule } from '../translations/translations.module';
import { localeInitializerFactory } from './factories/locale/locale.factory';
import { translateInitializerFactory, translateHttpLoaderFactory } from './factories/translate/translate.factory';
import {
  analysisFutureState, analysisLandingFutureState, analyticsFutureState, tabReportFutureState,
  mySavedFutureState, homeFutureState, reportFutureState, reportsLandingFutureState, reportsDrillDownFutureState
} from './app.states';
import { uiRouterConfigFactory } from './factories/ui-router/ui-router-config.factory';
import { MainContainerService } from './services/main-container/main-container.service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LocaleInterceptor } from './interceptors/locale.interceptor';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';
import { VersionInterceptor } from './interceptors/version.interceptor';
import { VersionRepositoryService } from './services/version-repository/version-repository.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { OverbarModule } from '../../components/overbar/overbar.module';
import { PendoService } from './services/pendo/pendo.service';
import { SnackBarModule } from '../../components/snackbar/snackbar.module';
import { TabReportService } from 'pages/tab-report/services/tab-report/tab-report.service';

@NgModule({
  imports: [
    AnalyticsModule,
    AuthModule,
    BrowserModule,
    CommonModule,
    DiagramModule,
    FormsModule,
    HttpClientModule,
    MatSnackBarModule,
    NavigationModule,
    OverbarModule.forRoot(),
    RestModule,
    SnackBarModule.forRoot(),
    ViewModelsModule,
    TranslationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    UiModule,
    UIRouterUpgradeModule.forRoot({
      config: uiRouterConfigFactory,
      states: [
        analysisFutureState,
        analysisLandingFutureState,
        analyticsFutureState,
        mySavedFutureState,
        tabReportFutureState,
        homeFutureState,
        reportFutureState,
        reportsLandingFutureState,
        reportsDrillDownFutureState
      ]
    }),
    UpgradeModule
  ],
  providers: [
    { provide: APP_INITIALIZER,
      useFactory: translateInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    MainContainerService,
    { provide: LOCALE_ID, useValue: localeInitializerFactory() },
    VersionRepositoryService,
    PendoService,
    Title,
    TabReportService
  ]
})

export class AppModule {
  constructor(private upgrade: UpgradeModule) {}

  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, [ajsAppModule.name], { strictDi: true });
  }
}