<cl-overlay-modal [options]="{ 'modalWidth': '500px' , 'modifiers': 'dark-header' }"  (onClose)="onModalClose()">
  <cl-overlay-modal-content [modalTitle]="'my-saved.Create New Report' | translate">
    <form class="cl-form" [formGroup]="createForm">
      <div class="cl-form__fieldset cl-form__fieldset--stacked">
        <div class="cl-form__field">
          <label class="cl-form-label" for="report-title">{{ 'my-saved.Title' | translate }} <strong class="required">*</strong></label>
          <input
            formControlName="title"
            id="report-title"
            class="cl-form-text"
            type="text"
            placeholder="{{ 'my-saved.Enter a report name' | translate }}"
            aria-required="true"
          />
          <span >*required</span>
          <div *ngIf="createForm && createForm.get('title')! && createForm.get('title')!.errors && (createForm.get('title')!.dirty || createForm.get('title')!.touched)"
               class="alert alert-danger error-margin">
            <div *ngIf="createForm.get('title')!.errors.invalidTitle">{{'notifications.errors.report.Report title can\'t contain any of the following characters' | translate}}</div>
          </div>
        </div>
        <div class="cl-form__field">
          <label class="cl-form-label" for="title">{{ 'my-saved.Select a folder' | translate }}</label>
          <ic-folders-listing *ngIf="showFolderListing"
            [parentFolder]="createForm.get('parentFolder')"
            [selectedFolderId]="selectedFolderId"
            (updateFolders)="updateFolders($event)"
          ></ic-folders-listing>
        </div>
      </div>
      <div class="cl-form__fieldset cl-form__fieldset">
        <div class="cl-form__actions">
          <button type="button" class="cl-btn cl-btn--secondary" (click)="closeModal()">
            {{ 'popup.Cancel' | translate }}
          </button>
          <button type="submit" [disabled]="!createForm.valid" class="cl-btn cl-btn--primary" (click)="onSubmit()">
            {{ 'my-saved.Create' | translate }}
          </button>
        </div>
      </div>
    </form>
  </cl-overlay-modal-content>
</cl-overlay-modal>