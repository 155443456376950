export function getLocalStorage() {
  try {
    const localStorage = window['localStorage'];
    const x = '__storage_test__';
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return window.localStorage;
  } catch (e) {
    return null;
  }
}
