<div [ngClass]="{'short': reportGroups.length < 2 }" active="active" no-transition="true">
  <div class="carousel">
    <button title="←" (click)="openPage(currentGroup - 1)"></button>
    <div class="pages">
      <button
        [ngClass]="{'active': i === currentGroup}"
        *ngFor="let reportGroup of reportGroups; index as i;"
        title="→ {{ i }}"
        (click)="openPage(i)"
      ></button>
    </div>
    <button title="→" (click)="openPage(currentGroup - 1)"></button>
  </div>
  <div *ngFor="let reportGroup of reportGroups; index as page;">
    <ul class="reports" *ngIf="page === currentGroup">
      <li class="report" *ngFor="let report of reportGroup">
        <div class="report-logo" [ngClass]="reportClass(report)"></div>
        <div class="three-line-description">
          <h4>{{ report.title }}</h4>
          <h5 *ngIf="report.subtitle">{{ report.subtitle }}</h5>
          <p>{{ report.description }}</p>
        </div>
        <div class="actions">
          <a 
            translate="analytics.landing.Learn More"
            href="/incites-app/redirect/help"
            rel="noopener noreferrer"
            target="_blank"
          ></a>
          <button 
            type="button"
            (click)="openReport(report)"
            class="btn btn-primary system-report-run"
            translate="analytics.landing.Run">
          </button>
        </div>
      </li>
    </ul>
  </div>
</div >
