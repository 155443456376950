import { EventEmitter, Injectable } from '@angular/core';
import { isUndefined } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarMessageModel } from './snackbar.model';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  public messageChange$ = new EventEmitter();
  private message: SnackBarMessageModel;

  constructor(
    private translate: TranslateService
  ) {
  }

  // tslint:disable-next-line:no-any
  open(message: string, action?: string, config?: MatSnackBarConfig<any>) {
    this.message = {
      action: isUndefined(action) ? this.translate.instant('notifications.Dismiss') : action,
      config: isUndefined(config) ? { duration: 3000 } : config,
      message: message,
    };
    this.messageChange$.emit(this.message);
  }
}