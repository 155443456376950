import { Ng1StateDeclaration } from '@uirouter/angularjs';
import { StateService } from '@uirouter/core';
import { IWindowService } from 'angular';

import { DEFAULT_STATE } from '../app/app.module.ajs';

export const signinState: Ng1StateDeclaration = {
  name: 'signin',
  url: '/signin',
  template: '<div>Redirecting…</div>',
  controller: ['$window', '$state', 'userService', 'navigation',
    function(
      $window: IWindowService,
      $state: StateService,
      userService: any, // tslint:disable-line
      navigation: any // tslint:disable-line
    ) {
      if (userService.getCurrentUser()) {
        // TODO: While updating to Angular use "StateService.go()" instead of "$state.go"
        // which is available in the latest version of UI-Router.
        $state.go(DEFAULT_STATE);
      } else {
        $window.location.href = navigation.localizeLink('/incites-app/redirect/signin');
      }
    }
  ]
};

export const signoutState: Ng1StateDeclaration = {
  name: 'signout',
  url: '/signout',
  template: '<div>Redirecting…</div>',
  controller: ['$window', 'navigation',
    function($window: IWindowService, navigation: any) { // tslint:disable-line
      $window.location.href = navigation.localizeLink('/incites-app/redirect/signout');
    }
  ]
};

export const redirectState: Ng1StateDeclaration = {
  name: 'redirect',
  url: '/redirect',
  controller: ['$state', 'userService',
    function($state: StateService, userService: any) { // tslint:disable-line
      // TODO: While updating to Angular replace "$state.go" with "StateService.go()"
      // which is available in the latest version of UI-Router.
      if (userService.getCurrentUser()) {
        $state.go(DEFAULT_STATE);
      } else {
        $state.go('signin');
      }
    }
  ]
};

export const forbiddenState: Ng1StateDeclaration = {
  name: 'forbidden',
  url: '/forbidden',
  template: '<div>Forbidden</div>'
};
