import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { FolderExtModel } from 'components/common/interfaces/folder.interface';

@Injectable()
export class FolderViewModelService {

  constructor() {}

  getFolderViewModel(folder: FolderExtModel, parentFolder: FolderExtModel) {
    let viewModel = cloneDeep(folder);
    viewModel.type = 'folder';
    viewModel.parent = parentFolder;
    viewModel.folders = [];
    return viewModel;
  }

}