<cl-overlay-modal [options]="{ 'modifiers': 'dark-header' }" (onOpen)="onModalOpen()">

  <cl-overlay-modal-button [buttonClass]="'cl-btn cl-btn--secondary cl-btn--with-icon ic-report__header__actions__btn'">
    <cl-icon [iconId]="'save'"></cl-icon>
    <span class="cl-btn__text">{{ 'dashboard.Save As' | translate }}</span>
  </cl-overlay-modal-button>

  <cl-overlay-modal-content class="save-dialog" [modalTitle]="'report.Save Report As…' | translate">
    <form class="cl-form" [formGroup]="saveForm" (ngSubmit)="saveReport()">
      <div class="cl-form__fieldset cl-form__fieldset--stacked">
        <div class="cl-form__field">
          <label class="cl-form-label" for="title">{{ 'report.Title' | translate }}</label>
          <input class="cl-form-text" type="text" id="title" formControlName="title" aria-required="true"/>
        </div>
        <div class="cl-form__field cl-form__field--flex">
          <label class="cl-form-label">{{ 'report.Save to' | translate }}</label>

          <button class="cl-btn cl-btn--text cl-btn--icon" type="button" (click)="openCreateFolderModal()">
            <cl-icon [iconId]="'folder-add'" [label]="'create a new folder'" [isDisabled]="!selectedFolder"></cl-icon>
          </button>
        </div>
        <div class="cl-form__field cl-folders-wrapper" *ngIf="folders && folders.length > 0">
          <cl-folders
            [items]="folders | icTitleSorting"
            [api]="foldersApi"
            (onSelect)="setSelectedFolder($event)"
          ></cl-folders>
        </div>
        <div class="cl-form__actions">
          <button type="submit" [disabled]="!saveForm.valid||!selectedFolder" class="cl-btn cl-btn--primary">{{ 'report.Save' | translate }}</button>
        </div>
      </div>
    </form>
  </cl-overlay-modal-content>

</cl-overlay-modal>

<ic-create-folder [parentFolder]="selectedFolder" (onCreate)="onFolderCreate($event)"></ic-create-folder>