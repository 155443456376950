import { Pipe, PipeTransform} from '@angular/core';
import { FolderModel } from '@ic/component-lib/src/components/modules/folders/folders.model';
import { isArray } from 'lodash';

@Pipe({
  name: 'icTitleSorting',
  pure: false
})
export class IcTitleSortPipe implements PipeTransform {

  constructor() {}

  transform(folders: FolderModel[]) {
    if (folders && folders.length) {
      this.sortFolder(folders[0].folders);
    }
    return folders;
  }

  sortFolder(folders: FolderModel[]) {
    if (isArray(folders)) {
      folders.sort(this.compare);
      folders.forEach( folder => this.sortFolder(folder.folders));
    }
    return folders;
  }

  private compare(current: FolderModel, next: FolderModel ) {
    const currentTitle = current.title.toLowerCase();
    const nextTitle = next.title.toLowerCase();
    if ( currentTitle < nextTitle ) {
      return -1;
    }
    if ( currentTitle > nextTitle ) {
      return 1;
    }
    return 0;
  }

}