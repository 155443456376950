import * as pubsub from 'pubsub-js';

import { Injectable } from '@angular/core';
import { StateService, StateParams } from '@uirouter/core';

import { ajsAppModule } from '../../../../angularjs/app/app.module.ajs';
import { downgradeInjectable } from '@angular/upgrade/static';

import { UserModel } from 'components/auth/interfaces/user.model';

import { GoogleAnalyticsService } from 'components/common/services/google-analytics/google-analytics.service';
import { SnowplowService } from '../snowplow/snowplow.service';
import { WindowRefService } from 'components/common/window/window-ref.service';

@Injectable()
export class EventsTrackerService {

  constructor(
    private ga: GoogleAnalyticsService,
    private snowplow: SnowplowService,
    private stateService: StateService,
    private windowRef: WindowRefService
  ) {
    pubsub.subscribe('userAuthenticated', (_channel: string, user: UserModel) => {
      this.registerUser(user);
    });

    pubsub.subscribe('userSignedOut', () => {
      this.registerUser(undefined);
    });
  }

  /**
   * Track event
   * @constructor
   * @param {string} category - Category.
   * @param {string} label - Label.
   * @param {string} action - Action.
   * @param {string} value - Value.
   */
  trackEvent(
    category: string,
    label: string,
    action: string|null = null,
    value: string|null = null
  ): void {
    this.ga.trackEvent(category, <string>action, label, <string>value);
    this.snowplow.trackEvent(category, <string>action, label, <string>value);
  }

  trackPageView(): void {
    const pageName = this.processPageName(<string>this.stateService.current.name, this.stateService.params);
    this.ga.trackPageView(pageName, this.windowRef.nativeWindow().location.hash.replace('#', ''));
    this.snowplow.trackPageView(this.stateService.current.name);
  }

  private registerUser(user: UserModel|undefined): void {
    if (user && user.userId) {
      this.snowplow.registerUser(user);
    }
  }

  private processPageName(pageName: string, params: StateParams): string | undefined {
    switch (pageName.replace('app.', '')) {
      case 'explore':
        return pageName + '.' + params.entity;
      case 'report':
        return pageName + '.' + params.reportId;
      case 'redirect':
        return undefined;
      default:
        return pageName;
    }
  }
}

// Downgrade this service for use in AngularJS module
ajsAppModule.factory('EventsTrackerService', downgradeInjectable(EventsTrackerService));
