import { Injectable } from '@angular/core';
import { isObject, isString, isUndefined } from 'angular';
import { environmentCommonConfig } from 'components/app/services/environment/environment-common.config';
import { BaselineIndicatorsModel } from 'components/rest/interfaces/baseline-indicators-model';

@Injectable()
export class BaselineIndicatorsService {

  // tslint:disable-next-line: no-any
  updateBaselineIndicators(data: any, benchmarks: any) {
    let baseDocVal = 0;
    let baseCitesVal = 0;
    if (!isUndefined(benchmarks.items)) {
    // tslint:disable-next-line: no-any
    benchmarks.items.forEach((benchmarkItem: { value: any[]; name: string }) =>  {
     
      benchmarkItem.value.forEach((bmItem: { [x: string]: number; }) => {
      baseDocVal = this.returnIndicatorValue(bmItem[environmentCommonConfig.baselineShareIndicators.wosDocuments]);
      baseCitesVal = this.returnIndicatorValue(bmItem[environmentCommonConfig.baselineShareIndicators.timesCited]);
    
      
      // tslint:disable-next-line: no-any
      data.forEach((item: BaselineIndicatorsModel) => {
        if (benchmarkItem.name === 'all') {
          bmItem[environmentCommonConfig.baselineShareIndicators.prcntBaselineAllDocs] = 100;
          bmItem[environmentCommonConfig.baselineShareIndicators.prcntBaselineAllCites] = 100;
          if (!isUndefined(item.prcntBaselineAllDocs) && this.isPublicationYearExist(item, bmItem) && baseDocVal > 0) {
            let wosDocVal = this.returnIndicatorValue(item.wosDocuments);
            item.prcntBaselineAllDocs = (wosDocVal / baseDocVal) * 100;
          }
          if (!isUndefined(item.prcntBaselineAllCites) && this.isPublicationYearExist(item, bmItem) && baseCitesVal > 0) {
            let timeCitedVal = this.returnIndicatorValue(item.timesCited);
            item.prcntBaselineAllCites = (timeCitedVal / baseCitesVal) * 100;
          }
        }
       if (benchmarkItem.name === 'global') {
        bmItem[environmentCommonConfig.baselineShareIndicators.prcntGlobalBaseDocs] = 100;
        bmItem[environmentCommonConfig.baselineShareIndicators.prcntGlobalBaseCites] = 100;
        if (!isUndefined(item.prcntGlobalBaseDocs) && this.isPublicationYearExist(item, bmItem) && baseDocVal > 0) {
          let wosDocVal = this.returnIndicatorValue(item.wosDocuments);
          item.prcntGlobalBaseDocs = (wosDocVal / baseDocVal) * 100;
        }
        if (!isUndefined(item.prcntGlobalBaseCites) && this.isPublicationYearExist(item, bmItem) && baseCitesVal > 0) {
          let timeCitedVal = this.returnIndicatorValue(item.timesCited);
          item.prcntGlobalBaseCites = (timeCitedVal / baseCitesVal) * 100;
        }
       }
       if (benchmarkItem.name === 'pinned') {
        bmItem[environmentCommonConfig.baselineShareIndicators.prcntBaselineForPinnedDocs] = 100;
        bmItem[environmentCommonConfig.baselineShareIndicators.prcntBaselineForPinnedCites] = 100;
        if (!isUndefined(item.prcntBaselineForPinnedDocs) && this.isPublicationYearExist(item, bmItem) && baseDocVal > 0) {
          let wosDocVal = this.returnIndicatorValue(item.wosDocuments);
          item.prcntBaselineForPinnedDocs = (wosDocVal / baseDocVal) * 100;
        }
        if (!isUndefined(item.prcntBaselineForPinnedCites) && this.isPublicationYearExist(item, bmItem) && baseCitesVal > 0) {
          let timeCitedVal = this.returnIndicatorValue(item.timesCited);
          item.prcntBaselineForPinnedCites = (timeCitedVal / baseCitesVal) * 100;
        }
       }
      });
      });
    });
   }
    return [data, benchmarks];
  }

  // tslint:disable-next-line: no-any
  private returnIndicatorValue(item: any) {
    const isValObj =  item != null && !isString(item) && isObject(item);
    return isValObj ? item.value : item;
   }

  // tslint:disable-next-line: no-any
  private isPublicationYearExist(item: BaselineIndicatorsModel, benchmarkItem: any) {
   const year = Object.keys(item).includes('year') ? 'year' : (Object.keys(item).includes('yearStr') ? 'yearStr' : '');
    if (year && !isUndefined(item[year]) && !isUndefined(benchmarkItem[year])) {
     return item[year] === benchmarkItem[year];
    }
    
    return true;
    } 

}

