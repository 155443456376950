export type BenchmarkType = 'global'
  | 'national'
  | 'all'
  | 'pinned';

export type DiagramLongType = 'bar chart'
  | 'bar amCharts'
  | 'bubble chart'
  | 'cloud chart'
  | 'cloud diagram'
  | 'heat map'
  | 'heatmap amCharts'
  | 'impactprofile'
  | 'line amCharts'
  | 'line chart'
  | 'linearBar chart'
  | 'linearClustered chart'
  | 'many2many'
  | 'map amCharts'
  | 'map chart'
  | 'multiIndicatorLine amCharts'
  | 'multiIndicatorLine chart'
  | 'network diagram'
  | 'one2many'
  | 'period amCharts'
  | 'period chart'
  | 'radar chart'
  | 'scatterplot chart'
  | 'stackedBar chart'
  | 'tree heatmap'
  | 'tree map'
  | 'treemap amCharts'
  | 'treemap amchartsSDG'
  | 'wordCloud graph'
  | 'pie amCharts'
  ;

export type DiagramType = 'bar'
  | 'barAmcharts'
  | 'bubble'
  | 'cloud'
  | 'groupBar'
  | 'heatmap'
  | 'heatmapAmcharts'
  | 'linear'
  | 'linearAmcharts'
  | 'linearBar'
  | 'linearClustered'
  | 'many2many'
  | 'map'
  | 'mapAmCharts'
  | 'pie'
  | 'radar'
  | 'radial'
  | 'stackedBar'
  | 'scatterplot'
  | 'treemap'
  | 'treeHeatmap'
  | 'treemapAmcharts'
  | 'treemapAmchartsSDG'
  | 'wordCloudAmcharts'
  | 'pieAmcharts'
  ;

export type DiagramTypesModel = {
  [K in DiagramLongType]: DiagramType;
};

const diagramTypes: DiagramTypesModel = {
  'network diagram': 'radial',
  'bubble chart': 'bubble',
  'map chart': 'map',
  'tree map': 'treemap',
  'line chart': 'linear',
  'period chart': 'linear',
  'period amCharts': 'linear',
  'multiIndicatorLine chart': 'linear',
  'multiIndicatorLine amCharts': 'linearAmcharts',
  'line amCharts': 'linearAmcharts',
  'bar chart': 'bar',
  'bar amCharts': 'barAmcharts',
  'heat map': 'heatmap',
  'heatmap amCharts': 'heatmapAmcharts',
  'cloud chart': 'cloud',
  'cloud diagram': 'cloud',
  'one2many': 'pie',
  'many2many': 'many2many',
  'radar chart': 'radar',
  'linearBar chart': 'linearBar',
  'linearClustered chart': 'linearClustered',
  'scatterplot chart': 'scatterplot',
  'impactprofile': 'groupBar',
  'tree heatmap': 'treeHeatmap',
  'map amCharts': 'mapAmCharts',
  'treemap amCharts': 'treemapAmcharts',
  'treemap amchartsSDG': 'treemapAmchartsSDG',
  'stackedBar chart': 'stackedBar',
  'wordCloud graph': 'wordCloudAmcharts',
  'pie amCharts': 'pieAmcharts'
};

export function getDiagramAlternativeLongType(type: DiagramType) {
  const types = {
    'tree map': 'treemap amCharts',
    'treemap amCharts': 'tree map',
    'map chart': 'map amCharts',
    'map amCharts': 'map chart',
    'line chart': 'line amCharts',
    'line amCharts': 'line chart',
    'bar amCharts': 'bar chart',
    'bar chart': 'bar amCharts',
    'heatmap amCharts': 'heat map',
    'heat map': 'heatmap amCharts',
    'period chart': 'period amCharts',
    'period amCharts': 'period chart',
    'multiIndicatorLine amCharts': 'multiIndicatorLine chart',
    'multiIndicatorLine chart': 'multiIndicatorLine amCharts',
    'one2many': 'pie amCharts',
    'pie amCharts': 'one2many'
  };
  // @ts-ignore
  return types[type];
}

export function getDiagramType(type: DiagramLongType): DiagramType {
  return diagramTypes[type];
}

export function getDiagramLongType(type: DiagramType) {
  // @ts-ignore
  return Object.keys(diagramTypes).find(key => diagramTypes[key] === type);
}

export function getDiagramAlternativeType(type: DiagramType) {
  const types = {
    'treemap': 'treemapAmcharts',
    'treemapAmcharts': 'treemap',
    'map': 'mapAmCharts',
    'mapAmCharts': 'map',
    'linearAmcharts': 'linear',
    'linear': 'linearAmcharts',
    'barAmcharts': 'bar',
    'bar': 'barAmcharts',
    'heatmapAmcharts': 'heatmap',
    'heatmap': 'heatmapAmcharts',
    'pie': 'pieAmcharts',
    'pieAmcharts': 'pie'
  };
  // @ts-ignore
  return types[type];
}

export function isAmChartsType(type: string) {
  return ['mapAmCharts', 'treemapAmchartsSDG',
    'treemapAmcharts', 'linearAmcharts', 'wordCloudAmcharts', 'barAmcharts', 'heatmapAmcharts', 'pieAmcharts'].includes(type);
}

export function isD3Type(type: string) {
  return ['bar'
    , 'bubble'
    , 'cloud'
    , 'groupBar'
    , 'heatmap'
    , 'linear'
    , 'linearBar'
    , 'linearClustered'
    , 'many2many'
    , 'map'
    , 'pie'
    , 'radar'
    , 'radial'
    , 'scatterplot'
    , 'treemap'
    , 'treeHeatmap'].includes(type);
}

export function isCompatibleWithGroup(type: DiagramLongType) {
  const chartTypes = ['bar chart', 'bar amCharts', 'bubble chart', 'line amCharts', 'line chart', 'multiIndicatorLine amCharts',
    'multiIndicatorLine chart', 'period amCharts', 'period chart', 'radar chart', 'scatterplot chart', 'tree heatmap', 'tree map',
    'treemap amCharts', 'pie amCharts', 'one2many', 'wordCloud graph', 'treemap amchartsSDG'];
  return chartTypes.includes(type);
}

export const templates = {
  average: `
    <div class="scatterplot">
      <h1 class="tooltip-title">{{ title }}</h1>
      {{ value }}
    </div>`,
  full: `
    <div>
      <h1 class="tooltip-title">{{ title }}</h1>
      <div class="tooltip-content">{{ value }}</div>
    </div>`,
  short: `
    <div>
      <h1 class="tooltip-title">{{ title }}</h1>
    </div>`,
  scatterplotFull: `
    <div class="scatterplot">
      <h1 class="tooltip-title">{{ title }}</h1>
      <div class="tooltip-content"><p>{{ value.x }}</p>
        <p>{{ value.y }}</p>
        <p>{{ value.pointRadius }}</p>
      </div>
    </div>`
};