import { NgModule } from '@angular/core';
import { DiagramOptionsService } from './services/diagram-options/diagram-options.service';
import { DiagramTransformService } from './services/diagram-transform/diagram-transform.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    DiagramOptionsService,
    DiagramTransformService,
  ]
})

export class DiagramModule {}
