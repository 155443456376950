import { AnalysisFilterModel } from 'pages/analysis/models';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { FocusService } from '@ic/component-lib/src/components/utility/trap-focus/focus.service';
import { RangeInputOptionsModel } from '@ic/component-lib/src/components/modules/range-input/models';
import { TranslateService } from '@ngx-translate/core';
import { AppliedFilterValuesModel } from '@ic/component-lib/src/components/modules/applied-filters/models';
import { Subscription } from 'rxjs';
import { AnalysisService } from 'pages/analysis/services/analysis-service/analysis.service';
import { IcTogglesNgService } from 'components/common/services/ic-toggles-ng/ic-toggles-ng.service';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { Globals } from 'components/shared/globalData';
import { DateRangeConfig } from 'pages/tab-report/interfaces/tab-report-filters.model';
@Component({
  selector: 'ic-date-range-filter',
  templateUrl: 'date-range-filter.component.html',
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class DateRangeFilterComponent implements OnInit, OnChanges {
  @Input() config!: AnalysisFilterModel | DateRangeConfig;
  @Input() reportsFilter: boolean = false;
  @Output() valueChange = new EventEmitter<number[] | string[]>();
  @Output() onChange = new EventEmitter();
  public currentPeriodValue!: number[];
  public periods: { label: string; value: string; }[] = [];
  public showCustomRange = false;
  public rangeInputOptions: Partial<RangeInputOptionsModel> = {
    maxLabel: 'End',
    minLabel: 'Start',
    step: 1,
  };

  public currentPeriod!: string;
  filterDefaultValue$!: Subscription;
  menuOpened: boolean = false;
  selectedPeriod!: string;
  @Input() context: string = '';
  selectedDateRangeLabel: string = this.translate.instant('analysis.sidebar.filters.dateRange.select date range');
  skipHideMenu: boolean = true;
  env = this.environmentService.getEnvironment();
  toggleSubscription: Subscription;
  IS_NEW_DATE_FORMAT_ENABLED: boolean = false;
  constructor(
    private elementRef: ElementRef,
    private focusService: FocusService,
    private translate: TranslateService,
    private analysisService: AnalysisService,
    public globalData: Globals,
    private icTogglesNgService: IcTogglesNgService,
    private environmentService: EnvironmentService,
  ) {
    if (!this.globalData?.toggleData) {
      this.setSplitIoToggles();
    } else {
      const IS_NEW_DATE_FORMAT_ENABLED = this.env.splitioTreatment.newDateFormat;
      this.IS_NEW_DATE_FORMAT_ENABLED = this.globalData?.toggleData[IS_NEW_DATE_FORMAT_ENABLED] === 'on';
    }
  }

  setSplitIoToggles() {
    this.toggleSubscription = this.icTogglesNgService.toggles().subscribe((toggles) => {
      this.globalData.toggleData = toggles;
      const IS_NEW_DATE_FORMAT_ENABLED = this.env.splitioTreatment.newDateFormat;
      this.IS_NEW_DATE_FORMAT_ENABLED = toggles[IS_NEW_DATE_FORMAT_ENABLED] === 'on';
    });
  }
  ngOnInit() {
    if (!this.selectedPeriod) {
      this.selectedPeriod = this.periods.find(data => data.value === this.periods[0].value)!.label;
    }
    this.filterDefaultValue$ = this.analysisService.filterDefaultValue.subscribe((value) => {
      const filterPeriodValueRest = value;
      if (filterPeriodValueRest) {
        this.currentPeriod = this.periods[0].value;
        this.currentPeriodValue =  this.periods[0].value.split('-').map(data => Number(data));
        this.selectedPeriod = this.periods.find(data => data.value === this.currentPeriod)!.label;
        this.onSelectChange();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this.updateFilterPeriods(changes.config.firstChange);
    }
  }

  private updateFilterPeriods(isFirstChange: boolean) {
    const [minYear, maxYear] = this.config.source as number[];

    const lastFiveYears = `${this.config.last5Years![0]}-${this.config.last5Years![1]}`;
    const allYears = `${minYear}-${maxYear}`;
    const yearToDate = `${maxYear}-${maxYear}`;
    const customYearRange = 'custom-year-range';

    const lastFiveYearsLabel = this.translate.instant('analysis.sidebar.filters.dateRange.lastFive');
    const allYearsLabel = this.translate.instant('analysis.sidebar.filters.dateRange.all');
    const yearToDateLabel = this.translate.instant('analysis.sidebar.filters.dateRange.yearToDate');
    const customLabel = this.translate.instant('analysis.sidebar.filters.dateRange.custom');

    const appliedValue = this.getAppliedValue(this.config.appliedValues);

    this.periods = [
      { label: `${lastFiveYearsLabel} (${lastFiveYears})`, value: lastFiveYears },
      { label: `${allYearsLabel} (${allYears})`, value: allYears },
      { label: `${yearToDateLabel} (${maxYear})`, value: yearToDate },
      { label: customLabel, value: 'custom-year-range' },
    ];

    if (appliedValue) {
      this.currentPeriodValue = this.config.appliedValues!.is as number[];
    } else {
      this.currentPeriodValue = this.config.last5Years!;
    }

    switch (appliedValue) {
      case lastFiveYears:
      case null:
        this.currentPeriod = lastFiveYears;
        break;
      case allYears:
        this.currentPeriod = allYears;
        break;
      case yearToDate:
        this.currentPeriod = yearToDate;
        break;
      default:
        this.currentPeriod = customYearRange;
    }

    if (this.context === 'smartReport') {
      if (isFirstChange || !appliedValue ) {
        if (this.periods[0].label !== this.selectedDateRangeLabel) {
          this.periods.unshift({label: this.selectedDateRangeLabel, value: this.selectedDateRangeLabel});
        }
        this.currentPeriod = this.periods.find(data => data.value === this.periods[0].value)!.label;
      }
    }

    this.onSelectChange(false);
  }

  onSelectChange(triggerDefault = true) {
    if (this.currentPeriod === 'custom-year-range') {
      this.showCustomRange = true;
      this.focusService.placeFocusOnFirstElementIn(this.elementRef.nativeElement, '.cl-range-input');
      this.selectedPeriod = this.periods.find(data => data.value === this.currentPeriod)!.label;
      const periodValue = this.config.appliedValues!.is as number[];
      if (periodValue && periodValue.length > 0) {
        this.currentPeriodValue = periodValue;
      }
      return;
    }

    if (this.currentPeriod === 'Last5Years') {    
      this.currentPeriod = `${this.config.last5Years![0]}-${this.config.last5Years![1]}`; 
    }
    if (triggerDefault && this.currentPeriod !== this.selectedDateRangeLabel) {
      const range = this.currentPeriod.split('-').map(value => Number(value));
      this.triggerValueChange(range);
    }
    this.selectedPeriod = this.periods.find(data => data.value === this.currentPeriod)!.label;
  }

  onSelectRange() {
    this.showCustomRange = false;
    this.config.appliedValues = {is: [this.config.last5Years![0], this.config.last5Years![1]]};
    if (this.currentPeriod === 'custom-year-range') {
      const appliedValues = this.config.appliedValues as unknown as number[];
      if (Array.isArray(appliedValues) && appliedValues.length >= 2) {
        this.currentPeriod = `${appliedValues[0]}-${appliedValues[1]}`;
      } else {
        console.error('Invalid format for appliedValues');
        this.currentPeriod = 'Last5Years'; 
      }
    } else {
      this.currentPeriod = 'Last5Years'; 
    }
   
    this.showMenu();
    this.skipHideMenu = true;
    this.focusService.placeFocusOnFirstElementIn(this.elementRef.nativeElement, '.cl-form-select');
  }

  onRangeInputChange(values: number[] | string[]) {
    this.triggerValueChange(values);
  }

  private getAppliedValue(appliedValues?: AppliedFilterValuesModel): string | null {
    // @ts-ignore
    const isValueApplied = appliedValues && appliedValues.is && appliedValues.is.length > 0;

    if (isValueApplied) {
      // @ts-ignore
      return `${appliedValues!.is![0]}-${appliedValues!.is![1]}`;
    }

    return null;
  }

  private triggerValueChange(range: number[] | string[]) {
    if (this.reportsFilter) {
      this.onChange.emit({ start: range[0], end: range[1] });
    } else {
      this.valueChange.emit(range);
    }
  }

  ngOnDestroy() {
    if (this.filterDefaultValue$) {
      this.filterDefaultValue$.unsubscribe();
    }
  }

  hideMenu() {
    this.menuOpened = false;
    this.skipHideMenu = false;
  }

  showMenu() {
    this.menuOpened = true;
  }

  toggleMenu() {
    this.menuOpened ? this.hideMenu() : this.showMenu();
  }

   // tslint:disable-next-line:no-any
   onClick(event: { target: any; }) {
    if (!this.skipHideMenu && !this.elementRef.nativeElement.contains(event.target))
      this.hideMenu();
    this.skipHideMenu = false;
  }

}
