import { UIRouter, HookMatchCriteria, HookResult } from '@uirouter/core';
import { DEFAULT_STATE } from 'angularjs/app';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { EventsTrackerService } from 'components/common/services/events-tracker/events-tracker.service';
import { WindowRefService } from 'components/common/window/window-ref.service';

export function uiRouterConfigFactory(router: UIRouter) {

  // Disable flagged routes in production environment
  let disabledRoutes: HookMatchCriteria = { to: (state) => state && state.data && state.data.disableInProd };

  router.transitionService.onBefore(disabledRoutes, (transition): HookResult => {

    const envConfig = (new EnvironmentService(new WindowRefService())).getEnvironment();

    if (envConfig.disableRoutesInProd) {
      return transition.router.stateService.target(DEFAULT_STATE);
    }
    return true;
  });

  router.transitionService.onSuccess({}, (transition) => {
    const tracker: EventsTrackerService = transition.injector().get('EventsTrackerService');
    tracker.trackPageView();
  });
  // If URL does not match any states, go to landing page
  router.urlService.rules.otherwise({ state: DEFAULT_STATE });

}
