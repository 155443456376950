import * as pubsub from 'pubsub-js';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { EnvironmentService } from '../services/environment/environment.service';
import { SnackBarService } from 'components/snackbar/services/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { isUndefined } from 'lodash';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  // tslint:disable-next-line:no-any
  env: { [key: string]: any; };
  standardErrorMessage: string = 'The Server is not responding. Please try again in a few minutes.';

  constructor(
    private environmentService: EnvironmentService,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
  ) {
    this.env = this.environmentService.getEnvironment();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) { // tslint:disable-line:no-any
    return next.handle(req).pipe(
      catchError((err: any) => { // tslint:disable-line:no-any
        // DOTO: Remove it after IC-18572 issue fix
        console.error(err);
        this.standardErrorMessage = this.translate.instant('notifications.errors.common');

        switch (err.status) {
          case 401:
          case 403:
            if (req.url.indexOf('share') > 1) {
              this.snackBarService.open(this.standardErrorMessage);
            } else if (-1 < req.url.indexOf('/isAdmin')) {
              console.log(err);
            } else {
              pubsub.publish('signinRedirect', err);
            }
            break;
          case 419:
            pubsub.publish('userSignedOut', () => {});
            break;
          case 500:
            break;
          case 400:
          default:
            if (
              isUndefined(this.env.serverErrorInterceptor.exclude[err.status]) ||
              !this.env.serverErrorInterceptor.exclude[err.status].some((pattern: string) => err.url.match(pattern))
            ) {
              if (err.error && err.error.status && typeof err.error.status === 'string') {
                this.snackBarService.open(err.error.status);
              } else if (err.error && err.error.message) {
                return throwError(err);
              } else {
                this.snackBarService.open(this.standardErrorMessage);
              }
            }
        }
        return throwError(err);
      })
    );
  }
}