import { Directive, ElementRef, HostListener, OnDestroy, Renderer2, AfterViewChecked } from '@angular/core';

@Directive({
  selector: '[ic-top-scroll]'
})

export class TopScrollComponent implements AfterViewChecked, OnDestroy {

  private topScroll: any; // tslint:disable-line:no-any
  private topScrollContent: any; // tslint:disable-line:no-any
  private topScrollListener: any; // tslint:disable-line:no-any
  private tableElement: any; // tslint:disable-line:no-any

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {
    this.topScroll = renderer.createElement('div');
    this.topScrollContent = renderer.createElement('div');

    this.renderer.addClass(this.topScroll, 'top-scroll');
    this.renderer.addClass(this.topScrollContent, 'top-scroll-content');
    this.renderer.appendChild(this.topScroll, this.topScrollContent);
    this.renderer.insertBefore(
      this.element.nativeElement.parentElement,
      this.topScroll,
      this.element.nativeElement
    );

    this.topScrollListener = this.renderer.listen(
      this.topScroll,
      'scroll',
      () => this.element.nativeElement.scrollLeft = this.topScroll.scrollLeft
    );
  }

  ngAfterViewChecked() {
    this.tableElement = this.element.nativeElement.childNodes[1];
    const width = `${this.element.nativeElement.scrollWidth}px`;

    this.renderer.setStyle(this.tableElement, 'width', width);
    this.renderer.setStyle(this.topScrollContent, 'width', width);
  }

  ngOnDestroy() {
    this.topScrollListener();
  }

  @HostListener('scroll')
  onElementScroll() {
    this.topScroll.scrollLeft = this.element.nativeElement.scrollLeft;
  }

}