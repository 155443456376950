import { Component, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarService } from '../../../snackbar/services/snackbar/snackbar.service';
import { SnackBarMessageModel } from '../../../snackbar/services/snackbar/snackbar.model';

import { ajsAppModule } from '../../../../angularjs/app/app.module.ajs';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'ic-snackbar',
  templateUrl: './snackbar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SnackBarComponent {
  public message$ = this.snackBarService.messageChange$;

  constructor(
    public snackBarService: SnackBarService,
    readonly snackBar: MatSnackBar,
  ) {
    this.message$.subscribe(this.open.bind(this));
  }

  open(message: SnackBarMessageModel) {
    this.snackBar.open(message.message, message.action, message.config);
  }
}

// Downgrade component for use in AngularJS template
ajsAppModule.directive(
  'icSnackbar',
  downgradeComponent({ component: SnackBarComponent }) as angular.IDirectiveFactory
);