import { NgModule } from '@angular/core';

import { AuthenticationService } from './services/authentication/authentication.service';
import { AuthorizationService } from './services/authorization/authorization.service';
import { LocalStorageService } from './../common/services/local-storage/local-storage.service';
import { DatasetsVersionService } from './services/datasets-version/datasets-version.service';
import { UserService } from './services/user/user.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AuthenticationService,
    AuthorizationService,
    DatasetsVersionService,
    LocalStorageService,
    UserService,
  ]
})

export class AuthModule {}