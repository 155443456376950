import { Injectable } from '@angular/core';
import { DatasetsRepositoryService } from 'components/rest/services/datasets-repository/datasets-repository.service';
import { DatePipe } from '@angular/common';
import { DiagramModel } from '../../interfaces/diagram.model';
import { ExploreParamsModel } from 'components/common/interfaces/explore.request.model';
import { FileSystemService } from '../file-system/file-system.service';
import { FilterService } from 'components/analytics/services/filter/filter.service';
import { Indicator } from 'components/common/interfaces/indicator.interface';
import { isArray, isUndefined, compact, flatten, assign } from 'lodash';
import { MetadataRepositoryService } from 'components/rest/services/metadata-repository/metadata-repository.service';
import { PdfService } from './pdf.service';
import { TranslateService } from '@ngx-translate/core';
import { utilities } from 'angularjs/utilities/utilities';
import { ReportTileModel } from 'components/common/interfaces/report-tile.model';

@Injectable()
export class DiagramPdfGeneratorService {
  private fileName: string;
  private datasetLabel: string;
  private indicatorsLabel: string;

  constructor(
    private datasetsRepository: DatasetsRepositoryService,
    private datePipe: DatePipe,
    private filterService: FilterService,
    private fsService: FileSystemService,
    private metadataRepository: MetadataRepositoryService,
    private pdf: PdfService,
    private translate: TranslateService,
  ) {
    this.fileName = 'export';

    this.pdf.setupPdfMakeFonts();
    this.datasetLabel = this.translate.instant('analytics.explore.Dataset');
    this.indicatorsLabel = this.translate.instant('analytics.explore.Indicators');
  }

  downloadTilePdf(tile: ReportTileModel, base64: string, dataUpdateInfo?: string) {
    return this.prepareDiagram(tile, base64)
      .then((diagram) => this.downloadDiagramPdf(diagram, '', dataUpdateInfo));
  }

  downloadDiagramPdf(diagram: DiagramModel, fileName?: string, dataUpdateInfoVal?: string) {
    this.fileName = this.fsService.stringToFilename(fileName || diagram.title);

    if (dataUpdateInfoVal) {
      assign(diagram, {dataUpdateInfo : dataUpdateInfoVal});
      return this.pdf.downloadPdf(this.getDiagramPdf(diagram), this.fileName);
    } else {
      return this.getDataUpdateInfo()
        .then((dataUpdateInfo) => assign(diagram,  {dataUpdateInfo}))
        .then((dgrm) => this.pdf.downloadPdf(this.getDiagramPdf(dgrm), this.fileName));
    }

  }

  diagramToPdfBase64(diagram: DiagramModel): Promise<string> {
    return this.getDataUpdateInfo()
      .then((dataUpdateInfo) => assign(diagram, {dataUpdateInfo}))
      .then((dgrm) => this.pdf.pdfToBase64(this.getDiagramPdf(dgrm)));
  }

  prepareDiagram(tile: ReportTileModel, base64: string): Promise<DiagramModel> {
    let params: ExploreParamsModel;

    try {
      params = utilities.fromJson(tile.params);
    } catch (e) {
      params = {datasetId: '0'} as ExploreParamsModel;
    }

    return Promise.all([
      this.getDatasetInfo(params.datasetId),
      this.getFilters(tile.id, params)
    ]).then((result) => {
      const [datasetTitle, filters] = result;
      const {title, subtitle} = tile;
      const indicatorsTitles = this.getIndicatorsTitles(tile);

      return {title, subtitle, filters, datasetTitle, indicatorsTitles, base64} as DiagramModel;
    });
  }

  private getFilters(tileId: string, params: ExploreParamsModel) {
    if (isUndefined(params.entity) || isUndefined(params.request)) {
      return Promise.resolve(null);
    }

    return this.metadataRepository.getFilters(params.datasetId, params.entity, tileId)
      .then((response) => {
        const filters = flatten(this.filterService.groupFilters(response.filters, response.groups))
          .concat(response.period);
        const summary = this.filterService.getFilterSummary(filters, params.request.filters);

        return {summary};
      });
  }

  private getDatasetInfo(datasetId: string) {
    return this.datasetsRepository.getList()
      .then((datasets) => {
        const dataset = datasets.find((dt) => dt.datasetId === datasetId.toString());

        return dataset ? dataset.title : undefined;
      });
  }

  private getIndicatorsTitles(tile: ReportTileModel): string[] {
    try {
      const vis = JSON.parse(tile.vis);

      if (isUndefined(vis.dimensions)) {
        return ['Web of Science Documents'];
      }

      let dimensions = isUndefined(vis.dimensions.value) ? vis.dimensions : vis.dimensions.value;

      if (!isArray(dimensions)) {
        const indicator = tile.indicators.find((ind) => ind.name === vis.dimensions.value) as Indicator;

        return indicator ? [indicator.title] : [];
      } else {
        if (dimensions[0] && dimensions[0].value) {
          dimensions = dimensions.map((dimension: {value: string}) => dimension.value);
        }

        let indicators = dimensions.map((value: string) => {
          const indicator = tile.indicators.find((ind) => ind.name === value);

          return indicator ? indicator.title : undefined;
        });

        return compact(indicators);
      }
    } catch (error) {
      console.log(error);
      return ['Web of Science Documents'];
    }
  }

  private getDataUpdateInfo(): Promise<string> {
    return this.metadataRepository.getDataUpdateInfo()
      .then((dataUpdateInfo) => {
        const exportDate = `Export Date: ${ this.datePipe.transform((new Date()))}.`;
        const dateInfo = {
          deploydate: this.datePipe.transform(dataUpdateInfo.deploydate),
          wosextractdate: this.datePipe.transform(dataUpdateInfo.wosextractdate)
        };

        return this.translate.instant('analytics.explore.Data Update Info', dateInfo) + exportDate;
      });
  }

  private getDiagramPdf(diagram: DiagramModel): Object {
    return PdfService.genPdfDocument(
      this.genDiagramHeader(diagram),
      [{ image: diagram.base64, fit: [800, 500], alignment: 'center' }],
      this.genDiagramFooter(diagram),
      this.translate.store.currentLang,
      'landscape',
      [10, 30, 10, 48]
    );
  }

  private genDiagramHeader(diagram: DiagramModel) {
    const headerText: Array<Object> = [{text: diagram.title}];

    if (diagram.subtitle) {
      headerText.push({text: `  ${diagram.subtitle}`, bold: false});
    }

    return {
      style: 'diagramHeader',
      layout: 'noBorders',
      table: {
        widths: ['*'],
        body: [[{text: headerText, margin: [ 10, 5, 10, 5]}]]
      }
    };
  }

  private genDiagramFooter(diagram: DiagramModel) {
    const lineMixWidth = 180;
    const diagramDesc = this.genDiagramDescriptions(diagram);
    const diagramDescLength = diagramDesc.reduce((counter, item) => counter + item.text.length, 0);
    const diagramDescLinesCount = parseInt((diagramDescLength / lineMixWidth).toString(), 10) + 1;
    const margins = diagramDescLinesCount === 1 ?  [ 10, 11, 10, 60] : [ 10, 5, 10, 60];

    return {
      style: 'diagramFooter',
      layout: 'noBorders',
      table: {
        widths: ['*'],
        body: [[
          {stack: [{text: diagramDesc}, {text: ` ${diagram.dataUpdateInfo}`, italics: true}], margin: margins}
        ]]
      }
    };
  }

  private genDiagramDescriptions(diagram: DiagramModel) {
    const textArr: { text: string, bold?: boolean }[] = [];

    if (diagram.indicatorsTitles.length > 0) {
      textArr.push({ text: ' ' + this.indicatorsLabel + ': ', bold: true });
      textArr.push({ text: diagram.indicatorsTitles.join(', ') + '. ' });
    } else {
      // if indicators field is empty, spacer should be added
      textArr.push({ text: ' '});
    }

    if (diagram.filters) {
      diagram.filters.summary.forEach((filter) => {
        textArr.push({ text: filter.title + ': ', bold: true });
        textArr.push({ text: this.toTitleCase(filter.value.join(', ') + '. ') });
      });
    }

    if (diagram.datasetTitle) {
      textArr.push({ text: this.datasetLabel + ': ', bold: true });
      textArr.push({ text: diagram.datasetTitle + '. ' });
    }

    return textArr;
  }

  private toTitleCase(txt: string) {
    const strings = txt.toLowerCase().split(' ');
    for (let i = 0; i < strings.length; i++) {
      strings[i] = strings[i].charAt(0).toUpperCase() + strings[i].slice(1);
    }
    return strings.join(' ');
  }

}