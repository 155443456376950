import * as pubsub from 'pubsub-js';
import {Injectable} from '@angular/core';
import {downgradeInjectable} from '@angular/upgrade/static';

import {authModule} from '../../../../angularjs/auth/auth.module';
import {IcTogglesNgService} from 'components/common/services/ic-toggles-ng/ic-toggles-ng.service';
import {UserModel} from '../../interfaces/user.model';
import {NotificationsRepositoryService} from 'components/rest/services/notifications-repository/notifications-repository.service';
import {extend} from 'lodash';

@Injectable()
export class UserService {

  currentUser!: UserModel | undefined;
  currentUserPromise: Promise<UserModel>;
  currentUserPromiseResolver!: (user: UserModel) => void;

  constructor(
    private icTogglesNgService: IcTogglesNgService,
    private notificationsRepository: NotificationsRepositoryService,
  ) {
    this.currentUserPromise = new Promise(resolve => {
      this.currentUserPromiseResolver = resolve;
    });
    this.subscribeToAuthenticationEvents();
  }

  private subscribeToAuthenticationEvents() {
    pubsub.subscribe('userAuthenticated', (_channel: string, user: UserModel) => {
      this.setCurrentUser(user);
      this.initializeToggles();
    });
    pubsub.subscribe('userSignedOut', () => {
      this.currentUser = undefined;
    });
  }

  getCurrentUserPromise() {
    return this.currentUserPromise;
  }

  getCurrentUser() {
    return this.currentUser;
  }

  setCurrentUser(user: UserModel) {
    let authBefore = !!this.currentUser;

    this.currentUser = user;
    this.currentUserPromiseResolver(this.currentUser);

    if (!this.currentUser.userPref) {
      this.currentUser.userPref = {};
    }

    if (!authBefore) {
      this.currentUser.notificationsAdmin = false;
      this.notificationsRepository.isAdmin().then((result) => {
        this.currentUser!.notificationsAdmin = result;
      });
    }
  }

  initializeToggles() {
    this.icTogglesNgService.init(
      this.currentUser!.customerId.toString(),
      {
        email: this.currentUser!.name
      }
    );
  }

  updateUserSettings(settings: Object) {
    this.currentUser = extend(this.currentUser, settings);
  }

  isCurrentUser(userName?: string) {
    if (!this.currentUser || typeof userName !== 'string') return false;
    return this.currentUser.name.toLowerCase() === userName.toLowerCase();
  }

}

// Downgrade this service for use in AngularJS module
authModule.factory('userService', downgradeInjectable(UserService));
