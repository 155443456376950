import 'intro.js';

// import only the required extensions from `jquery-ui 1.12.1` core
import 'jquery-ui/ui/data';
import 'jquery-ui/ui/ie';
import 'jquery-ui/ui/keycode';
import 'jquery-ui/ui/scroll-parent';
import 'jquery-ui/ui/version';
import 'jquery-ui/ui/widget';

// import only the required `jquery-ui 1.12.1` widget extensions
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/widgets/sortable';

import 'select2';
import '../../vendor/select2-decorator.js';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import 'angular-animate';
import '../../vendor/angular-intro-custom.js';
import 'angular-touch';
import 'angular-recursion';
import 'angular-ui-bootstrap';
import 'angular-ui-indeterminate';
import 'angular-ui-select2/src/select2';
import 'angular-ui-slider';
import 'angular-ui-sortable';
import 'angular-ui-validate';

// AngularJS module and components from the component library package
import { componentLibraryAppModule } from '@ic/component-lib/src/app/app.module.ajs';

import { navigationModule } from '../navigation';

// upgraded components
import { OverbarService } from 'components/overbar/services/overbar/overbar.service';

export const uiModule = angular.module('incitesApp.ui', [
  'angular-intro',
  componentLibraryAppModule.name,
  navigationModule.name,
  'ngAnimate',
  'ngTouch',
  'RecursionHelper',
  'ui.bootstrap',
  'ui.indeterminate',
  'ui.select2',
  'ui.slider',
  'ui.sortable',
  'ui.validate'
]);

uiModule.config(['$provide', ($provide: any) => { // tslint:disable-line:no-any
  $provide.decorator('uiSelect2Directive', ['$delegate', ($delegate: any) => { // tslint:disable-line:no-any
    let directive = $delegate[0];
    directive.priority = 10;
    return $delegate;
  }]);
}]);

uiModule.run(['uiSelect2Config', (uiSelect2Config: any) => { // tslint:disable-line:no-any
  angular.extend(uiSelect2Config, {
    minimumResultsForSearch: -1,
    shouldFocusInput: () => true
  });
}]);

uiModule.factory('overbar', downgradeInjectable(OverbarService));