<cl-overlay-modal [options]="{ 'modalWidth': '450px' , 'modifiers': 'dark-header' }">
  <cl-overlay-modal-content [modalTitle]="labels.title | translate">
    <div class="cl-form__fieldset cl-form__fieldset">
      <div class="cl-form__field">
        <p class="text-left confirm-text"> {{ labels.message| translate }} </p>
      </div>
    </div>
    <div class="cl-form__fieldset cl-form__fieldset">
      <div class="cl-form__actions">
        <button type="button" class="cl-btn cl-btn--secondary" (click)="closeModal()">
          {{ labels.cancelLabel | translate }}
        </button>
        <button type="submit" class="cl-btn cl-btn--primary" (click)="confirm()">
          {{ labels.okLabel | translate }}
        </button>
      </div>
    </div>
  </cl-overlay-modal-content>
</cl-overlay-modal>