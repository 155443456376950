import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { LocaleService } from 'components/common/services/locale/locale.service';
import { Globals } from 'components/shared/globalData';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

  constructor(public locale: LocaleService, public globalData: Globals) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) { // tslint:disable-line:no-any
    let localeReq;
    if (req.url.match('/bna') || req.url.match('/xlsx')) {
      localeReq = req.clone({
        setHeaders: {'Accept-Language': this.locale.id, 'X-1P-INC-SID': this.globalData.pssidToken},
      });
    } else {
      localeReq = req.clone({
        setHeaders: {'Accept-Language': this.locale.id },
      });
    }
    
    return next.handle(localeReq);
  }
}