import {Component, ViewEncapsulation, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output, SimpleChanges} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { extend, isUndefined } from 'lodash';

import { FoldersRepositoryService } from 'components/rest/services/folders-repository/folders-repository.service';
import { FoldersService } from 'components/analytics/services/folders/folders.service';
import { FoldersViewModelService } from 'components/view-models/services/folders-view-model/folders-view-model.service';
import { NavigationService } from 'components/navigation/services/navigation/navigation.service';
import { OverbarService } from 'components/overbar/services/overbar/overbar.service';
import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';

import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';

import { FolderExtModel } from 'components/common/interfaces/folder.interface';
import { FolderModel } from '@ic/component-lib/src/components/modules/folders/folders.model';
import { ReportModel } from 'components/common/interfaces/report.model';
import { CreateFolderComponent } from '../create-folder/create-folder.component';
import { CreateReportComponent } from '../create-report/create-report.component';
import { ReportTileModel } from 'components/common/interfaces/report-tile.model';
import { StateService } from '@uirouter/core';
import { assign } from 'core-js/fn/object';
import {EventsTrackerService} from 'components/common/services/events-tracker/events-tracker.service';
import {BreadcrumbItem} from 'components/modules/breadcrumbs/breadcrumbs.model';
import { invalidCharacterTitleValidator } from 'pages/my-saved/components/saved-folders/saved-folders.validators';

@Component({
  selector: 'ic-tile-save',
  templateUrl: './tile-save.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TileSaveComponent {
  @Input() report: Partial<ReportModel> = {};
  @Input() tile: Partial<ReportTileModel> = {};
  @ViewChild(OverlayModalComponent, { static: true }) overlayModal!: OverlayModalComponent;
  @ViewChild(CreateFolderComponent, { static: true }) createFolderComponent!: CreateFolderComponent;
  @ViewChild(CreateReportComponent, { static: true }) createReportComponent!: CreateReportComponent;
  @Input() action: string = '';
  @Input() breadcrumbs: BreadcrumbItem[] = [];
  @Input() reportFolders: FolderModel[] = [];
  @Input() navigations: BreadcrumbItem[] = [];
  @Output() onReportSaveSubmit = new EventEmitter<{folderId:  number | undefined, title: string, annotation: string} | ReportModel>();
  @Output() setBreadcrumbs = new EventEmitter<string>();

  public saveForm: FormGroup;
  public folders: FolderModel[] = [];
  public foldersInvalid: boolean = false;
  private foldersVisited: boolean = false;

  public selectedReport: ReportModel | null = null;
  public selectedFolder: FolderModel | null = null;
  private foldersApi: Partial<{
    selectFolder: (folder: FolderModel) => void,
    showFolder: (folder: FolderModel) => void,
    getFolderById: (reportFolderId: number | 'root') => void
  }> = {};

  private titleMaxLength = 100;
  private subtitleMaxLength = 250;
  constructor(
    public translate: TranslateService,
    public foldersRepository: FoldersRepositoryService,
    public foldersService: FoldersService,
    public foldersViewModel: FoldersViewModelService,
    public navigation: NavigationService,
    public overbar: OverbarService,
    public reportsRepository: ReportsRepositoryService,
    private cd: ChangeDetectorRef,
    private stateService: StateService,
    private tracker: EventsTrackerService
  ) {
    this.saveForm = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.titleMaxLength),
        invalidCharacterTitleValidator.bind(this),
      ]),
      subTitle: new FormControl('', [
        Validators.maxLength(this.subtitleMaxLength)
      ])
    });
  }

  onSubmit() {
    let value = {
      title : this.saveForm.value.title,
      annotation : this.saveForm.value.subTitle
    };

    if (this.action === 'updateTile') {
      if (this.isSingleTileUpdate()) {
        this.updateTile();
      } else {
        this.onReportSaveSubmit.emit(value as ReportModel);
        this.overlayModal.closeModal();
      }
    } else if (!this.report || !this.report.id || this.action === 'addToReport') {
      if (this.isSingleTileUpdate()) {
        this.saveTile(this.selectedReport!);
      } else {
        this.onReportSaveSubmit.emit({folderId : this.selectedFolder!.id, ...value} as ReportModel);
        this.overlayModal.closeModal();
      }
    } else {
      this.saveTileCopy(this.selectedReport!);
    }
    this.tracker.trackEvent('Explore', this.action, 'Update analysis');

    if (this.isSingleTileUpdate()) {
      this.tile.title = this.tile.title || this.saveForm.value.title;
      this.tile.subtitle = this.tile.subtitle || this.saveForm.value.subTitle;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reportFolders && changes.reportFolders.currentValue) {
      this.folders = changes.reportFolders.currentValue;
    }
  }

  updateTileInReport(currentReport: Partial<ReportModel>) {
    const { title } = this.saveForm.value;
    const { subTitle } = this.saveForm.value;
    const { params } = this.tile;
    const { vis } = this.tile;
    const tileIndex = currentReport.tiles!.findIndex((tile: { id: string | undefined; }) => tile.id === this.tile.id);
    assign(currentReport.tiles![tileIndex], {title, subtitle: subTitle, params, vis});
    const savePromise = this.reportsRepository.save(currentReport as ReportModel);

    return this.displayProgress(savePromise, 'notifications.Tile Saved');
  }

  updateTile() {
    this.report['editSubTile'] = false;
    if (isUndefined(this.report.id)) {
      return this.updateTileInReport(this.report);
    }
    this.reportsRepository.get(this.report.id as string)
      .then((currentReport) => {
        this.report.tiles?.forEach((tile) => {
          if (this.report.id === tile.reportId && this.tile.id === tile.id) {
            this.report['editSubTile'] = true;
            currentReport['editSubTile'] = true;
          }
        });

        return this.updateTileInReport(currentReport);
      });

  }

  onFoldersMouseleave() {
    this.foldersVisited = true;
  }

  openCreateFolderModal() {
    this.createFolderComponent.openModal();
  }

  openCreateReportModal() {
    this.createReportComponent.openModal();
  }

  onFolderCreate(folder: FolderModel) {
    this.selectedFolder!.folders.push(folder);
    this.foldersApi.selectFolder!(folder);
    this.foldersApi.showFolder!(folder);
    this.cd.detectChanges();
  }

  onReportCreate(report: any) { // tslint:disable-line no-any
    this.selectedFolder!.folders.push(report);
    this.foldersApi.selectFolder!(report);
    this.foldersApi.showFolder!(report);
    this.cd.detectChanges();
  }

  async onModalOpen() {
    if (this.isSingleTileUpdate()) {
      this.saveForm.setValue({ title: this.tile.title || this.translate.instant('analytics.explore.New Tile'), subTitle: this.tile.subtitle || '' });
    } else {
      this.saveForm.setValue({ title: this.report.title || `${this.translate.instant('analytics.my-folders.Report')} 1`, subTitle: this.report.subTitle || '' });
    }

    if (this.reportFolders && this.reportFolders.length > 0) {
      this.folders = this.reportFolders;
    } else {
      this.folders = await this.loadReportsAndFolders();
    }
    this.setDefaultFolder();
    this.cd.detectChanges();
  }

  onModalClose() {
    this.folders = [];
    this.selectedFolder = null;
    this.selectedReport = null;
  }

  setSelectedItem(item: { type: string, selected: boolean }) {
    item.selected = true;
    const isFolder = item.type === 'folder';
    this.selectedFolder = isFolder ? <FolderModel>item : null;
    this.selectedReport = isFolder ? null : <ReportModel>item;
    this.foldersInvalid = this.foldersVisited && !this.selectedReport;
  }

  openModal() {
    this.overlayModal.openModal();
  }

  private loadReportsAndFolders() {
    if (this.folders && this.folders.length > 0) {
      return;
    }

    return Promise.all([
      this.reportsRepository.getList({ group: 'user', sharedWithMe: 'false' }),
      this.foldersRepository.getList(),
      this.reportsRepository.get('dashboard')
    ]).then((results) => {
      const [reports, folders, dashboard] = results;
      return this.mergeResults({ reports, folders, dashboard });
    }).catch((error) => {
      const message = error.data && error.data.message;

      if (message) {
        this.overbar.notifyError(message);
      }
    });
  }

  private saveTileCopy(
    destinationReport: Partial<ReportModel>,
  ) {
    const params = {
      srcReportId: this.report.id,
      srcTileId: this.tile.id,
      dstReportId: destinationReport.id,
      dstTileTitle: this.saveForm.value.title,
      dstTileSubtitle: this.saveForm.value.subTitle
    };
    const clonePromise = this.reportsRepository.cloneTile(<{ [index: string]: string }>params);

    return this.displayProgress(clonePromise, 'notifications.Tile Saved To Report');
  }

  private saveTile(
    destinationReport: Partial<ReportModel>,
  ) {

    const title = this.saveForm.value.title;
    const subtitle = this.saveForm.value.subTitle;
    const params = assign(this.tile, { title, subtitle });
    const savePromise = this.reportsRepository.createTile(destinationReport!.id as string, params);

    return this.goToSavedTile(savePromise);
  }

  // tslint:disable-next-line:no-any
  goToSavedTile(process: Promise<any>) {
    return process.then((tile) => {
      try {
        const params = JSON.parse(tile.params);

        this.stateService.go('app.analysis', {
          datasetId: params.datasetId,
          entityId: params.entity,
          filterAggId: tile.filterAggId,
          message: 'notifications.Tile Saved To Report',
          queryDataCollection: params.queryDataCollection,
          reportId: tile.reportId,
          tileId: tile.id,
        });
      } catch (error) {
        console.log(error);
      }
    });
  }

  private displayProgress(process: Promise<any>, message: string) { // tslint:disable-line
    return process.then(() => {
      this.showSuccessMessage(message);
      this.setBreadcrumbs.emit(this.saveForm.value.title);
    })
    .catch((error: Error) => console.error(error))
      // @ts-ignore
    .finally(() => this.overlayModal.closeModal());
  }

  private showSuccessMessage(message: string) {
    const tile = this.saveForm.value.title;
    const report = this.getLinkToReport();

    this.overbar.notify(this.translate.instant(message, { tile, report }));
  }


  private getLinkToReport() {
    const destinationReport = this.action === 'updateTile' ? this.report as ReportModel : this.selectedReport!;

    return destinationReport.dashboard ?
      this.navigation.buildDashboardLinkModerize() :
      this.navigation.buildReportLinkModerize(destinationReport);
  }

  private setDefaultFolder() {
    const reportFolderId = this.report && this.report.folderId;
    const defaultFolder = reportFolderId ? this.foldersApi.getFolderById!(reportFolderId) : this.folders[0];

    if (defaultFolder) {
      this.setSelectedItem(defaultFolder);
    }
  }

  private mergeResults(results: { folders: FolderExtModel[], reports: ReportModel[], dashboard: ReportModel }) {
    const folders = this.foldersViewModel.createRootArray(results.folders);
    let mergedItems = folders.map((item: FolderExtModel) => {
      item.type = 'folder';
      return item;
    });

    const dashboard = extend(results.dashboard, {
      title: this.translate.instant('report.Dashboard'),
      type: 'report'
    });

    mergedItems.push(dashboard);

    results.reports.forEach((item) => {
      item.type = 'report';

      const id = item.folderId || 'root';
      let folder = this.foldersService.getFolderById(mergedItems, id);

      if (folder) {
        // @ts-ignore
        folder.folders.push(item);
      }
    });

    return mergedItems;
  }

  goTo({ state }: { state: string }) {
    this.stateService.go(state);
  }

  getTitle() {
    const isUpdateTileAction = this.action === 'updateTile';

    if (this.isSingleTileUpdate()) {
      return isUpdateTileAction ?
        'analytics.explore.tile.Update tile' :
        'analytics.explore.tile.Save a Copy';
    }
    return isUpdateTileAction ? 'report.Update report' : 'my-saved.Create New';
  }

  getSaveLabel() {
    return this.isSingleTileUpdate() ?
      'analytics.explore.tile.Save' : (this.action === 'updateTile' ? 'analytics.explore.tile.Save Updates' : 'report.Save');
  }

  isSingleTileUpdate() {
    return Object.keys(this.tile).length > 0;
  }

}