import {Injectable, OnDestroy} from '@angular/core';
import { ChartOptionsModel } from '@ic/charts/src/models/chart.model';
import {
  VisualizationDimensionsModel,
  VisualizationModel,
  VisualizationParamsModel,
  VisualizationResolvedDimensionsModel,
  VisualizationSelectedDimensionsModel,
} from 'components/diagram/interfaces/diagram-options.model';
import {Observable, Subject, forkJoin, of, Subscription} from 'rxjs';
import { cloneDeep, extend, intersection, first, includes, isArray, flatten, merge, find, filter, xor, isEqual, omit, isUndefined, clone, assign} from 'lodash';
import { DataRepositoryService } from 'components/rest/services/data-repository/data-repository.service';
import { AdditionalData, DiagramOptionsService } from 'components/diagram/services/diagram-options/diagram-options.service';
import { ExploreTileResponseItemModel, ExploreTileResponseModel } from 'components/common/interfaces/exploretile.response.model';
import { FilterValueModel } from 'components/analytics/interfaces/filter.interface';
import { Indicator } from 'components/common/interfaces/indicator.interface';
import { map, mergeMap } from 'rxjs/operators';
import { ReportTileModel } from 'components/common/interfaces/report-tile.model';
import {RequestParamsModel, AnalysisStateConfig, ChartSettingsModel, AnalysisStateIndicatorsModel, AnalysisStateFiltersModel} from 'pages/analysis/models/analysis-state.model';
import { utilities } from 'angularjs/utilities/utilities';
import {AnalysisIndicatorModel, AnalysisBenchmarkModel, AnalysisAppliedFiltersModel, AnalysisFilterModel} from 'pages/analysis/models';
import { AnalysisDataService } from '../analysis-data/analysis-data.service';
import {EnvironmentService} from 'components/app/services/environment/environment.service';
import {DiagramLongType} from 'components/diagram/services/diagram-options/diagram-options.config';
import { BaselineIndicatorsService } from 'components/rest/services/baseline-indicators/baseline-indicators.service';
import {SettingsRepositoryService} from 'components/rest/services/settings-repository/settings-repository.service';
import {TogglesState} from '@wos/toggles-core';
import {AnalysisFiltersService} from 'components/common/services/analysis-filters/analysis-filters.service';

export interface GetChartOptionsSettings {
  benchmarks: AnalysisBenchmarkModel[];
  benchmarksItems$: Observable<ExploreTileResponseModel>;
  chartSettings: ChartSettingsModel;
  exploreItems$: Observable<ExploreTileResponseModel>;
  indicators: AnalysisStateIndicatorsModel;
  oldRequestParams?: RequestParamsModel;
  queryDataCollection: string|undefined;
  requestParams: RequestParamsModel;
  tile: ReportTileModel|undefined;
  visualizations$: Observable<VisualizationModel[]>;
  datasetTitle: string;
  filtersApplied: AnalysisStateFiltersModel;
  dataUpdateInfo: string;
  datasetId: string;
  entityId: string;
  toggles: TogglesState;
}

const ESI_BIMONTHLY_INDICATORS = ['esibimonthlyhotpapers', 'esibimonthlyhighlycitedpapers'];

@Injectable()
export class ChartOptionsService implements OnDestroy {

  private benchmarks!: AnalysisBenchmarkModel[];
  private queryDataCollection?: string|undefined;
  private _requestParams!: RequestParamsModel;
  private _itemsCount: number = 5;
  private tile: ReportTileModel | undefined;
  private dataUpdateInfo: string = '';
  datasetId: string = '';
  entityId: string = '';
  currentTreemapLevel: Subject<number> = new Subject<number>();
  issnOrSource: string = this.analysisFiltersService.issnOrSource.getValue() || 'jrnname';
  iSSNorSourceSubscription: Subscription;
  compositeEntityKey: string;
  // tslint:disable-next-line:no-any
  env: { [key: string]: any; };

  get requestParams(): RequestParamsModel {
    return extend({}, this._requestParams, {take: this._itemsCount});
  }

  constructor(
    private analysisDataService: AnalysisDataService,
    private dataRepository: DataRepositoryService,
    private diagramOptions: DiagramOptionsService,
    private environmentService: EnvironmentService,
    private baselineIndicatorsService: BaselineIndicatorsService,
    private settingsRepository: SettingsRepositoryService,
    private analysisFiltersService: AnalysisFiltersService,
  ) {
    if (this.analysisFiltersService.issnOrSource) {
      this.iSSNorSourceSubscription = this.analysisFiltersService.issnOrSource.subscribe((type) => {
        this.issnOrSource = type;
      });
    }
    this.env = this.environmentService.getEnvironment();
  }

  getChartOptions(settings: GetChartOptionsSettings, config: AnalysisStateConfig): Observable<ChartOptionsModel> {
    const {chartSettings, indicators, tile, visualizations$, exploreItems$, benchmarksItems$, oldRequestParams, datasetTitle, filtersApplied} = settings;
    this.benchmarks = settings.benchmarks;
    this._requestParams = Object.assign({}, clone(settings.requestParams), {skip: 0});
    this._itemsCount = chartSettings.itemsCount;
    this.queryDataCollection = settings.queryDataCollection;
    this.tile = settings.tile;
    this.dataUpdateInfo = settings.dataUpdateInfo;
    this.entityId = settings.entityId;
    this.datasetId = settings.datasetId;
    this.compositeEntityKey = this.settingsRepository.getCompositeEntityKey(this.entityId,
      filtersApplied.values, this.entityId === 'journal' ? this.issnOrSource : undefined);

    const stateVisualization = this.initStateVisualization(chartSettings, tile, isUndefined(oldRequestParams));

    return visualizations$.pipe(
      mergeMap(visualizations => this.mergeVisualizations(
        visualizations,
        stateVisualization,
        settings,
        config,
        datasetTitle,
        filtersApplied,
        indicators,
        exploreItems$,
        benchmarksItems$,
      ))
    );
  }

  mergeVisualizations(
    visualizations: VisualizationModel[],
    stateVisualization: ChartSettingsModel | {
      // tslint:disable-next-line:no-any
      type: any;
      // tslint:disable-next-line:no-any
      itemsCount: any;
      // tslint:disable-next-line:no-any
      zoomOptions: any;
      selectedDimensions: VisualizationSelectedDimensionsModel;
    },
    settings: GetChartOptionsSettings,
    config: AnalysisStateConfig,
    datasetTitle: string,
    filtersApplied: AnalysisStateFiltersModel,
    indicators: AnalysisStateIndicatorsModel,
    exploreItems$: Observable<ExploreTileResponseModel>,
    benchmarksItems$: Observable<ExploreTileResponseModel>,
  ) {
    const currentVisualization = this.initCurrentVisualization(stateVisualization, visualizations, settings);

    if (currentVisualization.extraType) {
      this._requestParams = extend(this._requestParams, {extraType: currentVisualization.extraType});
    }

    return currentVisualization.dataUrl ?
      this.getRemoteDiagramOptions(currentVisualization, config, datasetTitle, filtersApplied, indicators) :
      this.getLocalDiagramOptions(currentVisualization, indicators, exploreItems$, benchmarksItems$, datasetTitle, filtersApplied, config);
  }

  private initStateVisualization(
    chartSettings: ChartSettingsModel,
    tile?: ReportTileModel,
    isInitialRun?: boolean
  ) {
    if (isInitialRun && tile) {
      const vis: {[index: string]: any} = utilities.fromJson(tile.vis); // tslint:disable-line: no-any

      if (vis) {
        const { type, itemsCount, zoomOptions } = vis;
        const { selectedDimensions } = chartSettings;
        return {type, itemsCount, zoomOptions, selectedDimensions};
      }
    }

    return chartSettings;
  }

  isVisualizationAppliable(
    requestParams: RequestParamsModel,
    visualization?: VisualizationModel,
  ) {
    if (visualization && visualization.hasOwnProperty('watchFilters')) {
      return visualization.watchFilters.some((filterName) => !!requestParams.filters[filterName]);
    }

    return !!visualization;
  }

  private initCurrentVisualization(
    stateVisualization: ChartSettingsModel,
    visualizations: VisualizationModel[],
    settings: GetChartOptionsSettings,
  ): VisualizationModel {
    const { chartSettings, indicators, requestParams, oldRequestParams } = settings;
    const selectedVisualizations = visualizations.find((item) => item.type === stateVisualization.type);
    const switchToVisualization = visualizations
      .filter((visualization) => visualization.hasOwnProperty('watchFilters'))
      .sort((visualization) => visualization.default ? -1 : 1)
      .find((visualization) => visualization.watchFilters.some((filterName) => !isEqual(
        requestParams.filters[filterName],
        oldRequestParams && oldRequestParams.filters[filterName]))
      );

    let currentVisualization;

    if (switchToVisualization && !this.tile) {
      currentVisualization = switchToVisualization;
    } else if (this.isVisualizationAppliable(requestParams, selectedVisualizations)) {
      currentVisualization = selectedVisualizations;
    } else {
      currentVisualization = find(visualizations, {default: true});
    }

    const vis = extend(currentVisualization, omit(stateVisualization, ['type']));

    if (vis.dimensions) {
      vis.dimensions.sort(this.sortDimensionsByPosition);
      const resolvedDimensions = this.dimensionDetails(vis.dimensions, indicators);

      const selectedDimensions = this.getDefaultIndicatorsForDimensions(
        resolvedDimensions,
        chartSettings.selectedDimensions || {},
        false
      );

      return extend(vis, {resolvedDimensions, selectedDimensions});
    } else {
      vis.resolvedDimensions = [];
    }

    return vis;
  }

  mergeMapForRemoteDiagramOptions(
    totalCount: number,
    vis: VisualizationModel,
    config: AnalysisStateConfig,
    filtersApplied: AnalysisStateFiltersModel
  ) {
    const {getTotalData} = vis;
    let max;
    const isMultiIndicatorTrendGraph = ['multiIndicatorLine amCharts', 'multiIndicatorLine chart']
        .includes(vis.type) &&
      this.env.feature.multiIndicatorTrendGraphPOC;
    if (this._itemsCount !== 0) {
      const isImpactProfile = this.isImpactProfile(vis);
      if (isImpactProfile) {
        max = Math.min(5, totalCount);
      } else if (isMultiIndicatorTrendGraph) {
        max = Math.min(1, totalCount);
      } else if (filtersApplied.values.schema.is.toString() === this.env.SDG) {
        max = Math.min(17, totalCount);
      } else {
        const maxConfigured = this.env.analysis.visualization.max[vis.type];

        max = Math.min(
          (isUndefined(maxConfigured) ? 25 : maxConfigured),
          totalCount
        );
      }
      // @ts-ignore
      this._itemsCount = vis.type === 'wordCloud graph' ? vis.itemsCount : Math.min(max, this._itemsCount || 5);
    }

    let requestParams;

    if (getTotalData && totalCount) {
      let takeMax = isUndefined(max) ? this._itemsCount : max;

      requestParams = assign({}, this.requestParams, {
        take: this.env.analysis.visualization.takeUseItemsCount && this.env.analysis.visualization.takeUseItemsCount.includes(vis.type) ?
          Math.min(takeMax, this._itemsCount) :
          totalCount
      });
    } else {
      requestParams = this.requestParams;
    }
    let { dataItems$, esibimonthlyItems$ } = this.fetchTrendPages(requestParams, vis);
    const benchmarksItems$ = this.getBenchmarksItems(vis.benchmarksUrl);
    const groupsItem$ = this.getGroupsItems(vis.groupDataUrl, requestParams, config);
    return forkJoin([dataItems$, esibimonthlyItems$, benchmarksItems$, groupsItem$]);
  }

  mergeForRemoteDiagramOptions(
    // tslint:disable-next-line:no-any
    results: [any, any, any, any],
    vis: VisualizationModel,
    datasetTitle: string,
    filtersApplied: AnalysisStateFiltersModel,
    indicators: AnalysisStateIndicatorsModel
  ) {
    const [data, esibimonthlydata, benchmarks, groups] = results;
    this.combineTrendPages(esibimonthlydata, data as ExploreTileResponseModel);

    if (!isUndefined(this.baselineIndicatorsService)) {
      this.baselineIndicatorsService.updateBaselineIndicators((data as ExploreTileResponseModel).items, benchmarks);
    }

    let noDataFlag: boolean = false;

    if (!data || !(data as ExploreTileResponseModel).items || (data as ExploreTileResponseModel).items.length === 0) {
      noDataFlag = true;
    }

    const indicatorNames = isUndefined(data) || isUndefined((data as ExploreTileResponseModel).indicators) ?
      [] : (data as ExploreTileResponseModel).indicators.map(indicator => indicator.name);
    const visOptions = this.getOptions(
      vis,
      this.requestParams.filters,
      indicatorNames,
      isUndefined(data) ? '' : (data as ExploreTileResponseModel).sortBy!,
      vis.selectedDimensions!
    );
    const additionalData = {
      tile : this.tile,
      datasetTitle : datasetTitle,
      dataUpdateInfo : this.dataUpdateInfo,
      filters : filtersApplied,
      _requestParams : this._requestParams,
      indicators : indicators
    } as AdditionalData;

    let dataItems = isUndefined(data) ? [] : (data as ExploreTileResponseModel).items;
    if (Object.keys(groups).length > 0 && (groups as ExploreTileResponseModel).items.length > 0) {
      if (vis.type === 'multiIndicatorLine amCharts') {
        dataItems = this.settingsRepository.getCombinedDataItems((groups as ExploreTileResponseModel).items, dataItems, 1, vis.dataUrl, this.compositeEntityKey);
      } else {
        dataItems = this.settingsRepository.getCombinedDataItems(
          (groups as ExploreTileResponseModel).items,
          dataItems,
          isUndefined(data) ? 0 : (data as ExploreTileResponseModel).take as number,
          vis.dataUrl,
          this.compositeEntityKey
        );
      }
    }
    const newOptions = this.diagramOptions.getOptions(
      visOptions,
      dataItems,
      isUndefined(data) ? [] : (data as ExploreTileResponseModel).indicators,
      // @ts-ignore
      benchmarks && benchmarks.items,
      benchmarks && benchmarks.indicators,
      vis.selectedDimensions,
      noDataFlag,
      additionalData
    );

    newOptions.size = 'large';
    if (this.env.feature.amchartsPOC) {
      newOptions.zoomable = true;
    }
    return newOptions;
  }

  // @ts-ignore
  private getRemoteDiagramOptions(vis: VisualizationModel, config: AnalysisStateConfig, datasetTitle: string,
    filtersApplied: AnalysisStateFiltersModel,
                                  indicators: AnalysisStateIndicatorsModel): Observable<ChartOptionsModel> {
    let dataObservable = this.analysisDataService.getItemsTotal(config, this.requestParams);

    return dataObservable
      .pipe(
        mergeMap((totalCount: number) => this.mergeMapForRemoteDiagramOptions(totalCount, vis, config, filtersApplied)),
        map(results => this.mergeForRemoteDiagramOptions(results, vis, datasetTitle, filtersApplied, indicators))
      );
  }

  private combineTrendPages(
    esibimonthlydata: ExploreTileResponseModel & { items: ExploreTileResponseItemModel[]; },
    data: ExploreTileResponseModel & { items: ExploreTileResponseItemModel[]; }
  ) {
    if (esibimonthlydata.items.length) {
      for (let i = 0; i < data.items.length; i++) {
        for (let itemKey in esibimonthlydata.items[i]) {
          if (esibimonthlydata.items[i].hasOwnProperty(itemKey)) {
            data.items[i][itemKey] = esibimonthlydata.items[i][itemKey];
          }
        }
      }
      esibimonthlydata.indicators.forEach((indicator) => {
        if (ESI_BIMONTHLY_INDICATORS.includes(indicator.name)) {
          data.indicators.push(indicator);
        }
      });
    }
  }

  private fetchTrendPages(requestParams: RequestParamsModel, vis: VisualizationModel) {
    let esibimonthlyIndicatorFound = false;
    let highlyCitedPapersFound = false;
    let hotPapersFound = false;
    let requestParamsMain = cloneDeep(requestParams);

    for (let i = requestParamsMain.indicators.length - 1; i >= 0; i--) {
      if (requestParamsMain.indicators[i] === 'highlyCitedPapers') {
        highlyCitedPapersFound = true;
      }
      if (requestParamsMain.indicators[i] === 'hotPapers') {
        hotPapersFound = true;
      }
      if (ESI_BIMONTHLY_INDICATORS.includes(requestParamsMain.indicators[i])) {
        esibimonthlyIndicatorFound = true;
        requestParamsMain.indicators.splice(i, 1);
      }
    }
    const dataItems$ = this.dataRepository.exploreByUrl(vis.dataUrl!, requestParamsMain, this.queryDataCollection).pipe(map((data) => {
      return this.analysisDataService.markPinnedItems(data, requestParams.pinned);
    }));
    let esibimonthlyItems$ = of<ExploreTileResponseModel & {
      items: ExploreTileResponseItemModel[];
    }>({ indicators: [], items: [] });

    if (esibimonthlyIndicatorFound && (highlyCitedPapersFound || hotPapersFound)) {
      let requestParamsEsibimonthly = cloneDeep(requestParams);
      requestParamsEsibimonthly.indicators = ['key', 'seqNumber', ...ESI_BIMONTHLY_INDICATORS];
      if (highlyCitedPapersFound) {
        requestParamsEsibimonthly.indicators.push('highlyCitedPapers');
      }
      if (hotPapersFound) {
        requestParamsEsibimonthly.indicators.push('hotPapers');
      }
      if (!requestParamsEsibimonthly.indicators.includes(requestParamsEsibimonthly.sortBy)) {
        requestParamsEsibimonthly.indicators.push(requestParamsEsibimonthly.sortBy);
      }
      esibimonthlyItems$ = this.dataRepository.exploreByUrl(vis.dataUrl!, requestParamsEsibimonthly, this.queryDataCollection).pipe(map((data) => {
        return this.analysisDataService.markPinnedItems(data, requestParams.pinned);
      })) as Observable<ExploreTileResponseModel & { items: ExploreTileResponseItemModel[]; }>;
    }
    return { dataItems$, esibimonthlyItems$ };
  }

  private isTreemap(type: DiagramLongType) {
    return this.env.feature.treemapPOC &&
    ['tree map', 'treemap amCharts'].includes(type);
  }

  mergeMapForLocalDiagramOptions(
    totalCount: number,
    visOptions: VisualizationModel,
    sbjname: boolean,
    additionalData: AdditionalData,
    vis: VisualizationModel,
    exploreItems$: Observable<ExploreTileResponseModel>,
    benchmarksItems$: Observable<ExploreTileResponseModel>,
    config: AnalysisStateConfig,
  ) {
    if (this._itemsCount !== 0) {
      let max = Math.min(this.isTreemap(visOptions.type) ? 100 : 25, totalCount);

      if (sbjname && additionalData && additionalData.filters && additionalData.filters.headerFilters
        && additionalData.filters.headerFilters[0].appliedValues &&
        additionalData.filters.headerFilters[0].appliedValues.is === this.env.SDG) {
        max = Math.min(17, totalCount);
      }
      // @ts-ignore
      this._itemsCount = visOptions.itemsCount = Math.min(max, this._itemsCount || 5);
    }

    let exploresItems:  Observable<ExploreTileResponseModel>;
    let groupsItem$: Observable<ExploreTileResponseModel>;
    if (this.isTreemap(visOptions.type)) {
      const newRequestParams = cloneDeep(this.requestParams);
      newRequestParams.skip = 0;
      newRequestParams.take = this._itemsCount;
      const oldExploreItems = of({}) as Observable<ExploreTileResponseModel>;
      exploresItems = this.analysisDataService.getExploreItems(oldExploreItems, config, newRequestParams) as Observable<ExploreTileResponseModel>;
      groupsItem$ = this.getGroupsItems(vis.groupDataUrl, newRequestParams, config) as Observable<ExploreTileResponseModel>;
    } else {
      exploresItems = exploreItems$;
      groupsItem$ = this.getGroupsItems(vis.groupDataUrl, this.requestParams, config) as Observable<ExploreTileResponseModel>;
    }
    return  forkJoin([exploresItems, benchmarksItems$, groupsItem$]);
  }

  indicatorSequenceNumberComparator(sequenceNumber: AnalysisIndicatorModel | undefined) {
    return (a: any, b: any) => a[sequenceNumber!.name] - b[sequenceNumber!.name]; // tslint:disable-line no-any
  }

  mapMergedForLocalDiagramOptions(
    response: ExploreTileResponseModel[],
    vis: VisualizationModel,
    comparator: ((a: ExploreTileResponseItemModel, b: ExploreTileResponseItemModel) => number) | undefined
  ) {
    const [ exploreItemsResponse, benchmarksItemsResponse, groups ] = response;

    if (!isUndefined(this.baselineIndicatorsService)) {
      this.baselineIndicatorsService.updateBaselineIndicators(exploreItemsResponse.items, benchmarksItemsResponse);
    }

    const {items, indicators: indicatorsList} = exploreItemsResponse;
    const pinnedItems = items.filter((item) => item.pinned).sort(comparator);
    const regularItems = items.filter((item) => !item.pinned).sort(comparator);
    let newItems = pinnedItems.concat(regularItems).slice(0, this._itemsCount);
    if (Object.keys(groups).length > 0 && groups.items.length > 0) {
      newItems = this.settingsRepository.getCombinedDataItems(groups.items, items, this._itemsCount as number, vis.dataUrl, this.compositeEntityKey);
    }

    return {items: newItems, indicators: indicatorsList, benchmarks: benchmarksItemsResponse};
  }

  mapFinalForLocalDiagramOptions(
    // tslint:disable-next-line:no-any
    data: { items: any; indicators: any; benchmarks: any; },
    vis: VisualizationModel,
    visOptions: VisualizationModel,
    additionalData: AdditionalData | undefined,
  ) {
    const newOptions = this.diagramOptions.getOptions(
      visOptions,
      data.items,
      data.indicators,
      // TODO: remove it after ipmplementation
      // @ts-ignore
      data.benchmarks && data.benchmarks.items,
      // TODO: remove it after ipmplementation
      // @ts-ignore
      data.benchmarks && data.benchmarks.indicators,
      vis.selectedDimensions,
      undefined,
      additionalData
    );
    newOptions.size = 'large';
    if (['treemap', 'treemapAmcharts'].includes(newOptions.type) &&
      this.env.feature.treemapPOC) {
      newOptions.fetchData = (name: string, schemalevel: string): Promise<ExploreTileResponseModel> => {
        let requestParams = cloneDeep(this.requestParams);
        assign(requestParams, {skip: 0},
          {take: schemalevel === 'Macro' ? 326 : 2444});

        extend(requestParams.filters, {
            'sbjname': {
              'is': [
                name
              ]
            }
          }
        );

        requestParams.filters.schemalevel = {
          // @ts-ignore
          'is': schemalevel
        // tslint:disable-next-line:no-any
        } as any as AnalysisAppliedFiltersModel;

        return this.dataRepository.explore(this.datasetId, this.entityId, requestParams, this.queryDataCollection).toPromise();
      };

      newOptions.setCurrentTreemapLevel = (chartLevel: number) => {
        this.currentTreemapLevel.next(chartLevel);
      };
    }
    return newOptions;
  }

  private getLocalDiagramOptions(
    vis: VisualizationModel,
    indicators: AnalysisStateIndicatorsModel,
    exploreItems$: Observable<ExploreTileResponseModel>,
    benchmarksItems$: Observable<ExploreTileResponseModel>,
    datasetTitle: string, filters: AnalysisStateFiltersModel,
    config: AnalysisStateConfig
  ): Observable<ChartOptionsModel> {
    const sequenceNumber = this.requestParams.indicators
      .map((indicator: string) => find(indicators.indicators, { name: indicator }))
      // @ts-ignore
      .find((indicator: Indicator|undefined) => indicator && indicator.sequenceNumber);

    const comparator = this.indicatorSequenceNumberComparator(sequenceNumber);
    const additionalData = {
      tile : this.tile,
      datasetTitle : datasetTitle,
      dataUpdateInfo : this.dataUpdateInfo,
      filters : filters,
      _requestParams : this._requestParams,
      indicators : indicators
    } as AdditionalData;
    const visOptions = this.getOptions(
      vis,
      this.requestParams.filters,
      this.requestParams.indicators,
      this.requestParams.sortBy,
      vis.selectedDimensions!
    );
    let sbjname = this.subName(additionalData.filters.headerFilters);
    let dataObservable = this.analysisDataService.getItemsTotal(config, this.requestParams);
    return  dataObservable
      .pipe(
        mergeMap((totalCount: number) => this.mergeMapForLocalDiagramOptions(
          totalCount, visOptions, sbjname, additionalData, vis, exploreItems$, benchmarksItems$, config
        )),
        map((response: ExploreTileResponseModel[]) => this.mapMergedForLocalDiagramOptions(response, vis, comparator)),
        map((data) => this.mapFinalForLocalDiagramOptions(data, vis, visOptions, additionalData))
      );
  }

  getItems(selectedBenchmarks: AnalysisBenchmarkModel[], url: string) {
    const payload = extend({benchmarkNames: selectedBenchmarks.map((bm: AnalysisBenchmarkModel) => bm.name)}, this.requestParams);

    return this.dataRepository.exploreByUrl(url, payload, this.queryDataCollection);
  }

  private getBenchmarksItems(benchmarksUrl?: string) {
    const selectedBenchmarks = this.benchmarks.filter(bm => bm.enabled);

    return benchmarksUrl && selectedBenchmarks.length ? this.getItems(selectedBenchmarks, benchmarksUrl) : of({} as ExploreTileResponseModel);
  }

  // TODO: Legacy code. We should reimplement it
  private resolveDiagramParams(
    diagramParams: VisualizationParamsModel[],
    filters: { [index: string]: FilterValueModel },
    indicators: string[],
    sortIndicator: string,
    selectedDimensions: VisualizationSelectedDimensionsModel
  ) {
    let resolvedParams = {};
    const params = cloneDeep(diagramParams);

    params.forEach((param: any) => { // tslint:disable-line: no-any
      if (param.value.indicator) {
        const availableIndicators = intersection(
          [param.value.indicator].concat(param.availableIndicators || []),
          indicators
        );
        param.value = includes(availableIndicators, sortIndicator) ? sortIndicator : first(availableIndicators);

      } else if (param.value.indicatorByOrder) {
        param.value = selectedDimensions.value[param.value.indicatorByOrder];
      } else if (param.value.filter) {
        let usedFilters = param.value.filter;
        if (!isArray(usedFilters)) {
          usedFilters = [usedFilters];
        }
        let values: any[] = []; // tslint:disable-line: no-any
        usedFilters.forEach((fltr: any) => { // tslint:disable-line: no-any
          const value = filters[fltr];
          if (value) {
            values.push(value.is); // TODO: fully support is/not
          }
        });

        values = flatten(values);
        if (values.length) {
          param.value = values.join('; ');
        } else {
          delete param.value;
        }
      } else if (param.value.dimension) {
        if (param.value.source) {
          param.value = `indicator-${param.value.source}`;
        } else {
          if (param.value.prefix) {
            // @ts-ignore
            param.value = param.value.prefix + (selectedDimensions && selectedDimensions[param.value.dimension]);
          } else {
            // @ts-ignore
            param.value = selectedDimensions && selectedDimensions[param.value.dimension];
          }
        }
      }

      resolvedParams = merge(resolvedParams, this.parseDiagramParam(param));
    });

    return resolvedParams;
  }

  private parseDiagramParam(param: VisualizationParamsModel): {[index: string]: string|Object} {
    const result: {[index: string]: string|Object} = {};
    const parts = param.name.split('.');

    if (parts.length > 1) {
      param.name = parts.slice(1).join('.'); // TODO: remove sideffect here
      result[parts[0]] = this.parseDiagramParam(param);
    } else {
      result[param.name] = param.value;
    }

    return result;
  }

  getFullIndicatorList(
    dimension: VisualizationResolvedDimensionsModel,
    indicators: string[],
    numberToAdd: number,
  ) {
    const difference = xor(indicators, dimension.indicators.map((indicator: AnalysisIndicatorModel) => indicator.name));
    return indicators.concat(difference.slice(0, numberToAdd));
  }

  getSuitableSelectedIndicators(
    dimension: VisualizationResolvedDimensionsModel,
    index: number,
    visualizationResolvedDimensions: VisualizationResolvedDimensionsModel[],
    indicators: string[],
  ) {
    indicators = !isArray(indicators) ? [indicators] : indicators;
    const suitableIndicators = this.intersection(indicators, dimension);

    if (suitableIndicators.length > 0) {
      return dimension.multipleChoice ? suitableIndicators : suitableIndicators[0];
    } else {
      return this.getDefaultIndicator(dimension, index, visualizationResolvedDimensions);
    }
  }

  private getDefaultIndicatorsForDimensions(
    resolvedDimensions: VisualizationResolvedDimensionsModel[],
    selectedDimensions: VisualizationSelectedDimensionsModel,
    isInit: boolean
  ): VisualizationSelectedDimensionsModel {
    return resolvedDimensions.reduce((acc, dimension, i, arr) => {
      const curSelectedIndicators = selectedDimensions[dimension.name];

      // @ts-ignore
      acc[dimension.name] = curSelectedIndicators ? this.getSuitableSelectedIndicators(dimension, i, arr, curSelectedIndicators)
        : this.getDefaultIndicator(dimension, i, arr);

      if (dimension.multipleChoice && dimension.numberOfSelected && !isInit) {
        let numberToAdd = dimension.numberOfSelected - acc[dimension.name].length;

        if (numberToAdd > 0) {
          acc[dimension.name] = this.getFullIndicatorList(dimension, acc[dimension.name], numberToAdd);
        }
      }

      return acc;
    }, {} as {[index: string]: string[]}) as VisualizationSelectedDimensionsModel;
  }

  private getDefaultIndicator(
    dimension: VisualizationResolvedDimensionsModel,
    i: number,
    arr: VisualizationResolvedDimensionsModel[]
  ) {

    const sortByIndicator = find(dimension.indicators, { name: this.requestParams.sortBy });
    const indicator = this.getIndicator(dimension, i, arr, sortByIndicator!);

    return dimension.multipleChoice ? [indicator.name] : indicator.name;
  }

  private getOptions(
    diagram: VisualizationModel,
    filters: { [index: string]: FilterValueModel },
    indicators: string[],
    sortIndicator: string,
    selectedDimensions: VisualizationSelectedDimensionsModel
  ) {
    const diagramOptions = cloneDeep(diagram);
    const resolved = this.resolveDiagramParams(diagram.params!, filters, indicators, sortIndicator, selectedDimensions);
    delete diagramOptions.params;
    return extend(diagramOptions, resolved);
  }

  getGroupsItemsFromUrl(
    groupsUrl: string,
    requestParams:  RequestParamsModel,
    config: AnalysisStateConfig,
  ) {
    const hasGroupPinned = !isUndefined(requestParams.groupPinned);
    const requestParamsCopy = cloneDeep(requestParams);
    const payload = extend( requestParamsCopy, {compositeEntityKey: this.compositeEntityKey});

    return this.analysisDataService.getGroupsItems(of({}) as Observable<ExploreTileResponseModel>, config, payload, groupsUrl)
      .pipe(map((data) => this.analysisDataService.markPinnedItems(data as ExploreTileResponseModel, hasGroupPinned ? requestParamsCopy.groupPinned! : [])));
  }

  private getGroupsItems(groupsUrl: string, requestParams:  RequestParamsModel, config: AnalysisStateConfig) {
    return groupsUrl ? this.getGroupsItemsFromUrl(groupsUrl, requestParams, config) : of({} as ExploreTileResponseModel);
  }

  ngOnDestroy() {
    if (typeof this.iSSNorSourceSubscription !== 'undefined') {
      this.iSSNorSourceSubscription.unsubscribe();
    }
  }
  sortDimensionsByPosition(a: VisualizationDimensionsModel, b: VisualizationDimensionsModel) {
    if (b.position && a.position) {
      return a.position - b.position;
    }

    return a.name > b.name ? -1 : 1;
  }

  dimensionDetails(dimensions: VisualizationDimensionsModel[], indicators: AnalysisStateIndicatorsModel) {
    return dimensions.map((dimension) => {
      return {
        name: dimension.name,
        indicators: filter(indicators.indicators, (indicator) => includes(dimension.indicators, indicator.name)).sort((a, b) => {
          return dimension.indicators.indexOf(a.name) - dimension.indicators.indexOf(b.name);
        }),
        multipleChoice: dimension.multipleChoice,
        numberOfSelected: dimension.numberOfSelected,
        title: dimension.title ? `diagram.${dimension.title}` : undefined
      };
    });
  }

  getIndicator(dimension: VisualizationResolvedDimensionsModel, i: number,
    arr: VisualizationResolvedDimensionsModel[], sortBy: AnalysisIndicatorModel) {
      if (i > 0 && arr && dimension.indicators.length - 1 >= i) {
        if (sortBy && i === dimension.indicators.indexOf(sortBy)) {
          i = 0;
        }
        return dimension.indicators[i];
      }

      return sortBy || dimension.indicators[0];
    }

  intersection(indicators: string[], dimension: VisualizationResolvedDimensionsModel) {
      return intersection(indicators, dimension.indicators.map(indicator => indicator.name));
    }

  subName(data: AnalysisFilterModel[]) {
    return data.find(({ name }) => name === 'sbjname') ? true : false;
  }

  isImpactProfile(vis: VisualizationModel) {
    if (vis.type === 'impactprofile') {
      return true;
    }
    return false;
  }
}
