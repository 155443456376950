import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationComponent } from './navigation.component';
import { NavigationService } from './services/navigation/navigation.service';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

// tslint:disable-next-line:no-any
(window as any).retain = [NavigationComponent];

@NgModule({
  imports: [
    CommonModule,
    UIRouterUpgradeModule
  ],
  declarations: [NavigationComponent],
  exports: [NavigationComponent],
  entryComponents: [NavigationComponent],
  providers: [NavigationService]
})

export class NavigationModule {}
