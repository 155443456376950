import { Injectable } from '@angular/core';
import { UserStorageService } from '../../../analytics/services/user-storage/user-storage.service';
import { UserService } from 'components/auth/services/user/user.service';
import { MetadataRepositoryService } from 'components/rest/services/metadata-repository/metadata-repository.service';
import { DatasetsRepositoryService } from 'components/rest/services/datasets-repository/datasets-repository.service';
import { DatasetModel } from 'components/rest/services/datasets-repository/models';
import { filter, find, includes, union, cloneDeep, assign } from 'lodash';
import { Indicator, IndicatorResponseModel } from 'components/common/interfaces/indicator.interface';
import { Tile } from 'components/analytics/interfaces/tile.interface';
import { FiltersResponseModel } from 'components/analytics/interfaces/filter.interface';
import { Observable, from, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExploreStateModel } from 'components/analytics/interfaces/expore-state.interface';

@Injectable()
export class ExploreStateService {

  public ready: Promise<ExploreStateModel> | undefined;
  public filters: Promise<FiltersResponseModel> | undefined;
  public datasets!: Promise<DatasetModel[]>;

  constructor(
    private datasetsRepository: DatasetsRepositoryService,
    private metadataRepository: MetadataRepositoryService,
    private userService: UserService,
    private userStorage: UserStorageService
  ) { }

  load(datasetId: string, entity: string, tileid: string, receivedTile: Promise<Tile>) {
    const saveExploreState = this.userStorage.saveExploreState.bind(this.userStorage);
    let exploreState = this.userStorage.loadExploreState(entity, datasetId);
    let state = exploreState && exploreState.datasetId === datasetId ? exploreState : {};
    let requestParams = state.request || { take: 25, skip: 0 };


    const indicators = this.getIndicators(entity, receivedTile, datasetId).then((result) => {
      let [metadata, tile] = result;

      const requiredIndicators = metadata.indicators
        .filter((indicator: Indicator) => indicator.required || indicator.group === 'dptmnt')
        .map((indicator: Indicator) => indicator.name);

      requestParams.sortBy = this.setOrder(requestParams, tile, 'sortBy') || metadata.primaryIndicator;
      requestParams.sortOrder = this.setOrder(requestParams, tile, 'sortOrder') || metadata.primaryOrder;
      requestParams.indicators = union(this.setOrder(requestParams, tile, 'indicators') || metadata.enabledIndicators, requiredIndicators
      );

      return metadata;
    });

    const filtersReady: any = {}; // tslint:disable-line
    filtersReady.promise = new Promise(resolve => filtersReady.resolve = resolve);

    let filters = this.filters = this.getFilters(datasetId, entity, tileid, receivedTile).then((result) => {
      let [metadata, tile] = result;
      metadata.values = tile && tile.request ? tile.request.filters : cloneDeep(requestParams.filters);
      metadata.ready = filtersReady;
      return metadata;
    });

    let benchmarks = this.getBenchmarks(datasetId, entity, tileid, receivedTile).then((result) => {
      let [metadata, tile] = result,
      benchmarkList = metadata || [];

      // tslint:disable-next-line:no-any
      benchmarkList.forEach((benchmark: { selected: boolean; name: any; }) => {
        benchmark.selected = includes(tile ? tile.benchmarks : state.benchmarks, benchmark.name);
      });
      return benchmarkList;
    });

    const visualizations = this.getVisualization(receivedTile, state).pipe(
      // tslint:disable-next-line:no-any
      tap((visualization: any) => {
        if (visualization && visualization.itemsCount > requestParams.take) {
          requestParams.take = requestParams.take * Math.ceil(visualization.itemsCount / requestParams.take);
        }
      })
    );

    const dimensions = this.getDimensions(receivedTile, state);

    this.datasets = this.dataSets(receivedTile, datasetId, tileid)
      .then((datasetList) => {
        return datasetList.map((dtst) => dtst.datasetId === datasetId ? assign(dtst, { selected: true }) : dtst);
      });

    filtersReady.promise.then((allFilter: { [keys: string]: string }) => {
      requestParams.filters = allFilter;
    });

    if (receivedTile) {
      receivedTile.then((tile) => {
        requestParams.isSourceYear = tile.request && tile.request.isSourceYear;
        requestParams.citedOrCiting = tile.request && tile.request.citedOrCiting;
        requestParams.pinned = tile.request && tile.request.pinned;
      });
    }

    this.ready = Promise.all([indicators, filters, benchmarks, requestParams, filtersReady.promise, visualizations.toPromise(), dimensions, this.datasets])
      .then((result) => {
        const [
          stateIndicators,
          stateFilters,
          stateBenchmarks,
          stateRequest,
          stateReady,
          stateVisualization,
          stateDimensions,
          stateDatasets
        ] = result;

        return state = {
          indicators: stateIndicators,
          filters: stateFilters,
          benchmarks: stateBenchmarks,
          request: stateRequest,
          ready: !!stateReady,
          visualization: stateVisualization,
          dimensions: stateDimensions,
          datasets: stateDatasets,
          getDefaultIndicators: function() {
            return state.indicators.enabledIndicators
              .map((indicator: any) => find(state.indicators.indicators, { name: indicator }));  // tslint:disable-line
          },
          getEnabledIndicators: function() {
            return this.request.indicators
              .map((i: any) => find(state.indicators.indicators, { name: i })); // tslint:disable-line
          },
          getEnabledBenchmarks: function() {
            return filter(state.benchmarks, 'selected');
          },
          serialize: function() {
            return {
              request: this.request,
              benchmarks: this.getEnabledBenchmarks().map((item: any) => item.name), // tslint:disable-line
              visualization: this.visualization,
              dimensions: this.dimensions,
              datasetId: datasetId
            };
          },
          save: async function() {
            if (await receivedTile) { return; }
            let serializedState = this.serialize();
            saveExploreState(entity, serializedState);
          },
        };

      });

    if (!receivedTile) {
      this.userStorage.setLastUsedDatasetId(datasetId);
      this.userStorage.setLastUsedEntity(entity);
    }

    return this.ready;

  }

  getDataSets(requiredDatasetId: string, owner: string, filterAggId: string): Promise<DatasetModel[]> {
    return this.datasetsRepository.getList({ filterAggId: filterAggId })
      .then((datasets: DatasetModel[]) => {
        const dataset = find(datasets, { datasetId: requiredDatasetId });
        if (dataset) return datasets;

        return this.datasetsRepository
          .getList({ datasetId: requiredDatasetId, owner: owner })
          .then((requiredDatasets) => datasets.concat(requiredDatasets));
      });
  }

  getIndicators(entity: string, tile: Promise<Tile>, datasetId: string): Promise<[IndicatorResponseModel, Tile]> {
    return Promise.all([
      this.metadataRepository.getIndicators(entity, datasetId).toPromise(),
      tile
    ]);
  }

  getFilters(datasetId: string, entity: string, tileid: string, tile: Promise<Tile>) {
    return Promise.all([
      this.metadataRepository.getFilters(datasetId, entity, tileid),
      tile
    ]);
  }

  getBenchmarks(datasetId: string, entity: string, tileid: string, tile: Promise<Tile>) {
    return Promise.all([
      this.metadataRepository.getBenchmarks(datasetId, entity, tileid).toPromise(),
      tile
    ]);
  }

  getVisualization(tileWrap: Promise<Tile>, state: any): Observable<any> { // tslint:disable-line
    if (tileWrap) {
      return from(tileWrap).pipe(
        map((tile: Tile) => {
          const tileVis = {
            title: tile.vis.title,
            type: tile.vis.type,
            itemsCount: tile.vis.itemsCount,
            zoomOptions: tile.vis.zoomOptions
          };
          return tile.vis && tileVis || state.visualization;
        })
      );
    }

    return of(state.visualization);
  }

  getDimensions(tileWrap: Promise<Tile>, state: any) { // tslint:disable-line
    if (tileWrap) {
      return tileWrap.then((tile: Tile) => tile.vis && tile.vis.dimensions || state.dimensions);
    }
    return Promise.resolve(state.dimensions);
  }

  dataSets(tileWrap: Promise<Tile>, datasetId: string, tileid: string): Promise<DatasetModel[]> {
    if (tileWrap) {
      return tileWrap.then((tile: Tile) => this.getDataSets(tile.datasetId, tile.owner, tileid));
    }
    return this.getDataSets(datasetId, this.userService.getCurrentUser()!.name, tileid);
  }

  setOrder(requestParams: { [key: string]: any[] }, tile: Tile, type: string) { // tslint:disable-line
    return tile && tile.request ? tile.request[type] : requestParams[type];
  }

}