import * as angular from 'angular';
import { StateDeclaration, StateRegistry } from '@uirouter/core';
import { upgradeModule } from '@uirouter/angular-hybrid';

import { signinState, signoutState, redirectState, forbiddenState } from './auth.states';

export const authModule = angular.module('incitesApp.auth', [
  upgradeModule.name,
]);

authModule.run(['$stateRegistry', ($stateRegistry: StateRegistry) => {
  $stateRegistry.register(signinState as StateDeclaration);
  $stateRegistry.register(signoutState as StateDeclaration);
  $stateRegistry.register(redirectState as StateDeclaration);
  $stateRegistry.register(forbiddenState as StateDeclaration);
}]);
