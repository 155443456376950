
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class VersionRepositoryService {

  private version!: string;
  private version$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor() {}

  set(newVersion: string|null) {
    if (!this.version && newVersion) {
      this.version = newVersion;
      this.version$.next(newVersion);
    }
  }

  get(): BehaviorSubject<string> {
    return this.version$;
  }

}