<div class="ic-sidebar-filters" *ngIf="datasets && filters">

  <div class="ic-analysis-sidebar__description">
    <p>{{ 'analysis.sidebar.filters.description' | translate }}</p>
  </div>

  <div class="ic-analysis-sidebar__header">
    <fieldset class="cl-form__fieldset cl-form__fieldset--stacked">
      <div class="cl-form__field" *ngIf="datasets && datasets.length > 0">
        <label class="cl-form-label" for="dataset">{{ 'analysis.sidebar.filters.Dataset' | translate }}</label>
        <span class="cl-form-select" [ngClass]="{ 'disabled' : entity?.id ==='department'}">
          <nav aria-label="Main Navigation" class="cl-entity-menu ic-menu-dropdown">
            <ul class="cl-navigation__list--primary">
              <li class="cl-navigation__link--primary has-submenu title">
                <a href="javascript:void(0)" (click)="toggleMenu();" class="selected-entity" title="{{ currentDataSetTitle }}">{{currentDataSetTitle}}</a>
                <ul
                  class="cl-navigation__list--secondary dataSetDropDown"
                  [ngClass]="{ 'active': menuOpened }"
                  [attr.aria-hidden]="!menuOpened"
                >
                  <li class="cl-navigation__item--secondary" *ngFor="let dataset of datasets">
                    <label [attr.for]="dataset.id" (click)="hideMenu()" [ngClass]="dataset.title === currentDataSetTitle ? 'active' : 'none'" title="{{dataset.title}}">
                      {{dataset.title}}
                      <input type="radio" (change)="onDatasetChange()" [(ngModel)]="currentDatasetId"
                             [value]="dataset.datasetId" [attr.name]="dataset.id" [attr.id]="dataset.id">
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </span>
        <span class="ic-analysis-sidebar__note--small" *ngIf="currentDataset && currentDataset.modifiedDate">
          {{ 'analysis.sidebar.filters.Last updated' | translate }} {{ currentDataset.modifiedDate | date }}
        </span>
      </div>

      <div class="cl-form__field ic-esci-checkbox" *ngIf="!isPreprintsDatasetSelected">
        <input type="checkbox"
          class="cl-form-checkbox"
          id="esci-documents"
          name="esci-documents"
          [checked]="includeEsciDocuments"
          (change) = "onEsciChange(!includeEsciDocuments)"
        />
        <label class="cl-form-label" for="esci-documents">
          {{ 'report.Include ESCI documents' | translate }}
        </label>

        <cl-tooltip
          class="ic-esci-info"
          [options]="{
            id: 'esci-documents-tooltip',
            tooltipWidth: '230px',
            align: context === 'smartReport' ? 'bottom-right' : ( currentLang === 'zh' ? 'top-center' : 'top-right')
          }"
        >
          <cl-tooltip-button [buttonClass]="'cl-btn cl-btn--text cl-btn--icon'">
            <cl-icon [iconId]="'info'" [color]="'color-gray40'" [label]="'more information on ESCI documents'"></cl-icon>
          </cl-tooltip-button>
          <cl-tooltip-content>
            <p>
              {{ 'report.ESCI Tooltip' | translate }}
            </p>
            <a
              href="/incites-app/redirect/esci"
              class="cl-btn cl-btn--text cl-btn--with-icon ic-esci-info__learn-more"
              target="_blank"
            >
              <span class="cl-btn__text">{{ 'report.ESCI Link' | translate }}</span>
              <cl-icon [iconId]="'link'"></cl-icon>
            </a>
          </cl-tooltip-content>
        </cl-tooltip>
      </div>

      <ic-date-range-filter
        *ngIf="periodFilter"
        [config]="periodFilter"
        (valueChange)="onDateRangeChange($event)"
        [context] = "context"
      ></ic-date-range-filter>
      <div class="data-update-dates" *ngIf="dataUpdateInfo">
        <p>{{dataUpdateInfo.split('Export Date')[0]}}
        </p>
      </div>
    </fieldset>
  </div>

  <div class="ic-analysis-sidebar__main" [ngClass]="{
    'auto-height': detailsClosing,
    'fixed': detailsOpen
  }">
    <ul class="ic-analysis-sidebar__list">
      <li *ngFor="let group of filters.filtersGroups">
        <ng-container *ngIf="!group.hidden">
          <button *ngIf="group.name!='citedDocs'"
              type="button"
              class="cl-btn ic-analysis-sidebar__list__btn"
              (click)="openFilterGroup(group,sidebarDetails)"
              [ngClass]="{ 'disable-group' : group.disabled && !singleTileSelect() }"
            >
              <span class="ic-analysis-sidebar__list__title">{{ group.title }}</span>
              <span
                *ngIf="isAnyFilterApplied(group)"
                class="ic-analysis-sidebar__list__item__enabled"
                [attr.aria-label]="'analysis.sidebar.indicators.enabled' | translate"
                role="text"
              ></span>
              <cl-icon [iconId]="'caret-right'"></cl-icon>
            </button>
        </ng-container>
      </li>
    </ul>
  </div>

  <div class="ic-analysis-sidebar__footer">
    <div class="ic-analysis-sidebar__footer__help">
      <p>{{ 'analysis.sidebar.filters.help' | translate }}</p>
      <p>
        <button class="cl-btn cl-btn--link cl-btn--with-icon" (click)="goToAnalysis()">
          <span class="cl-btn__text">
            {{ 'analysis.sidebar.filters.Browse starter analyses' | translate }}
          </span>
          <cl-icon [iconId]="'link'"></cl-icon>
        </button>
      </p>
    </div>
  </div>

</div>

<div class="ic-analysis-sidebar__details"
  [attr.aria-hidden]="!detailsOpen"
  [ngClass]="{
    'active': detailsOpen,
    'closing': detailsClosing
  }"
  #sidebarDetails
>
  <ic-sidebar-filter
    *ngIf="activeFilterGroup"
    [allFilters]="filters.filters"
    [outsideClickEvent]="outsideClickEvent"
    [filterGroup]="activeFilterGroup"
    (showChooseTreeModal)="showChooseTreeModal.emit($event)"
    (filterApply)="onFilterApply($event)"
    (close)="onDetailsClose($event)"
    (inputFilters) = "emittedInputFilters($event)"
    [entity]="entity?.id"
  ></ic-sidebar-filter>
</div>
