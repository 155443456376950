import { Component, ViewEncapsulation } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

import { ajsAppModule } from '../../angularjs/app/app.module.ajs';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  encapsulation: ViewEncapsulation.None
})

export class NavigationComponent {}

// Downgrade component for use in AngularJS template
ajsAppModule.directive(
    'navigation',
    downgradeComponent({ component: NavigationComponent }) as angular.IDirectiveFactory
  );
