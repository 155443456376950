import {clone, extend, find, forEach, isArray, isObject, isUndefined} from 'lodash';
import {Injectable} from '@angular/core';
import {catchError, map, mergeMap, share, tap} from 'rxjs/operators';
import {MultiSelectConfigModel, MultiSelectOptionModel} from '@ic/component-lib/src/components/modules/multi-select/models';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {OrgInfo, ReportModel, ReportTypes} from 'components/common/interfaces/report.model';
import {ReportsRepositoryService} from 'components/rest/services/reports-repository/reports-repository.service';
import {ReportTileModel} from 'components/common/interfaces/report-tile.model';
import {SettingsRepositoryService} from 'components/rest/services/settings-repository/settings-repository.service';
import {
  DatasetFilterItemModel,
  DatasetFilterModel,
  DateRangeConfig,
  FilterTypeOptionModel,
  ReportFiltersResponceData,
  SelectFilterModel,
  TabReportFiltersModel,
} from 'pages/tab-report/interfaces/tab-report-filters.model';
import {BucketModel, TabReportModel} from 'pages/tab-report/interfaces/tab-report.model';
import {TranslateService} from '@ngx-translate/core';
import {UserModel} from 'components/auth/interfaces/user.model';
import {UserService} from 'components/auth/services/user/user.service';
import {EnvironmentService} from 'components/app/services/environment/environment.service';
import {isNullOrUndefined} from 'util';
import {AnalysisFiltersService} from 'components/common/services/analysis-filters/analysis-filters.service';
import {TogglesState} from '@wos/toggles-core';
import {Globals} from 'components/shared/globalData';
import {IcTogglesNgService} from 'components/common/services/ic-toggles-ng/ic-toggles-ng.service';
import { SettingsModel } from 'components/rest/interfaces/settings.model';

interface DateRangeModel {
  start: string;
  end: string;
}

interface ReportConstantsModel {
  datasetEnabled: boolean;
  defaultTypeOptions?: { [index: string]: string } | undefined;
  prefix: string;
  value: string;
  filterName: string;
  dependsOn?: string[];
  filterList?: {
    defaultTypeOptions?: { [index: string]: string } | undefined,
    filterName: string,
    // tslint:disable-next-line:no-any
    selectValues?: any[],
    value: string
  }[];
}
@Injectable()
export class TabReportService {

  private tabReport!: TabReportModel;
  private filters!: TabReportFiltersModel;
  private availableReports: ReportModel[] = [];
  IS_PREPRINT_DATASET_DISABLED: boolean = false;
  private reportConstants: {[index in ReportTypes]: ReportConstantsModel} = {
    iipDept: {
      datasetEnabled: false,
      prefix: 'dept',
      value: 'iipOrganizations',
      filterName: 'wpdepts'
    },
    org: {
      datasetEnabled: true,
      prefix: 'org',
      value: 'organization',
      filterName: 'orgname',
    },
    iipFaculty: {
      datasetEnabled: true,
      prefix: 'researcher',
      value: 'iipPersons',
      filterName: 'wppersonId'
    },
    publisherRpt: {
      datasetEnabled: true,
      defaultTypeOptions: { publisherType: 'All' },
      prefix: 'publisher',
      value: 'publishers',
      filterName: 'publisher',
      filterList: [{
        defaultTypeOptions: { publisherType: 'All' },
        filterName: 'publisher',
        value: 'publishers',
      }, {
        filterName: 'orgname',
        value: 'publisherOrganizations',
      }],
    },
    researcher: {
      datasetEnabled: true,
      prefix: 'researcher',
      value: 'iipPersons',
      filterName: 'wppersonId'
    },
    researcherTabRpt: {
      datasetEnabled: true,
      prefix: 'researcher',
      value: 'iipPersons',
      filterName: 'wppersonId',
      dependsOn : ['personIdType']
    },
    fundingRpt: {
      datasetEnabled: true,
      prefix: 'funder',
      value: 'funders',
      filterName: 'funder',
      filterList: [{
        filterName: 'funder',
        value: 'funders',
      },
      {
        filterName: 'orgname',
        value: 'funderOrganizations',
      }
    ]
    },
    almaTabRpt: {
      datasetEnabled: false,
      prefix: 'alma',
      filterName: 'publisher',
      value: 'almaPublishers',
      defaultTypeOptions: { publisherType: 'All' },
      filterList: [{
        filterName: 'jrnSourceType',
        value: 'almaSourceType',
      }, {
        defaultTypeOptions: { publisherType: 'All' },
        filterName: 'publisher',
        value: 'almaPublishers',
      }, {
        filterName: 'sbjname',
        value: 'almaWosCategories',
        defaultTypeOptions : { schema : 'Web of Science' }
      }],
    }
  };
  // tslint:disable-next-line:no-any
  private linkFilters: { [index: string]: any } = {};
  // tslint:disable-next-line:no-any
  private reportFilterList: { [index: string]: any } = {};
  // tslint:disable-next-line:no-any
  private filterParams: { [x: string]: any; };
  private periodFilter: DateRangeConfig;
  // tslint:disable-next-line:no-any
  transformedObject: { settingName: string; settingValues: any; }[] | undefined;
  env = this.environmentService.getEnvironment();

  get settingsPrefix(): string {
    return this.reportConstants[this.tabReport.type!].prefix;
  }

  get settingsValue(): string {
    return this.reportConstants[this.tabReport.type!].value;
  }

  get datasetEnabled(): boolean {
    return this.reportConstants[this.tabReport.type!].datasetEnabled;
  }

  get defaultTypeOptions(): { [index: string]: string } | undefined {
    return this.reportConstants[this.tabReport.type!].defaultTypeOptions!;
  }

  get filterName(): string {
    return this.reportConstants[this.tabReport.type!].filterName;
  }

  // tslint:disable-next-line:no-any
  get filterList(): { filterName: string; selectValues?: any[]; settingsValue?: string, value: string }[] {
    return this.reportConstants[this.tabReport.type!].filterList!;
  }

  get currentHash(): string {
    return window.location.hash;
  }

  constructor(
    private environmentService: EnvironmentService,
    private reportsRepository: ReportsRepositoryService,
    private settingsRepository: SettingsRepositoryService,
    private translate: TranslateService,
    private userService: UserService,
    private analysisFiltersService: AnalysisFiltersService,
    private globalData: Globals,
    private icTogglesNgService: IcTogglesNgService
  ) {
    this.reportFilterList = this.environmentService.getEnvironment().report.filterList;
  }

  getPeriodFilter() {
    return this.periodFilter;
  }
  // tslint:disable-next-line:no-any
  updateFilterValues(filters: any) {
    const filterMapping = {
      wppersonId : 'iipPersons',
      wpdepts : 'iipOrganizations'
    };
    // tslint:disable-next-line:no-any
    let toSave: { [index: string]: any } = {};

    ['esci', 'period', 'dataset', 'wppersonId', 'wpdepts'].forEach((reportFilter) => {
      if (!isUndefined(filters[reportFilter])) {
        if (reportFilter === 'esci') {
          toSave['esciFlag'] =
            filters[reportFilter].toLowerCase() === 'true' ?
              'TRUE' :
              'FALSE';
        } else if (reportFilter === 'period') {
          const period = filters[reportFilter].split(',');
          const start = period[0];
          const end = period.length > 1 ? period[1] : start;

          toSave[`${this.settingsPrefix}StartYear`] = start;
          toSave[`${this.settingsPrefix}EndYear`] = end;
        } else if (reportFilter === 'dataset') {
          toSave['researchReptDataset'] = filters[reportFilter];
        } else {
          // @ts-ignore
          toSave[filterMapping[reportFilter]] = [filters[reportFilter]];
        }
      }
    });
    if (Object.keys(toSave).length > 0) {
      this.saveFilterValues(toSave);
    }
  }

  // tslint:disable-next-line:no-any
  updateLinkFilters(filters: any) {
    for (let reportFilter in this.reportFilterList) {
      if (this.reportFilterList.hasOwnProperty(reportFilter)) {
        if (filters[reportFilter]) {
          this.linkFilters[reportFilter] = this.reportFilterList[reportFilter].single ?
            filters[reportFilter] :
            Array.isArray(filters[reportFilter]) ?
              [...filters[reportFilter]] :
              [filters[reportFilter]];
        }
      }
    }
  }

  isSingleValue(settingsName: string) {
    return ['userEsciFlag', 'userDeptStartYear', 'userDeptEndYear', 'userIipWid', 'iipWidName', 'userResearchReptDataset',
      'userOrganization', 'userOrgStartYear', 'userOrgEndYear', 'userPublisherStartYear', 'userPublisherEndYear', 'userOrcidRidType',
      'userResearcherStartYear', 'userResearcherEndYear', 'userAlmaStartYear', 'userAlmaEndYear', 'userAlmaSourceType',
      'userAlmaOrganization', 'userAlmaTakeCount'].includes(settingsName);
  }

  async setToggleForSettingsEndPoint() {
    if (!this.globalData.toggleData) {
      let toggles = await new Promise((resolve, reject) => {
        this.icTogglesNgService.toggles().subscribe((toggle: TogglesState) => {
          resolve(toggle);
        }, reject);
      }) as TogglesState;
      this.globalData.toggleData = toggles;
    }
  }

  getReportSettings(tabReport: TabReportModel) {
    return this.settingsRepository.getReportSettings(tabReport.type as string).pipe(map((settings) => {
      let newUserSettings = settings.reduce((acc, setting) => {
          acc[setting.settingName] = this.isSingleValue(setting.settingName) ?
            (setting.settingValues.length > 0 ? setting.settingValues[0] : '') : setting.settingValues;
          return acc;
        },
        {} as UserModel);
      this.userService.updateUserSettings(newUserSettings);
      return tabReport;
    }));
  }

  // tslint:disable-next-line: no-any
  async init(tabReportId: string, filters: any): Promise<TabReportModel> {
    this.linkFilters = {};
    this.filterParams = filters;
    await this.setToggleForSettingsEndPoint();

    let urlHash = this.currentHash;
    if (urlHash.includes('wpdepts')) {
      this.updateFilterValues(filters);
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    this.tabReport = await this.reportsRepository.getTabReportsList().pipe(
      map((tabReports) => this.getTabReportById(tabReports, tabReportId)),
      mergeMap((tabReport) => {
        return this.getReportSettings(tabReport);
      }),
    ).toPromise();

    let reportConstant = this.reportConstants[this.tabReport.type as keyof {[index in ReportTypes]: ReportConstantsModel}];
    if (Object.keys(this.filterParams).length > 0 && reportConstant.defaultTypeOptions) {
      extend(this.filterParams, reportConstant.defaultTypeOptions);
    }
    let filterName = Object.keys(this.reportFilterList).find(key => !isNullOrUndefined(filters[key]));

    if (filterName) {
      // @ts-ignore
      reportConstant.filterName = filterName;
      reportConstant.value = this.reportFilterList[filterName as string].value as string;
    }

    if (this.filterParams[reportConstant.filterName] && reportConstant.dependsOn && !this.filterParams[reportConstant.dependsOn[0]]) {
      this.filterParams[reportConstant.dependsOn[0]] = reportConstant.filterName;
    }
    this.updateLinkFilters(filters);
    this.updateFilterValues(filters);
    return this.onUpdate(await this.loadFilters().toPromise());
  }

  async onUpdate(filters: TabReportFiltersModel): Promise<TabReportModel> {
    this.filters = filters || await this.loadFilters().toPromise();

    const availableSelectTypes = TabReportService.getAvailableFilterTypeOptions(this.filters);

    this.availableReports = this.filterTabs(availableSelectTypes);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return this.serialize();
  }
  // tslint:disable-next-line:no-any
  transformObject(originalObject: { [x: string]: any; }) {
    const keys = Object.keys(originalObject);

    if (keys.length === 1) {
      const settingName = keys[0];
      const settingValues = Array.isArray(originalObject[settingName]) ? originalObject[settingName] : [originalObject[settingName]];
      return [{
        settingName,
        settingValues
      }];
    } else {
        const transformedArray = keys.map(key => {
          return {
            settingName: key,
            settingValues: Array.isArray(originalObject[key]) ? originalObject[key] : [originalObject[key]]
          };
        });

        return transformedArray;
    }
  }
  // tslint:disable-next-line:no-any
  saveFilterValues(data: { [key: string]: string | string[] }): Promise<{ [key: string]: any }> {
    let flattened: { [key: string]: string | string[] } = {};
    let setting: string;

    for (setting in data) {
      if (data.hasOwnProperty(setting)) {
          const mappedSetting = this.settingsMapping[setting];
          if (mappedSetting) {
            if (typeof mappedSetting === 'string' && typeof data[setting] === 'object') {
              if (data[setting].length > 0) {
                flattened[mappedSetting] = data[setting];
              } else {
                flattened[mappedSetting] = (data[setting][0]) ? data[setting][0] : [''];
              }
            } else {
              flattened[mappedSetting] = data[setting];
            }
          } else {
            let hasReverseSettingsMapping = Object.keys(this.settingsMapping).find(key => this.settingsMapping[key] === setting);
            if (hasReverseSettingsMapping) {
              flattened[setting] = data[setting];
            }
          }
      }
    }
      this.transformedObject = this.transformObject(flattened);
      let newUserSettings = this.transformedObject.reduce((acc, settingValue) => {
          acc[settingValue.settingName] = this.isSingleValue(settingValue.settingName) ?
            (settingValue.settingValues.length > 0 ? settingValue.settingValues[0] : '') : settingValue.settingValues;
          return acc;
        },
        {} as UserModel);
      this.userService.updateUserSettings(newUserSettings);
      return this.settingsRepository.save(this.transformedObject as unknown as SettingsModel);
  }

  // tslint:disable-next-line:no-any
  filtersToSave(config: any, filters: { [index: string]: any }) {
    const { matchedFilter, selectValues } = config;
    // tslint:disable-next-line:no-any
    let toSave: { [index: string]: any } = {};
    // tslint:disable-next-line:no-any
    let toSearch: string[] = isUndefined(matchedFilter) ? Object.keys(filters) : [matchedFilter as string];
    toSearch.forEach((filter) => {
      if (filters.hasOwnProperty(filter)) {
        let keyToSave = isUndefined(this.reportFilterList[filter].value) ?
          filter :
          this.reportFilterList[filter].value;
        let { dependsOn } = config.selectConfig;
        if (dependsOn) {
          if (this.filterParams[dependsOn]) {
            toSave[keyToSave] = selectValues;
          } else {
            toSave[keyToSave] = [this.reportFilterList[filter].default];
          }
          if (config.selectConfig && config.selectConfig.typeOptions && config.selectConfig.typeOptions.length > 1) {
            toSave[config.selectConfig.typeOptions[0].settingsType] = this.filterParams[dependsOn];
          }
        } else {
          toSave[keyToSave] = isUndefined(filters[filter]) ?
            selectValues :
            this.reportFilterList[filter].single ?
              Array.isArray(filters[filter]) ? filters[filter][0] : filters[filter] :
              filters[filter];
        }
      }
    });
    return toSave;
  }

  getSelectedTypeValue(type: FilterTypeOptionModel): Observable<MultiSelectOptionModel[]> {
    const noValues: Observable<MultiSelectOptionModel[]> = of([]);
    const curUsr = this.userService.currentUser;
    const valuesInaccessible = !curUsr || (type.value !== (type.settingsType && curUsr[type.settingsType]));

    return valuesInaccessible ? noValues : this.formatSelectFilterValues(curUsr![this.settingsMapping[type.settingsValues]]);
  }

  serialize(): TabReportModel {
    const {id, description, title, type, subtitle} = this.tabReport;
    const filters = clone(this.filters);
    const reports = clone(this.availableReports);
    const tabs$ = clone(this.availableReports.map((tab) => {
      return this.loadTab(tab).pipe(
        catchError(_err => {
          const tabValue = clone(tab);

          tab.loadingErrorMessage = 'notifications.errors.common';
          return of(tabValue);
        }),
      );
    }));

    return {id, description, title, type, filters, reports, tabs$, subtitle} as TabReportModel;
  }

  private filterTabs(availableTypes: FilterTypeOptionModel[]|undefined) {
    const user: UserModel = this.userService.currentUser as UserModel;
    const selectedDataset = this.datasetEnabled ? ( user.userResearchReptDataset) : null;
    const selectedType = availableTypes ? availableTypes.find(item => item.default) || availableTypes[0] : undefined;
    const IS_PREPRINT_DATASET_DISABLED = this.env.splitioTreatment.preprintDatasetinReports;
    this.IS_PREPRINT_DATASET_DISABLED = this.globalData?.toggleData[IS_PREPRINT_DATASET_DISABLED] === 'on';

    const filteredReports = this.filterReportsByReportType(this.tabReport.reports, selectedDataset, this.IS_PREPRINT_DATASET_DISABLED);

    return filteredReports.filter((tab: BucketModel) => {
      const hideCondition = selectedType && tab.hideOn && tab.hideOn.hideOnSelectTypes;
      return hideCondition ? (
        isUndefined(selectedType!.value) || !hideCondition.includes(selectedType!.value)
      ) : true;
    });
  }

  private filterReportsByReportType(reports: BucketModel[], selectedDataset: string | null, IS_PREPRINT_DATASET_DISABLED: boolean) {
    if (this.tabReport.type === ReportTypes.publisherRpt) {
      if (selectedDataset === '1' && !IS_PREPRINT_DATASET_DISABLED) {
        return reports.filter((item) => item.datasetId === selectedDataset);
      } else if (selectedDataset === '0' || IS_PREPRINT_DATASET_DISABLED) {
        return reports.filter((item) => item.datasetId === '0');
      }
    }
    return reports;
  }

  private loadTab(report: ReportModel): Observable<ReportModel> {
    return this.reportsRepository.rxGet(report.id).pipe(
      map((tab) => this.reformatReport(tab)),
      share(),
      catchError(err => {
        return throwError(err);
      }),
    );
  }

  private reformatReport(report: ReportModel): ReportModel {
    forEach(report.tiles, (tile, i, arr) => {
      if (tile && (tile.tileGroup === 'double_domino') && tile.connectTo) {
        const mainTile: ReportTileModel = <ReportTileModel>find(report.tiles, { 'id': tile.connectTo });

        mainTile.url = [<string>mainTile.url, <string>tile.url];
        mainTile.title = [<string>mainTile.title, <string>tile.title];
        arr.splice(i, 1);
      }
    });
    return report;
  }

  private getTabReportById(tabReports: TabReportModel[], id: string): TabReportModel {
    const target = tabReports.find(tab => tab.id === id);

    if (target) {
      return target;
    } else {
      throw new Error(`TabReport ${id} not found`);
    }
  }

  private loadFilters(): Observable<TabReportFiltersModel> {
    const userSettings: UserModel = this.userService.currentUser as UserModel;
    const { defaultTypeOptions, selectedDataset, selectName, selectValues, dateRangeValues, esciFilter, selectFilterList } = this.getFilterConstants(userSettings);

    if (this.tabReport.type === ReportTypes.almaTabRpt) {
      this.settingsRepository.getOrgName().subscribe(
        (orgName: string) => {
          userSettings['userAlmaOrganization'] = orgName;
        });
    }

    return this.reportsRepository.getTabReportFilters(this.tabReport.id).pipe(
      mergeMap((data: ReportFiltersResponceData) => {
        const selectConfig = data.filters.find((item: { name: string }) => item.name === selectName);
        // tslint:disable-next-line:no-any
        let selectConfigs: any[] = [];

        selectFilterList.forEach((filterListItem) => {
          selectConfigs.push({
            ...data.filters.find((item: { name: string }) => item.name === filterListItem.filterName),
            ...filterListItem
          });
        });

        const { dataset } = data;
        const period = this.tabReport.type === ReportTypes.almaTabRpt ?
          data.filters.find(filter => filter.name === 'almaperiod') : data.period;
        this.periodFilter = period;
        let isExternalLink: boolean = false;
        let matchedFilter: string | undefined = undefined;
        if (Object.keys(this.linkFilters).length > 0) {
          for (let reportFilter in this.linkFilters) {
            if (selectConfig.name === reportFilter) {
              matchedFilter = reportFilter;
              // @TODO: Support multiple lookup values
              let lookup = Array.isArray(this.linkFilters[reportFilter]) ? this.linkFilters[reportFilter][0] : this.linkFilters[reportFilter];
              // const typeOptions;
              let { dependsOn } = selectConfig;
              let typeOptions;
              if (dependsOn && dependsOn.length > 0) {
                if (this.filterParams[dependsOn]) {
                  // @ts-ignore
                  typeOptions = Object.keys(this.filterParams).reduce((acc, key) => {
                    if (dependsOn.includes(key)) {
                      // @ts-ignore
                      acc[key] = this.filterParams[key];
                    }
                    return acc;
                  }, {});
                } else if (selectConfig.typeOptions && selectConfig.typeOptions.length > 0 && userSettings[
                  this.settingsMapping[selectConfig.typeOptions[0].settingsType]]) {
                  typeOptions = {[dependsOn] : userSettings[this.settingsMapping[selectConfig.typeOptions[0].settingsType]] };
                }
              }

              let url = '';
              if (dependsOn && this.filterParams.id) {
                url = `/incites-app/explore/${this.filterParams.id}/authorRecordNameById`;
                lookup = '';
                typeOptions = '';
              }
              return this.reportsRepository.getFilterOptions(url ? url : selectConfig.source, lookup, typeOptions ? typeOptions : this.defaultTypeOptions)
                .pipe(map((sourceList: String[]) => {
                  let matchingValue = sourceList.find(item => item.includes(lookup));
                  isExternalLink = sourceList.includes(lookup) || !isNullOrUndefined(matchingValue);
                  if (isExternalLink) {
                    if (!dependsOn) {
                      userSettings[this.settingsMapping[this.reportFilterList[reportFilter].value]] = this.reportFilterList[reportFilter].single ?
                        Array.isArray(this.linkFilters[reportFilter]) ?
                          this.linkFilters[reportFilter][0] :
                          this.linkFilters[reportFilter] :
                        [this.linkFilters[reportFilter]];
                      selectValues[0] = lookup;
                    } else {
                      userSettings[this.settingsMapping[this.reportFilterList[reportFilter].value]] = [ matchingValue ];
                      selectValues.length = 0;
                      selectValues[0] = matchingValue;
                    }

                    selectConfigs.forEach((fig) => {
                      if (fig.choice === 'single' && fig.name === reportFilter) {
                        fig.value = lookup;
                      }
                    });
                  }
                  return {period, dataset, selectConfigs, selectValues, selectConfig, isExternalLink, matchedFilter};
                }));
            }
          }
        }
        return of({period, dataset, selectConfigs, selectValues, selectConfig , isExternalLink, matchedFilter});
      }),
      tap((config) => {
        // @ts-ignore
        if (config.isExternalLink && config.selectValues) {
          let filterToSave = this.filtersToSave(config, this.linkFilters);
          filterToSave = Object.fromEntries(Object.entries(filterToSave).map(([key, value]) => [this.settingsMapping[key] || key, value]));
          this.transformedObject = this.transformObject(filterToSave);
          this.settingsRepository.save(this.transformedObject as unknown as SettingsModel);
          Object.keys(filterToSave).forEach(key => {
            userSettings[this.settingsMapping[key] || key] = filterToSave[key];
          });
        }
      }),
      mergeMap((config) => {
        return this.getFilterSource(config);
      }),
      mergeMap((config) => {
        const { selectConfigs, selectConfig } = config;
        const period = this.getPeriodFilterConfig(config.period, dateRangeValues);
        const dataset = this.getDatasetFilterConfig(config.dataset, selectedDataset);
        const esci = esciFilter;
        return this.getSelectFilterOptions(selectConfig, config.selectValues, selectConfigs).pipe(
          map(select => {
            return ({ defaultTypeOptions, period, dataset, esci, select } );
          }));
      }),
      mergeMap((config) => {
        if (config.select.typeOptions) {
          const avalableTypeOptions: FilterTypeOptionModel[] = TabReportService.getAvailableFilterTypeOptions(config)!;
          if (avalableTypeOptions && avalableTypeOptions.length === 1) {
            config.select.typeOptions = TabReportService.setDefaultSelectType(avalableTypeOptions);
            config.select.placeholder = avalableTypeOptions[0].placeholder;
          } else if (avalableTypeOptions && avalableTypeOptions.length > 1 && avalableTypeOptions[0].settingsType) {
            const defaultTypeString = userSettings[this.settingsMapping[avalableTypeOptions[0].settingsType]];

            config.select.typeOptions = TabReportService.setDefaultSelectType(avalableTypeOptions, defaultTypeString);
            // @ts-ignore
            config.select.placeholder = find(avalableTypeOptions, {value: defaultTypeString})!.placeholder as string | undefined;
          }

          return this.formatSelectFilterValues(
            userSettings[ this.settingsMapping[avalableTypeOptions[0].settingsValues]]
          ).pipe(
            map((values) => {
              config.select.values = values;

              return config;
            })
          );
        } else if (config.select.configs) {
          let values$$: Observable<MultiSelectOptionModel[]>[] = config.select.configs.map((configgy) => {
            return this.formatSelectFilterValues(
              configgy.selectValues || []
            );
          });

          forkJoin([...values$$])
            .subscribe((configgies) => {
              configgies.forEach((values: MultiSelectOptionModel[] | undefined, i: number) => {
                config.select.configs[i].values = values;
              });
            });
        }

        let organizationKey =  this.settingsMapping['almaOrganization'];
        if (userSettings[organizationKey]) { // @ts-ignore
          config['almaOrganization'] = userSettings[organizationKey]; // @ts-ignore
          config[organizationKey] = userSettings[organizationKey];
        }

        return of(config);
      })
    );
  }

  get settingsMapping(): {[key: string]: string} {
    return {
      'organization': 'userOrganization',
      'orgStartYear': 'userOrgStartYear',
      'orgEndYear': 'userOrgEndYear',
      'iipPersons': 'userIIPPersons',
      'iipOrganizations': 'userIIPOrganizations',
      'orcidRidType': 'userOrcidRidType',
      'orcidRidValues': 'userOrcidRidValues',
      'iipWid': 'userIipWid',
      'iipWidName': 'iipWidName',
      'researcherStartYear': 'userResearcherStartYear',
      'researcherEndYear': 'userResearcherEndYear',
      'researchReptDataset': 'userResearchReptDataset',
      'deptStartYear': 'userDeptStartYear',
      'deptEndYear': 'userDeptEndYear',
      'esciFlag': 'userEsciFlag',
      'publishers': 'userPublishers',
      'publisherOrganizations': 'userPublisherOrganizations',
      'publisherStartYear': 'userPublisherStartYear',
      'publisherEndYear': 'userPublisherEndYear',
      'funders': 'userFunders',
      'funderStartYear': 'userFunderStartYear',
      'funderEndYear': 'userFunderEndYear',
      'almaStartYear': 'userAlmaStartYear',
      'almaEndYear': 'userAlmaEndYear',
      'almaPublishers': 'userAlmaPublishers',
      'almaWosCategories': 'userAlmaWosCategories',
      'almaSourceType': 'userAlmaSourceType',
      'almaOrganization': 'userAlmaOrganization',
      'almaTakeCount': 'userAlmaTakeCount',
      'funderOrganizations': 'userFunderOrganizations',
    };
  }

  // tslint:disable-next-line:no-any
  getFilterSource(config: any) {
    if (this.tabReport.type === ReportTypes.almaTabRpt) {
      return this.analysisFiltersService.requestFilterOptions(config.period.source as string, {}).pipe(map((response) => {
        let source = response.map(s => Number(s.value));
        if (source && isArray(source) && source.length > 0) {
          config.period.source = source.map(s => Number(s)) as number[];
        } else {
          config.period.source = [1900, 2023];
        }
        return config;
      }));
    } else {
      return of(config);
    }
  }

  esciToBoolean(esciStr: string) {
    return !isUndefined(esciStr) && esciStr.toLowerCase() === 'true';
  }

  private getFilterConstants(user: UserModel) {
    const selectedDataset = this.datasetEnabled ? (user.userResearchReptDataset) : null;
    const dateRangeValues: DateRangeModel = {
      start: user[this.settingsMapping[`${this.settingsPrefix}StartYear`]],
      end: user[this.settingsMapping[`${this.settingsPrefix}EndYear`]]
    };
    const defaultTypeOptions = this.defaultTypeOptions;
    const esciFilter = selectedDataset === '1' ? false : this.esciToBoolean(user[this.settingsMapping['esciFlag']]);
    const selectName = this.filterName;
    const value = user[this.settingsMapping[this.settingsValue]];
    let selectValues;
    if (value) {
      selectValues = Array.isArray(value) ? value : [value];
    } else {
      selectValues = [];
    }
    let selectFilterList = this.filterList || (
      defaultTypeOptions ? [{
        defaultTypeOptions: defaultTypeOptions,
        filterName: selectName,
        value: value || [],
      }] : [{
        filterName: selectName,
        value: value || [],
      }]
    );
    if (this.filterList) {
      selectFilterList.forEach((filter) => {
        let settingsValue = filter.value;
        let filterValue = user[this.settingsMapping[settingsValue]];
        filter.settingsValue = settingsValue;
        filter.selectValues = Array.isArray(filterValue) ? filterValue : (filterValue ? [filterValue] : []);
      });
    }

    return {
      defaultTypeOptions,
      selectedDataset,
      selectName,
      selectValues,
      dateRangeValues,
      esciFilter,
      selectFilterList,
    };
  }

  static getAvailableFilterTypeOptions(filters: TabReportFiltersModel): FilterTypeOptionModel[]|undefined  {
    if (!filters.select.typeOptions) {
      return undefined;
    }

    const defaulDatasetItem = filters.dataset.source.find(item => !!item.selected);
    const datasetValue = defaulDatasetItem && defaulDatasetItem.value || filters.dataset.source[0].value;

    return filters.select.typeOptions.filter((option) => option.hideOnDataset !== datasetValue);
  }

  static getSelectedTypeOption(filters: TabReportFiltersModel): FilterTypeOptionModel|undefined {
    const availableTypes = TabReportService.getAvailableFilterTypeOptions(filters);

    return  availableTypes ? availableTypes.find(item => item.default) || availableTypes[0] : undefined;
  }

  static setDefaultSelectType(selectTypeOptions: FilterTypeOptionModel[], selectedTypeString?: string): FilterTypeOptionModel[] {
    selectedTypeString = selectedTypeString || selectTypeOptions[0].value;

    return selectTypeOptions.map((type) => {
      if (selectedTypeString === type.value) {
        type.default = true;
      } else if (type.default) {
        delete type.default;
      }
      return type;
    });
  }

  private getPeriodFilterConfig(period: {source: Array<number>}, dateRangeValues: DateRangeModel) {
    return {
      options: this.getRangeOptions(period.source),
      label: this.tabReport.type === 'almaTabRpt' ? 'Collection data range' : this.translate.instant('report.Date range'),
      values: dateRangeValues,
      settingsValues : [this.settingsMapping[`${this.settingsPrefix}StartYear`],
        this.settingsMapping[`${this.settingsPrefix}EndYear`]]
    };
  }

  private getRangeOptions(source: Array<number>): Array<{label: string, value: string}> {
    const options: Array<{label: string, value: string}> = [];

    for (let counter = source[0]; counter <= source[1]; counter++) {
      options.push({label: counter.toString(), value: counter.toString()});
    }

    return options;
  }

  private getDatasetFilterConfig(dataset: DatasetFilterModel, selectedDataset: null|string) {
    let source = dataset.source.map((item: DatasetFilterItemModel) => {
      return item.value === selectedDataset ? extend(item, {selected: true}) : item;
    });

    return {title: dataset.title, source};
  }

  private getSelectFilterOptions(filterConfig: any, filterValues: string[], filterConfigs: any[]): Observable<SelectFilterModel> { // tslint:disable-line: no-any
    const config = this.getSelectFilterConfig(filterConfig);
    const source = filterConfig.source;
    const singleValue = filterConfig.choice === 'single';
    const typeOptions: FilterTypeOptionModel[]|undefined = filterConfig.typeOptions;
    let placeholder = filterConfig.placeholder;
    let configs = clone(filterConfigs);
    let selectValuesForReportTypes = [
      'almaTabRpt',
       'fundingRpt',
      'publisherRpt',
    ];

    // tslint:disable-next-line:no-any
    configs = configs.map((configgy: any) => {
      let selected = this.getSelectFilterConfig(configgy);
      let configPlaceholder = configgy.placeholder;

      if (isObject(configPlaceholder) && this.defaultTypeOptions && isObject(this.defaultTypeOptions)) {
        // tslint:disable-next-line:forin
        for (let typeOption in this.defaultTypeOptions) {
          configPlaceholder = configgy.placeholder[this.defaultTypeOptions[typeOption]];
          break;
        }
      }

      return {
        ...selected,
        placeholder: configPlaceholder,
        selectValues: (configgy.choice === 'single' && configgy.type !== 'list') ? [configgy.value] :
        (selectValuesForReportTypes.includes(this.tabReport.type as ReportTypes) ? configgy.selectValues : configgy.value),
        settingsValue: configgy.settingsValue,
        singleValue: configgy.choice === 'single',
        source: configgy.source,
        options : configgy.type === 'list' ?  this.reportsRepository.getFilterOptions(configgy.source, '', this.defaultTypeOptions)
          .pipe(
            map(data => data.map(val => ({ label: val, value: val })))
          ) : of({}),
        visibleOnTabs: configgy.visibleOnTabs,
        layout : this.tabReport.type === ReportTypes.almaTabRpt ? 'filter' : 'default',
        filterType : configgy.type
      };
    });
    if (isObject(placeholder) && this.defaultTypeOptions && isObject(this.defaultTypeOptions)) {
      // tslint:disable-next-line:forin
      for (let typeOption in this.defaultTypeOptions) {
        placeholder = filterConfig.placeholder[this.defaultTypeOptions[typeOption]];
        break;
      }
    }
    return this.formatSelectFilterValues(filterValues).pipe(
      map(values => ({config, source, singleValue, typeOptions, values, placeholder, configs }))
    );
  }

  private getSelectFilterConfig(filterConfig: any): Partial<MultiSelectConfigModel> { // tslint:disable-line: no-any
    return {
      detailsHeading: this.translate.instant('report.Organization details'),
      inputId: 'ic-tab-report-filters__search-input',
      inputLabel: filterConfig.title,
      loadingMessage: this.translate.instant('report.Updating data'),
      noResultsMessage: this.translate.instant('dashboard.No results available'),
      typeSelectLabel: this.translate.instant('report.Search by'),
      hideSelectedValues : this.tabReport.type === 'almaTabRpt',
    };
  }

  private formatSelectFilterValues(values: string[]): Observable<MultiSelectOptionModel[]> {
    const isDefined = (obj: string) => obj != null;
    const formatDetails = (info: OrgInfo) => {
      if (info && info.organisation_name) {
        const url = info.web_address;
        const heading = info.organisation_name;
        let body: string = '';

        if (isDefined(info.postal_address)) {
          body += `${info.postal_address}<br />`;
        }

        if (isDefined(info.phone_number)) {
          body += `${info.phone_number}<br />`;
        }

        return {heading, url, body};
      }

      return undefined;
    };
    let detailsInfo: Observable<Object[]> = of([]);

    if (this.tabReport.type === ReportTypes.organization) {
      detailsInfo = this.reportsRepository.getOrganizationsInfo(values);
    }

    return detailsInfo.pipe(
      map((info) => {
        return values ? values.map((value, i) => ({value, label: value, details: formatDetails(info[i] as OrgInfo)})) : [];
      })
    );
  }
  getTabReport() {
    return this.tabReport;
  }

}
