<div class="ic-sidebar-indicator">

  <div class="ic-analysis-sidebar__details__back">
  <button class="cl-btn cl-btn--text cl-btn--with-icon" type="button" (click)="onBackClick()">
    <cl-icon [iconId]="'chevron-left'"></cl-icon>
    <span class="cl-btn__text">{{ 'analysis.sidebar.indicators.Back to all indicators' | translate }}</span>
  </button>
  </div>

  <div class="ic-analysis-sidebar__header">

    <h3 class="h4 ic-analysis-sidebar__header__title">
      <span class="ic-analysis-sidebar__label">{{ 'analysis.sidebar.indicators.Add indicator' | translate }}</span>
      {{ indicator.title }}
    </h3>

    <p *ngIf="indicator.description"
      [fullText]="indicator.description"
      [clTextTruncation]="{
        hideButtonLabel: '…Hide full description',
        maxLines: 3,
        showButtonLabel: '…Show full description'
      }"
    ></p>
    
    <ng-container *ngTemplateOutlet="toggleButton; context: { indicator: indicator }"></ng-container>
    <ng-container *ngTemplateOutlet="indicatorHelpLink; context: { indicator: indicator }"></ng-container>

    <dl class="ic-sidebar-indicator__normalized" *ngIf="hasNormalizedBy">
      <dt>{{ 'analysis.sidebar.indicators.Normalized by' | translate }}</dt>
      <dd>
        <ul class="ic-sidebar-indicator__normalized__list">
          <li *ngFor="let item of indicator.normalizedBy" class="ic-sidebar-indicator__normalized__list__item">
            {{ item }}
          </li>
        </ul>
      </dd>
    </dl>

  </div>

</div>

<ng-template #toggleButton let-indicator="indicator">
  <button
    type="button"
    class="cl-btn cl-btn--with-icon ic-sidebar-indicator__child__toggle-btn"
    (click)="onToggleClick(indicator)"
    [disabled]="indicator.required || indicator.disabled"
    [ngClass]="{
      'cl-btn--primary-outline': !indicator.enabled,
      'cl-btn--primary': indicator.enabled
    }"
  >
    <ng-container *ngIf="indicator.enabled">
      <cl-icon [iconId]="'close-small'" [color]="'color-white'" [size]="8"></cl-icon>
      <span class="cl-btn__text">{{ 'analysis.sidebar.indicators.Remove' | translate }}</span>
    </ng-container>
    <ng-container *ngIf="!indicator.enabled">
      <cl-icon [iconId]="'add'" [size]="8" [color]="indicator.disabled ? 'color-white' : 'color-teal'"></cl-icon>
      <span class="cl-btn__text">{{ 'analysis.sidebar.indicators.Add' | translate }}</span>
    </ng-container>
  </button>
</ng-template>

<ng-template #indicatorHelpLink let-indicator="indicator">
  <a
    *ngIf="indicator.helpUrl"
    [href]="indicator.helpUrl"
    class="cl-btn cl-btn--link cl-btn--with-icon ic-sidebar-indicator__help-link"
  >
    <span class="cl-btn__text">{{ 'analysis.sidebar.indicators.Learn more' | translate }}</span>
    <cl-icon [iconId]="'link'" [size]="10"></cl-icon>
  </a>
</ng-template>
