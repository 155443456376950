import { Component, Input } from '@angular/core';
import { TreeViewOptionModel } from '@ic/component-lib/src/components/modules/tree-view/models';
import { ChooseTreeModalComponent } from '../choose-tree-modal/choose-tree-modal.component';

@Component({
  selector: 'ic-dlm-search-rows',
  templateUrl: './dlm-search-rows.component.html'
})
export class DlmSearchRowsComponent {
  @Input() option!: TreeViewOptionModel;
  @Input() searchterm!: string;
  parent!: ChooseTreeModalComponent;
  constructor(public chooseTreeModalComponent: ChooseTreeModalComponent) { 
    this.parent = chooseTreeModalComponent;
  }

  selectOption() {
    this.option.selected = !this.option.selected;
    this.parent.selectedDepartments(this.option);
  }

}
