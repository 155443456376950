import { Component, ViewEncapsulation, ViewChild, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { OverlayModalOptionsModel } from '@ic/component-lib/src/components/modules/overlay-modal/models';
import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';
import { MetadataRepositoryService } from 'components/rest/services/metadata-repository/metadata-repository.service';
import { pick, toPairs, omitBy, isUndefined, merge, extend } from 'lodash';
import { RequestParamsModel } from 'pages/analysis/models/analysis-state.model';
import { DocumentsIndicatorMetadataModel } from 'pages/analysis/models';
import {EMPTY, Subscription} from 'rxjs';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { SortableTableRowModel } from '@ic/component-lib/src/components/modules/sortable-table/models';
import { environmentCommonConfig } from 'components/app/services/environment/environment-common.config';
import {catchError, mergeMap} from 'rxjs/operators';

@Component({
  selector: 'ic-wos-view-modal',
  templateUrl: './wos-view-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class WosViewModalComponent implements OnInit, OnDestroy {

  @Input() table!: {sortBy: 'asc'|'desc'|undefined, sortOrder: string};
  @Input() request!: Partial<RequestParamsModel>;
  @Input() documentindicator!: DocumentsIndicatorMetadataModel;
  @Input() row: SortableTableRowModel;

  @ViewChild('wosModal', { static: true }) wosModal!: OverlayModalComponent;

  maxUtCount = 50000;
  wosModalConfig: Partial<OverlayModalOptionsModel> = {
    focusElementOpen: '.cl-overlay-modal__body__close-btn',
    modalWidth: '60%',
    modifiers: 'dark-header wos-modal'
  };
  isLoading: null|string = null;
  queryId: null|string = null;
  citationFromPatents: boolean = false;
  redirectToWosDetails = {
    url: this.envService.getEnvironment().gatewayLink,
    params: {
      GWVersion: 2,
      SrcAuth: 'Incites',
      SrcApp: 'IC2JCR',
      DestApp: 'WOS',
      DestLinkType: 'QuerySummary',
      KeyQueryID: this.queryId
    }
  };

  redirectToWosDerwentDetails = {
    url: this.envService.getEnvironment().derwentGatewayLink,
    params: {
      GWVersion: 2,
      SrcAuth: 'Incites',
      SrcApp: 'IC2UI',
      DestApp: 'DIIDW',
      DestLinkType: 'QuerySummary',
      KeyQueryID: this.queryId
    }
  };

  private utsSubscription!: Subscription;
  private modalOpensubscription!: Subscription;

  constructor(
    private metadataRepositoryService: MetadataRepositoryService,
    private envService: EnvironmentService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.modalOpensubscription = this.metadataRepositoryService.$openWosModalObs
    .subscribe(() => {
      this.showWosModal();
    });
  }

  get redirectToWosDetailsUrl() {
    return toPairs(this.redirectToWosDetails.params).reduce((url, [key, value], i) => {
      const attach = i === 0 ? '?' : '&';
      return `${url}${attach}${key}=${value}`;
    }, this.redirectToWosDetails.url);
  }

  get redirectToWosDerwentDetailsUrl() {
    return toPairs(this.redirectToWosDerwentDetails.params).reduce((url, [key, value], i) => {
      const attach = i === 0 ? '?' : '&';
      return `${url}${attach}${key}=${value}`;
    }, this.redirectToWosDerwentDetails.url);
  }

  showLoading() {
    this.isLoading = 'analytics.explore.wosviewmodal.Please wait to load';
  }

  hideLoading() {
    this.isLoading = null;
  }

  onClose() {
    if (this.utsSubscription && !this.utsSubscription.closed) {
      this.utsSubscription.unsubscribe();
    }
    this.citationFromPatents = false;
  }

  private showWosModal() {
    this.wosModal.openModal();
    this.getUtsAndFsid();
  }

  private getUtsAndFsid() {
    if (this.documentindicator.name === environmentCommonConfig.citationsFromPatents) {
      this.citationFromPatents = true;
    }
    this.showLoading();

    const { value: count, dataByIndicator, name } = this.documentindicator;
    const params =  pick(
      omitBy({...this.table, skip: 0, take: (count <= this.maxUtCount ? count : this.maxUtCount), dataColumn: name}, isUndefined),
      ['skip', 'take', 'sortBy', 'sortOrder', 'dataColumn']
    );
    let request = pick({...this.request, indicators: ['ut', params.sortBy]}, ['indicators', 'filters', 'pinned', 'citedOrCiting', 'isSourceYear'] );
    if (this.citationFromPatents) {
      request = pick({...this.request, indicators: ['DERWENT_PRIMARY_ACCESSION_NUMBER', params.sortBy]}, ['indicators', 'filters', 'pinned', 'citedOrCiting', 'isSourceYear'] );
    }
    if (this.row.compositeEntityKey) {
      extend(request, { compositeEntityKey : this.row.compositeEntityKey} );
    }

    this.utsSubscription = this.metadataRepositoryService.getUts(dataByIndicator, params, request).pipe(mergeMap((utsCollectedList) =>
      (this.citationFromPatents ? this.metadataRepositoryService.postWosDerwentSearch(utsCollectedList.split(',')) :
        this.metadataRepositoryService.postWosSearch(utsCollectedList.split(','))).pipe(
        catchError(() => {
          this.hideLoading();
          this.cd.detectChanges();
          return EMPTY;
        })
      )
    )).subscribe((str) => {
      if (this.citationFromPatents) {
        this.queryId = str.replace(/^"|"$/g, '');
        this.redirectToWosDerwentDetails = merge(this.redirectToWosDerwentDetails, { params: { KeyQueryID: this.queryId } });
      } else {
        this.queryId = str.replace(/^"|"$/g, '');
        this.redirectToWosDetails = merge(this.redirectToWosDetails, { params: { KeyQueryID: this.queryId } });
      }
      this.hideLoading();
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.modalOpensubscription && !this.modalOpensubscription.closed) {
      this.modalOpensubscription.unsubscribe();
    }
  }
}