import * as pubsub from 'pubsub-js';
import { Injectable } from '@angular/core';

import { downgradeInjectable } from '@angular/upgrade/static';
import { authModule } from './../../../../angularjs/auth/auth.module';

import { DatasetsVersionService } from './../datasets-version/datasets-version.service';
import { UserService } from './../user/user.service';
import { UserModel } from 'components/auth/interfaces/user.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

const baseUrl: string = '/incites-app/';

@Injectable()
export class AuthenticationService {
  private datasetsVersion = 'v2';
  private serviceUrl: string = `${baseUrl}authentication`;
  private userDetailsUrl: string = `${baseUrl}userdetails`;

  constructor(
    private datasetsVersionService: DatasetsVersionService,
    private http: HttpClient,
    private userService: UserService,
  ) {}

  authenticate(): Promise<any> { // tslint:disable-line: no-any
    return this.http.get(this.userDetailsUrl)
      .pipe(
        map((response: any) => { // tslint:disable-line: no-any
          if (response && response.userId ) {
            const user: UserModel = <UserModel>response;
            if (user.email) {
              user.name = user.email;
            }
            this.userService.setCurrentUser(user);
            this.datasetsVersionService.setDatasetsVersion(this.datasetsVersion);
            pubsub.publish('userAuthenticated', user);

            return user;
          } else if (response && response.errorCode) {
            return Promise.reject(response.errorCode);
          }
          return Promise.reject(401);
        })
      )
      .toPromise();
  }

  signOut(): Promise<{status: string}> {
    return this.http.get<{status: string}>(`${this.serviceUrl}/delete`)
      .toPromise()
      .then((data) => {
        if (data.status === 'done') {
          pubsub.publish('userSignedOut', {});
        }

        return data;
      });
  }
}

// Register downgraded Angular providers with this AngularJS module.
authModule.factory(
  'authenticationService',
  downgradeInjectable(AuthenticationService)
);
