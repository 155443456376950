export interface CanvasContent {
    type: ContentType;
    text: TextFormat[];
    imageSource?: HTMLImageElement;
}

export interface TextFormat {
    value: string;
    font: TextSize;
    isBold: TextWeight;
    isItalic: boolean;
}

export enum TextSize {
    medium = 22,
    small = 18
}

export enum TextWeight {
    bolder = 900,
    bold = 600,
    semiBold = 400
}

export enum ContentType {
    Text = 'text',
    Image = 'image'
}


