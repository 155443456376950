<cl-overlay-modal #wosModal [options]="wosModalConfig" (onClose)="onClose()">
    <cl-overlay-modal-content [modalTitle]="'analytics.explore.wosviewmodal.Thank Using InCites' | translate">
        <div class="wos-modal__loading" *ngIf="isLoading; else wosViewContent">
            <cl-loading-indicator [label]="isLoading | translate"></cl-loading-indicator>
        </div>
        <ng-template #wosViewContent>
            <p>{{ 'analytics.explore.wosviewmodal.Leaving InCites' | translate }} {{ 'analytics.explore.wosviewmodal.Citation Data In InCites' | translate }} </p>
            <p>{{ 'analytics.explore.wosviewmodal.Web of Science Data Updated' | translate }}</p>
            <p>{{ 'analytics.explore.wosviewmodal.First 50K Docs Exported' | translate }}</p>

            <a *ngIf="!citationFromPatents" href="{{redirectToWosDetailsUrl}}" target="_blank" method="POST">Continue to WOS, {{ 'analytics.explore.wosviewmodal.Click here' | translate }}</a>
            <a *ngIf="citationFromPatents" href="{{redirectToWosDerwentDetailsUrl}}" target="_blank" method="POST">Continue to WOS DERWENT, {{ 'analytics.explore.wosviewmodal.Click here' | translate }}</a>
        </ng-template>
    </cl-overlay-modal-content>
</cl-overlay-modal>