import { Component, HostListener, ViewEncapsulation } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'components/auth/services/authentication/authentication.service';
import { UserService } from './../../../auth/services/user/user.service';
import { UserModel } from './../../../auth/interfaces/user.model';

import {EnvironmentService} from 'components/app/services/environment/environment.service';

@Component({
  selector: 'ic-user-menu',
  templateUrl: './user-menu.component.html',
  encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent {
  IS_CDX_HEADER_ON = false;
  screenWidth: number;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    public translate: TranslateService,
    private environmentService: EnvironmentService,
  ) {
    this.userService.getCurrentUserPromise().then((user: UserModel) => {
      this.setCurrentUser(user);
    });
    this.screenWidth = window.innerWidth;
    this.IS_CDX_HEADER_ON = this.environmentService.getEnvironment().feature.cdxHeaderFooter;
  }

  currentUser!: UserModel;

  setCurrentUser(user: UserModel) {
    this.currentUser = user;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  logout() {
    this.authenticationService.signOut();
  }
}