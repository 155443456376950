import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';
import { ReportModel } from 'components/common/interfaces/report.model';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'ic-legacy-reports',
  templateUrl: './legacy-reports.component.html',
  encapsulation: ViewEncapsulation.None
})

export class LegacyReportsComponent implements OnInit {
  public reportGroups: ReportModel[][] = [];
  public currentGroup: number = 0;

  constructor(
    private reportsRepository: ReportsRepositoryService,
    private stateService: StateService,
  ) {}

  ngOnInit() {
    this.reportsRepository
      .getList({ group: 'system' })
      .then((reports) => {
        if (reports && reports.length > 0) {
          this.reportGroups = this.splitReportsIntoGroupsOfFourItems(reports);
        }
      });
  }

  reportClass(report: ReportModel) {
    // @ts-ignore TODO: remove when report types will be fixed
    if (report.reportType === 'wp' || report.reportType === 'iipFaculty' || report.wp) {
      return 'researcher-report';
    // @ts-ignore TODO: remove when report types will be fixed
    } else if (report.reportType === 'wpdept' || report.reportType === 'iipDept' || report.wpdept) {
      return 'department-report';
    } else if (report.institutionProfile) {
      return 'institution-profile';
    } else if (report.ljur) {
      return 'ljur';
    } else {
      return;
    }   
  }

  openReport(report: ReportModel) {
    this.stateService.go('app.report', { reportId: report.id, slug: report.safeTitle!() });
  }

  openPage(pageNumber: number) {
    const maxPage: number = this.reportGroups.length - 1;
    
    if (pageNumber < 0) {
      pageNumber = maxPage;
    } else if (pageNumber > maxPage) {
      pageNumber = 0;
    }

    this.currentGroup = pageNumber;
  }

  private splitReportsIntoGroupsOfFourItems(reports: ReportModel[]): ReportModel[][] {
    let groups: ReportModel[][] = [];
    
    for (let i = 0; i <= reports.length; i = i + 4) {
      const group: ReportModel[] = [];

      for (let index = i; (index < i + 4) && (index < reports.length); index++) {
        group.push(reports[index]);
      }

      groups.push(group);
    }

    return groups;
  }

}