import { DeferredModel } from '../interfaces/deferred.model';

export class Deferred<T> implements DeferredModel<T> {
  public promise!: Promise<T>;
  public resolve!: (value?: T | PromiseLike<T> | undefined) => void;

  constructor() {
    this.promise = new Promise(resolve => this.resolve = (resolve as (value?: T | PromiseLike<T> | undefined) => void));
  }

  resolved() {
    // @ts-ignore
    return this.promise.$$state && this.promise.$$state.status === 1;
  }

}
