import { Component, ViewEncapsulation, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { PopoverModalComponent } from '@ic/component-lib/src/components/modules/popover-modal/popover-modal.component';

export interface DropdownItemModel {
  name: string;
  title: string;
  execute?: Function;
  icon?: string;
  size?: number;
  iconColor?: string;
}

export interface DropdownOptions {
  arrow?: boolean;
  border?: boolean;
  icon?: string;
  iconColor?: string;
  width?: number;
}

@Component({
  selector: 'ic-dropdown',
  templateUrl: './dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})

export class DropdownComponent implements OnChanges {
  @Input() items!: DropdownItemModel[];
  @Input() options: DropdownOptions = {};
  @Input() title!: string;
  @Input() disabled: boolean = false;
  @Output() onClick: EventEmitter<string> = new EventEmitter();
  @ViewChild(PopoverModalComponent, { static: false }) popover!: PopoverModalComponent;

  public width: string = '150px';
  labelItem: string = '';


  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.width = `${this.options.width || 150}px`;
    }
  }

  select(item: DropdownItemModel) {
    this.labelItem = item.name;
    if (item.execute) {
      item.execute(item.name);
    }

    this.onClick.emit(item.name);
    this.popover.closePopover();
  }

  generateButtonClasses() {
    let classes: string = 'cl-btn';

    if (this.options) {
      if (this.options.border) {
        classes += ' cl-btn--secondary';
        if (this.options.icon) {
          classes += ' cl-btn--with-icon';
        }
      } else {
        classes += ' cl-btn--text';
        if (this.options.icon) {
          classes += ' cl-btn--icon';
        }
      }
    }

    return classes + ' cl-context-menu__btn';
  }

}
