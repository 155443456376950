<cl-overlay-modal [options]="{ 'modifiers': 'dark-header','modalWidth' : isSingleTileUpdate() ? '370px' : '450px' }" class="ic-save-tile" (onOpen)="onModalOpen()" (onClose)="onModalClose()">
  <cl-overlay-modal-content class="save-dialog" [modalTitle]="getTitle() | translate">
    <div *ngIf="action === 'updateTile' && breadcrumbs && breadcrumbs.length > 0">
      <div class="ic-report__breadcrumbs ic-save-tile__breadcrumbs">
        <cl-breadcrumbs [items]="breadcrumbs"></cl-breadcrumbs>
      </div>
    </div>
    <form class="cl-form" [formGroup]="saveForm" (ngSubmit)="onSubmit()">
      <fieldset class="cl-form__fieldset cl-form__fieldset--stacked">
        <legend class="visually-hidden">{{getTitle() | translate}}</legend>
        <div class="p--small" *ngIf="navigations.length && this.action === 'updateTile'"> <strong>{{ 'reports.Saved to' | translate }}: </strong>
          <ul class="navigation-list">
            <li class="cursor-pointer" (click)="goTo(nav)" *ngFor="let nav of navigations;let i = index; let l = length">
              <ng-container *ngIf="!(i === 0 || i === l - 1)">/</ng-container>{{ nav.title }}
            </li>
          </ul>
        </div>
        <div class="cl-form__field">
          <label class="cl-form-label">
            {{ 'analytics.explore.tile.Title' | translate }}
            <input class="cl-form-text title" type="text" formControlName="title"  placeholder="{{ 'analytics.explore.New Tile' | translate }}"/>
          </label>
        </div>

        <div class="cl-form__field ic-save-tile__subtitle">
          <label class="cl-form-label">
            {{ 'analytics.explore.tile.Subtitle' | translate }}
            <input *ngIf="isSingleTileUpdate()" class="cl-form-text subtitle" type="text" formControlName="subTitle"/>
            <textarea *ngIf="!isSingleTileUpdate()" class="cl-form-text subtitle subtitle-textarea" type="text" formControlName="subTitle"></textarea>
          </label>
        </div>

        <div class="cl-form__field cl-form__field--flex ic-save-tile__save-to" *ngIf="this.action !== 'updateTile'">
          <label class="cl-form-label">{{ 'analytics.explore.tile.Save To' | translate }}</label>
          <span>

            <button class="cl-btn cl-btn--text cl-btn--icon" type="button" (click)="openCreateFolderModal()" [disabled]="!selectedFolder">
              <cl-icon [iconId]="'folder-add'" [label]="'create a new folder'" [isDisabled]="!selectedFolder"></cl-icon>
            </button>

            <button class="cl-btn cl-btn--text cl-btn--icon" type="button" (click)="openCreateReportModal()" [disabled]="!selectedFolder || !this.isSingleTileUpdate()">
              <cl-icon [iconId]="'report-add'" [label]="'create a new report'" [isDisabled]="!selectedFolder"></cl-icon>
            </button>

          </span>
        </div>

        <div class="cl-form__field cl-folders-wrapper"
          [ngClass]="{'ng-invalid': foldersInvalid }"
          (mouseleave)="onFoldersMouseleave()"
          *ngIf="this.action !== 'updateTile'"
        >
        <cl-loading-indicator *ngIf="folders && folders.length === 0"></cl-loading-indicator>
          <cl-folders
            *ngIf="folders && folders.length > 0"
            [items]="folders | icTitleSorting"
            [api]="foldersApi"
            (onSelect)="setSelectedItem($event)"
            [attr.title]="'analytics.explore.tile.Select report' | translate"
          ></cl-folders>
        </div>
        <div class="cl-form__actions">
          <button type="submit"
            class="cl-btn cl-btn--primary"
            [disabled]="this.action === 'updateTile' ? !saveForm.valid : ((isSingleTileUpdate() ? !selectedReport : !selectedFolder) || !saveForm.valid)"
          >
           {{getSaveLabel() | translate}}
          </button>
        </div>
      </fieldset>
    </form>
  </cl-overlay-modal-content>
</cl-overlay-modal>

<ic-create-folder [parentFolder]="selectedFolder" (onCreate)="onFolderCreate($event)"></ic-create-folder>
<ic-create-report [destinationFolder]="selectedFolder" (onCreate)="onReportCreate($event)"></ic-create-report>
