<div class="cl-form__field ic-date-range-filter">

  <label class="cl-form-label" for="publication-date" *ngIf="!reportsFilter">
    {{ 'analysis.sidebar.filters.Publication Date' | translate }}
  </label>

  <span *ngIf="!showCustomRange" class="cl-form-select">

    <nav aria-label="Main Navigation" class="cl-entity-menu ic-menu-dropdown">
      <ul class="cl-navigation__list--primary">
        <li class="cl-navigation__link--primary has-submenu">
          <a href="javascript:void(0)" (click)="toggleMenu();" class="selected-entity">{{selectedPeriod}}</a>
          <ul
            class="cl-navigation__list--secondary"
            [ngClass]="{ 'active': menuOpened }"
            [attr.aria-hidden]="!menuOpened"
          >
            <li class="cl-navigation__item--secondary" *ngFor="let period of periods">
              <label [attr.for]="period.value" (click)="hideMenu()" [ngClass]="period.label === selectedPeriod ? 'active' : 'none'">
                {{ period.label }}
                <input type="radio" id="publication-date"
                [(ngModel)]="currentPeriod"
                (change)="onSelectChange()"
                [value]="period.value" [attr.name]="period.value" [attr.id]="period.value">
              </label>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </span>

  <div *ngIf="showCustomRange">
    <span *ngIf="!IS_NEW_DATE_FORMAT_ENABLED">
    <cl-range-input
      [current]="currentPeriodValue"
      [range]="config.source"
      [options]="rangeInputOptions"
      (valueChange)="onRangeInputChange($event)"
    ></cl-range-input>
  </span>
  <span *ngIf="IS_NEW_DATE_FORMAT_ENABLED">
    <cl-formatted-range-input
    [current] = "currentPeriodValue"
    (valueChange)="onRangeInputChange($event)"
    >
    </cl-formatted-range-input>
  </span>
    <button
      type="button"
      class="cl-btn cl-btn--text cl-btn--with-icon ic-date-range-filter__select-range"
      (click)="onSelectRange()"
    >
      <cl-icon [iconId]="'caret-left'"></cl-icon>
      <span class="cl-btn__text">
        {{ 'analysis.sidebar.filters.dateRange.Select a different range' | translate }}
      </span>
    </button>
  </div>

</div>
