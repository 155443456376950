import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FolderExtModel } from 'components/common/interfaces/folder.interface';
import { RootFolderModel } from './root-folder.model';

@Injectable()
export class FoldersViewModelService {

  constructor(private translate: TranslateService) {}

  /**
   * Returns root folder wrappened into array with some methods
   * @param {Folder[]} folders - Array of folders from server.
   */
  createRootArray(folders: FolderExtModel[]) {
    const rootFolder = new RootFolderModel(
      folders,
      this.translate.instant('analytics.my-folders.My Items')
    );
    // tslint:disable-next-line:no-any
    let rtrn: any = [rootFolder];

    rtrn.find = rootFolder.find.bind(rootFolder);
    rtrn.remove = rootFolder.remove.bind(rootFolder);
    rtrn.reveal = rootFolder.reveal.bind(rootFolder);

    return rtrn;
  }

  /**
   * Returns root folder object
   * @param {Folder[]} folders - Array of folders from server.
   */
  createRootFolder(folders: FolderExtModel[]) {
    return new RootFolderModel(
      folders,
      this.translate.instant('analytics.my-folders.My Items')
    );
  }

}