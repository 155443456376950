import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { AnalysisStateModel } from 'pages/analysis/models/analysis-state.model';
import { AnalysisAppliedFiltersModel, AnalysisBenchmarkModel } from 'pages/analysis/models';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
@Component({
  selector: 'ic-analysis-sidebar',
  templateUrl: 'analysis-sidebar.component.html'
})
export class AnalysisSidebarComponent implements OnChanges {
  @Input() state!: AnalysisStateModel;
  @Input() activeTabIndex!: number;
  @Output() datasetChange = new EventEmitter<string>();
  @Output() filterApply = new EventEmitter<AnalysisAppliedFiltersModel>();
  @Output() indicatorToggle = new EventEmitter<string[]>();
  @Output() benchmarkToggle = new EventEmitter<AnalysisBenchmarkModel>();
  @Output() esciToggle = new EventEmitter();
  @Output() inputFilters = new EventEmitter();
  @Output() showChooseTreeModal = new EventEmitter();
  @Input() context: string = '';
  multiTrendFlag!: boolean;
  outsideClickEvent: boolean = false;
  // tslint:disable-next-line:no-any
  env: { [key: string]: any; };
  isInside: boolean = false;
  reputationGrp: boolean = false;
  constructor(private environmentService: EnvironmentService) {
    this.env = this.environmentService.getEnvironment();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('state') && changes.state.currentValue.tile) {
      try {
        const tileType = JSON.parse(changes.state.currentValue.tile.vis).type;
        const { filterAggId } = changes.state.currentValue.tile;
        if (['multiIndicatorLine amCharts', 'multiIndicatorLine chart']
          .includes(tileType)) {
          this.multiTrendFlag = !this.env.feature.multiIndicatorTrendGraphPOC;
        } else {
          this.multiTrendFlag = this.env.analysis.visualization.disableChooseDifferentVisualization.includes(filterAggId);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  @HostListener('click', ['$event'])
  clicked() {
    this.outsideClickEvent = false;
    this.isInside = true;
  }

  @HostListener('document:click')
  clickedOut() {
    if (!this.isInside) {
      this.outsideClickEvent = true;
    }
    this.isInside = false;
  }

  // tslint:disable-next-line:no-any
  emittedInputFilters(inputValues: any) {
    this.inputFilters.emit(inputValues);
  }
  // tslint:disable-next-line:no-any
  onFilterApply(event: any) {
    this.reputationGrp = event.reputationGroup ? event.reputationGroup : false;
    this.filterApply.emit(event);
  }
}

