<cl-overlay-modal [options]="{ 'modalWidth': '500px', 'modifiers': 'dark-header' }">
  <cl-overlay-modal-content [modalTitle]="'report.tile.remove.Are you sure' | translate">
    <div class="cl-form__fieldset cl-form__fieldset">
      <div class="cl-form__field">
        <p class="text-left"> {{ 'report.tile.remove.You are about to delete' | translate }} <strong>{{ tile.title }}</strong></p>
      </div>
    </div>
    <div class="cl-form__fieldset cl-form__fieldset">
      <div class="cl-form__actions">
        <button type="button" class="cl-btn cl-btn--secondary" (click)="closeModal()">
          {{ 'report.tile.remove.No do not delete it' | translate }}
        </button>
        <button type="submit" class="cl-btn cl-btn--primary" (click)="delete()">
          {{ 'report.tile.remove.Yes delete it' | translate }}
        </button>
      </div>
    </div>
  </cl-overlay-modal-content>
</cl-overlay-modal>