import { Injectable } from '@angular/core';
import { MetadataRepositoryService } from 'components/rest/services/metadata-repository/metadata-repository.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { DiagramModel } from 'components/common/interfaces/diagram.model';
import { FileSystemService, FileTypes } from '../file-system/file-system.service';
import { ContentType, TextSize, TextWeight, TextFormat, CanvasContent } from 'components/common/interfaces/canvas.model';
import {assign} from 'lodash';

@Injectable()
export class PngGeneratorService {

  public canvasProperty = {
    font: 'normal 600 18px Source Sans Pro',
    fontColor: '#00020b',
    width: 1,
    height: 1,
    backgroundColor: '#f6f6f6',
    backgroundColorWhite: '#ffffff',
    contextYPosition: 0,
    ratio: function () { return this.height / this.width; }
  };

  private datasetLabel: string;
  private indicatorsLabel: string;

  constructor(
    private datePipe: DatePipe,
    private metadataRepository: MetadataRepositoryService,
    private translate: TranslateService,
    private fileSystem: FileSystemService
  ) {
    this.datasetLabel = this.translate.instant('analytics.explore.Dataset');
    this.indicatorsLabel = this.translate.instant('analytics.explore.Indicators');
  }

  canvasGraph(diagramDetails: DiagramModel, fileName: string, dataUpdateInfo?: string) {
    this.getRequiredWidthHeight(diagramDetails.base64)
      .then(() => {
        const { canvas, context } = this.canvasCreation();

        this.canvasHeader(canvas, context, diagramDetails);
        this.canvasCenter(canvas, context, diagramDetails)
          .then(() => this.canvasFooter(canvas, context, assign(diagramDetails, { dataUpdateInfo })))
          .then(() => {
            this.incYPos(canvas);
            this.removeGreySpace(canvas, context);
            this.fileSystem.saveFile(canvas.toDataURL(), FileTypes.png, fileName);
            this.canvasProperty.contextYPosition = 0;

            document.body.appendChild(canvas);
            canvas.parentNode!.removeChild(canvas);
          });

      });
  }

  private removeGreySpace(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
    const { width, height } = canvas;
    const { contextYPosition, backgroundColorWhite } = this.canvasProperty;
    if (contextYPosition < canvas.height) {
      context.fillStyle = backgroundColorWhite;
      context.fillRect(0, contextYPosition, width, height - contextYPosition);
    }
  }

  private getRequiredWidthHeight(base64: string): Promise<void> {
    return new Promise((resolve) => {
      const graphImage = new Image();
      graphImage.onload = () => {
        this.canvasProperty = {
          ...this.canvasProperty,
          width: Math.floor(graphImage.width) + 50,
          height: Math.floor(graphImage.height) + 230
        };
        resolve();
      };
      graphImage.src = base64;
    });
  }

  private canvasCreation(): { canvas: HTMLCanvasElement, context: CanvasRenderingContext2D } {
    const canvas = document.createElement('canvas');
    canvas.hidden = true;

    const { width, height } = this.canvasProperty;
    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d') as CanvasRenderingContext2D;

    const { backgroundColor } = this.canvasProperty;
    context.textBaseline = 'bottom';

    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, width, height);

    context.strokeStyle = backgroundColor;
    context.strokeRect(0, 0, width, height);

    context.fillStyle = this.canvasProperty.fontColor;

    return { canvas, context };
  }

  private canvasHeader(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, { title, subtitle }: DiagramModel) {
    if (title) {
      this.calculateTextWidth(canvas, context, {
        type: ContentType.Text,
        text: this.breakText(title, TextSize.medium, TextWeight.bold)
      });
    }
    if (subtitle) {
      this.calculateTextWidth(canvas, context, {
        type: ContentType.Text,
        text: this.breakText(subtitle)
      });
    }
  }

  private canvasCenter(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, { base64 }: DiagramModel) {
    return new Promise<void>((resolve) => {
      const graphImage = new Image();
      graphImage.onload = () => {
        this.calculateTextWidth(canvas, context, {
          type: ContentType.Image,
          text: [],
          imageSource: graphImage
        });
        resolve();
      };
      graphImage.src = base64;
    });
  }

  private canvasFooter(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, diagramDetails: DiagramModel) {
    this.calculateTextWidth(canvas, context, {
      type: ContentType.Text,
      text: this.genDiagramDescriptions(diagramDetails)
    });
    if (diagramDetails.dataUpdateInfo) {
      this.calculateTextWidth(canvas, context, {
        type: ContentType.Text,
        text: this.breakText(diagramDetails.dataUpdateInfo)
      });
      return Promise.resolve();
    }
    return this.getDataUpdateInfo()
      .then((deployDate) => {
        this.calculateTextWidth(canvas, context, {
          type: ContentType.Text,
          text: this.breakText(deployDate)
        });
      });
  }

  private breakText(texts: string, font = TextSize.small, isBold = TextWeight.semiBold, isItalic = false): TextFormat[] {
    return texts.split(' ').map(text => ({ value: `${text} `, font, isBold, isItalic }));
  }

  private calculateTextWidth(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, content: CanvasContent) {
    const canvasWidth = canvas.width;

    if (content.type === ContentType.Text) {
      const gapFromBorder = 10;
      const nextLineGap = 10;
      const nextLineContinueGap = 5;

      if (content.text.length) {
        const { font, isBold, isItalic } = content.text[0];
        context.font = `${isItalic ? 'italic' : 'normal'} ${isBold} ${font}px Source Sans Pro`;
      }

      let leftOffset = gapFromBorder;
      content.text.forEach((currentText, index) => {
        const xPositionAfterText = leftOffset + context.measureText(currentText.value).width;

        if (index === 0) this.incYPos(canvas, currentText.font + nextLineGap);

        if (xPositionAfterText > (canvasWidth - gapFromBorder)) {
          this.incYPos(canvas, currentText.font + nextLineContinueGap);
          this.fillContentInCanvas(content, context, gapFromBorder, currentText);
          leftOffset = context.measureText(currentText.value).width + gapFromBorder;
          return;
        }

        this.fillContentInCanvas(content, context, leftOffset, currentText);
        leftOffset = xPositionAfterText;
      });
    }
    if (content.type === ContentType.Image) {
      const { height: imgHeight, width: imgWidth } = content.imageSource as HTMLImageElement;

      const { backgroundColorWhite, fontColor } = this.canvasProperty;

      context.fillStyle = backgroundColorWhite;
      this.incYPos(canvas);
      context.fillRect(2, this.canvasProperty.contextYPosition, canvasWidth - 4, imgHeight + 20);

      this.fillContentInCanvas(content, context, (canvasWidth - imgWidth) / 2);
      this.incYPos(canvas, imgHeight + 20);
      context.fillStyle = fontColor;
    }
  }

  private incYPos(canvas: HTMLCanvasElement, increaseBy = 10) {
    this.canvasProperty.contextYPosition += increaseBy;

    if (this.canvasProperty.contextYPosition > canvas.height) {
      // TODO: increase the height of canvas once contextYPosition is more that canvas height
      return;
    }
  }

  private fillContentInCanvas(content: CanvasContent, context: CanvasRenderingContext2D, xPosition: number, currentText?: TextFormat) {
    if (content.type === ContentType.Text) {
      context.fillText(currentText!.value, xPosition, this.canvasProperty.contextYPosition);
    }
    if (content.type === ContentType.Image) {
      context.drawImage(content.imageSource!, xPosition, this.canvasProperty.contextYPosition);
    }

  }

  private genDiagramDescriptions({ indicatorsTitles, filters, datasetTitle }: DiagramModel) {
    const textArr: TextFormat[] = [];

    if (indicatorsTitles.length > 0) {
      textArr.push(...this.breakText(`${this.indicatorsLabel}: `));
      textArr.push(...this.breakText(`${indicatorsTitles.join(', ')}. `));
    }

    if (filters) {
      filters.summary.forEach((filter) => {
        textArr.push(...this.breakText(`${filter.title}: `));
        textArr.push(...this.breakText(`${this.toTitleCase(filter.value.join(', '))}. `));
      });
    }

    if (datasetTitle) {
      textArr.push(...this.breakText(`${this.datasetLabel}: `));
      textArr.push(...this.breakText(`${datasetTitle}. `));
    }

    return textArr;
  }

  private toTitleCase(txt: string) {
    const strings = txt.toLowerCase().split(' ');
    strings.forEach(str => str.charAt(0).toUpperCase() + str.slice(1));
    return strings.join(' ');
  }

  private getDataUpdateInfo(): Promise<string> {
    return this.metadataRepository.getDataUpdateInfo()
      .then((dataUpdateInfo) => {
        const exportDate = `Export Date: ${this.datePipe.transform((new Date()))}.`;
        const dateInfo = {
          deploydate: this.datePipe.transform(dataUpdateInfo.deploydate),
          wosextractdate: this.datePipe.transform(dataUpdateInfo.wosextractdate)
        };
        return this.translate.instant('analytics.explore.Data Update Info', dateInfo) + exportDate;
      });
  }
}