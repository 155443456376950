import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { getLocaleId } from '../locale/locale.factory';

// wait for locale data to load
export function translateInitializerFactory(translate: TranslateService, injector: Injector) {
  // tslint:disable-next-line:no-any
  return () => new Promise<any>((resolve: any) => {

      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

      locationInitialized.then(() => {
        const langToSet = getLocaleId().toLowerCase();
        
        translate.getTranslation(langToSet)
        .subscribe(() => {
          translate.use(langToSet);
          resolve(null);
        }, (error) => {
          console.error(`Problem with '${langToSet}' language initialization. ${error.message}`);
          resolve(null);
        });
      });

  });
}

// required for AOT compilation
export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'data/locale/', '.json');
}