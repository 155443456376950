export const environmentQa3Config = {
  hostname: 'incites.qa3.dev-incites.com',
  showDebugLocales: false,
  feature: {
    amchartsPOC: true,
    downloadPdf: true,
    explorerTour: false,
    geoMapZoom: false,
    pasteFilterValues: true,
    tooltipsGuide: true,
    unlockRank: true,
    treemapPOC: true,
    barAmchartsPOC: true,
    heatmapAmchartsPOC: true,
    multiIndicatorTrendGraphPOC: true,
    trendGraphPOC: true,
    fiveYearTrendGraphPOC: true,
    retainAnalysisOnDatasetSwitch: true,
    wordCloudPOC: true,
    cdxHeaderFooter: true,
    pieAmchartsPOC: true,
    groups: true,
    checkboxBulk: true,
    moveBulkButton: true,
    cookieBannerContext: true,
  },
  pasteFilterValues: {
    issn: true,
    people: {
      assprsnIdType: ['allUniqueId', 'orcId', 'researcherId'],
      clbrprsnIdType: ['allUniqueId', 'orcId', 'researcherId'],
      personIdType: ['allUniqueId', 'orcId', 'researcherId'],
      singlePrsnIdType: ['allUniqueId', 'orcId', 'researcherId'],
    },
  },
  splitioKey: 'hninsqc0nn2bsd9l7n12fdd8bku8g0mfea41',
  wos: 'https://apps-uw2.qa1.dev-wos.com/',
  gatewayLink: 'https://links.dev-stable.clarivate.com/api/gateway/',
  cookieBannerScriptId: '0f5e5dc7-7949-4ad1-b44c-ca62a091ce8a-test',
};