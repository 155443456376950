<div class="container" style="text-align:center;">

  <h2 style="padding:15px;">NGX-Translate Demo</h2>

  <h3>"Dismiss" translated using TranslateService</h3>
  <button type="button" class="btn">{{ dismissBtnTxt }}</button>

  <h3>"Dismiss" translated using pipe</h3>
  <button type="button" class="btn">{{ 'notifications.Dismiss' | translate }}</button>

  <h3>"Dismiss" translated using directive</h3>
  <button type="button" class="btn" [translate]="'notifications.Dismiss'"></button>

  <h3>"Dismiss" translated as a title attribute</h3>
  <button type="button" class="btn" [translate]="'notifications.Dismiss'" [attr.title]="'notifications.Dismiss' | translate"></button>

  <h2 style="padding:15px;">Number Formatting by Locale</h2>
  <p>9999999: {{ 9999999 | number }}</p>

  <h2 style="padding:15px;">Date Formatting by Locale</h2>
  <p>{{ today | date }}</p>

</div>