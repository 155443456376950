<div class="table-content" *ngIf="tableIndicators && tableValues">
  <table summary="{{ 'report.Table displaying data' | translate }}">
    <thead>
      <th *ngIf="hasHotOrHighlyCitedPapersField(tableValues[0])"></th>
      <th class="first-column" [ngClass]="{'text-left' : hasHotOrHighlyCitedPapersField(tableValues[0])}">{{ tableIndicators.name }}</th>
      <th>{{ tableIndicators.value }}</th>
      <th *ngIf="tableIndicators?.value1">{{ tableIndicators.value1 }}</th>
    </thead>
    <tr *ngFor="let line of tableValues; index as index">
      <td *ngIf="hasHotOrHighlyCitedPapersField(tableValues[0])">
        <cl-icon [iconId]="'fire'" [color]="'color-red'" *ngIf="line.hot_paper_docs" (mouseenter)="showHideValues(line,'esi_bimonthly_hp',true,index)" (mouseleave)="showHideValues(line,'esi_bimonthly_hp',false,index)"></cl-icon>
        <cl-popover-modal class="hp" id="esi_bimonthly_hp{{index}}" [options]="{modalWidth: '350px', isContext: true, align : 'bottom-left' }" *ngIf="line.esi_bimonthly_hp">
          <cl-popover-modal-content>
            This hot paper appeared on ESI bimonthly: {{line.esi_bimonthly_hp}}
          </cl-popover-modal-content>
        </cl-popover-modal>
        <cl-icon [iconId]="'trophy'" [color]="'color-orange-default'" *ngIf="line.esi_most_cited_article" (mouseenter)="showHideValues(line,'esi_bimonthly_hcp',true,index)" (mouseleave)="showHideValues(line,'esi_bimonthly_hcp',false,index)"></cl-icon>
        <cl-popover-modal  class="hcp" id="esi_bimonthly_hcp{{index}}" [options]="{modalWidth: '350px', isContext: true, align : 'bottom-left'}" *ngIf="line.esi_bimonthly_hcp">
          <cl-popover-modal-content>
            This highly cited paper appeared on ESI bimonthly: {{line.esi_bimonthly_hcp}}
          </cl-popover-modal-content>
        </cl-popover-modal>
      </td>
      <td [ngClass]="{'first-column': index === 1,'text-left' : hasHotOrHighlyCitedPapersField(tableValues[0])}">
        <p *ngIf="line.name" class="label-bold">{{ line.name }}</p>
        <p *ngIf="line.info">{{ tableIndicators.info }}: {{ line.info }}</p>
        <span *ngIf="line.info1" class="label-uppercase">{{ line.info1 }}</span>
        <span *ngIf="line.info2">{{ tableIndicators.info2 }}{{ line.info2 }}</span>
        <span *ngIf="line.info3">{{ tableIndicators.info3 }}{{ line.info3 }}</span>
        <span *ngIf="line.info4">{{ tableIndicators.info4 }}{{ line.info4 }}</span>
        <span *ngIf="line.info5">{{ tableIndicators.info5 }}{{ line.info5 }}</span>
      </td>
      <td>{{ line.value }}</td>
      <td *ngIf="line?.value1">{{ line.value1 }}</td>
    </tr>
  </table>
</div>
<div class="table-list-footer">
  <button class="cl-btn cl-btn--text" (click)="drilldown()">View More Results</button>
</div>
