
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';

import { pull } from 'lodash';

import { NotificationDetailsModel, NotificatonDetails, OverbarNotificationModel } from './overbar.model';
import { TranslateService } from '@ngx-translate/core';

// @ts-ignore
@Injectable({ providedIn: 'root' })
export class OverbarService {
  private networkError!: string;
  private notifications: OverbarNotificationModel[] = [];
  public notificationChange$ = new EventEmitter();
  delayElapsed: boolean = true;

  constructor(private translate: TranslateService) {}

  notify(message: string, timeout = 5000): void {
    const info = { text: message };

    this.notifications.unshift(info);
    this.notificationChange$.emit(this.notifications);

    let intervalValue = interval(timeout)
      .pipe(take(1))
      .subscribe(() => {
        pull(this.notifications, info);
        this.notificationChange$.emit(this.notifications);
        intervalValue.unsubscribe();
      });
  }

  notifyError(message: string, details?: NotificatonDetails): void {
    const error = {
      text: message,
      details: details,
      isError: true,
      dismiss: (): void => {
        pull(this.notifications, error);
        this.notificationChange$.emit(this.notifications);
      }
    };
    this.notifications.unshift(error);
    this.notificationChange$.emit(this.notifications);
  }

  notifyAndLocalizeNetworkError(details: NotificationDetailsModel, value: string): void {
    const error = {
      text: this.translate.instant(`notifications.errors.${value}.${details.message}`),
      isError: true,
      dismiss: (): void => {
        pull(this.notifications, error);
        this.notificationChange$.emit(this.notifications);
      }
    };
    this.notifications.unshift(error);
    this.notificationChange$.emit(this.notifications);
  }

  notifyNetworkError(details?: NotificatonDetails): void {
    if (!this.delayElapsed) {
      return;
    }
    if (this.notifications.length > 0) { return; }    
    if (!this.networkError) {
      this.networkError = this.translate.instant('notifications.errors.common');
    }

    const error = {
      text: this.networkError,
      details: details,
      isError: true,
      dismiss: (): void => {
        this.delayElapsed = false;
        setTimeout(() => {
          this.delayElapsed = true;
        }, 5000);
        pull(this.notifications, error);
        this.notificationChange$.emit(this.notifications);
      }
    };
    this.notifications.unshift(error);
    this.notificationChange$.emit(this.notifications);
    setTimeout(() => { error.dismiss(); }, 3000);
  }

}
