import {EventEmitter, Injectable} from '@angular/core';
import { AppliedFilterModel, AppliedFilterValuesModel, AppliedFiltersOptionsModel } from '@ic/component-lib/src/components/modules/applied-filters/models';
import { FilterOptionModel } from 'pages/tab-report/interfaces';
import { AnalysisFilterModel } from '@ic/component-lib/src/components/modules/analysis-filters/models';
import { AnalysisStateFiltersModel } from 'pages/analysis/models/analysis-state.model';
import { TranslateService } from '@ngx-translate/core';
import {isArray, isEqual} from 'lodash';
import { AnalysisFiltersService } from 'components/common/services/analysis-filters/analysis-filters.service';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import {Globals} from 'components/shared/globalData';

@Injectable()
export class AnalysisAppliedFiltersService {

  private envConfig: { [key: string]: any }; // tslint:disable-line:no-any
  closeModel = new EventEmitter<AppliedFilterValuesModel>();
  constructor(
    private environmentService: EnvironmentService,
    private filtersService: AnalysisFiltersService,
    private translate: TranslateService,
    public globalData: Globals
  ) {
    this.envConfig = this.environmentService.getEnvironment();
  }

  getAppliedComponentParams(stateFilters: AnalysisStateFiltersModel, entity?: string): {
    options: Partial<AppliedFiltersOptionsModel>;
    appliedFilters: AppliedFilterModel[];
  } {
    let IS_DISPLAY_CORRECT_FILTER_CHIP_ENABLED = this.globalData.toggleData[this.envConfig.splitioTreatment.displayCorrectFilterChip] === 'on';
    const appliedFilters = this.getAppliedFilters(stateFilters, entity as string);
    let showClearAllButton: boolean;
    if (IS_DISPLAY_CORRECT_FILTER_CHIP_ENABLED) {
      showClearAllButton = appliedFilters.some(filter => !filter.disableRemove);
    } else {
      showClearAllButton = entity === 'department' ? appliedFilters.length > 3 : appliedFilters.length > 2;
    }

    const options = {
      labels: this.getAppliedFiltersLabels(),
      showClearAllButton,
      useTruncation: false,
    };
    return {options, appliedFilters};
  }

  getAppliedFilters(stateFilters: AnalysisStateFiltersModel, entity: string): AppliedFilterModel[] {
    const { filters, values } = stateFilters;
    const appliedFiltersNames = Object.keys(values);
    let constantlyVisibleFilters = ['period', 'schema'];
    let IS_DISPLAY_CORRECT_FILTER_CHIP_ENABLED = this.globalData?.toggleData[this.envConfig.splitioTreatment.displayCorrectFilterChip] === 'on';

    if (entity && IS_DISPLAY_CORRECT_FILTER_CHIP_ENABLED) {
      let defaultFilterChips = this.envConfig.defaultFilterChips[entity as string];
      if (defaultFilterChips && !defaultFilterChips.every((filter: string) => constantlyVisibleFilters.includes(filter))) {
        constantlyVisibleFilters.push(...defaultFilterChips.filter((filter: string) => !constantlyVisibleFilters.includes(filter)));
      }
    }
    const filtersWithValues = filters.filter((filter) => this.isFilterVisible(filter, appliedFiltersNames, constantlyVisibleFilters));

    return filtersWithValues.map((filter) => {
      const { name, title, options, choice, type, appliedValues: apdValues } = filter;
      let appliedValues: AppliedFilterValuesModel|undefined = apdValues!;
      const disableRemove = this.filtersService.isFilterDefault(filter);

      if (choice === 'range' && appliedValues.is && Array.isArray(appliedValues.is) && appliedValues.is.length > 1) {
        const [start, end] = apdValues!.is as number[];

        appliedValues = {is: `${start} to ${end}`};
      } else if (choice === 'checkbox' && type === 'boolean') {
        appliedValues = { is: (appliedValues.is && Array.isArray(appliedValues.is) && appliedValues.is[0] === 0) ? 'false' : 'true' };
      } else if (choice !== 'range' && options!.length > 0) {
        appliedValues = this.updateLabels(apdValues!, options!);
      }

      return { appliedValues, title, name, disableRemove };
    });
  }

  shouldSkipDefaultStateCheck(filter: AnalysisFilterModel) {
    return typeof filter.choice !== 'undefined' &&
      typeof this.envConfig.analysisFilterChoice.skipDefaultStateCheck !== 'undefined' &&
      this.envConfig.analysisFilterChoice.skipDefaultStateCheck[filter.choice];
  }

  isFilterApplied(filter: AnalysisFilterModel, appliedFiltersNames: string[]) {
    const isApplied = appliedFiltersNames.includes(filter.name);

    if (this.shouldSkipDefaultStateCheck(filter)) {
      return isApplied;
    }
    return isApplied ? !this.isRequirendFilterInDefaultState(filter) : false;
  }

  isFilterVisible(filter: AnalysisFilterModel, appliedFiltersNames: string[], constantlyVisibleFilters: string[]) {
    const isVisible = () => {
      if (filter.required || (filter.choice === 'checkbox' && filter.defaultValue)) {
        if (constantlyVisibleFilters.includes(filter.name)) {
          return true;
        }

        return !this.isRequirendFilterInDefaultState(filter);
      }

      return true;
    };

    const isApplied = appliedFiltersNames.includes(filter.name);

    return isApplied ? isVisible() : false;
  }

  private isRequirendFilterInDefaultState({type, appliedValues, options, defaultValue}: AnalysisFilterModel) {
    const currentValue = appliedValues && appliedValues.is;
    const filterDefaultValue = isArray(options) && options.length > 0 && ((type === 'boolean' && defaultValue) ? [options[0].value] : options[0].value);
    return isEqual(currentValue, filterDefaultValue);
  }

  private updateLabels(applied: AppliedFilterValuesModel, options: FilterOptionModel[]): AppliedFilterValuesModel {
    const updatedValues: AppliedFilterValuesModel = {};
    // @ts-ignore
    Object.keys(applied).forEach((key: 'is'|'not') => {
      const value = applied[key];
      const option = options.find((opt) => opt.value === value);

      if (option && option.label) {
        updatedValues[key] = option.label;
      } else {
        updatedValues[key] = value;
      }
    });

    return updatedValues;
  }

  private getAppliedFiltersLabels() {
    return {
      'clear all': this.translate.instant('analysis.applied filters.clear all'),
      'excluded': this.translate.instant('analysis.applied filters.excluded'),
      'show less': this.translate.instant('analysis.applied filters.show less'),
    };
  }

}
