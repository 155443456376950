import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { NotificationModel } from './notifications-repository.model';

const baseUrl: string = '/incites-app/';

@Injectable()
export class NotificationsRepositoryService {

  private serviceUrl: string = `${baseUrl}notifications/`;

  constructor(private http: HttpClient) {}

  add(notification: NotificationModel): Promise<NotificationModel> {
    return this.http.post<NotificationModel>(`${this.serviceUrl}add`, notification)
      .toPromise();
  }

  edit(notification: NotificationModel) {
    return this.http.post<NotificationModel>(`${this.serviceUrl}modify`, notification)
    .toPromise();
  }

  delete(id: string): Promise<{success: boolean}> {
    const httpParams = new HttpParams({ fromObject: { id: id } });

    return this.http.get<{success: boolean}>(`${this.serviceUrl}delete`, { params: httpParams })
      .toPromise();
  }

  find(notification: Partial<NotificationModel>): Promise<NotificationModel[]> {
    return this.http.post<NotificationModel[]>(`${this.serviceUrl}searchMessage`, notification)
      .toPromise();
  }

  getList(date: string): Promise<NotificationModel[]> {
    const httpParams = new HttpParams({ fromObject: { userCurrentTime: date } });

    return this.http.get<NotificationModel[]>(this.serviceUrl, { params: httpParams })
      .toPromise();
  }

  isAdmin(): Promise<boolean> {
    return this.http.get<{isAdmin: boolean}>(`${this.serviceUrl}isAdmin`)
      .pipe(
        map((result) => result.isAdmin)
      )
      .toPromise();
  }

  formatDate(date: Date, consist?: string) {
    const formattedMonth = this.numPad(date.getMonth() + 1);
    const formattedDate = this.numPad(date.getDate());
    const dateString = `${formattedMonth}/${formattedDate}/${date.getFullYear()}`;

    const formattedHours = this.numPad(date.getHours());
    const formattedMinutes = this.numPad(date.getMinutes());
    const formattedSeconds = this.numPad(date.getSeconds());
    const time = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    switch (consist) {
      case 'date':
        return dateString;
      case 'date time':
        return `${dateString} ${time}`;
      default:
        return `${dateString} ${time} ${this.formatTimeZone(date.getTimezoneOffset())}`;
    }
  }

  private formatTimeZone(timezoneOffset: number) {
    const s = timezoneOffset < 0 ? '+' : '';
    const hours = (timezoneOffset - timezoneOffset % -60) / -60;
    const minutes = Math.abs(timezoneOffset % 60);

    return `${s}${this.numPad(hours)}:${this.numPad(minutes)}`;
  }

  private numPad(num: number, size = 2) {
    const sign = num < 0 ? '-' : '';
    return sign + new Array(size).concat([Math.abs(num)]).join('0').slice(-size);
  }

}