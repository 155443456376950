import { isUndefined, isNumber, isString } from 'lodash';

export const utilities = {
  /**
   *  @description
   * Determines if a reference is defined.
   *
   * @param {*} value reference to check
   * @returns {boolean} True if `value` is defined.
   */
  isDefined(value: any) { // tslint:disable-line
    return typeof value !== 'undefined';
  },

  /**
   *  @description
   * Serializes input into a JSON-formatted string.
   *
   * @param {*} obj {Object|Array|Date|string|number|boolean} obj Input to be serialized into JSON.
   * @param {*} pretty {boolean|number} [pretty=2] If set to true, the JSON output will contain newlines and whitespace.
   *    If set to an integer, the JSON output will contain that many spaces per indentation.
   *
   * @returns {string|undefined} JSON-ified string representing `obj`.
   */
  toJson(obj: any, pretty: string|number|undefined = undefined) { // tslint:disable-line: no-any
    if (isUndefined(obj)) return undefined;
    if (!isNumber(pretty)) {
      pretty = pretty ? 2 : undefined;
    }
    return JSON.stringify(obj, undefined, pretty);
  },

  /**
   * @description
   * Deserializes a JSON string.
   *
   * @param {string} json JSON string to deserialize.
   * @returns {Object|Array|string|number} Deserialized JSON string.
   */
  fromJson(json: string) {
    return isString(json) ? JSON.parse(json) : json;
  }
};
