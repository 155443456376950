<div title="{{ 'analytics.explore.Export Visualization' | translate }}">
  <ic-dropdown 
    *ngIf="!isIE11;else ie11"
    [items]="dropdownMenu"
    [options]="{icon: 'download', size: 18, width: 220}"
    [disabled]="disabled || exporting"
  ></ic-dropdown>
  <ng-template #ie11>
    <button 
      type="button"
      class="cl-btn cl-btn--text cl-btn--icon"
      (click)="export()"
      title="{{ 'analytics.explore.Export Visualization' | translate }}"
      [disabled]="disabled || exporting"
    >
      <cl-icon [iconId]="'download'" [size]="'24'"></cl-icon>
    </button>
  </ng-template>
</div>

<cl-popover-modal [options]="{isContext: true, closeOnOutsideClick: false, modifiers: 'no-arrow', modalWidth: '313px'}">
  <cl-popover-modal-content><cl-loading-indicator [label]="'analytics.explore.Exporting'|translate"></cl-loading-indicator></cl-popover-modal-content>
</cl-popover-modal>

<button id="legacy-export" class="visually-hidden" (click)="legacyExport()">&dArr;</button>
<button id="export-pdf" class="visually-hidden" (click)="diagramToBase64()">&darr;</button>
<code id="pdf-base64" class="visually-hidden" data-ready="pdfReady.length > 0">{{ pdfBase64 }}</code>