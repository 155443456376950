import { Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { EntityModel } from 'components/rest/services/entities-repository/entity.model';
import { TabReportModel } from 'pages/tab-report/interfaces/tab-report.model';

@Component({
  selector: 'ic-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  encapsulation: ViewEncapsulation.None,
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class MenuDropdownComponent {

  @Input() menus: (EntityModel | TabReportModel)[] = [];
  @Input() selectedMenu!: EntityModel | TabReportModel;
  menuOpened: boolean = false;
  constructor(private eRef: ElementRef) {}

  hideMenu() {
    this.menuOpened = false;
  }

  showMenu() {
    this.menuOpened = true;
  }

  toggleMenu() {
    this.menuOpened ? this.hideMenu() : this.showMenu();
  }

  getReportOmitedTitle(menu: (EntityModel | TabReportModel)) {
    if (!menu) return '';
    if ( !menu.hasOwnProperty('owner') ) return menu.title;
    return menu.title.split(' ', 1);
  }

  // tslint:disable-next-line:no-any
  onClick(event: { target: any; }) {
    if (!this.eRef.nativeElement.contains(event.target))
      this.hideMenu();
  }
}
