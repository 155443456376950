import '@angular/compiler';
import './polyfills';

// Import the AngularJS app module and its dependencies
import './angularjs/app';

import { platformBrowser } from '@angular/platform-browser';
import { UIRouter, UrlService } from '@uirouter/core';
import { NgZone } from '@angular/core';

import { AppModule } from './components/app/app.module';

import { enableProdMode } from '@angular/core';

platformBrowser().bootstrapModule(AppModule)
  .then(platformRef => {

    // Initialize the Angular Module
    // get() the UIRouter instance from DI to initialize the router
    const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

    // Instruct UIRouter to listen to URL changes
    function startUIRouter() {
      urlService.listen();
      urlService.sync();
    }

    platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);

  });
enableProdMode();