export const environmentQa2Config = {
  hostname: 'incites.qa2.dev-incites.com',
  showDebugLocales: false,
  feature: {
    amchartsPOC: true,
    downloadPdf: true,
    explorerTour: false,
    geoMapZoom: false,
    pasteFilterValues: true,
    tooltipsGuide: true,
    unlockRank: true,
    treemapPOC: true,
    multiIndicatorTrendGraphPOC: true,
    trendGraphPOC: true,
    fiveYearTrendGraphPOC: true,
    retainAnalysisOnDatasetSwitch: true,
    wordCloudPOC: true,
    barAmchartsPOC: true,
    heatmapAmchartsPOC: true,
    cdxHeaderFooter: true,
    pieAmchartsPOC: true,
    groups: true,
    checkboxBulk: true,
    moveBulkButton: true,
    cookieBannerContext: true,
    shareGroup : false
  },
  oneTrustKey: '2f194359-395e-4b62-a9b9-e54615275b69',
  pasteFilterValues: {
    issn: true,
    people: {
      assprsnIdType: ['allUniqueId', 'orcId', 'researcherId'],
      clbrprsnIdType: ['allUniqueId', 'orcId', 'researcherId'],
      personIdType: ['allUniqueId', 'orcId', 'researcherId'],
      singlePrsnIdType: ['allUniqueId', 'orcId', 'researcherId'],
    },
  },
  splitioKey: 'p0c2j67db18p1j8uj9cpp2ar6o8encf7s0eb',
  wos: 'https://apps-uw2.qa1.dev-wos.com/',
  gatewayLink: 'https://links.dev-stable.clarivate.com/api/gateway/',
  derwentGatewayLink: 'https://api.dev-stable.clarivate.com/api/gateway',
  cookieBannerScriptId: '2f194359-395e-4b62-a9b9-e54615275b69-test',
};