<nav class="ic-menu-dropdown">
  <ul class="cl-navigation__list--primary">
    <li class="cl-navigation__link--primary has-submenu">
      <a href="javascript:void(0)" (click)="toggleMenu();" class="selected-entity selected-menu-link">{{ getReportOmitedTitle(selectedMenu) }}</a>
      <ul
        class="cl-navigation__list--secondary"
        [ngClass]="{ 'active': menuOpened }"
        [attr.aria-hidden]="!menuOpened"
      >
        <li class="cl-navigation__item--secondary" *ngFor="let menu of menus">
          <label [attr.for]="menu.id" (click)="hideMenu()">
            {{ getReportOmitedTitle(menu)}}
            <input type="radio" [(ngModel)]="selectedMenu"
                   [value]="menu" [attr.name]="menu.id" [attr.id]="menu.id">
          </label>
        </li>
      </ul>
    </li>
  </ul>
</nav>