import { Component, ViewEncapsulation } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

import { ajsAppModule } from '../../../../angularjs/app/app.module.ajs';

import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { OverbarNotificationModel } from '../../../overbar/services/overbar/overbar.model';
import { OverbarService } from '../../../overbar/services/overbar/overbar.service';

@Component({
  selector: 'ic-overbar',
  templateUrl: './overbar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class OverbarComponent {
  // tslint:disable-next-line:no-any
  public config: any;
  public notifications$ = this.overbarService.notificationChange$;

  constructor(
    private environmentService: EnvironmentService,
    public overbarService: OverbarService
  ) {
    this.config = this.environmentService.getEnvironment();
  }

  dissmissNotification(notification: OverbarNotificationModel): void {
    if (typeof notification.dismiss === 'function') {
      notification.dismiss();
    }
  }
}

// Downgrade component for use in AngularJS template
ajsAppModule.directive(
  'icOverbar',
  downgradeComponent({ component: OverbarComponent }) as angular.IDirectiveFactory
);