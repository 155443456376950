<cl-multi-select
  [config]="{
    inputId: 'ic-report-filter-multi-select',
    inputLabel: labels.label,
    noResultsMessage: 'dashboard.No results available'|translate
  }"
  [inputValue]="''"
  [options]="filterOptions|async"
  [values]="filterValues"
  [isLoading]="loading"
  [typeOptions]="filterTypes"
  [placeholderText]="placeholderText"
  (onInputChange)="onInputChange($event.value, $event.type)"
  (onValuesChange)="onValuesChange($event.values, $event.type)"
  (onTypeChange)="onTypeChange($event.type, $event.previousType)"
></cl-multi-select>
