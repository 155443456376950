import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractFilterComponent } from '@ic/component-lib/src/components/modules/analysis-filters/components/abstract-filter/abstract-filter.component';
import { AnalysisFilterModel } from '@ic/component-lib/src/components/modules/analysis-filters/models';
import {
  MultiSelectInputChangeEventModel,
  MultiSelectOptionModel,
  MultiSelectValuesChangeEventModel,
} from '@ic/component-lib/src/components/modules/multi-select/models';
import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';
import { isString } from 'angular';
import { AnalysisFiltersService } from 'components/common/services/analysis-filters/analysis-filters.service';
import { EventsTrackerService } from 'components/common/services/events-tracker/events-tracker.service';
import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';
import { cloneDeep, isArray } from 'lodash';
import { AnalysisStateModel } from 'pages/analysis/models/analysis-state.model';
import { FilterValuesModel } from 'pages/analysis/models/edit-filter-items.model';
import { AnalysisService } from 'pages/analysis/services/analysis-service/analysis.service';
import { FilterOptionModel } from 'pages/tab-report/interfaces';
import { FilterTypeOptionModel, MultiSelectConfigFilterModel } from 'pages/tab-report/interfaces/tab-report-filters.model';
import { TabReportService } from 'pages/tab-report/services/tab-report/tab-report.service';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MultiSelectComponent } from '@ic/component-lib/src/components/modules/multi-select/multi-select.component';
import { AppliedFilterValuesModel } from '@ic/component-lib/src/components/modules/applied-filters/models';

interface HeaderFilterConfig {
  type?: {
    hidden?: boolean;
    name: string;
    label?: string;
    options: FilterOptionModel[];
    value: string;
    onChange: (value: string) => void;
  };
  subType?: {
    hidden?: boolean;
    name: string;
    label?: string;
    options: FilterOptionModel[];
    value: string;
    url: string;
    onChange: (value: string) => void;
  };
  childSubtype?: {
    hidden?: boolean;
    name: string;
    value: string[];
  };
  search: {
    hidden?: boolean;
    name: string;
    filteredDataFound?: string | boolean;
    inputValue?: string;
    label?: string;
    onInputChange: (val: MultiSelectOptionModel) => void;
    onPastedChange: (val: MultiSelectOptionModel) => void;
    options$: Observable<FilterOptionModel[]>;
    placeholder?: string | { [key: string]: string };
    prefetch?: boolean;
    url: string;
    values: FilterOptionModel[];
    dependsOn?: string[];
    searchPlaceHolder?: { [key: string]: string };
  };
}

@Component({
  selector: 'ic-edit-filter-items',
  templateUrl: './edit-filter-items.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class EditFilterComponent extends AbstractFilterComponent implements OnChanges {
  @ViewChild(OverlayModalComponent, { static: true }) overlayModal!: OverlayModalComponent;
  @ViewChild(MultiSelectComponent, { static: false }) multiSelectComponent!: MultiSelectComponent;
  @HostBinding('class.cl-navigation__item--secondary') getSelectedFindingDataSource!: string;
  @Input() state!: AnalysisStateModel;
  // tslint:disable-next-line:no-any
  @Input() sidebarFilters: any;
  // tslint:disable-next-line:no-any
  @Input() reports: any;
  @Input() filtersConfig: HeaderFilterConfig = {} as HeaderFilterConfig;
// tslint:disable-next-line:no-any
  @Input() allFilters: any;
  @Input() schema: { [filter: string]: AppliedFilterValuesModel } = {};
  @Output() filterApply = new EventEmitter<{ [filter: string]: AppliedFilterValuesModel }>();
  @Output() onReportsFilterUpdate = new EventEmitter();
  eventValues: { [filter: string]: string } = {};
  getFiltersConfigSchema!: string;
  currentEntityId!: string;
  currentEntityTitle!: string;
  filterTitle: string | undefined;
  filterValues: FilterValuesModel[];
  filterLength: number;
  // tslint:disable-next-line:no-any
  originalResponse: any;
  subscription: Subscription[] = [];
  searchValues: MultiSelectOptionModel[] = [];
  searchOptions: FilterOptionModel[];
  currentFilter: string = '';
  headerMultiSelect: boolean = false;
  reportsMultiSelect: boolean = false;
  sidebarMultiSelect: boolean = false;
  reportsConfig = {};
  public getAvailableFilterTypeOptions = TabReportService.getAvailableFilterTypeOptions;
  constructor(
    private analysisService: AnalysisService,
    private analysisFiltersService: AnalysisFiltersService,
    private cd: ChangeDetectorRef,
    private tracker: EventsTrackerService,
    private reportsRepository: ReportsRepositoryService,
    public tabReport: TabReportService,
  ) {
    super();
  }
  // @ts-ignore
  ngOnChanges(changes: SimpleChanges) {
    this.loadFilterData();
  }

  openModal() {
    this.loadFilterData();
    this.sortFilters(true);
    this.overlayModal.openModal();
    if (this.multiSelectComponent) {
      this.multiSelectComponent.inputValue = '';
    }
    this.cd.detectChanges();
  }
  closeModal() {
    this.overlayModal.closeModal();
    this.cd.detectChanges();
  }
  onCancel() {
    this.filterValues = this.originalResponse;
    this.filterLength = this.filterValues.length;
    this.closeModal();
  }
  loadFilterData() {
    if (this.sidebarFilters) {
      this.sidebarMultiSelect = true;
      this.headerMultiSelect = this.reportsMultiSelect = false;
      this.filterValues = cloneDeep(this.sidebarFilters.inputFilters.selectedValues);
      this.currentFilter = this.sidebarFilters.inputFilters.filterName;
    } else if (this.filtersConfig.search) {
      this.headerMultiSelect = true;
      this.sidebarMultiSelect = this.reportsMultiSelect = false;
      this.filterValues = cloneDeep(this.filtersConfig.search.values);
      this.currentFilter = this.filtersConfig.search.name;
      this.filtersConfig.search.options$ = of([]);
    } else if (this.reports) {
      this.reportsMultiSelect = true;
      this.sidebarMultiSelect = this.headerMultiSelect = false;
      this.filterValues = cloneDeep(this.reports.reportsData.filters.select.values);
      this.currentFilter = this.reports.filterName;
      this.reportsConfig = isArray(this.reports.reportsData.filters.select.configs) ?
        this.reports.reportsData.filters.select.configs.find(((config: { settingsValue: string; }) => config.settingsValue === this.currentFilter)) :
        this.reports.reportsData.filters.select.configs;
      this.filterTitle = this.reports.filterTitle;
    }
    if (this.filterValues) {
      this.sortFilters(true);
      this.originalResponse = cloneDeep(this.filterValues);
      this.filterLength = this.filterValues.length;
      const filterName =  this.allFilters?.find((i: AnalysisFilterModel) => i.name === this.currentFilter);
      if (filterName) {
        this.filterTitle = filterName?.title;
      }
    }
    this.searchOptions = [];
  }

  private resetSearchFilterValuesAndOptions() {
    this.filtersConfig.search.values = [];
    this.filtersConfig.search.options$ = of([]);
  }

  onValuesChange(event: MultiSelectValuesChangeEventModel) {
    this.filterValues = event.values;
    this.filterLength = this.filterValues.length;
  }

  removeValue(element: FilterValuesModel) {
    const elementIndex = this.filterValues.indexOf(element);
    this.filterValues.splice(elementIndex, 1);
    this.filterLength = this.filterValues.length;
  }
  
  onChangeSubType(
    name: string,
    filters: { [p: string]: Partial<AnalysisFilterModel> }
  ) {
    this.analysisFiltersService.setISSNorSourceName(name);
    let previousFilterName = '';
    if (this.currentEntityId === 'journal') {
      previousFilterName = Object.keys(filters).filter(
        (key) => key === this.filtersConfig.search.name
      )[0];
    } else {
      previousFilterName = Object.keys(filters).filter(
        (key) => key !== name
      )[0];
    }
    const thatSearch = this.filtersConfig.search;
    thatSearch.name = name;
    thatSearch.placeholder = filters[name].placeholder as string | undefined;
    const previousAppliedValue = filters[previousFilterName].appliedValues;
    if (previousAppliedValue && Object.keys(previousAppliedValue).length > 0) {
      this.analysisService.filtersRemove([previousFilterName]);
      this.resetSearchFilterValuesAndOptions();
    } else {
      this.analysisService.onJournalTypeChange();
    }
    thatSearch.values = this.analysisFiltersService.getValuesFromAppliedValues(
      filters[name].appliedValues
    );
    thatSearch.options$ = of([]);
    thatSearch.url = filters[name].source as string;
    if (this.filtersConfig && this.filtersConfig.type) {
      this.getFiltersConfigSchema = this.filtersConfig.type.options.find(
        (data) =>
          this.checkValue(
            thatSearch.name.toLowerCase(),
            data.value.toLowerCase()
          )
      )!.label;
    }
    this.tracker.trackPageView();
  }
  // tslint:disable-next-line:no-any
  checkValue(value1: any, value2: any) {
    if (typeof value1 === 'string') {
      return value2.toLowerCase() === value1.toLowerCase();
    } else {
      // @ts-ignore
      const value = cloneDeep(value1).map((val: string) => val.toLowerCase());
      return value.includes(value2.toLowerCase());
    }
  }
  onUpdateFilter() {
    const isNot = this.sidebarFilters?.searchFilterThis.selectedIncludeToggleValue === 'Exclude Only' ? 'not' : 'is' ;
    const is = this.filterValues.map((item: { value: string; }) => item.value);
    if (this.reportsMultiSelect) {
      this.onReportsFilterUpdate.emit({
        options : this.filterValues,
        type : this.reports.updateData.type,
        config : this.reports.updateData.config
      });
    } else if (this.sidebarMultiSelect) {
      this.schema[this.currentFilter] = { [isNot]: is };
      this.filterApply.emit(this.schema);
    } else {
      this.analysisService.filterApply({ [this.currentFilter]: { [isNot]: is } });
    } 
    this.tracker.trackPageView();
    this.closeModal();
  }

  sortFilters(ascSort: boolean) {
    if (this.filterValues) {
      this.filterValues.sort((a: { label: string; }, b: { label: string; }) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA < labelB) {
          return ascSort ? -1 : 1;
        } else if (labelA > labelB) {
          return ascSort ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
  }
  
  onClearAllFilters() {
    this.filterValues = [];
    this.filterLength = 0;
  }

  onSidebarInputChange(event: MultiSelectInputChangeEventModel) {
     const output = {
     filter: this.currentFilter,
     value: event.value
   };
   this.onFilterInputChange(this.sidebarFilters.searchFilterThis.filter, output.value);
  }
  // @ts-ignore
  onReportsInputChange(text: string, type?: FilterTypeOptionModel, config?: MultiSelectConfigFilterModel) {
    if (text && text.length > 0) {
      const typeOptions = type ?
        { personIdType: type.value } :
        this.reports.reportsData.filters.defaultTypeOptions ?
          this.reports.reportsData.filters.defaultTypeOptions :
          undefined;
      const optionsUrl = type ? type.source : this.reports.reportsData.filters.select.source!;
        this.reportsRepository.getFilterOptions(optionsUrl, text, typeOptions)
          .pipe(
            map(data => data.map(val => ({ label: val, value: val }))
            )
          ).subscribe((options: FilterOptionModel[]) => this.updateFilter(options));
      this.cd.detectChanges();
    }
  }
  
  async onTypeChange(type: FilterTypeOptionModel, config: Partial<MultiSelectConfigFilterModel>) {
    this.reports.reportsData.filters.select.typeOptions = TabReportService.setDefaultSelectType(
      this.reports.reportsData.filters.select.typeOptions!,
      type.value
    );
    this.reports.reportsData.filters.select.placeholder = type.placeholder;
    const index  = this.reports.reportsData.filters.select.configs.findIndex(((fConfig: any) => fConfig.inputId === config.inputId)); // tslint:disable-line:no-any
    this.reports.reportsData.filters.select.values =  this.reports.reportsData.filters.select.configs[index].values = await this.tabReport.getSelectedTypeValue(type).toPromise();
    this.cd.detectChanges();
  }

  private updateFilter(options: FilterOptionModel[]) {
    this.searchOptions = options;
    this.cd.detectChanges();
  }

  onFilterInputChange(filter: AnalysisFilterModel, eventValue: string) {
    const { choice, dependsOn, name, prefetch, source } = filter;
    this.eventValues[name] = eventValue;
    if ((!prefetch || dependsOn) && isString(source)) {
      if (choice === 'search') filter.filteredDataFound = 'NOT_FOUND';
      const subscription = this.analysisFiltersService.requestFilterOptions(
        source,
        // @ts-ignore
        this.analysisFiltersService.getFilterOptionsRequestParams(this.sidebarFilters.searchFilterThis.filter, eventValue, this.allFilters, this.schema)
      ).subscribe((options: FilterOptionModel[]) => this.updateFilter(options));
      this.subscription.push(subscription);
    }
  }
}
