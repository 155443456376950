import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { PopoverModalComponent } from '@ic/component-lib/src/components/modules/popover-modal/popover-modal.component';
import { EventsTrackerService } from 'components/common/services/events-tracker/events-tracker.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Globals } from 'components/shared/globalData';
import { IcTogglesNgService } from 'components/common/services/ic-toggles-ng/ic-toggles-ng.service';

@Component({
  selector: 'ic-document-list-download',
  templateUrl: 'document-list-download.component.html'
})

export class DocumentListDownloadComponent implements OnChanges {
  @Input() total!: number;
  @Input() disabled!: boolean;
  @Output() download = new EventEmitter<{ fileName: string; fileType?: string, records: number }>();
  @ViewChild(PopoverModalComponent, { static: false }) popover!: PopoverModalComponent;
  @Input() citations: boolean;
  @Input() isReportDrilldown: boolean = false;

  public downloadForm!: FormGroup;
  private maxRecords: number;
  public maxRecordsValue!: number;
  public placeholder: string = '';
  IC_38113_EXCEL_DOWNLOAD: boolean = false;
  env = this.environmentService.getEnvironment();
  toggleSubscription: Subscription;
  types = ['Excel', 'csv'];
  constructor(
    private environmentService: EnvironmentService,
    private formBuilder: FormBuilder,
    private tracker: EventsTrackerService,
    public globalData: Globals,
    private icTogglesNgService: IcTogglesNgService
  ) {
    const env = this.environmentService.getEnvironment();
    this.maxRecords = env && env.export && env.export.maxRecords || 50000;
    this.maxRecordsValue = this.maxRecords;
    this.validateSplits();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.maxRecordsValue = Math.min(this.maxRecords, this.total);
    this.placeholder = this.citations ? 'Derwent Innovation Index Documents' : 'Web of Science Documents';
    if (changes.total && changes.total.currentValue) {
      if (this.IC_38113_EXCEL_DOWNLOAD) {
        this.downloadForm = this.formBuilder.group(
          {
            fileName: [this.placeholder, Validators.required],
            records: [
              this.maxRecordsValue,
              [
                Validators.required,
                Validators.min(1),
                Validators.max(this.maxRecordsValue)
              ]
            ],
            downloadOption: ['Excel', Validators.required]
          }
        );
      } else {
        this.downloadForm = this.formBuilder.group(
          {
            fileName: [this.placeholder, Validators.required],
            records: [
              this.maxRecordsValue,
              [
                Validators.required,
                Validators.min(1),
                Validators.max(this.maxRecordsValue)
              ]
            ],
          }
        );
      }

    }
  }

  onDownloadSubmit() {
    const { downloadOption, fileName, records } = this.downloadForm.value;
    this.download.emit({ fileName, records, fileType: downloadOption });
    this.popover.closePopover();
    this.tracker.trackEvent('Explore', fileName, 'Download table data');
  }

  setSplitIoToggles() {
    this.toggleSubscription = this.icTogglesNgService.toggles().subscribe((toggles) => {
      this.globalData.toggleData = toggles;
      const IC_38113_EXCEL_DOWNLOAD = this.env.splitioTreatment.IC_38113_excel_download;
      this.IC_38113_EXCEL_DOWNLOAD = toggles[IC_38113_EXCEL_DOWNLOAD] === 'on';
    });
  }

  validateSplits() {
    if (!this.globalData?.toggleData) {
      this.setSplitIoToggles();
    } else {
      const IC_38113_EXCEL_DOWNLOAD = this.env.splitioTreatment.IC_38113_excel_download;
      this.IC_38113_EXCEL_DOWNLOAD = this.globalData?.toggleData[IC_38113_EXCEL_DOWNLOAD] === 'on';
    }
  }

}
