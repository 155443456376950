import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FoldersViewModelService } from './services/folders-view-model/folders-view-model.service';
import { FolderViewModelService } from './services/folder-view-model/folder-view-model.service';

@NgModule({
  imports: [
    TranslateModule
  ],
  declarations: [],
  exports: [],
  providers: [FolderViewModelService, FoldersViewModelService]
})

export class ViewModelsModule {}
