import { Component, ViewEncapsulation, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';

import { SortableTableRowModel, SortableTableColumnModel } from '@ic/component-lib/src/components/modules/sortable-table/models';

import { Deferred } from 'components/common/objects/deferred.object';

import * as pubsub from 'pubsub-js';

@Component({
  selector: 'ic-drilldown-table',
  templateUrl: './drilldown-table.component.html',
  encapsulation: ViewEncapsulation.None
})

export class DrilldownTableComponent implements OnInit, OnDestroy {

  @Input() benchmarks: any; // tslint:disable-line no-any
  @Input() loading: any; // tslint:disable-line no-any
  @Input() rows: SortableTableRowModel[] = [];
  @Input() table: any; // tslint:disable-line no-any
  @Input('columns') visibleColumns: SortableTableColumnModel[] = [];
  @Output() onUpdate = new EventEmitter<string>();

  private abort!: Deferred<{}>;
  private sortOrder = {
    asc: 'asc',
    desc: 'desc'
  };

  drilldownSkipLimit: number = 50000;
  itemsPerPage: string = '10';

  constructor(
  ) {
  }

  ngOnInit() {
    this.loadValues();
  }

  ngOnDestroy() {
    if (this.abort && this.abort.resolve) {
      this.abort.resolve();
    }
  }

  private loadValues() {
    this.abort = new Deferred();
  }

  firstPage() {
    if (this.table.isFirstPage()) {
      return;
    }

    this.table.goToFirstPage();
    this.onUpdate.emit('table.update');
    pubsub.publish('drilldown-table.update', 'table.update');
  }

  lastPage() {
    if (this.table.isLastPage()) {
      return;
    }

    this.table.goToLastPage();
    this.onUpdate.emit('table.update');
    pubsub.publish('drilldown-table.update', 'table.update');
  }

  nextPage() {
    if (this.table.isLastPage()) {
      return;
    }

    this.table.goToNextPage();
    this.onUpdate.emit('table.update');
    pubsub.publish('drilldown-table.update', 'table.update');
  }

  prevPage() {
    if (this.table.isFirstPage()) {
      return;
    }

    this.table.goToPreviousPage();
    this.onUpdate.emit('table.update');
    pubsub.publish('drilldown-table.update', 'table.update');
  }

  setPageSize(newValue: string) {
    this.table.setPageSize(Number(newValue));
    this.onUpdate.emit('table.update');
    pubsub.publish('drilldown-table.update', 'table.update');
  }

  sort(column: SortableTableColumnModel) {
    if (column.name === this.table.sortBy) {
      this.table.sortOrder = this.table.sortOrder === this.sortOrder.asc ? this.sortOrder.desc : this.sortOrder.asc;
    } else {
      this.table.sortOrder = column.type === 'number' ? this.sortOrder.desc : this.sortOrder.asc;
      this.table.sortBy = column.name;
    }

    this.onUpdate.emit('table.update');
    pubsub.publish('drilldown-table.update', 'table.update');
  }

}