import { ChartImageGeneratorService } from '../chart-image-generator/chart-image-generator.service';
import { flattenDeep, assign, extend, isUndefined, has, isArray } from 'lodash';
import { FormatterService } from 'components/ui/filters/formatter/formatter.service';
import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import {Observable, forkJoin, of} from 'rxjs';
import { PdfService } from './pdf.service';
import { ReportModel } from 'components/common/interfaces/report.model';
import { TranslateService } from '@ngx-translate/core';
import { TabReportModel, BucketModel, SubBucketModel } from 'pages/tab-report/interfaces/tab-report.model';
import { TabReportFiltersModel } from 'pages/tab-report/interfaces/tab-report-filters.model';
import { ReportTileModel } from 'components/common/interfaces/report-tile.model';

const text = PdfService.genText;

@Injectable()
export class ReportPdfGeneratorService {

  constructor(
    private chartGenerator: ChartImageGeneratorService,
    private formatterService: FormatterService,
    private pdf: PdfService,
    private translate: TranslateService
  ) {
    this.pdf.setupPdfMakeFonts();
  }

  async downloadReportPdf(report: ReportModel|TabReportModel) {
    return this.getPdfDocument(this.getPdfContent(report))
      .then((pdf) => this.pdf.downloadPdf(pdf, report && report.title));
  }

  async reportToPdfBase64(report: ReportModel|TabReportModel): Promise<string> {
    return this.getPdfDocument(this.getPdfContent(report))
      .then((pdf) => this.pdf.pdfToBase64(pdf));
  }

  // Document generator //

  private getPdfContent(report: ReportModel|TabReportModel) {
    if (has(report, 'reports')) {
      return this.getTabReportPdf(report as TabReportModel);
    } else {
      return this.getLegacyReport(report as ReportModel);
    }
  }

  private getPdfDocument(contentPromise: Promise<Array<Object>>): Promise<Object> {
    const header = {};
    const locale = this.translate.store.currentLang;

    return contentPromise.then((content) => PdfService.genPdfDocument(header, content, this.getFooter, locale));
  }

  // Report generators //

  private getTabReportPdf(tabReport: TabReportModel): Promise<Array<Object>> {
    const pdf: Array<{}> = [];

    pdf.push(text(tabReport.title, 'h1Padded'));

    if (tabReport.description) {
      pdf.push(text(tabReport.description, 'description'));
    }

    pdf.push(...this.getTabReportFiltersPdf(tabReport.filters!));

    return forkJoin(
        tabReport.tabs$!.map((tabObservable) => tabObservable.pipe(mergeMap(this.getTabPdf.bind(this))))
      ).pipe(
        map((tabs) => pdf.concat(flattenDeep(tabs))),
      ).toPromise();
  }

  private getLegacyReport(tab: ReportModel): Promise<Array<Object>> {
    const pdf: Array<Object> = [];
    const dominoTiles = tab.tiles.filter((tile) => tile.tileGroup === 'domino');
    const tableTiles = tab.tiles.filter(tile => tile.tileGroup === 'table-list' || tile.tileType === 'alma-analysis-list');
    const visTiles = tab.tiles.filter(tile => (tile.tileGroup === 'explore' || tile.tileGroup === 'rich') && tile.tileType !== 'alma-analysis-list');

    pdf.push(text(tab.title, 'h1'));
    if (!isUndefined(tab.subTitle)) {
      pdf.push(text(tab.subTitle, 'filter'));
    }

    return of(pdf).pipe(
      mergeMap((pdfs: Array<Object>) => { // Add domino tiles
        if (dominoTiles.length > 0) {
          const dominoTiles$: Array<Observable<Object>> = dominoTiles.map(this.getDominoTilePdf.bind(this));

          return this.getDominosList(dominoTiles$).pipe(
            map((tiles) => pdfs.concat([tiles]))
          );
        }

        return of(pdfs);
      }),
      mergeMap((pdfs: Array<Object>) => { // Add chart tiles
        if (visTiles.length > 0) {
          return forkJoin(visTiles.map(this.getVisTilePdf.bind(this))).pipe(
            map((tiles) => pdfs.concat(tiles))
          );
        }

        return of(pdfs);
      }),
      mergeMap((pdfs: Array<Object>) => { // Add table list tiles
        if (tableTiles.length > 0) {
          return forkJoin(tableTiles.map(this.getTableListTilePdf.bind(this))).pipe(
            map((tiles) => pdfs.concat(tiles))
          );
        }

        return of(pdfs);
      })
    ).toPromise();
  }

  // Section generators //

  private getFooter(currentPage: number, pageCount: number) {
    const currentYear = (new Date()).getFullYear();
    const link = {text: 'InCites', link: 'http://incites.clarivate.com/', alignment: 'left', margin: [20, 0, 20, 0]};
    const page = {text: `${currentPage} of ${pageCount}`, alignment: 'center', margin: [10, 0, 10, 0], fontSize: 12, color: 'black'};
    const copyright = {text: `© ${currentYear} Clarivate`, link: 'http://clarivate.com/', alignment: 'right', margin: [20, 0, 20, 0]};

    return {
      layout: 'noBorders',
      table: {widths: ['*', '*', '*'], body: [[link, page, copyright]]},
      style: {color: 'gray', fontSize: 10}
    };
  }

  private getTabPdf(tab: BucketModel): Observable<Array<Object>> {
    const pdf: Array<Object> = [];

    pdf.push(text(tab.title, 'h2', 'before'));

    return of(pdf).pipe(
      mergeMap((pdfs) => {
        if (tab.subBuckets && isArray(tab.subBuckets)) {
          const subBucketsPdf$ = tab.subBuckets.map(subBucket => this.getSubBucketPdf(subBucket, tab.tiles));

          return forkJoin(subBucketsPdf$).pipe(
            map((buckets) => pdfs.concat(buckets))
          );
        }
        return of([]);
      })
    );
  }

  private getTabReportFiltersPdf(filters: TabReportFiltersModel): Array<Object> {
    let values = '';
    if (filters.select.configs && filters.select.configs.length === 1) {
      values = `${filters.select.config.inputLabel}: ${filters.select.values.map((val) => val.label).join(', ')}`;
    } else if (filters.select.configs && filters.select.configs.length > 1) {
      values = filters.select.configs.reduce((acc, config, index) => {
        if (config.values && config.values.length > 0 ) {
          acc += `${index === 0 || acc === '' ? '' : ' | '}${config.inputLabel}: ${config.values.map((val) => val.label).join(', ')}`;
        }
        return acc;
      }, '');
    }

    const period = `${!values ? '' : '|'} ${this.translate.instant('report.Date range')} ${filters.period.values.start} - ${filters.period.values.end}`;
    const esci = filters.esci ? `| ${this.translate.instant('report.Include ESCI documents')}` : '';
    const fltr = `${values} ${period} ${esci}`;

    return [text(fltr, 'filter')];
  }

  private getSubBucketPdf(subBucket: SubBucketModel, tiles: ReportTileModel[]) {
    const pdf: Array<Object> = [];
    const tilesPdf$ = subBucket.tilesIds.map((tileId) => tiles.find((tile) => tile.id === tileId))
      .filter((tile) => !isUndefined(tile))
      .map((tile) => this.getGetTilePdf(tile as ReportTileModel));

    pdf.push(text(subBucket.name, 'h3'));

    return forkJoin(tilesPdf$).pipe(
      map((tilesPdf) => pdf.concat(tilesPdf))
    );
  }

  // Tile converters //

  private getGetTilePdf(tile: ReportTileModel): Observable<Object> {
    switch (tile.tileGroup) {
      case 'domino':
        return this.getDominoTilePdf(tile);
      case 'table-list':
        return this.getTableListTilePdf(tile);
      case 'explore' :
        if (tile.tileType === 'alma-analysis-list') {
          return this.getTableListTilePdf(tile);
        } else {
          return this.getVisTilePdf(tile);
        }
      default:
        return this.getVisTilePdf(tile);
    }
  }

  private getDominoTilePdf(tile: ReportTileModel): Observable<Object> {
    return tile.data$!.pipe(
      map((data) => ({ title: tile.title, subtitle: tile.subtitle, tileType: tile.tileType, value: data.value })),
      map((tl) => {
        const subTitle = tl.subtitle ? ' ' + tl.subtitle : '';
        const value: string = this.formatterService.format(tl.value.value, {type: 'number', unit: tl.tileType});

        return {
          text: [text(<string>tl.title, 'h4'), subTitle, ' = ', text(value, 'value')],
          style: 'domino'
        };
      })
    );
  }

  private getVisTilePdf(tile: ReportTileModel): Observable<Object> {
    return tile.data$!.pipe(
      map(data => extend(tile, data)),
      mergeMap(data => tile.noData ? of('noData') : this.chartGenerator.tileToPngBase64(data)),
      map(base64Image => PdfService.chartToPdf(tile, base64Image))
    );
  }

  private getTableListTilePdf(tile: ReportTileModel): Observable<Object> {
    return tile.data$!.pipe(
      mergeMap((tileData) => {
        if (tile.subscribedCountParams) {
          // @ts-ignore
          return tile.subscribedCount.pipe(map((data) => {
            // @ts-ignore
            tile.sCount = data.totalItems;
            return assign(tile, tileData);
          }));
        } else {
          return of(assign(tile, tileData));
        }
      }),
      map((tileData) => PdfService.tableToPdf(tileData as ReportTileModel)));
  }

  private getDominosList(lines: Array<Observable<Object>>): Observable<Object> {
    return forkJoin(lines).pipe(
      map((strings) => ({type: 'none', margin: [0, 10, 0, 10], ul: strings}))
    );
  }
}
