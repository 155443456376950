<cl-overlay-modal>
  <cl-overlay-modal-content [modalTitle]="'analytics.my-folders.Create New Report' | translate">
    <form class="cl-form" [formGroup]="createForm" (ngSubmit)="onSubmit()">
      <fieldset class="cl-form__fieldset cl-form__fieldset--stacked">
        <legend class="visually-hidden">{{ 'analytics.my-folders.Create New Report' | translate }}</legend>
        <div class="cl-form__field">
          <label class="cl-form-label">
            {{ 'analytics.my-folders.Title' | translate }}
            <input class="cl-form-text" type="text" formControlName="title" />
          </label>          
        </div>
        <div class="cl-form__actions">
          <button type="submit" [disabled]="!createForm.valid" class="cl-btn cl-btn--primary">
            {{ 'popup.Done' | translate }}
          </button>
        </div>
      </fieldset>
    </form>
  </cl-overlay-modal-content>
</cl-overlay-modal>