import * as angular from 'angular';
import 'angular-cookies';
import 'angular-sanitize';
import 'angular-translate';
import 'angular-translate-loader-static-files';

export const commonModule = angular.module('incitesApp.common', [
  'ngCookies',
  'ngSanitize',
  'pascalprecht.translate'
]);
