import { Inject, Injectable } from '@angular/core';
import { forOwn } from 'lodash';
import { downgradeInjectable } from '@angular/upgrade/static';

import { WindowRefService } from './../../window/window-ref.service';
import { commonModule } from './../../../../angularjs/common/common.module';

@Injectable()
export class LocalStorageService {
  storage: any = {}; // tslint:disable-line
  constructor(private windowRef: WindowRefService,
    @Inject('LocalStorage') private localStorage: WindowLocalStorage
  ) {
    if (this.localStorage) {
      this.storage = this.windowRef.nativeWindow().localStorage;
    } else {
      let localStorageVar: any = {}; // tslint:disable-line
      this.storage = Object.assign(this.storage, {
        getItem(key: string): string {
          return localStorageVar[key];
        },
        setItem(key: string, value: any): void { // tslint:disable-line
          localStorageVar[key] =
            value && value !== null ? value.toString() : value;
        },
        clear(): void {
          localStorageVar = {};
        },
        serialize(): any { // tslint:disable-line
          let serialized: any = []; // tslint:disable-line
          forOwn(localStorageVar, function(value, key) {
            serialized.push({
              key: key,
              value: value
            });
          });
          return serialized;
        }
      });
    }
  }

  getItem(key: string) {
    return this.storage.getItem(key);
  }

  setItem(key: string, value: string) {
    this.storage.setItem(key, value);
  }

  serialize() {
    let serialized: any = []; // tslint:disable-line
    if (this.localStorage) {
      for (let i = 0; i < this.storage.length; i++) {
        serialized.push({
          key: this.storage.key(i),
          value: this.storage.getItem(this.storage.key(i))
        });
      }
    } else {
      serialized = this.storage.serialize();
    }
    return serialized;
  }

  clear() {
    this.storage.clear();
  }
}

// Register downgraded Angular providers with this AngularJS module.
commonModule.factory('localStorage', downgradeInjectable(LocalStorageService));