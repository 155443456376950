import { Component, ViewEncapsulation } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslateService } from '@ngx-translate/core';
import { DocumentRefService } from './../../../common/document/document-ref';
import { ajsAppModule  } from '../../../../angularjs/app/app.module.ajs';

@Component({
  selector: 'ic-skip-link',
  templateUrl: './skip-link.component.html',
  encapsulation: ViewEncapsulation.None
})

export class SkipLinkComponent {

  constructor(public translate: TranslateService, public documentRef: DocumentRefService) {}

  goMain() {
    let mainElem = <HTMLElement> this.documentRef.nativeDocument().querySelector('main');
    mainElem.scrollIntoView();
    mainElem.tabIndex = -1;
    mainElem.focus();
  }
}

// Downgrade component for use in AngularJS template
ajsAppModule.directive(
  'icSkipLink',
  downgradeComponent({ component: SkipLinkComponent }) as angular.IDirectiveFactory
);