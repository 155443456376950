// @deprecated since version 3.1.2. Use ic-analysis-chart-export.
import { AuthenticationService } from 'components/auth/services/authentication/authentication.service';
import { BrowserDetectionService } from 'components/common/services/browser-detection/browser-detection.service';
import { Component, ViewEncapsulation, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DiagramModel } from 'components/common/interfaces/diagram.model';
import { DiagramPdfGeneratorService } from 'components/common/services/pdf-generator/diagram-pdf-generator.service';
import { LocaleService } from 'components/common/services/locale/locale.service';
import { PdfServerRendererService } from 'components/rest/services/pdf-server-renderer/pdf-server-renderer.service';
import { StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { UserStorageService } from 'components/analytics/services/user-storage/user-storage.service';
import { DropdownItemModel } from 'components/common/components/dropdown/dropdown.component';
import { PopoverModalComponent } from '@ic/component-lib/src/components/modules/popover-modal/popover-modal.component';
import { PngGeneratorService } from 'components/common/services/png-generator/png-generator.service';

@Component({
  selector: 'ic-diagram-export',
  templateUrl: './diagram-export.component.html',
  encapsulation: ViewEncapsulation.None
})

export class DiagramExportComponent {
  @Input() datasetTitle!: string;
  @Input() disabled!: boolean;
  @Input() filters!: { summary: any[] }; // tslint:disable-line:no-any
  @Input() indicators!: string[];
  @Input() options!: { title: string; toPng?: (range?: number) => Promise<string> };
  @Input() tile!: { title: string, subtitle: string };
  @Input() serialize!: () => {};
  @Input() toPngImage!: (range?: number) => Promise<string>;
  @ViewChild(PopoverModalComponent, { static: true }) popover!: PopoverModalComponent;

  private fileName: string = '';
  public dropdownMenu!: DropdownItemModel[];
  public exporting: boolean = false;
  public isIE11: boolean;
  public pdfBase64: string = '';

  constructor(
    private authenticationService: AuthenticationService,
    private browserDetectionService: BrowserDetectionService,
    private cd: ChangeDetectorRef,
    private locale: LocaleService,
    private pdfGenerator: DiagramPdfGeneratorService,
    private pdfServerRenderer: PdfServerRendererService,
    private stateService: StateService,
    private translate: TranslateService,
    private userStorage: UserStorageService,
    private pngGenrator: PngGeneratorService
  ) {
    this.isIE11 = this.browserDetectionService.isIE11();

    const downloadImage = { 
      name: 'downloadPng', 
      title: 'report.tile.Download as image',
      icon: 'download',
      execute: this.downloadPng.bind(this)
    };
    const downloadPdf = { 
      name: 'downloadPdf', 
      title: 'report.tile.Download as Pdf',
      icon: 'download',
      execute: this.exportDiagram.bind(this)
    };

    this.dropdownMenu = [downloadImage, downloadPdf];
  }

  export() {
    return this.isIE11 ? this.legacyExport() : this.exportDiagram();
  }

  downloadPng() {
    this.setToPng();

    return this.toPngImage(1.2)
      .then((base64) => this.setDiagramDetails(base64))
      .then((diagramDetails) => this.pngGenrator.canvasGraph(diagramDetails, this.getFilename()))
      // @ts-ignore
      .finally(() => this.cd.detectChanges());
  }

  diagramToBase64() {
    return this.prepareDiagram()
      .then((diagram) => this.pdfGenerator.diagramToPdfBase64(diagram))
      .then((base64) => this.pdfBase64 = base64)
      .catch(err => console.error(err));
  }

  private exportDiagram() {
    this.showLoading();

    return this.prepareDiagram()
      .then((diagram) => this.pdfGenerator.downloadDiagramPdf(diagram, this.getFilename()))
      .catch(err => console.error(err))
      // @ts-ignore
      .finally(() => {
        this.hideLoading();
        this.cd.detectChanges();
      });
  }

  legacyExport() {
    this.showLoading();

    return this.authenticationService.authenticate().then((user) => {
      if (!user || !user.name) {
        this.authenticationService.signOut();
        return ;
      }

      const { entity, datasetId, filterAggId, queryDataCollection } = this.stateService.params;

      this.saveStateParams(entity, datasetId);
  
      const url = this.extendUrlWithLocale(this.stateService.href(
        'app.explore',
        {entity, datasetId, filterAggId, queryDataCollection},
        {inherit: false, absolute: true}
      ));
      this.fileName = this.getFilename();
  
      return this.pdfServerRenderer.fetch(url, this.fileName);
    })
    // @ts-ignore
    .finally(() => {
      this.hideLoading();
      this.cd.detectChanges();
    });
  }

  private prepareDiagram(): Promise<DiagramModel> {
    this.setToPng();

    return this.toPngImage()
      .then((base64) => {
        return this.setDiagramDetails(base64);
      });
  }

  private setDiagramDetails(base64: string): DiagramModel {
    return {
      title: this.tile && this.tile.title || this.options.title,
      subtitle: this.tile && this.tile.subtitle,
      filters: this.filters,
      datasetTitle: this.datasetTitle,
      indicatorsTitles: this.indicators ? this.indicators : ['Web of Science Documents'],
      base64: base64
    };
  }

  private getFilename() {
    return this.tile ? 
      this.tile.title : `${this.datasetTitle} ${this.getLocalizedTitle(this.stateService.params.entity)} ${this.options.title}`;
  }

  private getLocalizedTitle(entity: string) {
    const translate = (text: string) => this.translate.instant(text);

    switch (entity) {
      case 'organization':
        return translate('analytics.export.Organizations');
      case 'person':
        return translate('analytics.export.Researchers');
      case 'region':
        return translate('analytics.export.Locations');
      case 'subject':
        return translate('analytics.export.Research Areas');
      case 'journal':
        return translate('analytics.export.Journals');
      case 'funder':
        return translate('analytics.export.Funder');
      default:
        return '';
    }
  }

  private extendUrlWithLocale(url: string): string {
    if (this.locale.id !== 'en') {
      const parts = url.split('#');
      url = `${parts[0]}${this.locale.id}/#${parts[1]}`;
    }

    return url;
  }

  private saveStateParams(entity: string, datasetId: string) {
    const lastExploreState = this.userStorage.loadExploreState(entity, datasetId);
      
    this.userStorage.saveExploreState(this.stateService.params.entity, this.serialize());

    if (lastExploreState) {
      this.userStorage.saveExploreState(entity, lastExploreState);
    }
  }

  private showLoading() {
    this.exporting = true;
    this.popover.openPopover();
  }

  private hideLoading() {
    this.popover.closePopover();
    this.exporting = false;
  }

  private setToPng() {
    if (this.options.toPng) {
      this.toPngImage = this.options.toPng;
    }
  }

}