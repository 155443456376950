import { Component, ViewEncapsulation } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

import { cloneDeep } from 'lodash';

import { NotificationsRepositoryService } from
'./../../../rest/services/notifications-repository/notifications-repository.service';
import { NotificationModel } from './../../../rest/services/notifications-repository/notifications-repository.model';

import { ajsAppModule } from './../../../../angularjs/app/app.module.ajs';

@Component({
  selector: 'notifications-editor-component',
  templateUrl: './notifications-editor.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NotificationsEditorComponent {
  currentNotification!: NotificationModel;
  search: NotificationModel;
  notifications: NotificationModel[] = [];
  msg!: NotificationModel;
  validLongDatePattern = /^(\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2})$/;
  validShortDatePattern = /^(\d{2}\/\d{2}\/\d{4})$/;

  constructor(private notificationsRepository: NotificationsRepositoryService) {
    this.search = {
      id: '',
      startDate: '',
      endDate: '',
      title: '',
      messageBody: '',
      lastModifiedUser: ''
    };
  }

  state = 'list';
  stateHeaders: any = {  // tslint:disable-line
    find: 'Find notifications',
    add: 'Add',
    edit: 'Edit',
    list: ''
  };

  changeState(newSate: string) {
    setTimeout(() => (this.state = newSate), 0);
  }

  getStateHeaders(): string {
    return this.stateHeaders[this.state];
  }
  postMsg() {
    this.msg = cloneDeep(this.currentNotification);
    switch (this.state) {
      case 'add':
        if (this.msg!.id) {
          delete this.msg.id;
        }
        this.notificationsRepository.add(this.msg).then(() => {
          setTimeout(() => (this.state = 'list'), 0);
        });
        break;
      case 'edit':
        this.notificationsRepository.edit(this.msg).then(() => {
          setTimeout(() => (this.state = 'list'), 0);
        });
        break;
      default:
        break;
    }
  }

  add() {
    let startD = new Date();
    let endD = new Date();

    endD.setMonth(startD.getMonth() + 1);
    this.currentNotification = {
      startDate: this.notificationsRepository.formatDate(startD, 'date time'),
      endDate: this.notificationsRepository.formatDate(endD, 'date time'),
      title: '',
      messageBody: ''
    };
    setTimeout(() => (this.state = 'add'), 0);
  }

  findMsg() {
    this.search = {
      id: '',
      startDate: '',
      endDate: '',
      title: '',
      messageBody: '',
      lastModifiedUser: ''
    };
    setTimeout(() => (this.state = 'find'), 0);
  }

  applySearch() {
    let searchCopy = cloneDeep(this.search);

    if (searchCopy.startDate) {
      searchCopy.startDate += ' 00:00:00';
    }

    if (searchCopy.endDate) {
      searchCopy.startDate += ' 00:00:00';
    }

    this.notificationsRepository.find(searchCopy)
      .then((msgs) => { 
        this.notifications = msgs;
        setTimeout(() => (this.state = 'list'), 0);
      });
  }

  editMsg(msg: NotificationModel) {
    this.currentNotification = msg;
    setTimeout(() => (this.state = 'edit'), 0);
  }

  deleteMsg(id: string) {
    this.notificationsRepository.delete(id).then(() => {
      this.notifications = this.notifications.filter(item => item.id !== id);
      this.state = 'list';
    });
  }

  readLastMessages() {
    let d = new Date();

    const startDate = new Date(d.setMonth(d.getMonth() - 2));
    const endDate = new Date(d.setMonth(d.getMonth() + 2));

    let searchRequest: NotificationModel = {
      id: undefined,
      startDate: this.notificationsRepository.formatDate(
        startDate,
        'date time'
      ),
      endDate: this.notificationsRepository.formatDate(endDate, 'date time'),
      title: '',
      messageBody: '',
      lastModifiedUser: undefined
    };

    this.notificationsRepository.find(searchRequest).then((msgs: any[]) => { // tslint:disable-line
      this.notifications = msgs;
      this.state = 'list';
    });
  }
}

// Downgrade component for use in AngularJS template
ajsAppModule.directive('notificationsEditorComponent', downgradeComponent({
  component: NotificationsEditorComponent
}) as angular.IDirectiveFactory);
