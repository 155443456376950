import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { extend } from 'lodash';

import { FolderExtModel } from 'components/common/interfaces/folder.interface';
import { FolderModel } from '@ic/component-lib/src/components/modules/folders/folders.model';
import { ReportModel } from 'components/common/interfaces/report.model';

import { FoldersRepositoryService } from 'components/rest/services/folders-repository/folders-repository.service';
import { FoldersViewModelService } from 'components/view-models/services/folders-view-model/folders-view-model.service';
import { OverbarService } from 'components/overbar/services/overbar/overbar.service';
import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';
import {FolderItemModel} from 'components/rest/services/folders-repository/models';
import { StateService } from '@uirouter/core';

@Injectable()
export class FoldersService {

  constructor(
    public foldersRepository: FoldersRepositoryService,
    public foldersViewModel: FoldersViewModelService,
    public overbar: OverbarService,
    public reportsRepository: ReportsRepositoryService,
    public translate: TranslateService,
    private stateService: StateService,
  ) {}

  getFolderById(folders: FolderExtModel[], folderId: number|'root'): FolderExtModel|null {
    const folderHasSubfolders = (folder: FolderExtModel): boolean|undefined => {
      return folder.folders && folder.folders.length > 0;
    };

    const reduceFolders = (fldrs: FolderExtModel[], test: Function, initVal: FolderExtModel|null = null): FolderExtModel|null => {
      return fldrs.reduce((result, fldr) => {
        if (test(fldr)) {
          return fldr;
        } else if (folderHasSubfolders(fldr)) {
          return reduceFolders(fldr.folders!, test, result);
        } else {
          return result;
        }
      }, initVal);
    };

    return reduceFolders(folders, (folder: FolderExtModel) => {
      return folder.id === folderId;
    });
  }

  loadReportsAndFolders(report: Partial<ReportModel>, reportFolders: FolderModel[], reload: boolean = false) {
    if (!reload && reportFolders && reportFolders.length > 0) {
      return reportFolders;
    }

    return Promise.all([
      this.reportsRepository.getList({ group: 'user', sharedWithMe: 'false' }),
      this.foldersRepository.getList(),
      this.stateService.params.reportId !== 'dashboard' ?  this.reportsRepository.get('dashboard') : Promise.resolve({} as ReportModel)
    ]).then((results) => {
      const [reports, folders, dashboard] = results;
      return this.mergeResults(report, { reports, folders, dashboard });
    }).catch((error) => {
      const message = error.data && error.data.message;

      if (message) {
        this.overbar.notifyError(message);
      }
    });
  }

  private mergeResults(report: Partial<ReportModel>, results: { folders: FolderExtModel[], reports: ReportModel[], dashboard: ReportModel }) {
    const folders = this.foldersViewModel.createRootArray(results.folders);
    let mergedItems = folders.map((item: FolderExtModel) => {
      item.type = 'folder';
      return item;
    });

    if (!report!.dashboard) {
      const dashboard = extend(results.dashboard, {
        title: this.translate.instant('report.Dashboard'),
        type: 'report'
      });

      mergedItems.push(dashboard);
    }
    results.reports.forEach((item) => {
      item.type = 'report';

      const id = item.folderId || 'root';
      let folder = this.getFolderById(mergedItems, id);

      if (folder) {
        // @ts-ignore
        folder.folders.push(item);
      }
    });

    return mergedItems;
  }

  isItemMoved(moveItemToId: number | string, item: FolderItemModel | FolderModel) {
    if (item) {
      const { type } = item;
      if (type === 'folder') return (<FolderModel>item).parent ? (<FolderModel>item)!.parent!.id as string !== moveItemToId : (<FolderModel>item).parentFolderId !== moveItemToId;
      if (type === 'dataset' || type === 'report' || type === 'groups' || type === 'journalcollection') {
        if (!(<FolderItemModel>item).folderId && moveItemToId === 'root') return false;
        return (<FolderItemModel>item).folderId !== moveItemToId;
      }
    }
    return false;
  }
}