import {Component, EventEmitter, Input, ViewEncapsulation, Output, ViewChild, ChangeDetectorRef} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OverbarService } from 'components/overbar/services/overbar/overbar.service';
import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';

import { FoldersRepositoryService } from 'components/rest/services/folders-repository/folders-repository.service';
import { FolderExtModel } from 'components/common/interfaces/folder.interface';
import { FolderViewModelService } from 'components/view-models/services/folder-view-model/folder-view-model.service';

@Component({
  selector: 'ic-create-folder',
  templateUrl: './create-folder.component.html',
  encapsulation: ViewEncapsulation.None
})

export class CreateFolderComponent {
  @Input() parentFolder: Partial<FolderExtModel> = {};
  @Output() onCreate = new EventEmitter<Partial<FolderExtModel>>();
  @ViewChild(OverlayModalComponent, { static: true }) overlayModal!: OverlayModalComponent;

  public createForm: FormGroup;
  private titleMaxLength = 150;

  constructor(
    private overbarService: OverbarService,
    private foldersRepository: FoldersRepositoryService,
    private folderViewModel: FolderViewModelService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    this.createForm = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.titleMaxLength)
      ])
    });
  }

  onSubmit() {
    this.createFolder(this.createForm.value.title, <FolderExtModel>this.parentFolder);
  }

  private createFolder(title: string, parentFolder: FolderExtModel) {
    let folder: Partial<FolderExtModel> = { title: title };

    if (parentFolder && parentFolder.parent) {
      folder.parentFolderId = parentFolder.id;
    }
    this.foldersRepository.create(folder)
      .then((fldr: FolderExtModel) => {
        folder = this.folderViewModel.getFolderViewModel(fldr, parentFolder);
        this.overbarService.notify(this.translate.instant('notifications.Folder Created', { folder: fldr.title }));
        this.onCreate.emit(folder);
      })
      .catch((error: Error) => {
        console.error(error);
      }).then(() => {
        this.closeModal();
      });
  }

  openModal() {
    this.createForm.controls['title'].setValue(
      this.translate.instant('analytics.my-folders.New Folder'),
      {onlySelf: true}
    );
    this.overlayModal.openModal();
  }

  closeModal() {
    this.overlayModal.closeModal();
    this.cd.detectChanges();
  }

}