import {Component, OnInit, Input, DoCheck, ViewChildren, QueryList} from '@angular/core';
import { cloneDeep, isEqual, forEach } from 'lodash';

import { downgradeComponent } from '@angular/upgrade/static';
import { ajsAppModule } from 'angularjs/app/app.module.ajs';

import { utilities } from '../../../../angularjs/utilities/utilities';
import { StateService } from '@uirouter/core';
import { ReportModel } from 'components/common/interfaces/report.model';
import { ReportTileModel } from 'components/common/interfaces/report-tile.model';
import { environmentCommonConfig } from 'components/app/services/environment/environment-common.config';
import { PopoverModalComponent } from '@ic/component-lib/src/components/modules/popover-modal/popover-modal.component';

@Component({
 selector: 'ic-table-list-tile',
 templateUrl: './table-list-tile.component.html'
})
export class TableListTileComponent implements DoCheck, OnInit {
  @Input() data: Partial<ReportTileModel> = {};
  @Input() report: Partial<ReportModel> = {};

  private previousData: Partial<ReportTileModel> = {};

  public tableIndicators: { [index: string]: string } = {};
  public tableValues: { [index: string]: string|number }[] = [];
  @ViewChildren(PopoverModalComponent) popover!:  QueryList<PopoverModalComponent>;
  constructor(private stateService: StateService) {}

  ngOnInit() {
    this.setLoading();
  }

  ngDoCheck() {
    if (this.data && this.data.value && !isEqual(this.data, this.previousData)) {
      this.previousData = cloneDeep(this.data);
      this.onDataChange();
    }
  }

  drilldown() {
    this.stateService.go('app.drilldown', {
      reportId: this.report.id,
      tileId: this.data.id,
      slug: this.data.safeTitle!(),
      group: this.data.tileGroup
    });
  }

  private onDataChange() {
    if (this.data.value.length > 0) {
      let visOptions = utilities.fromJson(this.data.vis!);
      let series = visOptions.series;
      let value = this.data.value;

      this.tableValues = value.map((currentValue: { [index: string]: string|number }) => {
        let seriesObj: { [index: string]: string|number } = {};
        forEach(series, (elem, key) => {
          seriesObj[key] = currentValue[elem.toLowerCase()];
        });

        forEach(environmentCommonConfig.hiddenDrillDownIndicators, (indicator) => {
          if (currentValue.hasOwnProperty(indicator)) {
            seriesObj[indicator] = currentValue[indicator];
          }
        });
        return seriesObj;
      });

      let indicators = this.data.indicators;
      this.tableIndicators = forEach(series, (elem, key, obj) => {
        let indicator = indicators!.find((ind) => ind.name === elem.toLowerCase());

        if (indicator) {
          obj[key] = indicator.title;
        }
      });
    }

    this.unsetLoading();
  }

  private setLoading() {
    // this.myRenderer.addClass(this.parentElement, 'loading');
  }

  private unsetLoading() {
    // this.myRenderer.removeClass(this.parentElement, 'loading');
  }

  hasHotOrHighlyCitedPapersField(firstRow: { [index: string]: string|number }) {
    return !!Object.keys(firstRow).find((key) => environmentCommonConfig.hiddenDrillDownIndicators.includes(key)) &&
      !this.tableValues.every(value =>
        parseFloat(value[environmentCommonConfig.hiddenDrillDownIndicators[0]] as string) === 0 &&
        parseFloat(value[environmentCommonConfig.hiddenDrillDownIndicators[1]] as string) === 0);
  }

  showHideValues(line: {[p: string]: string | number}, esibimonthlyType: string, open: boolean, index: number) {
    if (line[esibimonthlyType]) {

      let popover = this.popover.find((popoverObj) => { return popoverObj.elRef.nativeElement.id === esibimonthlyType + index; });
      if (popover?.isOpen && !open) {
        popover.closePopover();
      } else if (!popover?.isOpen && open) {
        popover!.openPopover();
      }
    }
  }

}

// Downgrade component for use in AngularJS template
ajsAppModule.directive(
  'icTableListTile',
  downgradeComponent({ component: TableListTileComponent }) as angular.IDirectiveFactory
);