import { registerLocaleData } from '@angular/common';

import { LocaleService } from '../../../common/services/locale/locale.service';

const localeService = new LocaleService();

export function getLocaleId() {
  const id = localeService.id;

  return id;
}

export function localeInitializerFactory() {
  const id = getLocaleId();

  if (id !== localeService.fallbackLang) {
    registerLocale(id);
  }

  return id;
}

export function registerLocale(locale: string) {
  // Note: this dynamic import with magic comments causes the test build
  // to create a bundle for each of the 1048 locale files in this directory
  // import(
  //   /* webpackInclude: /(ru|zh)\.js$/ */
  //   `@angular/common/locales/${locale}.js`
  // ).then((lang) => registerLocaleData(lang.default));

  // Using explicit strings instead of a template greatly improves performance of the test build
  if (locale === 'ru') {
    import('@angular/common/locales/ru.js').then((lang) => registerLocaleData(lang.default));
  } else if (locale === 'zh') {
    import('@angular/common/locales/zh.js').then((lang) => registerLocaleData(lang.default));
  }
}