<cl-overlay-modal
  [options]="{ modifiers: 'dark-header', modalWidth: '1000px' }"
  (onClose)="onCancel(false)"
>
  <cl-overlay-modal-content
    [modalTitle]="'viewDepartment.Select or Edit Department Filter' | translate"
  >
    <div class="ic_dlm_search_bar">
      <span class="cl-form-text--icon" style="flex-grow: 1">
        <cl-text-input
          [inputId]="'searchinput'"
          #searchinput
          name="searchinput"
          [inputClass]="
            'cl-form-text cl-form-text--underline cl-form-text--large'
          "
          (input)="updateSearch($event)"
          [inputPlaceholder]="'eg: University of Toronto'"
        ></cl-text-input>
      </span>
      <cl-icon
        class="cl-dept_close"
        [iconId]="'close-small'"
        [size]="10"
        (click)="clear()"
        *ngIf="searching"
      ></cl-icon>
      <cl-icon
        [iconId]="'search'"
        [color]="'color-gray80'"
        [size]="16"
      ></cl-icon>
    </div>
    <div class="ic_department_modal_pop_up_buttons">
      <cl-tree-view
        *ngIf="treeOptions && treeOptions.length > 0"
        [style.display]="isLoading || searching ? 'none' : 'block'"
        [options]="treeOptions"
        [values]="treeValues"
        (onSelect)="onTreeValuesChange($event)"
        [configs]="treeViewConfigs"
        (onToggleChildren)="onToggleChildren($event)"
      ></cl-tree-view>
      <div *ngIf="!isLoading && searching">
        <ic-dlm-search-rows
          *ngFor="let option of searchResponse; trackBy: optionTracker"
          [option]="option"
          [searchterm]="searchTerm"
        >
        </ic-dlm-search-rows>
      </div>
      <div *ngIf="isLoading" class="ic-analysis__loading">
        <cl-loading-indicator
          [label]="'Loading Departments...'"
        ></cl-loading-indicator>
      </div>
    </div>
    <div class="cl-form__fieldset cl-form__fieldset">
      <div class="cl-form__actions">
        <button
          type="button"
          class="cl-btn cl-btn--secondary"
          (click)="onCancel()"
        >
          {{ "popup.Cancel" | translate }}
        </button>
        <button
          type="submit"
          class="cl-btn cl-btn--primary"
          (click)="onUpdateFilter()"
          [disabled]="!isFilterChanged()"
        >
          {{ "analysis.edit Filter.Update Filter" | translate }}
        </button>
      </div>
    </div>
  </cl-overlay-modal-content>
</cl-overlay-modal>
