import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';

@Component({
     selector: 'ic-alert-content',
  templateUrl: './alert-content.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AlertComponent {
  @ViewChild(OverlayModalComponent, { static: true }) overlayModal!: OverlayModalComponent;
  @Input() message: string = '';
  constructor(public translate: TranslateService) {
    
  }
  openModal() {
    this.overlayModal.openModal();
  }
  closeModal() {
    this.overlayModal.closeModal();
  }

}