<a
  class="ic-global-header__top__nav__item__link"
  [href]="'/incites-app/redirect/signin' | redirectLink"
  *ngIf="!currentUser"
>
  {{ "header.Sign In" | translate }}
</a>

<cl-popover-modal
  *ngIf="currentUser"
  class="cl-popover-modal--top-nav cl-popover-modal--top-nav__header"
  [options]="{ isContext: true }"
>
  <cl-popover-modal-button
    [buttonClass]="
      'cl-btn cl-btn--text cl-btn--link cl-btn--with-icon cl-context-menu__btn'
    "
  >
    <ng-container *ngIf="screenWidth > 1315">
      <span class="cl-btn__text" *ngIf="!IS_CDX_HEADER_ON">{{currentUser?.firstName||currentUser?.name}}</span>
      <span class="cl-btn__text" *ngIf="IS_CDX_HEADER_ON">{{
        currentUser?.name || currentUser?.firstName
      }}</span>
    </ng-container>
    <cl-icon [iconId]="IS_CDX_HEADER_ON ? 'caret-down' : 'arrow-down'" [color]="IS_CDX_HEADER_ON ? 'color-black': 'color-white'" class="ic-logout-caret"></cl-icon>
  </cl-popover-modal-button>

  <cl-popover-modal-content>
    <ul class="cl-context-menu__list cl-context-menu-list__float">
      <li class="cl-context-menu__list__item cl-banner-header__content__item"  *ngIf="screenWidth <= 1315">
        <button
          type="text"
          class="
            cl-btn cl-btn--text cl-btn--link cl-btn--with-icon
            cl-context-menu__list__item__btn
          "
        >
          <span class="cl-btn__text" *ngIf="!IS_CDX_HEADER_ON">{{currentUser?.firstName||currentUser?.name}}</span>
          <span class="cl-btn__text" *ngIf="IS_CDX_HEADER_ON">{{
            currentUser?.name || currentUser?.firstName
          }}</span>
        </button>
      </li>
      <li
        class="cl-context-menu__list__item"
        *ngIf="currentUser?.notificationsAdmin"
      >
        <cl-overlay-modal>
          <cl-overlay-modal-button
            [buttonClass]="
              'cl-btn cl-btn--text cl-btn--link cl-btn--with-icon cl-context-menu__list__item__btn'
            "
          >
            {{ "userNotification.Notifications editor" | translate }}
          </cl-overlay-modal-button>
          <cl-overlay-modal-content
            [modalTitle]="'userNotification.Notifications editor' | translate"
          >
            <notifications-editor-component></notifications-editor-component>
          </cl-overlay-modal-content>
        </cl-overlay-modal>
      </li>

      <li class="cl-context-menu__list__item">
        <button
          type="button"
          class="
            cl-btn cl-btn--text cl-btn--link cl-btn--with-icon
            cl-context-menu__list__item__btn
          "
          (click)="logout()"
        >
          <cl-icon [iconId]="'logout'" [color]="'color-white'" class="ic-logout"></cl-icon>
          <span class="cl-btn__text ic-logout-text">{{ "header.Logout" | translate }}</span>
        </button>
      </li>
    </ul>
  </cl-popover-modal-content>
</cl-popover-modal>
