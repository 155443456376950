import * as pdfMake from 'pdfmake-lite/build/pdfmake';
import fontLoader from './font-loader.service';
import { isUndefined } from 'util';
import { Injectable } from '@angular/core';
import { ReportTileModel } from 'components/common/interfaces/report-tile.model';
import {isPlainObject} from 'lodash';

/* Styles */

export const styles = {
  centered: {alignment: 'center'},
  description: {color: '#383838', fontSize: 13, margin: [0, 4, 0, 4]},
  diagramFooter: {fillColor: '#f6f6f6', width: '*', fontSize: 10, borders: [false, false, false, false], height: 60},
  diagramHeader: {fillColor: '#f6f6f6', alignment: 'left', bold: true, fontSize: 12, margin: [0, 0, 0, 0]},
  domino: {fontSize: 12, margin: [0, 2, 0, 2]},
  filter: {color: '#383838', fontSize: 13, margin: [0, 2, 0, 4]},
  filters: {alignment: 'center', color: 'gray', fontSize: 12, margin: [0, 4, 0, 4]},
  h1: {fontSize: 26, margin: [0, 0, 0, 2]},
  h1Padded: {fontSize: 26, margin: [0, 300, 0, 2]},
  h2: {fontSize: 20, margin: [0, 0, 0, 4]},
  h3: {fontSize: 16, margin: [0, 6, 0, 0]},
  h4: {bold: true, fontSize: 12, margin: [0, 4, 0, 2]},
  noData: {color: 'gray', fontSize: 12, margin: [0, 4, 0, 6]},
  quote: {italics: true},
  small: {fontSize: 8},
  table: {fontSize: 10},
  tableH1: {alignment: 'center', bold: true, fontSize: 10},
  value: {bold: true}
};

export interface PdfText {
  text: string;
  style: string | string[];
  pageBreak: 'before' | 'after' | undefined;
}

@Injectable()
export class PdfService {

  constructor() {}

  downloadPdf(pdf: Object, fileName: string): void {
    pdfMake.createPdf(pdf).download(fileName + '.pdf');
  }

  pdfToBase64(pdf: Object): Promise<string> {
    const pdfMimeType = 'data:application/pdf;base64,';

    return new Promise((resolve) => pdfMake.createPdf(pdf).getBase64((data: string) => resolve(`${pdfMimeType},${data}`)));
  }

  setupPdfMakeFonts() {
    pdfMake.vfs = fontLoader.vfs;
  }

  /* Generators */

  static genText(text: string, style: string|string[], pageBreak?: 'before'|'after') {
    return {text, style, pageBreak};
  }

  static genTable(body: Array<Object>, width: Array<string>): {table: {}, layout: {}} {
    return {
      table: {headerRows: 1, body: body, widths: width},
      layout: {hLineColor: () => '#CCCCCC', vLineColor: () => '#CCCCCC'}
    };
  }

  static genPdfDocument(
    header: Object,
    content: Array<Object>,
    footer: Object,
    locale: string = 'en',
    pageOrientation: 'landscape'|'portrait' = 'portrait',
    pageMargins?: Array<number>,
  ): Object {
    const defaultStyle = {font: this.getDefaultFont(locale)};

    return {header, content, footer, defaultStyle, pageOrientation, pageMargins, pageSize: 'A4', styles};
  }

  static chartToPdf(tile: ReportTileModel, base64Image: string): Object {
    const unbreakable: boolean = true;
    const stack: Array<Object> = [];

    if (tile.subtitle) {
      stack.push({
        text: [PdfService.genText(<string>tile.title, 'h4'), ` ${tile.subtitle}`]
      });
    } else {
      stack.push(PdfService.genText(<string>tile.title, 'h4'));
    }

    if (base64Image === 'noData') {
      stack.push(PdfService.genText('No values', 'noData'));
    } else {

      try {
        const vis = JSON.parse(tile.vis);
        const { type } = vis;
        if ( ['bar chart', 'bar amCharts', 'line chart', 'line amCharts', 'multiIndicatorLine amCharts', 'multiIndicatorLine chart',
          'period chart', 'period amCharts'].includes(type)) {
          stack.push({image: base64Image, fit: [500, 600], alignment: 'center'});
        } else {
          stack.push({image: base64Image, fit: [500, 350], alignment: 'center'});
        }
      } catch (error) {
        console.log(error);
      }
    }

    return {unbreakable, stack, margin: [0, 10, 0, 10]};
  }

  static tableToPdf(tile: ReportTileModel): {} {
    // @ts-ignore
    const tileStack: Array<Object> = [PdfService.genText(<string>tile.subscribedCountUrl ? `${tile.sCount} ${tile.title}` : tile.title, 'h4')];
    const getIndicatorTitle = (id: string) => {
      const indicator =  tile.indicators.find(i => i.name === id);
      if (tile.tileType === 'alma-analysis-list' && indicator!.name === 'timesCited') {
        return 'Citations to the journal/books/ series';
      }
      return indicator ? indicator.title : '';
    };

    if (!tile.value || tile.value.length === 0) {
      tileStack.push(PdfService.genText('No values', 'noData'));
      return { unbreakable: true, stack: tileStack };
    }

    try {
      const body: Array<{}> = [];
      const vis = JSON.parse(tile.vis);
      let width;

      if (tile.tileType === 'alma-analysis-list') {
        width = ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
        let columnNames = vis.series.value;
        let keys = Object.keys(tile.value[0]);
        let columns: PdfText[] = columnNames.map((name: string) => {
          let indicatorName = keys.find(key => key.toLowerCase() === name.toLowerCase());
          return PdfService.genText(getIndicatorTitle(indicatorName as string), 'tableH1');
        });

        body.push(columns);

        let tileBody = tile.value.map((row: { [x: string]: string; }) => {
          return columnNames.reduce((acc: PdfText[], name: string) => {
          let indicatorName = keys.find(key => key.toLowerCase() === name.toLowerCase());
          let cell = row[indicatorName as string];
          if (isPlainObject(cell)) {
          // @ts-ignore
          acc.push(PdfService.genText(cell.value, ''));
          } else {
            if (indicatorName === 'isOAFlag') {
          cell = cell ? 'YES' : 'NO';
          }
          acc.push(PdfService.genText(cell, ''));
          }

          return acc;
          }, []);
          });

        body.push(...tileBody);
      } else {
        width = ['*', 'auto'];
        const nameId: string = vis.series.name.toLowerCase();
        const infoId: string = vis.series.info ? vis.series.info.toLowerCase() : null;
        const info1Id: string = vis.series.info1 ? vis.series.info1.toLowerCase() : null;
        const info2Id: string = vis.series.info2 ? vis.series.info2.toLowerCase() : null;
        const info3Id: string = vis.series.info3 ? vis.series.info3.toLowerCase() : null;
        const info4Id: string = vis.series.info4 ? vis.series.info4.toLowerCase() : null;
        const info5Id: string = vis.series.info5 ? vis.series.info5.toLowerCase() : null;
        const valueId: string = vis.series.value.toLowerCase();
        const value1Id: string = vis.series.value1 ? vis.series.value1.toLowerCase() : null;

        const genNameCell = (line: { [index: string]: string }) => {
          const stack: Array<Object> = [{text: isUndefined(line[nameId]) ? '' : line[nameId], bold: true}];

          if (infoId) {
            stack.push(line[infoId]);
          }
          if (info1Id) {
            stack.push(line[info1Id]);
          }
          if (info2Id) {
            stack.push(line[info2Id]);
          }
          if (info3Id) {
            stack.push(line[info3Id]);
          }
          if (info4Id) {
            stack.push(line[info4Id]);
          }
          if (info5Id) {
            stack.push(line[info5Id]);
          }

          return stack;
        };
        const genValueCell = (line: { [index: string]: string }) => {
          const stack = [PdfService.genText((typeof line[valueId] !== 'undefined') ? line[valueId] : '', 'centered')];

          if (value1Id) {
            stack.push(PdfService.genText(line[value1Id], 'centered'));
          }

          return stack;
        };

        if (value1Id) {
          body.push([getIndicatorTitle(nameId), getIndicatorTitle(valueId), getIndicatorTitle(value1Id)]);
        } else {
          body.push([PdfService.genText(getIndicatorTitle(nameId), 'tableH1'), PdfService.genText(getIndicatorTitle(valueId), 'tableH1')]);
        }

        const rows = tile.value.map((line: { [index: string]: string }) => [genNameCell(line), genValueCell(line)]);

        body.push(...rows);
      }
      tileStack.push(this.genTable(body, width));
    } catch (error) {
      console.log(error);
    }

    return { unbreakable: false, stack: tileStack };
  }

  private static getDefaultFont(_locale: string) {
    return 'Arial_Unicode_MS';
  }

}
