<ul>
  <li
    class="cl-tree-view-row cl-tree-view-row--open">

    <div class="cl-tree-view-row__top" style="padding-bottom: 1.5%">

      <input type="checkbox"
        (change)="selectOption()" [checked]="option.selected"
        class="cl-tree-view-row__checkbox"
        [disabled]="!option.hasDocs"
        />
      <button class="cl-btn cl-btn--with-icon cl-tree-view-row__label">
        <span class="cl-btn__text" [title]="option.name" [innerHTML]="option.name | highlight: searchterm"></span>
      </button>

    </div>
  </li>
</ul>