// TODO: remove demo
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslateService } from '@ngx-translate/core';

import { ajsAppModule } from '../../angularjs/app/app.module.ajs';

@Component({
  selector: 'ic-translations',
  templateUrl: './translations.component.html',
  encapsulation: ViewEncapsulation.None
})

export class TranslationsComponent implements OnInit {

  dismissBtnTxt: string = '';
  today!: Date;

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.dismissBtnTxt = this.translate.instant('notifications.Dismiss');
    this.today = new Date();
  }
}

// Downgrade component to use in AngularJS template
ajsAppModule.directive(
  'icTranslations',
  downgradeComponent({ component: TranslationsComponent }) as angular.IDirectiveFactory
);
